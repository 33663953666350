import { defineStore } from "pinia";
import logger from "@/services/loggerService";
import { t } from "@/plugins/i18n";
import * as listable from "./listableMixin";
import { cloneDeep } from "lodash";
import { defaultSearchOptions } from "./listableMixin";
import { useNotificationsStore } from "@/stores/notifications";
import * as productsService from "@/services/productsService";
import { buildOption, SelectOption } from "@/models/selectOptions.interface";
import {
  FeatureTypeDecoder,
  possibleFeatureTypes,
  ProductFeature,
} from "@/models/productFeature.interface";

export interface ProductFeaturesState extends listable.ListableMixinState {
  items: ProductFeature[];
  currentFeature?: ProductFeature;
  emptyFeature: ProductFeature;
}

export const useProductFeaturesStore = defineStore({
  id: "productFeatures",
  state: (): ProductFeaturesState => ({
    isLoading: false,
    searchOptions: cloneDeep(defaultSearchOptions),
    items: [],
    currentFeature: undefined,
    searchError: t("productsFetchError"),
    searchFnc: productsService.searchFeatures,
    emptyFeature: {
      id: "",
      name: "",
      description: "",
      type: "1",
    },
  }),
  getters: {
    productFeaturesOptions: (state): SelectOption<ProductFeature>[] => {
      return state.items.map((item) => {
        return buildOption<ProductFeature>(item, "id", "name");
      });
    },
    // featuresTypesOptions: (state): SelectOption<string>[] => {
    featuresTypesOptions: (): SelectOption<string>[] => {
      return possibleFeatureTypes.map((n: string) => {
        return {
          value: n,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          label: FeatureTypeDecoder[n],
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fullItem: FeatureTypeDecoder[n],
        };
      });
    },
  },
  actions: {
    ...listable.actions,
    setCurrentFeature(type: ProductFeature | undefined) {
      this.currentFeature = type;
    },
    async createFeature(feature: ProductFeature) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await productsService.postFeature(feature);
        this.search();
        notifications.success(t("productFeatureSaveSuccess"));
      } catch (err) {
        logger.error(err);
        notifications.error(t("productFeatureSaveError"));
      } finally {
        this.isLoading = false;
      }
    },
    async saveFeature(feature: ProductFeature) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await productsService.putFeature(feature);
        this.items = this.items.map((listItem) => {
          if (listItem.id == feature.id) {
            return feature;
          } else {
            return listItem;
          }
        });
        notifications.success(t("productFeatureSaveSuccess"));
      } catch (err) {
        logger.error(err);
        notifications.error(t("productFeatureSaveError"));
      } finally {
        this.isLoading = false;
      }
    },
    async removeFeature(id: string) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await productsService.removeFeature(id);
        this.items = this.items.filter((listItem) => {
          return listItem.id != id;
        });
        notifications.success(t("productFeatureDeleteSuccess"));
      } catch (err) {
        logger.error(err);
        notifications.error(t("productFeatureDeleteError"));
      } finally {
        this.isLoading = false;
      }
    },
    async fetchProductFeatures() {
      if (!this.items.length) {
        this.isLoading = true;
        const notifications = useNotificationsStore();
        try {
          this.items = await productsService.fetchFeatures();
        } catch (err) {
          logger.error(err);
          notifications.error(t("productsTypesFetchError"));
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
});
