<template>
  <va-page-title>{{ t("productEdit") }}</va-page-title>
  <va-back-link
    v-if="productsStore.currentProduct"
    :target="`/products/${productsStore.currentProduct?.id}`"
  >
    {{ t("back") }}
  </va-back-link>
  <product-form
    v-if="productsStore.originalProduct"
    :product="productsStore.originalProduct"
    @save="productsStore.put"
  />
  <va-loader :loading="!productsStore.originalProduct" />
</template>

<script setup lang="ts">
import { onBeforeMount } from "vue";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import { t } from "@/plugins/i18n";
import { useRoute } from "vue-router";
import ProductForm from "@/components/organisms/ProductForm.vue";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import VaLoader from "@/components/atoms/VaLoader.vue";
import { useProductsStore } from "@/stores/products";
import { useMeta } from "vue-meta";

useMeta({
  title: "Products",
});
const route = useRoute();
const productsStore = useProductsStore();

onBeforeMount(async () => {
  await productsStore.getOriginal(route.params.id as string);
});
</script>
