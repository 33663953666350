import DealModel, {
  Deal,
  DealFormData,
  tableConfig,
} from "@/models/deal.interface";
import axios from "axios";
import * as configService from "@/services/configService";
import {
  SearchOptions,
  searchOptionsSerialize,
} from "@/models/listable.interface";
import { cloneDeep } from "lodash";
import dealHistoryModel, { DealHistory } from "@/models/dealHistory.interface";

export async function get(id: string): Promise<Deal> {
  const res = await axios.get(`${configService.dealsUrl}/${id}`);
  return DealModel.deserialize(res.data);
}

export async function getHistory(deal: Deal): Promise<DealHistory[]> {
  const res = await axios.get(`${configService.dealsUrl}/${deal.id}/history`);
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  return res.data.items.map((item: any) => {
    return dealHistoryModel.deserialize(item, deal.currency);
  });
}

export async function post(data: DealFormData): Promise<string> {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const serializedData = cloneDeep(data) as any;
  serializedData.estimatedCloseDate = data.estimatedCloseDate.getTime();
  serializedData.startDate = data.startDate
    ? data.startDate.getTime()
    : undefined;

  const res = await axios.post(`${configService.dealsUrl}`, serializedData);
  return res.data;
}

export async function search(
  options: SearchOptions
): Promise<{ items: Deal[]; count: number }> {
  const opt = options ? searchOptionsSerialize(options, tableConfig) : {};
  const res = await axios.post(configService.dealsSearchUrl, opt);
  return {
    items: res.data.items.map(DealModel.deserialize),
    count: res.data.count,
  };
}

export async function updateProtectionStatus(deal: Deal): Promise<boolean> {
  const res = await axios.post(
    `${configService.dealsUrl}/${deal.id}/updateProtectionStatus`,
    {
      protectionStatus: deal.protectionStatus,
    }
  );
  return res.statusText == "ok";
}

export async function updateEbidApprovedValue(deal: Deal): Promise<boolean> {
  const res = await axios.post(
    `${configService.dealsUrl}/${deal.id}/updateEbidStatus`,
    {
      status: deal.eBidStatus,
      value: deal.eBidApprovedValue,
    }
  );
  return res.statusText == "ok";
}

export async function updateDealInfo(deal: Deal): Promise<boolean> {
  const res = await axios.put(`${configService.dealsUrl}/${deal.id}`, {
    description: deal.description,
    estimatedCloseDate: deal.estimatedCloseDate?.getTime(),
    chanceOfClosure: deal.closureChance,
    dealStatus: deal.status,
  });
  return res.statusText == "ok";
}

export async function updateEbidValue(deal: Deal): Promise<boolean> {
  const res = await axios.post(
    `${configService.dealsUrl}/${deal.id}/updateEbidValue`,
    {
      value: deal.eBidValue,
    }
  );
  return res.statusText == "ok";
}
