<template>
  <div>
    <h2>{{ t("productFeatures") }}</h2>

    <va-select :options="featureOptions" class="mb-6" @change="addFeature" />

    <div
      v-for="feature in formFeatures"
      :key="feature.id"
      class="grid grid-cols-3 gap-4 mb-3"
    >
      <div>
        <div>{{ feature.name }}</div>
        <div class="text-xs text-gray-500 flex justify-between">
          {{ feature.description }}
        </div>
      </div>
      <div class="col-span-2 flex justify-between">
        <va-date-picker
          v-if="feature.typeName == 'dateTime'"
          v-model="feature.value"
          class="flex-grow"
          :placeholder="t('date')"
          @change="emitUpdate"
        />
        <va-input
          v-if="feature.typeName == 'string'"
          v-model="feature.value"
          class="flex-grow"
          @input="emitUpdate"
        />
        <va-input
          v-if="feature.typeName == 'double' || feature.typeName == 'int'"
          v-model="feature.value"
          class="flex-grow"
          type="number"
          @input="emitUpdate"
        />
        <a-switch
          v-if="feature.typeName == 'bool'"
          v-model:checked="feature.value"
          @change="emitUpdate"
        />
        <va-input
          v-if="feature.typeName == 'object'"
          v-model="feature.value"
          class="flex-grow"
          type="textarea"
          @input="emitUpdate"
        />
        <va-button class="ml-4" @click="removeFeature(feature)">
          <i class="rm rm-ico-bin" />
        </va-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from "vue";
import { ProductFeature } from "@/models/productFeature.interface";
import { t } from "@/plugins/i18n";
import VaInput from "@/components/molecules/VaInput.vue";
import { cloneDeep } from "lodash";
import VaDatePicker from "@/components/molecules/VaDatePicker.vue";
import VaSelect from "@/components/molecules/VaSelect.vue";
import { useProductFeaturesStore } from "@/stores/productFeatures";
import { SelectOption } from "@/models/selectOptions.interface";
import VaButton from "@/components/molecules/VaButton.vue";

const emit = defineEmits(["update"]);

const props = defineProps({
  features: {
    type: Array as PropType<ProductFeature[]>,
    required: true,
  },
});

const productFeaturesStore = useProductFeaturesStore();
productFeaturesStore.fetchProductFeatures();

const formFeatures = ref(
  cloneDeep(props.features)?.map((f: ProductFeature) => {
    if (f.typeName == "object") {
      f.value = JSON.stringify(f.value) as string;
    }
    return f;
  }) as ProductFeature[]
);

const featureOptions = computed(() => {
  const activeFeatures = formFeatures.value.map((f2) => f2.id);
  return productFeaturesStore.productFeaturesOptions.filter((f) => {
    return !activeFeatures.includes(f.value);
  });
});

const emitUpdate = () => {
  const res = formFeatures.value.map((f) => {
    const f2 = cloneDeep(f);
    if (f2.typeName == "object") {
      try {
        f2.value = JSON.parse(f.value as string);
      } catch (e) {
        f2.value = {};
      }
    }
    if (f2.typeName == "string" && (!f2.value || f2.value == "")) {
      f2.value = "-";
    }
    return f2;
  });
  emit("update", res);
};

const addFeature = (arg: SelectOption<ProductFeature>) => {
  formFeatures.value.push(arg.fullItem);
  return emitUpdate();
};

const removeFeature = (arg: ProductFeature) => {
  formFeatures.value = formFeatures.value.filter((f) => f.id !== arg.id);
  return emitUpdate();
};
</script>
