import { TableConfig } from "@/models/listable.interface";

export interface Stock {
  accountId: string;
  accountName: string;
  productId: string;
  productName: string;
  productDescription: string;
  sku: string;
  quantity: number;
  extendedProductId?: string;
  isLicense: boolean;
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): Stock {
    return {
      accountId: input.accountId || "",
      accountName: input.accountName || "",
      productId: input.productId || "",
      productName: input.productName || "",
      productDescription: input.productDescription || "",
      sku: input.sku || "",
      quantity: input.quantity || 0,
      extendedProductId: input.extendedProductId || undefined,
      isLicense: input.isLicense || false,
    };
  },
  serialize(input: Stock): unknown {
    return {
      accountId: input.accountId,
      accountName: input.accountName,
      productId: input.productId,
      productName: input.productName,
      productDescription: input.productDescription,
      sku: input.sku,
      quantity: input.quantity,
      extendedProductId: input.extendedProductId,
      isLicense: input.isLicense,
    };
  },
};

export const tableConfig = {
  sku: {
    dtoName: "sku",
    sortable: true,
    filterable: true,
    label: "sku",
  },
  productName: {
    dtoName: "productName",
    sortable: false,
    filterable: true,
    label: "product",
  },
  productDescription: {
    dtoName: "productDescription",
    sortable: false,
    filterable: false,
    label: "description",
  },
  quantity: {
    dtoName: "quantity",
    sortable: true,
    filterable: false,
    label: "quantity",
  },
  accountName: {
    dtoName: "accountName",
    sortable: false,
    filterable: false,
    label: "account",
    hideColumn: true,
  },
} as TableConfig;
