<template>
  <account-edit-form v-if="account" class="mt-6" :account="account" />
  <address-edit-form v-if="account" class="mt-4" :account="account" />
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from "vue";
import AddressEditForm from "@/components/organisms/account/AddressEditForm.vue";
import AccountEditForm from "@/components/organisms/account/AccountEditForm.vue";
import { useAccountsStore } from "@/stores/accounts";
import { useRoute } from "vue-router";

const accountStore = useAccountsStore();
const route = useRoute();

const account = computed(() => accountStore.currentAccount);
onBeforeMount(() => {
  accountStore.get(route.params.id as string);
});
</script>
