<template>
  <va-page-title>{{ t("newAccountTitle") }}</va-page-title>
  <va-back-link target="/accounts">{{ t("backToNetwork") }}</va-back-link>
  <a-form
    class=""
    :model="formState"
    :validate-messages="validateMessages"
    @finish="onFinish"
  >
    <div class="va-card mt-6 relative p-4">
      <div class="grid grid-cols-2 gap-4">
        <a-form-item
          :name="['parentAccountId']"
          :label="t('parentAccount')"
          :rules="[{ required: true }]"
        >
          <va-select
            v-model="formState.parentAccountId"
            :options="currentChildren"
            :placeholder="t('createAsChild')"
            @change="selectChild"
          >
            <template #option="option">
              <option-account :item="option.nestedProps.fullItem" />
            </template>
          </va-select>
        </a-form-item>

        <a-form-item
          :name="['priceListId']"
          :label="t('priceList')"
          :rules="[{ required: true }]"
        >
          <va-select
            v-model="formState.priceListId"
            :disabled="!accountStore.canCreate"
            :options="possiblePriceLists"
          />
        </a-form-item>
      </div>
    </div>

    <div class="va-card mt-6 relative p-4">
      <div class="grid grid-cols-3 gap-4">
        <a-form-item
          :name="['accountName']"
          :label="t('accountName')"
          :rules="[{ required: true }]"
        >
          <va-input
            v-model="formState.accountName"
            :placeholder="t('accountName')"
          />
        </a-form-item>
        <a-form-item
          :name="['groupId']"
          :label="t('group')"
          :rules="[{ required: true }]"
        >
          <va-select
            v-model="formState.groupId"
            :options="groups"
            :disabled="!accountStore.canCreate"
          />
        </a-form-item>
        <a-form-item
          :name="['address', 'vatId']"
          :label="t('vatId')"
          :rules="[{ required: true }]"
        >
          <va-input
            v-model="formState.address.vatId"
            :placeholder="t('vatId')"
          />
        </a-form-item>
        <!--        <a-form-item-->
        <!--          :name="['priceListId']"-->
        <!--          :label="t('priceList')"-->
        <!--          :rules="[{ required: true }]"-->
        <!--        >-->
        <!--          <va-select-->
        <!--            v-model="formState.priceListId"-->
        <!--            :disabled="!accountStore.canCreate"-->
        <!--            :options="possiblePriceLists"-->
        <!--          />-->
        <!--        </a-form-item>-->
      </div>
      {{ t("address") }}:
      <div class="grid grid-cols-3 gap-4">
        <a-form-item
          :name="['address', 'street']"
          :label="t('street')"
          :rules="[{ required: true }]"
        >
          <va-input
            v-model="formState.address.street"
            :placeholder="t('street')"
          />
        </a-form-item>
        <a-form-item
          :name="['address', 'city']"
          :label="t('city')"
          :rules="[{ required: true }]"
        >
          <va-input v-model="formState.address.city" :placeholder="t('city')" />
        </a-form-item>
        <a-form-item :name="['address', 'locality']" :label="t('locality')">
          <va-input
            v-model="formState.address.locality"
            :placeholder="t('locality')"
          />
        </a-form-item>
        <a-form-item :name="['address', 'region']" :label="t('region')">
          <va-input
            v-model="formState.address.region"
            :placeholder="t('region')"
          />
        </a-form-item>
        <a-form-item
          :name="['address', 'postalCode']"
          :label="t('postalCode')"
          :rules="[{ required: true }]"
        >
          <va-input
            v-model="formState.address.postalCode"
            :placeholder="t('postalCode')"
          />
        </a-form-item>
        <a-form-item
          :name="['address', 'countryCode']"
          :label="t('country')"
          :rules="[{ required: true }]"
        >
          <va-select
            v-model="formState.address.countryCode"
            :options="lookupStore.countriesOptions"
            :placeholder="t('country')"
            :disabled="lookupStore.countriesLoading"
            @change="selectCountry"
          />
        </a-form-item>
      </div>

      {{ t("accountAdmin") }}:
      <div v-if="formState.user" class="grid grid-cols-3 gap-4">
        <a-form-item
          :name="['user', 'userName']"
          :label="t('email')"
          :rules="[{ type: 'email' }]"
        >
          <va-input
            v-model="formState.user.userName"
            :placeholder="t('email')"
          />
        </a-form-item>
        <a-form-item :name="['user', 'firstName']" :label="t('firstName')">
          <va-input
            v-model="formState.user.firstName"
            :placeholder="t('firstName')"
          />
        </a-form-item>
        <a-form-item :name="['user', 'lastName']" :label="t('lastName')">
          <va-input
            v-model="formState.user.lastName"
            :placeholder="t('lastName')"
          />
        </a-form-item>
      </div>

      <a-form-item>
        <va-button
          html-type="submit"
          class="float-right mb-4"
          :disabled="!accountStore.canCreate"
          :loading="accountStore.isLoading"
        >
          {{ t("save") }}
        </va-button>
      </a-form-item>
    </div>
    <!--    <div class="va-card mt-6 relative p-4">-->
    <!--      <div class="grid grid-cols-2 gap-4">-->
    <!--      </div>-->
    <!--    </div>-->
  </a-form>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import { t } from "@/plugins/i18n";
import { Address } from "@/models/address.interface";
import VaSelect from "@/components/molecules/VaSelect.vue";
import { useValidationMessages } from "@/composables/validation";
import { AccountGroup } from "@/models/accountGroup.interface";
import { Account, AccountFormData } from "@/models/account.interface";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import OptionAccount from "@/components/molecules/selectOptions/OptionAccount.vue";
import { useLookupStore } from "@/stores/lookup";
import { useAccountsStore } from "@/stores/accounts";
import { SelectOption } from "@/models/selectOptions.interface";
import { CountryData } from "@/models/countryData.interface";
import { usePricelistsStoreAccount } from "@/stores/pricelists";

const accountStore = useAccountsStore();
const lookupStore = useLookupStore();
const validateMessages = useValidationMessages();
const priceListsStore = usePricelistsStoreAccount();

const account = computed(() => accountStore.currentAccount);

const currentLevel = ref(
  accountStore.currentAccount?.group?.level
    ? accountStore.currentAccount.group.level
    : 50
);

const possiblePriceLists = computed(
  () => priceListsStore.possiblePriceListsOptions
);

const groups = computed(() => {
  const minLevel = Math.min(
    ...lookupStore.accountGroups
      .filter((g: AccountGroup) => g.level > currentLevel.value)
      .map((g: AccountGroup) => g.level)
  );
  return lookupStore.accountGroups
    .filter((group: AccountGroup) => {
      return group.level === minLevel;
    })
    .map((group: AccountGroup) => {
      return {
        value: group.groupId,
        label: t(group.name),
      };
    });
});

const currentChildren = computed(() => {
  return accountStore.currentNetworkOptions.filter(
    (opt) => opt.fullItem.group.level < 50
  ); //discard lower groups
});

const selectCountry = (item: SelectOption<CountryData>) => {
  formState.value.address.country = item.label;
};

const selectChild = (item: SelectOption<Account>) => {
  priceListsStore.fetchByParentId(item.value);
  currentLevel.value = item.fullItem.group?.level || 50;
  formState.value.groupId = "";
  formState.value.priceListId = "";
};

const formState = ref({
  accountName: "",
  groupId: "",
  priceListId: "",
  address: {
    legalName: "",
    vatId: "",
    street: "",
    city: "",
    locality: "",
    region: "",
    postalCode: "",
    country: "",
    countryCode: undefined,
  } as Address,
  user: {
    userName: "",
    firstName: "",
    lastName: "",
    timeZone: "en-US",
  },
  parentAccountId: "",
} as AccountFormData);

const onFinish = () => {
  formState.value.address.legalName = formState.value.accountName;
  accountStore.create(formState.value);
};

onBeforeMount(() => {
  if (
    !account.value ||
    (account.value?.group?.level && account.value.group.level >= 50)
  ) {
    formState.value.parentAccountId = "";
  } else {
    priceListsStore.fetchByParentId(account.value.id);
    formState.value.parentAccountId = account.value.id;
  }
  accountStore.fetchChildren();
  lookupStore.fetchCountries();
  lookupStore.fetchAccountGroups();
  lookupStore.fetchRoles();
});
</script>
