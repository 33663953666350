<template>
  <div v-if="product" id="productDetail" class="va-card mt-6">
    <div class="px-4 py-5 sm:px-6 flex">
      <div class="grow">
        <va-page-title> {{ product.name }}</va-page-title>
        <p class="va-subtitle mb-4">{{ product.sku }}</p>

        <va-back-link target="/products"
          >{{ t("backToProducts") }}
        </va-back-link>
      </div>

      <va-delete-button
        class="mr-4"
        :disabled="!productsStore.canDelete"
        :confirm-text="t('productRemoveDescription')"
        :confirm-title="t('productRemove')"
        @click:confirm="removeProduct"
      />

      <va-button
        :disabled="!productsStore.canUpdate"
        @click="router.push(`/products/${product?.id}/edit/`)"
      >
        <i class="rm rm-ico-edit" />
      </va-button>
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
      <dl class="sm:divide-y sm:divide-gray-200">
        <va-info-row :title="t('name')">
          {{ product.name }}
        </va-info-row>
        <va-info-row :title="t('sku')">
          {{ product.sku }}
        </va-info-row>
        <va-info-row :title="t('oemSKU')">
          {{ product.oemSKU }}
        </va-info-row>
        <va-info-row v-if="product.extendedSku" :title="t('extendedSku')">
          {{ product.extendedSku }}
        </va-info-row>
        <va-info-row :title="t('description')">
          {{ product.description }}
        </va-info-row>
        <va-info-row :title="t('environmentType')">
          {{
            productsStore.decodedEnvironmentType(product.environmentType).name
          }}
        </va-info-row>
        <va-info-row :title="t('price')">
          {{ useFormatCurrency(product.price, product.currency) }}
        </va-info-row>
        <va-info-row :title="t('isLicense')">
          <va-check-icon :check="product.isLicense" />
        </va-info-row>
        <va-info-row :title="t('licensePrefix')">
          {{ product.licensePrefix }}
        </va-info-row>
        <va-info-row :title="t('licenseDuration')">
          <div v-if="product.licenseIsPerpetual">
            <a-tag color="success">
              {{ t("perpetual") }}
            </a-tag>
          </div>
          <div v-else>
            <span v-if="product.licenseDurationYears > 0">
              {{ product.licenseDurationYears }} {{ t("years") }}
            </span>
            <span v-if="product.licenseDurationMonths > 0">
              &nbsp;{{ product.licenseDurationMonths }} {{ t("months") }}
            </span>
            <span v-if="product.licenseDurationDays > 0">
              &nbsp;{{ product.licenseDurationDays }} {{ t("days") }}
            </span>
          </div>
        </va-info-row>
        <va-info-row :title="t('licenseMaintenance')">
          <div v-if="product.maintenanceIsPerpetual">
            <a-tag color="success">
              {{ t("perpetual") }}
            </a-tag>
          </div>
          <div v-else>
            <span v-if="product.maintenanceDurationYears > 0">
              {{ product.maintenanceDurationYears }} {{ t("years") }}
            </span>
            <span v-if="product.maintenanceDurationMonths > 0">
              &nbsp;{{ product.maintenanceDurationMonths }} {{ t("months") }}
            </span>
            <span v-if="product.maintenanceDurationDays > 0">
              &nbsp;{{ product.maintenanceDurationDays }} {{ t("days") }}
            </span>
          </div>
        </va-info-row>
        <va-info-row :title="t('licenseActivationRule')">
          {{ t(product.licenseActivationRule) }}
        </va-info-row>
        <va-info-row :title="t('islicenseUpgradeRight')">
          <va-check-icon :check="product.islicenseUpgradeRight" />
        </va-info-row>
        <va-info-row :title="t('isLicenseTrial')">
          <va-check-icon :check="product.isLicenseTrial" />
        </va-info-row>
        <va-info-row :title="t('creationDate')">
          {{ formatDate(product.creationDate) }}
        </va-info-row>
        <va-info-row :title="t('status')">
          {{ t(product.status) }}
        </va-info-row>
        <va-info-row :title="t('airServerRequired')">
          <va-check-icon :check="product.airServerRequired" />
        </va-info-row>
        <va-info-row :title="t('tenants')">
          <span v-for="item in product.tenants" :key="item.id">
            {{ item.name }} &nbsp; &nbsp;
          </span>
        </va-info-row>
        <va-info-row :title="t('productType')">
          <div>{{ product.type.name }}</div>
          <div class="text-xs text-gray-500 flex justify-between">
            {{ product.type.description }}
          </div>
        </va-info-row>
      </dl>
    </div>
  </div>

  <div
    v-if="product?.type && product.type.features.length"
    class="va-card mt-6 p-4 sm:px-6"
  >
    <product-features-table :features="product.type.features" class="mt-3" />
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import { t } from "@/plugins/i18n";
import VaInfoRow from "@/components/atoms/VaInfoRow.vue";
import VaCheckIcon from "@/components/atoms/VaCheckIcon.vue";
import { useFormatDate } from "@/composables/formatters";
import VaButton from "@/components/molecules/VaButton.vue";
import router from "@/router";
import VaDeleteButton from "@/components/molecules/VaDeleteButton.vue";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import { useFormatCurrency } from "@/composables/formatters";
import { useProductsStore } from "@/stores/products";
import ProductFeaturesTable from "@/components/organisms/ProductFeaturesTable.vue";
import { useMeta } from "vue-meta";

useMeta({
  title: "Products",
});
const route = useRoute();
const productsStore = useProductsStore();
const formatDate = useFormatDate;

const product = computed(() => productsStore.currentProduct);

onBeforeMount(() => {
  productsStore.get(route.params.id as string);
});

const removeProduct = () => {
  productsStore.remove(route.params.id as string);
};
</script>
