import { defineStore } from "pinia";
import logger from "@/services/loggerService";
import { t } from "@/plugins/i18n";
import * as listable from "./listableMixin";
import * as permissions from "./permissionMixin";
import router from "@/router";
import { cloneDeep } from "lodash";
import { defaultSearchOptions } from "./listableMixin";
import { useNotificationsStore } from "@/stores/notifications";
import { PriceList, PriceListFormData } from "@/models/priceList.interface";
import {
  PriceListProduct,
  PriceListProductFormData,
} from "@/models/priceListProduct.interface";
import * as priceListService from "@/services/pricelistService";
import { buildOption, SelectOption } from "@/models/selectOptions.interface";

export interface PriceListsState
  extends listable.ListableMixinState,
    permissions.PermissionsMixinState {
  items: PriceList[];
  _possiblePriceLists: PriceList[];
  currentPriceList?: PriceList;
  currentProduct?: PriceListProduct;
}

function createStore<Id extends string>(id: Id) {
  return defineStore({
    id: id,
    state: (): PriceListsState => ({
      isLoading: false,
      searchOptions: cloneDeep(defaultSearchOptions),
      moduleName: "PriceLists",
      items: [],
      _possiblePriceLists: [],
      currentPriceList: undefined,
      searchError: t("priceListsFetchError"),
      searchFnc: priceListService.search,
    }),
    getters: {
      ...permissions.getters,
      // priceLists: (state): PriceList[] => state.items,
      possiblePriceListsOptions: (state): SelectOption<PriceList>[] => {
        const res = cloneDeep(state._possiblePriceLists);
        //add current pricelist even if is not available in possible pricelists
        if (
          state.currentPriceList &&
          !state._possiblePriceLists.some((pl: PriceList) => {
            return pl.id === state.currentPriceList?.id;
          })
        ) {
          res.push(state.currentPriceList);
        }
        return res.map((item) => {
          return buildOption<PriceList>(item, "id", "name");
        });
        // return res.map((list: PriceList) => {
        //   return {
        //     value: list.id,
        //     label: list.name,
        //   };
        // });
      },
      possiblePriceLists: (state): PriceList[] => {
        const res = cloneDeep(state._possiblePriceLists);
        //add current pricelist even if is not available in possible pricelists
        if (
          state.currentPriceList &&
          !state._possiblePriceLists.some((pl: PriceList) => {
            return pl.id === state.currentPriceList?.id;
          })
        ) {
          res.push(state.currentPriceList);
        }
        return res;
      },
    },
    actions: {
      ...listable.actions,
      setCurrentPriceList(pricelist: PriceList | undefined) {
        this.currentPriceList = pricelist;
      },
      async get(id: string) {
        //TODO REACTIVATE ON API CHANGE
        // if (!id || context.state.currentPriceList?.id === id) return;
        const notifications = useNotificationsStore();
        this.isLoading = true;
        try {
          this.currentPriceList = await priceListService.get(id);
        } catch (err) {
          logger.error(err);
          notifications.error(t("priceListsFetchError"));
        } finally {
          this.isLoading = false;
        }
      },
      async delete(id: string) {
        this.isLoading = true;
        const notifications = useNotificationsStore();
        try {
          await priceListService.remove(id);
          this.items = this.items.filter((listItem) => {
            return listItem.id != id;
          });
          router.push(`/pricelists`);
        } catch (err) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (err.response.data.errorCode == "PR523") {
            notifications.error(t("priceListsDeleteErrorInuse"));
          } else {
            logger.error(err);
            notifications.error(t("priceListsDeleteError"));
          }
        } finally {
          this.isLoading = false;
        }
      },
      async update(list: PriceList) {
        this.isLoading = true;
        const notifications = useNotificationsStore();
        try {
          await priceListService.update(list);
          this.currentPriceList = list;
        } catch (err) {
          logger.error(err);
          notifications.error(t("priceListsUpdateError"));
        } finally {
          this.isLoading = false;
        }
      },
      async fetchByParentId(id: string) {
        this.isLoading = true;
        const notifications = useNotificationsStore();
        try {
          this._possiblePriceLists = await priceListService.fetchAllByParentId(
            id
          );
        } catch (err) {
          logger.error(err);
          notifications.error(t("priceListsFetchError"));
        } finally {
          this.isLoading = false;
        }
      },
      async create(formData: PriceListFormData) {
        this.isLoading = true;
        const notifications = useNotificationsStore();
        try {
          const res = await priceListService.post(formData);
          router.push(`/pricelists/${res}`);
        } catch (err) {
          logger.error(err);
          notifications.error(t("priceListsCreateError"));
        } finally {
          this.isLoading = false;
        }
      },
      async postProduct(product: PriceListProductFormData) {
        this.isLoading = true;
        const notifications = useNotificationsStore();
        try {
          if (this.currentPriceList) {
            await priceListService.postProduct(this.currentPriceList, product);
            this.get(this.currentPriceList.id);
            notifications.success(t("priceListsUpdateSuccess"));
          }
        } catch (err) {
          logger.error(err);
          notifications.error(t("priceListsUpdateError"));
        } finally {
          this.isLoading = false;
        }
      },
      async putProduct(product: PriceListProductFormData) {
        this.isLoading = true;
        const notifications = useNotificationsStore();
        try {
          if (this.currentPriceList) {
            await priceListService.putProduct(this.currentPriceList, product);
            this.get(this.currentPriceList.id);
            notifications.success(t("priceListsUpdateSuccess"));
          }
        } catch (err) {
          logger.error(err);
          notifications.error(t("priceListsUpdateError"));
        } finally {
          this.isLoading = false;
        }
      },
      async deleteProduct(product: PriceListProduct) {
        this.isLoading = true;
        const notifications = useNotificationsStore();
        try {
          if (this.currentPriceList) {
            await priceListService.deleteProduct(
              this.currentPriceList,
              product
            );
            this.get(this.currentPriceList.id);
            notifications.success(t("priceListsUpdateSuccess"));
          }
        } catch (err) {
          logger.error(err);
          notifications.error(t("priceListsUpdateError"));
        } finally {
          this.isLoading = false;
        }
      },
      async getAssociatedPriceList(accountId: string) {
        this.isLoading = true;
        const notifications = useNotificationsStore();
        try {
          this.currentPriceList = await priceListService.getAssigment(
            accountId
          );
        } catch (err) {
          logger.error(err);
          notifications.error(t("accountsAssociatedListError"));
        } finally {
          this.isLoading = false;
        }
      },
      async associate(data: {
        priceListId: string;
        accountId: string;
        canPropagate: boolean;
      }) {
        this.isLoading = true;
        const notifications = useNotificationsStore();
        try {
          if (this.currentPriceList) {
            await priceListService.putAssigment(
              data.priceListId,
              data.accountId,
              data.canPropagate
            );
          } else {
            await priceListService.postAssigment(
              data.priceListId,
              data.accountId,
              data.canPropagate
            );
          }
          this.currentPriceList = await priceListService.getAssigment(
            data.accountId
          );
          notifications.success(t("priceListsUpdateSuccess"));
        } catch (err) {
          logger.error(err);
          notifications.error(t("priceListsUpdateError"));
        } finally {
          this.isLoading = false;
        }
      },
      async deleteAssignment(data: { priceListId: string; accountId: string }) {
        this.isLoading = true;
        const notifications = useNotificationsStore();
        try {
          await priceListService.removeAssigment(
            data.priceListId,
            data.accountId
          );
          this.currentPriceList = undefined;
          notifications.success(t("priceListsUpdateSuccess"));
        } catch (err) {
          logger.error(err);
          notifications.error(t("priceListsUpdateError"));
        } finally {
          this.isLoading = false;
        }
      },
    },
  });
}

export const usePricelistsStore = createStore("pricelists");
export const usePricelistsStoreAccount = createStore("pricelistsAccount");
