export interface CountryData {
  countryCode: string;
  countryName: string;
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): CountryData {
    return {
      countryCode: input.countryCode || "",
      countryName: input.countryName || "",
    };
  },
  serialize(input: CountryData): unknown {
    return {
      countryCode: input.countryCode,
      countryName: input.countryName,
    };
  },
};
