export interface Role {
  id: string;
  code: string;
  name: string;
  isSuperAdmin: boolean;
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): Role {
    return {
      id: input.roleId || "",
      code: input.name.replace(/\s/g, "") || "", //code generated by name need correct code from backend
      name: input.name || "",
      isSuperAdmin: input.isSuperAdmin || false,
    };
  },
  serialize(input: Role): unknown {
    return {
      roleId: input.id,
      name: input.name,
    };
  },
};
