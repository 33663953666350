<template>
  <metainfo>
    <template #title="{ content }">{{ content }} | Partner Portal</template>
  </metainfo>
  <va-toast />
  <router-view />
</template>
<script setup lang="ts">
import VaToast from "@/components/organisms/VaToast.vue";
import { useMeta } from "vue-meta";
import tenantService from "@/services/tenantService";

const logoUrl = tenantService.getLogoAsset("logo-horizontal.svg");
const appUrl = process.env.VITE_APP_URL as string;

useMeta({
  htmlAttrs: {
    lang: "en",
    amp: true,
  },
  meta: [
    { charset: "utf-8" },
    {
      property: "description",
      content: "Partner portal & sales network",
      vmid: "description",
    },
    {
      property: "og:title",
      content: "Partner Portal",
      vmid: "og:title",
    },
    {
      property: "og:url",
      content: appUrl,
      vmid: "og:url",
    },
    {
      property: "og:description",
      content: "",
      vmid: "og:description",
    },
    {
      property: "og:image",
      content: logoUrl,
      vmid: "og:image",
    },
    {
      property: "og:type",
      content: "website",
      vmid: "og:type",
    },
    {
      property: "og:url",
      content: "",
      vmid: "og:url",
    },
  ],
});
</script>
