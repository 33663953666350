import axios from "axios";
import * as configService from "@/services/configService";
import statsModel, {
  statsRequestData,
  DealStats,
  LicenseStats,
  OrderStats,
} from "@/models/stats.interface";

// export async function getAccountStats(
//   data: statsRequestData
// ): Promise<AccountsStats> {
//   const res = await axios.post(
//     `${configService.accountStatsUrl}`,
//     statsModel.serializeStatsRequestData(data)
//   );
//   return statsModel.deserializeAccountsStats(res.data);
// }

export async function getLicensesStats(
  data: statsRequestData
): Promise<LicenseStats> {
  const body = statsModel.serializeStatsRequestData(data);
  const res = await axios.post(`${configService.licensesStatsUrl}`, body);
  return statsModel.deserializeLicenseStats(res.data);
}

export async function getDealsStats(
  data: statsRequestData
): Promise<DealStats> {
  const res = await axios.post(
    `${configService.dealsStatsUrl}`,
    statsModel.serializeStatsRequestData(data)
  );
  return statsModel.deserializeDealStats(res.data);
}

export async function getOrdersStats(
  data: statsRequestData
): Promise<OrderStats> {
  const res = await axios.post(
    `${configService.ordersStatsUrl}`,
    statsModel.serializeStatsRequestData(data)
  );
  return statsModel.deserializeOrderStats(res.data);
}
