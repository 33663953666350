<template>
  <div class="w-full flex justify-center bg-black rounded-md">
    <div
      id="appleid-signin"
      class=""
      data-color="black"
      data-border="false"
      data-type="sign-in"
      data-height="32"
    ></div>
  </div>
</template>

<script setup lang="ts">
import logger from "@/services/loggerService";
import { onBeforeUnmount, onMounted } from "vue";
import { oauthProviderCodes } from "@/services/configService";
import { useAuthStore } from "@/stores/auth";
import { useRoute } from "vue-router";

const authStore = useAuthStore();
const route = useRoute();
/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
const onSuccess = (appleUser: any) => {
  authStore.login({
    oauthProviderType: oauthProviderCodes.apple,
    callbackUrl: `${window.location.origin}/auth/signin`,
    authCode: appleUser.detail.authorization.id_token,
    redirect: route.query?.redirect?.toString() || undefined,
  });
};

const initAppleId = () => {
  if (!window.AppleID) {
    logger.error(
      "'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js' not included included as a script."
    );
  } else {
    window.AppleID.auth.init({
      clientId: process.env.VITE_APPLE_CLIENT_ID,
      scope: "name email",
      redirectURI: `${window.location.origin}/auth/signin`,
      usePopup: true,
    });

    document.addEventListener("AppleIDSignInOnSuccess", onSuccess);
    document.addEventListener("AppleIDSignInOnFailure", logger.error);
  }
};

onMounted(initAppleId);
onBeforeUnmount(() => {
  document.removeEventListener("AppleIDSignInOnSuccess", onSuccess);
  document.removeEventListener("AppleIDSignInOnFailure", logger.error);
});
</script>
