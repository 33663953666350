<template>
  <va-button class="w-full flex justify-center blue-btn" @click="handleSignIn">
    {{ t("signInMicrosoft") }}
  </va-button>
</template>

<script setup lang="ts">
import * as msal from "@azure/msal-browser";
import logger from "@/services/loggerService";
import { oauthProviderCodes } from "@/services/configService";
import { useI18n } from "vue-i18n";
import VaButton from "@/components/molecules/VaButton.vue";
import { useAuthStore } from "@/stores/auth";
import { useRoute } from "vue-router";

const authStore = useAuthStore();
const route = useRoute();
const { t } = useI18n();

const msalInstance = new msal.PublicClientApplication({
  auth: {
    clientId: process.env.VITE_TEAMS_CLIENT_ID as string,
    redirectUri: window.location.origin,
  },
});

const handleSignIn = async () => {
  try {
    const msalUser = await msalInstance.loginPopup({
      scopes: ["openid User.ReadBasic.All"],
      prompt: "select_account",
    });
    if (msalUser) await onSuccess(msalUser);
  } catch (e) {
    logger.error(e);
  }
};

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
const onSuccess = (microsoftUser: any) => {
  authStore.login({
    oauthProviderType: oauthProviderCodes.microsoft,
    callbackUrl: `${window.location.origin}`,
    authCode: microsoftUser.idToken,
    redirect: route.query?.redirect?.toString() || undefined,
  });
};
</script>
<style lang="scss" scoped>
.blue-btn {
  @apply bg-blue-600 hover:bg-blue-700 focus:bg-blue-700 focus:ring-blue-500;
}
</style>
