import { TableConfig } from "@/models/listable.interface";
import ProductModel, { Product } from "@/models/product.interface";
import { CurrencyData } from "@/models/currencyData.interface";
import { useLookupStore } from "@/stores/lookup";

export interface PriceListProduct extends Product {
  priceListId: string;
  customPrice: number;
  customPriceCurrency: CurrencyData;
  extendedDescription?: string;
  extendedProductId?: string;
  extendedSku?: string;
}

export type PriceListProductFormData = Pick<
  PriceListProduct,
  "id" | "customPrice"
>;

export const tableConfig = {
  name: {
    dtoName: "name",
    sortable: true,
    filterable: true,
    label: "name",
  },
  id: {
    dtoName: "productId",
    sortable: false,
    filterable: false,
    label: "id",
    hideColumn: true,
  },
  sku: {
    dtoName: "sku",
    sortable: true,
    filterable: true,
    label: "sku",
  },
  description: {
    dtoName: "description",
    sortable: false,
    filterable: false,
    label: "description",
  },
  priceListId: {
    dtoName: "priceListId",
    sortable: false,
    filterable: false,
    label: "priceListId",
    hideColumn: true,
  },
  customPrice: {
    dtoName: "customPrice",
    sortable: true,
    filterable: false,
    label: "price",
  },
  actions: {
    dtoName: "",
    sortable: false,
    filterable: false,
    label: "actions",
    hideColumn: false,
  },
} as TableConfig;

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): PriceListProduct {
    //STORE DEPENDENCY: need already fetched currencies to decode from id to full currency item
    const lookupStore = useLookupStore();
    const baseProduct = ProductModel.deserialize(input) as PriceListProduct;
    baseProduct.priceListId = input.priceListId || "";
    baseProduct.customPrice = input.customPrice || 0;
    baseProduct.customPriceCurrency = lookupStore.decodedCurrency(
      input.customPriceCurrencyId
    );
    baseProduct.currency = lookupStore.decodedCurrency(
      input.customPriceCurrencyId
    );
    baseProduct.extendedDescription = input.extendedDescription || "";
    baseProduct.extendedProductId = input.extendedProductId || "";
    baseProduct.extendedSku = input.extendedSku || "";
    return baseProduct;
  },

  serialize(input: PriceListProduct): unknown {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    const serializedProduct = ProductModel.serialize(input) as any;
    serializedProduct.priceListId = input.priceListId;
    serializedProduct.customPrice = input.customPrice;
    serializedProduct.customPriceCurrencyId = input.customPriceCurrency.id;
    serializedProduct.extendedDescription = input.extendedDescription;
    serializedProduct.extendedProductId = input.extendedProductId;
    serializedProduct.extendedSku = input.extendedSku;
    return serializedProduct;
  },

  serializeFormData(input: PriceListProductFormData): unknown {
    return {
      productId: input.id,
      customPrice: input.customPrice,
    };
  },
};
