<template>
  <div class="va-card relative pt-5 px-4 pb-6 sm:pt-6 sm:px-6 mt-6">
    <div class="flex place-content-between">
      <div class="grow mb-6">
        <div class="va-title">
          {{ t("orders") }}
        </div>
      </div>
      <date-filters />
    </div>
    <div
      v-if="stats"
      class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4"
    >
      <va-data-badge
        :trend="stats.totalSellIn.trend"
        :value="useFormatNumber(stats.totalSellIn.current, true)"
        :label="t('totalSellIn')"
      />
      <va-data-badge
        :trend="stats.totalSellOut.trend"
        :value="useFormatNumber(stats.totalSellOut.current, true)"
        :label="t('totalSellOut')"
      />
      <va-data-badge
        :trend="stats.totalSellInPrice.trend"
        :value="
          useFormatCurrency(stats.totalSellInPrice.current, stats?.currency)
        "
        :label="t('totalSellInPrice')"
      />
      <va-data-badge
        :trend="stats.totalSellOutPrice.trend"
        :value="
          useFormatCurrency(stats.totalSellOutPrice.current, stats?.currency)
        "
        :label="t('totalSellOutPrice')"
      />
    </div>
    <div class="grid grid-cols-1 gap-5 lg:grid-cols-2 mt-5">
      <div>
        <vue3-chart-js
          v-if="!statsStore.ordersStatsLoading && chartConfig"
          :id="chartConfig.id"
          ref="chartRef"
          :type="chartConfig.type"
          :data="chartConfig.data"
          :options="chartConfig.options"
        />
        <div v-else class="flex justify-center my-8">
          <va-loader :loading="statsStore.dealsStatsLoading" />
        </div>
      </div>
      <div class="grid grid-cols-1 gap-5 sm:grid-cols-2">
        <div>
          <vue3-chart-js
            v-if="
              !statsStore.ordersStatsLoading &&
              chartConfig &&
              stats?.sellOutPieStatistics.length
            "
            :id="sellOutPieChartConfig.id"
            ref="chartRef"
            :type="sellOutPieChartConfig.type"
            :data="sellOutPieChartConfig.data"
            :options="sellOutPieChartConfig.options"
          />
          <div
            v-if="!stats?.sellOutPieStatistics.length"
            class="flex justify-center my-12 text-xs text-gray-500"
          >
            {{ t("nodata") }}
          </div>
          <div
            v-if="statsStore.ordersStatsLoading"
            class="flex justify-center my-8"
          >
            <va-loader :loading="statsStore.ordersStatsLoading" />
          </div>
        </div>
        <div>
          <vue3-chart-js
            v-if="
              !statsStore.ordersStatsLoading &&
              chartConfig &&
              stats?.sellOutSkuPieStatistics.length
            "
            :id="sellOutSkuPieChartConfig.id"
            ref="chartSkuRef"
            :type="sellOutSkuPieChartConfig.type"
            :data="sellOutSkuPieChartConfig.data"
            :options="sellOutSkuPieChartConfig.options"
          />
          <div
            v-if="!stats?.sellOutSkuPieStatistics.length"
            class="flex justify-center my-12 text-xs text-gray-500"
          >
            {{ t("nodata") }}
          </div>
          <div
            v-if="statsStore.ordersStatsLoading"
            class="flex justify-center my-8"
          >
            <va-loader :loading="statsStore.ordersStatsLoading" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import VaLoader from "@/components/atoms/VaLoader.vue";
import { useChartConfig, usePieChartConfig } from "@/composables/charts";
import { useStatsStore } from "@/stores/stats";
import { useFormatCurrency, useFormatNumber } from "@/composables/formatters";
import VaDataBadge from "@/components/molecules/VaDataBadge.vue";
import DateFilters from "@/components/organisms/charts/DateFilters.vue";

const { t } = useI18n();
const statsStore = useStatsStore();
const chartRef = ref(null);

const stats = computed(() => statsStore.ordersStats);

const chartConfig = computed(() => {
  const outCongif = useChartConfig(
    stats?.value?.statisticsResponse,
    statsStore.query.showPrevious,
    "sellOut",
    t("sellOut"),
    undefined,
    stats?.value?.currency.symbol
  );
  const inCongif = useChartConfig(
    stats?.value?.statisticsResponseIn,
    statsStore.query.showPrevious,
    "sellIn",
    t("sellIn"),
    true
  );
  if (outCongif && inCongif) {
    outCongif.data.datasets = outCongif.data.datasets.concat(
      inCongif.data.datasets
    );
  }
  return outCongif;
});

const sellOutPieChartConfig = computed(() => {
  return usePieChartConfig(
    stats?.value?.sellOutPieStatistics,
    "sellOutPie",
    stats?.value?.currency.symbol || ""
  );
});
const sellOutSkuPieChartConfig = computed(() => {
  return usePieChartConfig(
    stats?.value?.sellOutSkuPieStatistics,
    "sellOutPie",
    stats?.value?.currency.symbol || ""
  );
});
</script>
