<template>
  <div v-if="type" id="productDetail" class="va-card mt-6">
    <div class="px-4 py-5 sm:px-6 flex">
      <div class="grow">
        <va-page-title> {{ t("productTypeEdit") }}</va-page-title>
        <!--        {{ type }} <br /><br /><br /><br /><br />-->
        <!--        {{ formState }}-->
        <p class="va-subtitle mb-4">{{ type.description }}</p>
        <va-back-link :target="`/products/types/${route.params.id}`">
          {{ t("back") }}
        </va-back-link>
      </div>

      <va-delete-button
        class="mr-4"
        :disabled="!productTypesStore.canDelete"
        :confirm-text="t('productRemoveDescription')"
        :confirm-title="t('productRemove')"
        @click:confirm="removeType"
      />
    </div>
    <div class="px-4 py-5 sm:px-6">
      <a-form
        v-if="formState"
        class=""
        :model="formState"
        :validate-messages="validateMessages"
        @finish="onSave"
      >
        <a-form-item
          :name="['name']"
          :label="t('name')"
          :rules="[{ required: true }]"
          :disabled="productTypesStore.isLoading"
        >
          <va-input v-model="formState.name" :placeholder="t('name')" />
        </a-form-item>
        <a-form-item
          :name="['description']"
          :label="t('description')"
          :disabled="productTypesStore.isLoading"
        >
          <va-input
            v-model="formState.description"
            :placeholder="t('description')"
          />
        </a-form-item>
        <product-features-edit
          v-if="formState.features"
          :features="formState.features"
          @update="updateFeatures"
        />
        <a-form-item>
          <va-button
            html-type="submit"
            class="float-right mt-8"
            :disabled="productTypesStore.isLoading"
            :loading="productTypesStore.isLoading"
          >
            {{ t("save") }}
          </va-button>
        </a-form-item>
      </a-form>
    </div>
  </div>

  <!--  <div v-if="type && type.features.length" class="va-card mt-6 p-4 sm:px-6">-->
  <!--    <product-features-table :features="type.features" class="mt-3" />-->
  <!--  </div>-->
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { t } from "@/plugins/i18n";
import VaButton from "@/components/molecules/VaButton.vue";
import VaDeleteButton from "@/components/molecules/VaDeleteButton.vue";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import { useProductTypesStore } from "@/stores/productTypes";
import { useValidationMessages } from "@/composables/validation";
import VaInput from "@/components/molecules/VaInput.vue";
import clone from "lodash/clone";
// import { useProductFeaturesStore } from "@/stores/productFeatures";
import { ProductType } from "@/models/productType.interface";
import ProductFeaturesEdit from "@/components/organisms/ProductFeaturesEdit.vue";
import { ProductFeature } from "@/models/productFeature.interface";
import { useMeta } from "vue-meta";

useMeta({
  title: "Products",
});
const route = useRoute();
const validateMessages = useValidationMessages();

// const productFeaturesStore = useProductFeaturesStore();
// productFeaturesStore.fetchProductFeatures();

const productTypesStore = useProductTypesStore();

const type = computed(() => productTypesStore.currentType);

const formState = ref(clone(productTypesStore.currentType));

watch(type, (currentValue) => {
  formState.value = clone(currentValue);
});

// const featureCodes = computed(() =>
//   formState.value?.features?.map((f) => f.id)
// );

const updateFeatures = (args: ProductFeature[]) => {
  if (formState.value) formState.value.features = args;
};

// const changeFeatures = (args: string[]) => {
//   formState.value.features = productFeaturesStore.items.filter((t) => {
//     return args.includes(t.id);
//   });
// };

onBeforeMount(() => {
  productTypesStore.getType(route.params.id as string);
});

const onSave = () => {
  productTypesStore.saveType(formState.value as ProductType);
};

const removeType = () => {
  productTypesStore.removeType(route.params.id as string);
};
</script>
