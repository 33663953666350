import OrderModel, {
  Order,
  OrderFormData,
  tableConfig,
} from "@/models/order.interface";
import axios from "axios";
import * as configService from "@/services/configService";
import {
  SearchOptions,
  searchOptionsSerialize,
} from "@/models/listable.interface";

export async function get(id: string): Promise<Order> {
  const res = await axios.get(`${configService.ordersUrl}/${id}`);
  return OrderModel.deserialize(res.data);
}

export async function search(
  options: SearchOptions
): Promise<{ items: Order[]; count: number }> {
  const opt = options ? searchOptionsSerialize(options, tableConfig) : {};
  const res = await axios.post(configService.ordersSearchUrl, opt);
  return {
    items: res.data.items.map(OrderModel.deserialize),
    count: res.data.count,
  };
}

export async function sellOutSearch(
  options: SearchOptions
): Promise<{ items: Order[]; count: number }> {
  const opt = options ? searchOptionsSerialize(options, tableConfig) : {};
  const res = await axios.post(configService.ordersOutSearchUrl, opt);
  return {
    items: res.data.items.map(OrderModel.deserialize),
    count: res.data.count,
  };
}

export async function fetchAllByBuyerId(id: string): Promise<Order[]> {
  /** returns all the orders associated to the account id **/
  const res = await axios.post(configService.ordersSearchUrl, {
    limit: configService.maxApiResults,
    parentAccountId: id,
  });
  return res.data.items.map(OrderModel.deserialize);
}

export async function post(data: OrderFormData): Promise<string> {
  const res = await axios.post(`${configService.ordersUrl}`, data);
  return res.data;
}

export async function put(id: string, data: OrderFormData): Promise<string> {
  const res = await axios.put(`${configService.ordersUrl}/${id}`, data);
  return res.data;
}
export async function update(
  id: string,
  invoiced = false,
  paid = false,
  codeRef?: string,
  notes?: string
): Promise<string> {
  const res = await axios.put(`${configService.ordersUrl}/${id}/update`, {
    invoiced: invoiced,
    paid: paid,
    orderRefCode: codeRef,
    orderNote: notes,
  });
  return res.data;
}
