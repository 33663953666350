import { getBrowserVersion } from "@/services/browserVersionService";

export interface LoginData {
  //federate
  oauthProviderType?: number;
  callbackUrl?: string;
  authCode?: string;
  //plain
  email?: string;
  password?: string;
  //mfa
  mfaToken?: string;
  mfaCode?: string;
  redirect?: string;
}
export default {
  deserialize(input: LoginData): LoginData {
    return {
      email: input.email,
      password: input.password,
      mfaToken: input.mfaToken,
      mfaCode: input.mfaCode,
      oauthProviderType: input.oauthProviderType,
      callbackUrl: input.callbackUrl,
      authCode: input.authCode,
    };
  },

  serialize(input: LoginData): unknown {
    return {
      deviceUniqueId: getBrowserVersion(),
      appPlatform: "web",
      grantType: input.oauthProviderType ? "access_token" : "password",
      //plain
      email: input.email,
      password: input.password,
      //mfa
      mfaToken: input.mfaToken,
      mfaCode: input.mfaCode,
      //federate
      oauthProviderType: input.oauthProviderType,
      callbackUrl: input.callbackUrl,
      authCode: input.authCode,
    };
  },
};
