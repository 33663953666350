import { defineStore } from "pinia";
import logger from "@/services/loggerService";
import { t } from "@/plugins/i18n";
import * as listable from "./listableMixin";
import * as permissions from "./permissionMixin";
import router from "@/router";
import { cloneDeep } from "lodash";
import { defaultListableState } from "./listableMixin";
import { useNotificationsStore } from "@/stores/notifications";
import {
  Account,
  AccountFormData,
  AccountUserFormData,
} from "@/models/account.interface";
import * as accountsService from "@/services/accountsService";
import { Address } from "@/models/address.interface";
import { useAuthStore } from "@/stores/auth";
import { allItemsSearchOptions } from "@/services/configService";
import { buildOption, SelectOption } from "@/models/selectOptions.interface";

export interface AccountsState
  extends listable.ListableMixinState,
    permissions.PermissionsMixinState {
  items: Account[];
  currentAccount?: Account;
  currentChildren: Account[];
  currentParent?: string;
  fetchingChildren: boolean;
}

export const useAccountsStore = defineStore({
  id: "accounts",
  state: (): AccountsState => ({
    ...cloneDeep(defaultListableState),
    moduleName: "Accounts",
    currentAccount: undefined,
    currentChildren: [],
    fetchingChildren: false,
    searchFnc: accountsService.search,
  }),
  getters: {
    ...permissions.getters,
    currentChildrenOptions: (state): SelectOption<Account>[] => {
      return state.currentChildren.map((child) => {
        return buildOption<Account>(child, "id", "name");
      });
    },
    currentNetworkOptions: (state): SelectOption<Account>[] => {
      const children = state.currentChildren.map((child) => {
        return buildOption<Account>(child, "id", "name");
      });
      const authStore = useAuthStore();
      if (authStore.userAccount) {
        children.push({
          value: authStore.userAccount.id,
          label: authStore.userAccount.name,
          fullItem: authStore.userAccount,
        });
      }
      return children;
    },
  },
  actions: {
    ...listable.actions,
    async get(id: string) {
      if (!id || this.currentAccount?.id === id) return;
      const notifications = useNotificationsStore();
      this.isLoading = true;
      try {
        this.currentAccount = await accountsService.get(id);
      } catch (err) {
        logger.error(err);
        notifications.error(t("accountsGetError"));
      } finally {
        this.isLoading = false;
      }
    },
    async fetch() {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        this.items = await accountsService.fetch();
      } catch (err) {
        logger.error(err);
        notifications.error(t("accountsFetchError"));
      } finally {
        this.isLoading = false;
      }
    },
    async create(formData: AccountFormData) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      const data = cloneDeep(formData);
      try {
        if (data.user?.userName == "") {
          data.user = undefined;
        }
        const res = await accountsService.post(data);
        router.push(`/accounts/${res}`);
      } catch (err) {
        logger.error(err);
        notifications.error(t("accountsGetError"));
      } finally {
        this.isLoading = false;
        this.$reset();
      }
    },
    async cancel(id: string) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await accountsService.cancel(id);
        this.currentAccount = undefined;
        this.items = this.items.filter((i) => {
          i.id != id;
        });
        router.push("/accounts");
      } catch (err) {
        logger.error(err);
        notifications.error(t("accountsGetError"));
      } finally {
        this.isLoading = false;
      }
    },
    async createUser(formData: AccountUserFormData) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await accountsService.createUser(formData);
        notifications.success(t("newUserSuccess"));
      } catch (err) {
        logger.error(err);
        notifications.error(t("newUserError"));
      } finally {
        this.isLoading = false;
      }
    },
    async fetchChildren(id?: string) {
      if (!id) {
        const authState = useAuthStore();
        id = authState.user?.accountId || "";
      }
      const notifications = useNotificationsStore();
      if (
        !this.fetchingChildren &&
        (this.currentParent !== id || this.currentChildren.length == 0)
      ) {
        this.isLoading = true;
        this.fetchingChildren = true;
        this.currentParent = id;
        try {
          const childrenOptions = {
            ...allItemsSearchOptions,
            sorter: undefined,
            filters: undefined,
            parentAccountId: id,
            includeChildren: true,
          };
          const res = await accountsService.searchChildren(childrenOptions);
          this.currentChildren = res.items.filter((child) => {
            return child.id !== id;
          });
        } catch (err) {
          logger.error(err);
          notifications.error(t("accountsFetchError"));
        } finally {
          this.fetchingChildren = false;
          this.isLoading = false;
        }
      }
    },
    async updateCurrentAccountAddress(address: Address) {
      const id = this.currentAccount?.id;
      if (this.currentAccount && id) {
        const authStore = useAuthStore();
        this.isLoading = true;
        const notifications = useNotificationsStore();
        try {
          this.currentAccount.address = await accountsService.putAddress(
            address,
            id
          );
          notifications.success(t("accountUpdateSuccess"));
          //UPDATE auth state
          if (authStore.userAccount?.id == id) {
            const updatedUserAccount = cloneDeep(authStore.userAccount);
            updatedUserAccount.address = this.currentAccount.address;
            authStore.setUserAccount(updatedUserAccount);
          }
          // else {
          //   authStore.recoverLogin();
          // }
        } catch (err) {
          logger.error(err);
          notifications.error(t("accountsPutError"));
        } finally {
          this.isLoading = false;
        }
      }
    },
    async updateCurrentAccount(account: Account) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      const authStore = useAuthStore();
      try {
        this.currentAccount = await accountsService.put(account);
        notifications.success(t("accountUpdateSuccess"));
        //UPDATE auth state
        if (authStore.userAccount?.id == this.currentAccount.id) {
          authStore.setUserAccount(this.currentAccount);
        }
      } catch (err) {
        logger.error(err);
        notifications.error(t("accountsPutError"));
      } finally {
        this.isLoading = false;
      }
    },
    resetCurrentAccount() {
      const authStore = useAuthStore();
      this.currentAccount = authStore.userAccount;
      this.items = [];
    },
  },
});
