<template>
  <div v-if="license" id="licenseDetail">
    <div class="flex justify-between">
      <va-page-title>
        {{ t("license") }} {{ license?.serialKey }}
      </va-page-title>
      <div>
        <license-export-btn :account-id="accountId" :license="license" />
        <va-button @click="copyKey">
          <i class="rm rm-ico-document-copy" />
        </va-button>
      </div>
    </div>
    <va-back-link target="/licenses">{{ t("backToLicenses") }}</va-back-link>
    <div class="va-card mt-6">
      <div class="flex justify-between">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="va-title-s">
            {{ license?.serialKey }}
          </h3>
          <p class="va-subtitle">
            <span class="mr-4">
              {{ t("owner") }}: {{ license.ownerAccount.name }}
            </span>
            {{ t("hasBeenActivated") }}
            <va-check-icon :check="license.licenseHasBeenActivated" />
            <span v-if="license.product.environmentType == 2">
              {{ t("hasBeenExported") }}
              <va-check-icon :check="license.licenseHasBeenExported" />
            </span>

            <span v-if="license.derivedOrderId" class="ml-4">
              -
              <router-link
                class="text-indigo-700 ml-4 font-semibold"
                :to="`/sell-in/${license.derivedOrderId}`"
              >
                {{ t("orderReference") }}
              </router-link>
            </span>
          </p>
        </div>
        <div class="px-10 py-5 sm:px-6 text-center">
          <h3 class="va-title-s">{{ t("seats") }}</h3>
          <dd class="mt-1 text-sm font-bold">
            {{ license.seatsOccupied }} / {{ license.seats }}
          </dd>
        </div>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">
              {{ t("creationDate") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ formatDate(license.creationDate) }}
            </dd>
          </div>
          <div class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">
              {{ t("activationDate") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ formatDate(license.activationDate) }}
            </dd>
          </div>
          <div class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">
              {{ t("expirationDate") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              <div v-if="license.licenseIsPerpetual">
                <a-tag color="success">
                  {{ t("perpetual") }}
                </a-tag>
              </div>
              <div v-else-if="license.product.islicenseUpgradeRight">
                <a-tag color="success">
                  {{ t("notExpired") }}
                </a-tag>
              </div>
              <div v-else>
                {{
                  license.expirationDate
                    ? formatDate(license.expirationDate)
                    : t("noExpiration")
                }}
              </div>
            </dd>
          </div>
          <div class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">
              {{ t("maintenanceExpirationDate") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              <div v-if="license.maintenanceIsPerpetual">
                <a-tag color="success">
                  {{ t("perpetual") }}
                </a-tag>
              </div>
              <div v-else-if="license.product.islicenseUpgradeRight">
                <a-tag color="success">
                  {{ t("notExpired") }}
                </a-tag>
              </div>
              <div v-else>
                {{
                  license.maintenanceExpirationDate
                    ? formatDate(license.maintenanceExpirationDate)
                    : t("noExpiration")
                }}
              </div>
            </dd>
          </div>
        </dl>
        <dt class="text-sm font-medium text-gray-500 mt-6">
          {{ t("notes") }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <div class="border rounded p-3 font-sans">{{ license.notes }}</div>
          <div class="flex justify-end">
            <va-button class="mt-2" @click="openNotesDialog">
              {{ t("change") }}
            </va-button>
          </div>
          <va-dialog v-model="notesDialog">
            <va-input
              v-model="notes"
              type="textarea"
              :placeholder="t('notes')"
            />
            <div class="flex justify-end mt-4">
              <va-button class="mr-4" @click="notesDialog = false">
                {{ t("dismiss") }}
              </va-button>
              <va-button @click="updateNotes">
                {{ t("save") }}
              </va-button>
            </div>
          </va-dialog>
        </dd>
      </div>
    </div>

    <div v-if="license.product" class="va-card mt-6">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="va-title-s">{{ t("product") }}</h3>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div v-if="license.product" class="sm:col-span-2">
          <dl class="sm:divide-y sm:divide-gray-200">
            <va-info-row :title="t('name')">
              {{ license.product.name }}
            </va-info-row>
            <va-info-row :title="t('sku')">
              {{ license.product.sku }}
            </va-info-row>
            <va-info-row :title="t('description')">
              {{ license.product.description }}
            </va-info-row>
            <va-info-row :title="t('islicenseUpgradeRight')">
              <va-check-icon :check="license.product.islicenseUpgradeRight" />
            </va-info-row>
            <va-info-row :title="t('isLicenseTrial')">
              <va-check-icon :check="license.product.isLicenseTrial" />
            </va-info-row>
          </dl>
        </div>
      </div>
    </div>

    <div v-if="license.licenseSeats.length" class="va-card mt-6">
      <div class="px-4 py-5 sm:px-6 flex justify-between">
        <h3 class="va-title-s">{{ t("seats") }}</h3>
        <div>
          <!--          <va-button v-if="!isProduction" class="mr-2" @click="fullDeleteSeats">-->
          <!--            Full delete<i class="rm rm-ico-warning-sign ml-1" />-->
          <!--          </va-button>-->
          <va-delete-button
            v-if="licensesStore.canDeleteSeat"
            :disabled="licensesStore.isLoading"
            :confirm-text="t('clearSeatConfirm')"
            :confirm-title="t('clearSeatTitle')"
            @click:confirm="licensesStore.clearAllSeats"
          />
        </div>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div class="sm:col-span-2">
          <dd class="mt-1 text-sm text-gray-900">
            <table class="min-w-full divide-y divide-gray-300 mt-4">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ t("deviceName") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ t("deviceDescription") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ t("installedProduct") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ t("active") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ t("suspended") }}
                  </th>
                  <th
                    v-if="license.product.isLicenseTrial"
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ t("revoked") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ t("currentTrialStep") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ t("creationDate") }}
                  </th>
                  <th
                    v-if="licensesStore.canDeleteSeat"
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ t("actions") }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="seat in license.licenseSeats" :key="seat.id">
                  <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{ seat.deviceName }} <br />
                    <span class="text-xs"
                      >{{ t("deviceUniqueId") }}: {{ seat.deviceUuid }}</span
                    >
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{ seat.deviceDescription }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{ seat.installedProduct }} {{ seat.installedVersion }}
                  </td>
                  <!--                  <td class="whitespace-nowrap px-3 py-4 text-sm">-->
                  <!--                    {{ seat.installedVersion }}-->
                  <!--                  </td>-->
                  <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <va-check-icon :check="seat.active" />
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <va-check-icon :check="seat.suspended" />
                  </td>
                  <td
                    v-if="license.product.isLicenseTrial"
                    class="whitespace-nowrap px-3 py-4 text-sm"
                  >
                    <va-check-icon :check="seat.revoked" />
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{ seat.currentTrialStep }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <a-tooltip>
                      <template #title>{{
                        formatDateTime(seat.creationDate)
                      }}</template>
                      {{ formatDate(seat.creationDate) }}
                    </a-tooltip>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <va-delete-button
                      v-if="licensesStore.canDeleteSeat && !seat.suspended"
                      :disabled="licensesStore.isLoading"
                      :confirm-text="t('clearSeatConfirm')"
                      :confirm-title="t('clearSeatTitle')"
                      @click:confirm="licensesStore.clearSeat(seat)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </dd>
        </div>
      </div>
    </div>

    <div v-if="license.licenseDetails.length" class="va-card mt-6">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="va-title-s">{{ t("licenseHistory") }}</h3>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div class="sm:col-span-2">
          <!--        <dt class="text-sm font-medium text-gray-500">-->
          <!--          {{ t("licenseHistory") }}-->
          <!--        </dt>-->
          <dd class="mt-1 text-sm text-gray-900">
            <table class="min-w-full divide-y divide-gray-300 mt-4">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ t("serialKey") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ t("duration") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ t("licenseMaintenance") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ t("creationDate") }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="detail in license.licenseDetails" :key="detail.id">
                  <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{ detail?.serialKey }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <div v-if="detail?.licenseIsPerpetual">
                      <a-tag color="success">
                        {{ t("perpetual") }}
                      </a-tag>
                    </div>
                    <div v-else>
                      {{ t("years") }}: {{ detail?.durationYears }}
                      {{ t("months") }}: {{ detail?.durationMonths }}
                      {{ t("days") }}: {{ detail?.durationDays }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <div v-if="detail?.maintenanceIsPerpetual">
                      <a-tag color="success">
                        {{ t("perpetual") }}
                      </a-tag>
                    </div>
                    <div v-else>
                      {{ t("years") }}: {{ detail?.maintenanceDurationYears }}
                      {{ t("months") }}:
                      {{ detail?.maintenanceDurationMonths }} {{ t("days") }}:
                      {{ detail?.maintenanceDurationDays }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{ formatDate(detail.creationDate) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </dd>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import { t } from "@/plugins/i18n";
import VaInfoRow from "@/components/atoms/VaInfoRow.vue";
import VaCheckIcon from "@/components/atoms/VaCheckIcon.vue";
import { useFormatDate, useFormatDateTime } from "@/composables/formatters";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaDialog from "@/components/molecules/VaDialog.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import { useLicensesStore } from "@/stores/licenses";
import { useNotificationsStore } from "@/stores/notifications";
import { useAccountsStore } from "@/stores/accounts";
import LicenseExportBtn from "@/components/organisms/LicenseExportBtn.vue";
import VaDeleteButton from "@/components/molecules/VaDeleteButton.vue";
import { useMeta } from "vue-meta";

useMeta({
  title: "Licenses",
});
const route = useRoute();
const accountsStore = useAccountsStore();
const licensesStore = useLicensesStore();
const notifications = useNotificationsStore();
const formatDate = useFormatDate;
const formatDateTime = useFormatDateTime;

const notesDialog = ref(false);
const notes = ref("");

const license = computed(() => licensesStore.currentLicense);

const accountId = computed(() => {
  return accountsStore.currentAccount?.id;
});

const copyKey = () => {
  if (license.value) {
    navigator.clipboard.writeText(license.value.serialKey);
    notifications.info(t("licenseKeyCopied"));
  }
};

// const fullDeleteSeats = async () => {
//   if (!isProduction) {
//     await licensesStore.fullDeleteSeats();
//   }
// };
const openNotesDialog = () => {
  if (license.value) {
    notesDialog.value = true;
    notes.value = license.value.notes || "";
  }
};

const updateNotes = async () => {
  await licensesStore.updateNotes(notes.value);
  notesDialog.value = false;
};

onBeforeMount(() => {
  licensesStore.get(route.params.id as string);
  accountsStore.fetchChildren();
});
</script>
