<template>
  <va-page-title>{{ t("newSellOut") }}</va-page-title>
  <va-back-link target="/sell-out">{{ t("backToOrders") }}</va-back-link>
  <div class="va-card mt-6 relative t-4 px-4">
    <a-form
      class=""
      :model="formState"
      :validate-messages="validateMessages"
      @finish="onFinish"
    >
      <div class="grid grid-cols-1 gap-4 mt-4">
        <a-form-item
          :name="['buyerAccountId']"
          :label="t('Buyer')"
          :rules="[{ required: true }]"
        >
          <va-select
            v-model="formState.buyerAccountId"
            :options="accountsStore.currentChildrenOptions"
            :placeholder="t('Buyer')"
            :disabled="productsStore.isLoading || !orderStore.canCreate"
            @change="selectBuyer"
          >
            <template #option="option">
              <option-account :item="option.nestedProps.fullItem" />
            </template>
          </va-select>
        </a-form-item>

        <a-form-item :name="['orderRefCode']" :label="t('refCode')">
          <va-input
            v-model="formState.orderRefCode"
            :placeholder="t('refCode')"
          />
        </a-form-item>

        <a-form-item :name="['orderNote']" :label="t('notes')">
          <va-input
            v-model="formState.orderNote"
            type="textarea"
            :placeholder="t('notes')"
          />
        </a-form-item>
      </div>

      <div class="my-4">
        {{ t("addProduct") }}:
        <div class="flex mt-3">
          <div class="grow mr-4">
            <a-form-item :label="t('product')">
              <va-select
                v-model="selectedProduct"
                :options="productsStore.productOptions"
                :placeholder="t('product')"
                :disabled="
                  !formState.buyerAccountId ||
                  productsStore.isLoading ||
                  !orderStore.canCreate
                "
                :not-found-content="t('notAvailableProducts')"
                @change="selectProduct"
              >
                <template #option="option">
                  <option-product :item="option.nestedProps.fullItem" />
                </template>
              </va-select>
            </a-form-item>
          </div>
          <a-form-item :label="t('quantity')">
            <va-input
              v-model="selectedQuantity"
              type="number"
              :placeholder="t('quantity')"
              :min="1"
              integer
              class="mr-4"
            >
            </va-input>
          </a-form-item>
          <a-form-item :label="t('price')">
            <va-input
              v-model="selectedPrice"
              type="number"
              :suffix="pricelistStore.currentPriceList?.currency.symbol"
              :placeholder="t('price')"
              :min="0"
              class="mr-4"
            >
            </va-input>
          </a-form-item>

          <va-button
            class="float-right mt-8"
            :disabled="!orderStore.canCreate || selectedProduct === ''"
            @click="addProduct"
          >
            <i class="rm rm-ico-e-add" />
          </va-button>
        </div>
        <table class="min-w-full divide-y divide-gray-300 mt-4">
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {{ t("product") }}
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {{ t("quantity") }}
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {{ t("fullPrice") }}
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {{ t("orderPrice") }}
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {{ t("total") }}
              </th>

              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              ></th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-for="(item, index) in formState.items" :key="index">
              <td class="whitespace-nowrap px-3 py-4 text-sm">
                {{ item.fullItem?.name }}
                <div class="text-xs text-gray-500">
                  {{ item.fullItem?.sku }}
                </div>
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-right">
                {{ item.quantity }}
              </td>
              <td
                class="whitespace-nowrap px-3 py-4 text-sm text-right text-gray-500 line-through"
              >
                {{
                  useFormatCurrency(
                    item.fullItem?.customPrice,
                    item.fullItem?.customPriceCurrency
                  )
                }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-right">
                {{
                  useFormatCurrency(
                    item.price || 0,
                    item.fullItem?.customPriceCurrency
                  )
                }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-right">
                {{
                  useFormatCurrency(
                    (item.price || 0) * item.quantity,
                    item.fullItem?.customPriceCurrency
                  )
                }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm">
                <va-button
                  class="float-right"
                  :disabled="!orderStore.canCreate"
                  @click="removeProduct(index)"
                >
                  <i class="rm rm-ico-bin" />
                </va-button>
              </td>
            </tr>
            <tr>
              <td colspan="4" class="whitespace-nowrap px-3 py-4 text-sm"></td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-right">
                {{ t("orderTotal") }}:
                <span class="font-bold">
                  {{
                    useFormatCurrency(
                      total,
                      pricelistStore.currentPriceList?.currency
                    )
                  }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <stock-error :error-detail="orderStore.creationError" />

      <a-form-item>
        <va-button
          class="float-right"
          :disabled="
            !orderStore.canCreate ||
            !formState.items.length ||
            orderStore.isLoading
          "
          :loading="orderStore.isLoading"
          @click="confirmCreateDialog = true"
        >
          {{ t("save") }}
        </va-button>
      </a-form-item>
    </a-form>
    <va-confirm
      v-model="confirmCreateDialog"
      :title="t('confirmSellOutTitle')"
      @click:confirm="onFinish"
    >
      {{ t("confirmSellOutDescription") }}
    </va-confirm>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import { t } from "@/plugins/i18n";
import { useValidationMessages } from "@/composables/validation";
import { OrderFormData } from "@/models/order.interface";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import VaConfirm from "@/components/molecules/VaConfirm.vue";
import { useFormatCurrency } from "@/composables/formatters";
import VaSelect from "@/components/molecules/VaSelect.vue";
import OptionAccount from "@/components/molecules/selectOptions/OptionAccount.vue";
import OptionProduct from "@/components/molecules/selectOptions/OptionProduct.vue";
import StockError from "@/components/organisms/StockError.vue";
import { useProductsStore } from "@/stores/products";
import { useOrdersStore } from "@/stores/orders";
import { useAccountsStore } from "@/stores/accounts";
import { SelectOption } from "@/models/selectOptions.interface";
import { PriceListProduct } from "@/models/priceListProduct.interface";
import { Account } from "@/models/account.interface";
import { usePricelistsStore } from "@/stores/pricelists";
import { useMeta } from "vue-meta";

useMeta({
  title: "Orders Sell Out",
});
const accountsStore = useAccountsStore();
const productsStore = useProductsStore();
const orderStore = useOrdersStore();
const validateMessages = useValidationMessages();
const pricelistStore = usePricelistsStore();

const selectedProduct = ref("");
const selectedQuantity = ref(1);
const selectedPrice = ref(0);
const confirmCreateDialog = ref(false);
const formState = ref({
  source: "api",
  sourceData: "",
  buyerAccountId: undefined,
  addressId: "",
  orderNote: "",
  orderRefCode: "",
  items: [],
  parentAccountId: undefined,
} as OrderFormData);

const total = computed(() => {
  return formState.value.items.reduce(function (a, b) {
    return a + (b.price ? b.price : 0) * b.quantity;
  }, 0);
});

const selectProduct = (item: SelectOption<PriceListProduct>) => {
  selectedPrice.value = item.fullItem.customPrice;
  selectedQuantity.value = 1;
};

const selectBuyer = (item: SelectOption<Account>) => {
  formState.value.addressId = item.fullItem.address?.id;
  selectedProduct.value = "";
  formState.value.items = [];
  productsStore.fetchAvailableToBuyByBuyerId(item.value);
  pricelistStore.getAssociatedPriceList(item.value);
};

const onFinish = () => {
  orderStore.create(formState.value);
};

const addProduct = () => {
  if (selectedQuantity.value) {
    formState.value.items.push({
      productId: selectedProduct.value,
      quantity: selectedQuantity.value,
      price: selectedPrice.value,
      fullItem: productsStore.productOptions.find((p) => {
        return p.value === selectedProduct.value;
      })?.fullItem as PriceListProduct,
    });
  }
};

const removeProduct = (index: number) => {
  formState.value.items.splice(index, 1);
};

onBeforeMount(() => {
  accountsStore.fetchChildren();
  orderStore.cleanStockErrors();
});
</script>
