<template>
  <div v-if="certificate" id="certificateDetail">
    <div class="flex justify-between mt-8">
      <va-page-title>
        {{ t("certificate") }} {{ certificate?.certificateName }}
      </va-page-title>
      <div>
        <va-button
          class="mr-4"
          :disabled="!certificatesStore.canUpdate"
          @click="renewCertification"
        >
          {{ t("renewCertificate") }}
        </va-button>
      </div>
    </div>
    <va-back-link :target="`/accounts/${route.params.id}/certifications`">{{
      t("backToCertifications")
    }}</va-back-link>
    <div class="va-card mt-6">
      <div class="flex justify-between items-center px-4 py-5 sm:px-6">
        <div>
          <dt class="text-sm font-medium text-gray-500">
            {{ t("owner") }}
          </dt>
          <h3 class="va-title-s">
            {{ certificate.firstName }}
            {{ certificate.lastName }}
          </h3>
        </div>
        <va-button class="mr-4" :loading="isLoadingPdf" @click="downloadPdf">{{
          t("downloadCertificate")
        }}</va-button>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          {{ t("validationCode") }}
        </dt>
        <p>{{ certificate.validationCode }}</p>
        <div>
          <a id="validate-link" @click="goToValidationPage">{{
            t("validationPage")
          }}</a>
        </div>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div>
            <dt class="text-sm font-medium text-gray-500">
              {{ t("certificationCreationDate") }}
            </dt>
            {{ formatDateUTC(certificate.creationDate) }}
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500">
              {{ t("certificationExpirationDate") }}
            </dt>
            {{ formatDateUTC(certificate.expirationDate) }}
          </div>
        </dl>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2"></dl>
        <dt class="text-sm font-medium text-gray-500">
          {{ t("notes") }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <div class="border rounded p-3 font-sans">
            {{ certificate.notes }}
          </div>
          <div class="flex justify-end">
            <va-button
              class="mt-2"
              :disabled="!certificatesStore.canUpdate"
              @click="openNotesDialog"
            >
              {{ t("change") }}
            </va-button>
          </div>
          <va-dialog v-model="notesDialog">
            <va-input
              v-model="notes"
              type="textarea"
              :placeholder="t('notes')"
            />
            <div class="flex justify-end mt-4">
              <va-button class="mr-4" @click="notesDialog = false">
                {{ t("dismiss") }}
              </va-button>
              <va-button
                :loading="certificatesStore.isLoading"
                @click="updateNotes"
              >
                {{ t("save") }}
              </va-button>
            </div>
          </va-dialog>
        </dd>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import { t } from "@/plugins/i18n";
import { useFormatDateUTC } from "@/composables/formatters";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaDialog from "@/components/molecules/VaDialog.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import * as certificationService from "@/services/certificationsService";
import router from "@/router";
import { useCertificatesStore } from "@/stores/certificates";

const route = useRoute();
const certificatesStore = useCertificatesStore();
const formatDateUTC = useFormatDateUTC;

const notesDialog = ref(false);
const notes = ref("");

const certificate = computed(() => {
  return certificatesStore.currentUserCertification;
});

const isLoadingPdf = ref(false);

const openNotesDialog = () => {
  notesDialog.value = true;
  notes.value = certificate.value?.notes || "";
};

const updateNotes = async () => {
  if (certificate.value) {
    await certificatesStore.updateCertificationsNotes(
      certificate.value?.idUserCertificate,
      notes.value,
      "User"
    );
    notesDialog.value = false;
  }
};

async function downloadPdf() {
  if (certificate.value) {
    isLoadingPdf.value = true;
    const data = await certificationService.downloadPdf(
      certificate.value.idUserCertificate,
      "User"
    );
    const link = document.createElement("a");
    link.download = `${certificate.value.validationCode} - ${certificate.value.firstName} ${certificate.value.lastName}- ${certificate.value.certificateName}.pdf`;
    link.href = `data:application/pdf;base64,${data}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    isLoadingPdf.value = false;
  }
}

function goToValidationPage() {
  const route = router.resolve({
    path: `/verify?code=${certificate.value?.validationCode}`,
  });
  window.open(
    `${route.href}?code=${certificate.value?.validationCode}`,
    "_blank"
  );
}

onBeforeMount(() => {
  certificatesStore.getUserCertification(route.params.idCertificate as string);
});

const renewCertification = () => {
  if (certificate?.value?.idUserCertificate) {
    certificatesStore.renewCertification(
      certificate.value.idUserCertificate,
      "User"
    );
  }
};
</script>

<style lang="scss">
#cta-button {
  align-items: self-end;
  display: flex;
}
#validate-link {
  &:hover {
    cursor: pointer;
  }
  color: #a164d1;
  text-decoration-line: underline;
}
</style>
