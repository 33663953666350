<template>
  <va-page-title class="mt-4">{{ t("profileEdit") }}</va-page-title>
  <div class="flex justify-between">
    <va-back-link target="/profile">{{ t("backToProfile") }}</va-back-link>

    <!--    <va-button @click="confirmDeleteDialog = true">-->
    <!--      <i class="rm rm-ico-bin" />-->
    <!--    </va-button>-->
  </div>
  <va-confirm
    v-model="confirmDeleteDialog"
    :title="t('userRemove')"
    @click:confirm="removeUser"
  >
    {{ t("userRemoveYourselfDescription") }}
  </va-confirm>
  <div class="va-card mt-6 relative pt-4 px-4">
    <a-form
      class=""
      :model="formState"
      :validate-messages="validateMessages"
      @finish="onFinish"
    >
      <div class="grid grid-cols-2 gap-4">
        <a-form-item
          :name="['firstName']"
          :label="t('firstName')"
          :rules="[{ required: true }]"
          :disabled="usersStore.isLoading"
        >
          <va-input
            v-model="formState.firstName"
            :placeholder="t('firstName')"
          />
        </a-form-item>
        <a-form-item
          :name="['lastName']"
          :label="t('lastName')"
          :rules="[{ required: true }]"
          :disabled="usersStore.isLoading"
        >
          <va-input v-model="formState.lastName" :placeholder="t('lastName')" />
        </a-form-item>
        <a-form-item
          :name="['timeZone']"
          :label="t('timeZone')"
          :rules="[{ required: true }]"
        >
          <va-select
            v-model="formState.timeZone"
            :options="timeZonesOptions"
            :disabled="usersStore.isLoading"
          />
        </a-form-item>
        <a-form-item
          :name="['timeZone']"
          :label="t('languageCode')"
          :rules="[{ required: true }]"
        >
          <va-select
            v-model="formState.languageCode"
            :options="langCodes"
            :disabled="usersStore.isLoading"
          />
        </a-form-item>

        <a-form-item
          :name="['isNotificationEnabled']"
          :disabled="usersStore.isLoading"
          class="flex-shrink"
        >
          {{ t("notificationEnabled") }}:
          <a-switch
            v-model:checked="formState.isNotificationEnabled"
            class="p-4"
          />
        </a-form-item>
        <a-form-item>
          <va-button
            html-type="submit"
            class="float-right"
            :loading="usersStore.isLoading"
          >
            {{ t("save") }}
          </va-button>
        </a-form-item>
      </div>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import { t } from "@/plugins/i18n";
import VaSelect from "@/components/molecules/VaSelect.vue";
import { useValidationMessages } from "@/composables/validation";
import { timeZones, langCodes } from "@/services/countriesService";
import VaConfirm from "@/components/molecules/VaConfirm.vue";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import { useUsersStore } from "@/stores/users";
import { useAuthStore } from "@/stores/auth";

const validateMessages = useValidationMessages();
const usersStore = useUsersStore();
const authStore = useAuthStore();

const confirmDeleteDialog = ref(false);

const timeZonesOptions = computed(() =>
  timeZones.map((tz) => {
    return {
      value: tz,
      label: tz,
    };
  })
);

const formState = ref({
  id: authStore.user?.id || "",
  firstName: authStore.user?.firstName || "",
  lastName: authStore.user?.lastName || "",
  timeZone: authStore.user?.timeZone || "",
  languageCode: authStore.user?.languageCode || "",
  isNotificationEnabled: authStore.user?.isNotificationEnabled || false,
});

const onFinish = () => {
  usersStore.updatePersonalData(formState.value);
};

const removeUser = () => {
  if (authStore.user) {
    usersStore.remove(authStore.user);
  }
};
</script>
