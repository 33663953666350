<template>
  <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-16 w-auto" :src="logoUrl" alt="Remago" />
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        {{ title }}
      </h2>
      <p class="mt-2 text-center text-sm text-gray-600">
        {{ subTitle }}
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="va-card py-8 px-4 sm:px-10">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import tenantService from "@/services/tenantService";

defineProps({
  title: {
    type: String,
    required: false,
    default: "",
  },
  subTitle: {
    type: String,
    required: false,
    default: "",
  },
});

const logoUrl = tenantService.getLogoAsset("logo-square.svg");
</script>
