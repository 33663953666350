<template>
  <div
    v-if="ordersStore.stockError.length"
    class="va-card w-full pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden mb-6"
  >
    <div class="p-4">
      <div class="flex items-start">
        <div class="flex-shrink-0">
          <span class="text-red-500">
            <i class="rm rm-ico-c-warning" />
          </span>
        </div>
        <div class="ml-3 w-0 flex-1 pt-0.5">
          <p class="text-sm mb-1 font-medium text-gray-900">
            <span v-if="ordersStore.creationError.length">{{
              t("insufficientStockError")
            }}</span>
            <span v-if="ordersStore.revokeError.length">{{
              t("orderRevokeError")
            }}</span>
          </p>
          <p
            v-for="item in ordersStore.stockError"
            :key="item.product"
            class="text-sm text-gray-500"
          >
            {{ item.product }}: {{ item.quantity }} {{ t("missing") }}
          </p>
          <p class="text-sm mt-2 mb-1">
            {{ t("pleasePurchase") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/plugins/i18n";
import { useOrdersStore } from "@/stores/orders";

const ordersStore = useOrdersStore();
</script>
