<template>
  <div class="flex flex-col">
    <div
      class="va-table shadow border-b border-gray-200 sm:rounded-lg mt-4"
      :class="{ 'opacity-30': loading, 'animate-pulse': loading }"
    >
      <a-table
        :data-source="internalFilter ? items : props.dataSource"
        :columns="columns"
        :pagination="props.hidePagination ? false : searchOptions.pagination"
        :filtered="true"
        show-size-changer
        :total="85"
        :show-total="(total: number) => `Total ${total} items`"
        @change="handleTableChange"
      >
        <template #bodyCell="{ column, text, record }">
          <slot
            name="bodyCell"
            :column="column"
            :text="text"
            :record="record"
          />
        </template>
        <template #title>
          <slot name="title"></slot>
        </template>
        <template v-if="$slots.footer" #footer>
          <slot name="footer"></slot>
        </template>
        <template #customFilterIcon="{}">
          <i class="rm rm-ico-search mr-3" />
        </template>
        <template
          #customFilterDropdown="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
        >
          <div class="p-4">
            <a-input
              class="focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md shadow-sm h-8 mb-2"
              :placeholder="`${t('search')} ${column.title}`"
              :value="selectedKeys[0]"
              @change="
                (e: any) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @press-enter="confirm"
            />
            <div class="flex flex-row-reverse mt-3">
              <va-button class="ml-2" @click="confirm"
                >{{ t("search") }}
              </va-button>
              <va-button @click="clearFilters">{{ t("reset") }}</va-button>
            </div>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from "vue";

import {
  deserializeColumns,
  FilterResult,
  SearchOptions,
  TableConfig,
} from "@/models/listable.interface";
import { PropType } from "vue";
import { SorterResult } from "ant-design-vue/es/table/interface";
import { PaginationProps } from "ant-design-vue";
import VaButton from "@/components/molecules/VaButton.vue";
import { t } from "@/plugins/i18n";
import { cloneDeep } from "lodash";

const props = defineProps({
  dataSource: {
    type: Array,
    required: true,
    default: undefined,
  },
  tableConfig: {
    type: Object as PropType<TableConfig>,
    required: true,
  },
  searchOptions: {
    type: Object as PropType<SearchOptions>,
    required: true,
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
  hidePagination: {
    type: Boolean,
    required: false,
    default: false,
  },
  internalFilter: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const items = ref(props.dataSource);

watch(
  () => props.dataSource,
  (selection) => {
    items.value = selection;
  }
);

const emit = defineEmits(["click:row", "update"]);

const columns = computed(() => {
  const cols = deserializeColumns(props.tableConfig);
  return cols.map((col) => {
    //mark column as filtered or sorted id relative searchOptions are set
    if (
      col.sorter &&
      props.searchOptions.sorter &&
      col.key === props.searchOptions.sorter?.column?.key
    ) {
      col.sortOrder = props.searchOptions.sorter.order;
    }
    if (
      col.customFilterDropdown &&
      props.searchOptions.filters &&
      props.searchOptions.filters[col.dataIndex as string]
    ) {
      col.filtered = true;
      col.filteredValue = props.searchOptions.filters[col.dataIndex as string];
    }
    return col;
  });
});

function handleTableChange(
  pagination: PaginationProps,
  filters: FilterResult,
  sorter: SorterResult
) {
  if (!props.internalFilter) {
    emit("update", {
      pagination: pagination,
      sorter: sorter,
      filters: filters,
    });
  } else {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    let res = cloneDeep(props.dataSource) as any[];

    if (res && filters) {
      for (const property in filters) {
        if (filters[property]) {
          res = res.filter((prod) => {
            return (
              prod[property] &&
              (prod[property] as string)
                .toUpperCase()
                .includes((filters[property][0] as string).toUpperCase())
            );
          });
        }
      }
    }
    if (res && sorter && sorter.columnKey) {
      const key = sorter.columnKey as string;
      res.sort((a, b) => {
        if (a[key].toUpperCase() < b[key].toUpperCase()) {
          return sorter.order == "descend" ? 1 : -1;
        }
        if (a[key].toUpperCase() > b[key].toUpperCase()) {
          return sorter.order == "descend" ? -1 : 1;
        }
        return 0;
      });
    }
    items.value = res;
  }
}

onBeforeMount(() => {
  emit("update", props.searchOptions);
});
</script>

<style lang="scss">
.va-table {
  .ant-empty-img-simple {
    margin: auto;
  }

  .ant-table {
    @apply rounded-lg;
  }

  .ant-table-title {
    @apply rounded-lg bg-gray-50 py-2 -mb-2;
  }

  .ant-table table {
    @apply rounded-lg;
    @apply min-w-full;
  }

  .ant-table-thead {
    @apply bg-gray-50 border-b;
  }

  .ant-table-tbody {
    @apply border-b;
  }

  .ant-table th {
    @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
  }

  .ant-table tbody > tr:nth-child(even) {
    @apply bg-white;
  }

  .ant-table tbody > tr:nth-child(odd) {
    @apply bg-gray-50;
  }

  //.ant-table tbody > tr > td {
  //  @apply px-6 py-4 whitespace-nowrap text-sm text-gray-900;
  //}

  .ant-pagination {
    @apply px-6;
    .ant-pagination-item,
    .ant-pagination-item-link {
      @apply focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md shadow-sm hover:text-indigo-500;
    }

    .ant-pagination-item-active a {
      @apply text-indigo-500;
    }

    .ant-pagination-item:focus-visible a,
    .ant-pagination-item:hover a,
    .ant-pagination-item-link:hover {
      @apply text-indigo-400;
    }

    .ant-pagination-prev svg,
    .ant-pagination-next svg {
      margin-top: -6px;
    }
  }

  .ant-select,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    @apply focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md shadow-sm hover:text-indigo-500;
  }

  //
  //.ant-pagination {
  //  @apply relative z-0 inline-flex rounded-md shadow-sm -space-x-px;
  //}
  //
  //.ant-pagination
  //  > li:not(.ant-pagination-item-active):not(:first-child):not(:last-child) {
  //  @apply bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium;
  //}
  //
  //.ant-pagination > li.ant-pagination-item-active {
  //  @apply z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium;
  //}
  //
  //.ant-pagination > li:first-child > .ant-pagination-item-link {
  //  @apply relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50;
  //}
  //
  //.ant-pagination > li:last-child > .ant-pagination-item-link {
  //  @apply relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50;
  //}
}
</style>
