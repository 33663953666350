<template>
  <div
    class="va-card px-4 py-5 sm:col-span-2"
    :class="{
      'opacity-30': dealsStore.isLoading,
      'animate-pulse': dealsStore.isLoading,
    }"
  >
    <h3 class="va-title-s">{{ t("dealHistory") }}</h3>
    <dd class="mt-1 text-sm text-gray-900">
      <table class="min-w-full divide-y divide-gray-300 mt-4">
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("updateDate") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("closureChance") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("protectionStatus") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("dealStatus") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("updateOrigin") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("eBid") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("eBidStatus") }}
            </th>
          </tr>
        </thead>
        <tbody
          v-if="dealHistory.length"
          class="divide-y divide-gray-200 bg-white"
        >
          <tr v-for="(item, index) in dealHistory" :key="index">
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              {{ formatDate(item.updateDate) }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              {{ item.closureChance }}%
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              <span :class="statusColors(item.protectionStatus)">
                {{ t(item.protectionStatus) }}
              </span>
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              <span :class="statusColors(item.status)">
                {{ t(item.status) }}
              </span>
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              {{ t(item.source) }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              {{ useFormatCurrency(item.eBidValue, item.currency) }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              <span :class="statusColors(item.eBidStatus)">
                {{ t(item.eBidStatus) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </dd>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, watch } from "vue";
import { t } from "@/plugins/i18n";
import { useFormatDate } from "@/composables/formatters";
import { useStatusColors } from "@/composables/colors";
import { useFormatCurrency } from "@/composables/formatters";
import { useDealsStore } from "@/stores/deals";

const dealsStore = useDealsStore();
const formatDate = useFormatDate;
const statusColors = useStatusColors;

const deal = computed(() => dealsStore.currentDeal);
const dealHistory = computed(() => dealsStore.currentDealHistory);

onBeforeMount(() => {
  dealsStore.getHistory();
});

watch(deal, (currentValue) => {
  if (currentValue) {
    dealsStore.getHistory();
  }
});
</script>
