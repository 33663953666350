import { dealStatus, protectionStatus } from "@/models/deal.interface";
import { eBidStatus } from "@/models/dealEbid.interface";

export function useStatusColors(
  status: dealStatus | protectionStatus | eBidStatus
): string {
  switch (status) {
    case "New":
      return "text-gray-600";
    case "Trial":
    case "Demo":
    case "Negotiation":
    case "Pending":
    case "user_pending":
      return "text-yellow-600";
    case "Approved":
    case "Won":
    case "user_active":
      return "text-green-600";
    case "Lost":
    case "Refused":
    case "Duplicated":
    case "user_suspended":
      return "text-red-600";
    default:
      return "";
  }
}
