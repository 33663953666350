import { TableConfig } from "@/models/listable.interface";
import logger from "@/services/loggerService";

const possibleStatus = ["Confirmed", "Pending", "Revoked", "Canceled"];
export type certificationStatus = typeof possibleStatus[number];

export const deserializeCertificationStatus = (
  input?: string
): certificationStatus => {
  if (input && possibleStatus.includes(input)) {
    return input;
  } else {
    logger.error(`Certification deserializer: statusName value "${input}"`);
    return "Pending";
  }
};

export interface Certification {
  id: string;
  status: certificationStatus;
  name: string;
  description: string;
  durationMonths: number;
}

export type CertificationUser = {
  value: string;
  label: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DeserializeCertification = (input: any): Certification => {
  return {
    id: input.certificateId || "",
    status: deserializeCertificationStatus(input.statusName),
    name: input.name || "",
    description: input.description || "",
    durationMonths: input.durationMonths || 0,
  };
};

export type CertificationType = "Account" | "User";

export interface UserCertification {
  firstName: string;
  lastName: string;
  // email: string;
  id: string;
  idUserCertificate: string;
  idAccount: string;
  accountName: string;
  accountCode: string;
  validationCode: string;
  notes: string;
  creationDate: Date;
  expirationDate: Date;
  certificateName: string;
  isGuest: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DeserializeUserCertification = (input: any): UserCertification => {
  return {
    firstName: input.userFirstName || "",
    lastName: input.userLastName || "",
    // email: input.email || "",
    idUserCertificate: input.userCertificateId || "",
    id: input.certificateId || "",
    accountName: input.userAccountName || "",
    accountCode: input.accountCode || "",
    idAccount: input.userAccountId || "",
    validationCode: input.validationCode || "",
    notes: input.notes || "",
    creationDate: new Date(input.creationDate) || "",
    expirationDate: new Date(input.expirationDate) || "",
    certificateName: input.certificateName || "",
    isGuest: input.isGuestCertificate || false,
  };
};

export interface AccountCertification {
  name: string;
  accountCode: string;
  status: certificationStatus;
  id: string;
  idCertificate: string;
  idAccountCertificate: string;
  certificateName: string;
  validationCode: string;
  notes: string;
  creationDate: Date;
  expirationDate: Date;
}

export const DeserializeAccountCertification = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input: any
): AccountCertification => {
  return {
    id: input.accountId || "",
    status: deserializeCertificationStatus(input.statusName),
    name: input.accountName || "",
    accountCode: input.accountCode || "",
    idCertificate: input.certificateId || "",
    idAccountCertificate: input.accountCertificateId || "",
    certificateName: input.certificateName || "",
    validationCode: input.validationCode || "",
    notes: input.notes || "",
    creationDate: new Date(input.creationDate) || "",
    expirationDate: new Date(input.expirationDate) || "",
  };
};

export interface UserCertificationFormData {
  userId: string;
  certificateId: string;
  notes: string;
  userFirstName: string;
  userLastName: string;
  isGuestCertificate: boolean;
  parentAccountId: string;
}

export interface AccountCertificationFormData {
  accountId: string;
  certificateId: string;
  notes: string;
}

export const tableConfig = {
  certificateName: {
    dtoName: "certificateName",
    sortable: false,
    filterable: false,
    label: "certificateName",
  },
  firstName: {
    dtoName: "userFirstName",
    sortable: false,
    filterable: false,
    label: "name",
  },
  validationCode: {
    dtoName: "validationCode",
    sortable: false,
    filterable: false,
    label: "validationCode",
  },
  valid: {
    dtoName: "",
    sortable: false,
    filterable: false,
    label: "valid",
  },
  isGuest: {
    dtoName: "isGuestCertificate",
    sortable: false,
    filterable: false,
    label: "guestCertificate",
  },
  // email: {
  //   dtoName: "email",
  //   sortable: true,
  //   filterable: false,
  //   label: "email",
  // },
  actions: {
    dtoName: "",
    sortable: false,
    filterable: false,
    label: "actions",
    hideColumn: false,
  },
} as TableConfig;

export const accountTableConfig = {
  certificateName: {
    dtoName: "name",
    sortable: false,
    filterable: false,
    label: "certificateName",
  },
  validationCode: {
    dtoName: "validationCode",
    sortable: false,
    filterable: false,
    label: "validationCode",
  },
  valid: {
    dtoName: "",
    sortable: false,
    filterable: false,
    label: "valid",
  },
  actions: {
    dtoName: "",
    sortable: false,
    filterable: false,
    label: "actions",
    hideColumn: false,
  },
} as TableConfig;
