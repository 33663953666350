import { CurrencyData } from "@/models/currencyData.interface";
import { useLookupStore } from "@/stores/lookup";

export interface DealItem {
  id: string;
  dealId: string;
  productId: string;
  sku: string;
  name: string;
  description: string;
  quantity: number;
  price: number;
  currencyId: string;
  currency: CurrencyData;
  priceListId: string;
  priceListName: string;
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): DealItem {
    //STORE DEPENDENCY: need already fetched currencies to decode from id to full currency item
    const lookupStore = useLookupStore();
    return {
      id: input.dealItemId || "",
      dealId: input.dealId || "",
      productId: input.productId || "",
      sku: input.sku || "",
      name: input.productName || "",
      description: input.description || "",
      quantity: input.qt || 0,
      price: input.priceMSRP || 0,
      currencyId: input.priceCurrencyId || "",
      currency: lookupStore.decodedCurrency(input.priceCurrencyId),
      priceListId: input.priceListId || "",
      priceListName: input.priceListName || "",
    };
  },
  serialize(input: DealItem): unknown {
    return {
      dealItemId: input.id,
      dealId: input.dealId,
      productId: input.productId,
      sku: input.sku,
      productName: input.name,
      description: input.description,
      qt: input.quantity,
      priceMSRP: input.price,
      priceCurrencyId: input.currencyId,
      priceListId: input.priceListId,
      priceListName: input.priceListName,
    };
  },
};
