import { defineStore } from "pinia";
import { User, UserFormData } from "@/models/user.interface";
import logger from "@/services/loggerService";
import { t } from "@/plugins/i18n";
import * as usersService from "@/services/usersService";
import * as listable from "./listableMixin";
import * as permissions from "./permissionMixin";
import { defaultListableState, defaultSearchOptions } from "./listableMixin";
import router from "@/router";
import { cloneDeep } from "lodash";
import { getUser } from "@/services/authService";
import { useAuthStore } from "@/stores/auth";
// import { SearchOptions } from "@/models/listable.interface";
import { useNotificationsStore } from "@/stores/notifications";
// import { allItemsSearchOptions } from "@/services/configService";

// export const combinedSearch = async (
//   options: SearchOptions
// ): Promise<{ items: User[]; count: number }> => {
//   const [users, invitaions] = await Promise.all([
//     usersService.search(options),
//     usersService.searchInvitaiton(options),
//   ]);
//   return {
//     items: users.items.concat(invitaions.items),
//     count: users.count + invitaions.count,
//   };
// };

export interface UsersState
  extends listable.ListableMixinState,
    permissions.PermissionsMixinState {
  items: User[];
  currentUser?: User;
}

export const useUsersStore = defineStore({
  id: "users",
  state: (): UsersState => ({
    ...cloneDeep(defaultListableState),
    // isLoading: false,
    searchOptions: cloneDeep(defaultSearchOptions),
    moduleName: "Users",
    // items: [],
    currentUser: undefined,
    searchError: t("usersFetchError"),
    searchFnc: usersService.search,
  }),
  getters: {
    ...permissions.getters,
    // users: (state): User[] => state.items,
    confirmedUsers: (state): User[] =>
      state.items.filter((u) => {
        return u.status == "user_active";
      }),
  },
  actions: {
    ...listable.actions,
    async remove(user: User) {
      const notifications = useNotificationsStore();
      const auth = useAuthStore();
      this.isLoading = true;
      try {
        if (user.status === "user_pending") {
          await usersService.removeInvitation(user.id);
        } else {
          await usersService.remove(user.id);
        }
        this.items = this.items.filter((listItem) => {
          return listItem.id != user.id;
        });
        notifications.success(t("userRemoved"));
        if (auth.user?.id === user.id) {
          auth.logout();
        }
      } catch (err) {
        logger.error(err);
        notifications.error(t("userRemoveError"));
      } finally {
        this.isLoading = false;
      }
    },

    async updatePersonalData(data: UserFormData) {
      const notifications = useNotificationsStore();

      const auth = useAuthStore();
      this.isLoading = true;
      try {
        await usersService.update(data);
        const user = await getUser();
        auth.setUser(user);
        notifications.success(t("userUpdated"));
        router.push("/profile");
      } catch (err) {
        logger.error(err);
        notifications.error(t("userUpdateError"));
      } finally {
        this.isLoading = false;
      }
    },
  },
});
