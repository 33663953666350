import { CurrencyData } from "@/models/currencyData.interface";
import { useLookupStore } from "@/stores/lookup";

export interface OrderItem {
  id: string;
  orderId: string;
  productId: string;
  name: string;
  sku: string;
  description: string;
  quantity: number;
  price: number;
  currencyId: string;
  currency: CurrencyData;
  priceListId: string;
  priceListName: string;
  priceListPrice: number;
  priceListCustomPriceDiscountType: string;
  priceListCustomPriceDiscountAmount: number;
}
export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): OrderItem {
    //STORE DEPENDENCY: need already fetched currencies to decode from id to full currency item
    const lookupStore = useLookupStore();
    return {
      id: input.orderItemId || "",
      orderId: input.orderId || "",
      productId: input.productId || "",
      sku: input.productSku || "",
      name: input.productName || "",
      description: input.productDescription || "",
      quantity: input.qt || 0,
      price: input.price || 0,
      currencyId: input.priceCurrencyId || "",
      currency: lookupStore.decodedCurrency(input.priceCurrencyId),
      priceListId: input.priceListId || "",
      priceListName: input.priceListName || "",
      priceListPrice: input.priceListPrice || 0,
      priceListCustomPriceDiscountType:
        input.priceListCustomPriceDiscountType || "",
      priceListCustomPriceDiscountAmount:
        input.priceListCustomPriceDiscountAmount || 0,
    };
  },
  serialize(input: OrderItem): unknown {
    return {
      orderItemId: input.id,
      orderId: input.orderId,
      productId: input.productId,
      productSku: input.sku,
      productName: input.name,
      productDescription: input.description,
      qt: input.quantity,
      price: input.price,
      priceCurrencyId: input.currencyId,
      priceListId: input.priceListId,
      priceListName: input.priceListName,
      priceListPrice: input.priceListPrice,
      priceListCustomPriceDiscountType: input.priceListCustomPriceDiscountType,
      priceListCustomPriceDiscountAmount:
        input.priceListCustomPriceDiscountAmount,
    };
  },
};
