import LicenseModel, {
  License,
  LicenseCreationData,
  LicenseExportData,
  LicenseSeats,
  tableConfig,
} from "@/models/license.interface";
import {
  LicenseReport,
  deserializeReport,
  tableConfig as tableConfigReports,
} from "@/models/licenseReport.interface";
import axios from "axios";
import * as configService from "@/services/configService";
import {
  searchOptionsSerialize,
  SearchOptions,
} from "@/models/listable.interface";

export async function fetch(): Promise<License[]> {
  const res = await axios.post(configService.licensesSearchUrl, {
    limit: configService.maxApiResults,
  });
  return res.data.items.map(LicenseModel.deserialize);
}

export async function get(serial: string): Promise<License> {
  const res = await axios.get(`${configService.licensesUrl}/${serial}`);
  return LicenseModel.deserialize(res.data);
}

export async function post(creationData: LicenseCreationData): Promise<string> {
  const data = {
    Items: [
      {
        ProductId: creationData.product,
        Qt: creationData.quantity,
        GenerateSeparateKeys: creationData.generateSeparateKeys,
        Extensions: creationData.extensions,
      },
    ],
    licenseNote: creationData.licenseNote,
    parentAccountId: creationData.parentAccountId,
    derivedOrderId: creationData.derivedOrderId,
  };
  const res = await axios.post(`${configService.licensesUrl}`, data);
  return res.data;
}

export async function search(
  options: SearchOptions
): Promise<{ items: License[]; count: number }> {
  const opt = options ? searchOptionsSerialize(options, tableConfig) : {};
  const res = await axios.post(configService.licensesSearchUrl, opt);
  return {
    items: res.data.items.map(LicenseModel.deserialize),
    count: res.data.count,
  };
}

export async function searchReports(
  options: SearchOptions
): Promise<{ items: LicenseReport[]; count: number }> {
  const opt = options
    ? searchOptionsSerialize(options, tableConfigReports)
    : {};
  const res = await axios.post(configService.licensesReportUrl, opt);
  return {
    items: res.data.items.map(deserializeReport),
    count: res.data.count,
  };
}

export async function downloadReports(options: SearchOptions): Promise<void> {
  const opt = options
    ? searchOptionsSerialize(options, tableConfigReports)
    : {};
  const res = await axios({
    method: "post",
    url: configService.licensesReportDownloadUrl,
    responseType: "blob",
    data: opt,
  });
  const blob = new Blob([res.data], {
    type: res.headers["content-type"],
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `report${Date.now()}.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function update(key: string, notes: string): Promise<string> {
  const res = await axios.put(`${configService.licensesUrl}/${key}`, {
    licenseNote: notes,
  });
  return res.data;
}

export async function exportToEnv(data: LicenseExportData) {
  const res = await axios.post(
    `${configService.licensesExportUrl}/export`,
    data
  );
  if (res.data) {
    const textBlob = new Blob([res.data], { type: "text/plain" });
    const saveBtn = document.createElement("a");
    saveBtn.href = window.URL.createObjectURL(textBlob);
    saveBtn.download = `${data.serials[0].serialKey}.lic`;
    saveBtn.click();
    URL.revokeObjectURL(saveBtn.href);
  }
}

export async function fullDeleteSeats(id: string): Promise<void> {
  return await axios.delete(configService.licensesSeats + "/" + id);
}

export async function clearSeats(seats: LicenseSeats[]): Promise<void> {
  return await axios.put(configService.licensesRmaUrl, {
    LicenseSeats: seats.map((seat) => {
      return {
        LicenseSeatId: seat.id,
      };
    }),
  });
}
