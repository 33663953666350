// import logger from "@/services/loggerService";
import { t } from "@/plugins/i18n";

export function useValidationMessages() {
  return {
    required: t("rulesRequired"),
    matchPassword: t("rulesMatchPassword"),
    types: {
      email: t("rulesEmail"),
      number: t("rulesNumber"),
    },
  };
}

export function useRequiredChars(rule: unknown, value: string) {
  return new Promise<void>((resolve, reject) => {
    const regex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[^a-zA-Z\\d]).{8,}$"
    );
    regex.test(value) || !value ? resolve() : reject(t("passwordCriteria"));
  });
}

// export function useValidationRules() {
//   return (rule: string) => {
//     switch (rule) {
//       case "required":
//         return { required: true, message: t("rulesRequired") };
//       case "email":
//         return { type: "email", message: t("rulesEmail") };
//       case "number":
//         return { type: "number", message: t("rulesNumber") };
//       default:
//         logger.error(`Va-input: unhandled validation rule ${rule}`);
//     }
//   };
// }
