import { defineStore } from "pinia";
import { Notification } from "@/models/notification.interface";
import { notificationTimeout } from "@/services/configService";

export interface NotificationState {
  notification: Notification | undefined;
}

export const useNotificationsStore = defineStore({
  id: "notifications",
  state: (): NotificationState => ({
    notification: undefined,
  }),
  actions: {
    async displayNotification(notification: Notification) {
      this.notification = notification;
      if (!notification?.permanent) {
        setTimeout(() => {
          this.notification = undefined;
        }, notificationTimeout);
      }
    },
    async dismissNotification() {
      this.notification = undefined;
    },
    async info(data: string, title?: string) {
      this.displayNotification({
        title: title,
        text: data,
        type: "info",
      });
    },
    async success(data: string, title?: string) {
      this.displayNotification({
        title: title,
        text: data,
        type: "success",
      });
    },
    async warn(data: string, title?: string) {
      this.displayNotification({
        title: title,
        text: data,
        type: "warning",
      });
    },
    async error(data: string, title?: string) {
      this.displayNotification({
        title: title,
        text: data,
        type: "error",
      });
    },
  },
});
