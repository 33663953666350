import { TableConfig } from "@/models/listable.interface";

export interface ProductTenant {
  id: string;
  name: string;
  code: string;
}

export const tableConfig = {
  name: {
    dtoName: "tenantName",
    sortable: true,
    filterable: true,
    label: "name",
  },
  code: {
    dtoName: "tenantCode",
    sortable: true,
    filterable: true,
    label: "code",
  },
  id: {
    dtoName: "tenantId",
    sortable: true,
    filterable: true,
    label: "id",
    hideColumn: true,
  },
} as TableConfig;

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  deserialize(input: any): ProductTenant {
    return {
      id: input?.tenantId || "",
      name: input?.tenantName || "",
      code: input?.tenantCode || "",
    };
  },

  serialize(input: ProductTenant): unknown {
    return {
      tenantId: input.id,
      tenantName: input.name,
      tenantCode: input.code,
    };
  },
};
