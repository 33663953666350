<template>
  <div
    v-if="priceListsStore.currentPriceList"
    id="pricelistDetail"
    class="va-card mt-6"
  >
    <div class="px-4 py-5 sm:px-6">
      <va-button
        class="float-right"
        :disabled="!priceListsStore.canCreate"
        @click="onNewProduct"
      >
        <i class="rm rm-ico-e-add" />
      </va-button>

      <va-button
        class="mr-2 float-right"
        :disabled="!priceListsStore.canUpdate"
        @click="openPricelistEditDialog"
      >
        <i class="rm rm-ico-edit" />
      </va-button>

      <va-delete-button
        class="mr-2 float-right"
        :disabled="!priceListsStore.canDelete"
        :confirm-text="t('priceListsRemoveDescription')"
        :confirm-title="t('priceListsRemove')"
        @click:confirm="deletePriceList"
      />

      <va-page-title> {{ priceList?.name }}</va-page-title>

      <p class="va-subtitle">
        {{ t("creationDate") }}: {{ formatDate(priceList?.creationDate) }} -
        {{ priceList?.status }} - {{ t("currency") }}:
        {{ priceList?.currency.name }}
      </p>
    </div>
    <va-back-link target="/pricelists" class="px-4 my-6">
      {{ t("priceListBack") }}
    </va-back-link>

    <va-dialog v-model="editPricelistDialog">
      <a-form
        v-if="editPricelistDialog"
        :model="formState"
        :validate-messages="validateMessages"
        @finish="onEditPricelist"
      >
        <a-form-item
          :name="['name']"
          :label="t('name')"
          :rules="[{ required: true }]"
        >
          <va-input v-model="formState.name" :placeholder="t('name')" />
        </a-form-item>
        <a-form-item :name="['currencyId']" :label="t('currency')">
          <va-select
            v-model="formState.currency.id"
            :options="lookupStore.currencyOptions"
            :placeholder="t('currency')"
            :disabled="lookupStore.currenciesLoading"
            @change="onChangeCurrency"
          />
        </a-form-item>

        <a-form-item>
          <va-button html-type="submit" class="float-right">
            {{ t("save") }}
          </va-button>
          <va-button
            class="float-right mr-4"
            @click="editPricelistDialog = false"
          >
            {{ t("dismiss") }}
          </va-button>
        </a-form-item>
      </a-form>
    </va-dialog>

    <va-dialog v-model="newProductDialog">
      <price-list-new-product-form
        @save="closeNewProductDialog"
        @dismiss="closeNewProductDialog"
      />
    </va-dialog>
    <va-dialog v-model="editProductDialog">
      <price-list-update-product-form
        @save="closeEditProductDialog"
        @dismiss="closeEditProductDialog"
      />
    </va-dialog>
    <va-table
      :data-source="priceList?.products"
      :table-config="tableConfig"
      :search-options="allItemsSearchOptions"
      :hide-pagination="true"
      :loading="priceListsStore.isLoading"
      class="w-100"
      :internal-filter="true"
    >
      <!--      <template #bodyCell="{ text, column, item }">-->
      <template #bodyCell="{ text, column, record }">
        <template v-if="column.dataIndex === 'name'">{{ text }}</template>
        <template v-if="column.dataIndex === 'sku'">{{ text }}</template>
        <template v-if="column.dataIndex === 'description'">
          <div class="cell-ellipsis">
            {{ text }}
          </div>
        </template>
        <template v-if="column.dataIndex === 'customPrice'">
          <div class="text-right pr-6">
            {{ useFormatCurrency(text, record.currency) }}
          </div>
        </template>
        <template v-if="column.dataIndex === 'customPriceDiscountType'">
          {{ text }}
        </template>
        <template v-if="column.dataIndex === 'customPriceDiscountAmount'">
          <div class="text-right pr-6">
            {{ text }}
          </div>
        </template>
        <template v-if="column.dataIndex === 'actions'">
          <va-delete-button
            class="mr-2"
            :disabled="!priceListsStore.canUpdate"
            :confirm-text="t('priceListsRemoveProductDescription')"
            :confirm-title="t('priceListsRemoveProduct')"
            @click:confirm="priceListsStore.deleteProduct(record)"
          />

          <va-button
            :disabled="!priceListsStore.canUpdate"
            @click="onEditProduct(record)"
          >
            <i class="rm rm-ico-edit" />
          </va-button>
        </template>
      </template>
    </va-table>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import { t } from "@/plugins/i18n";
import { useFormatDate, useFormatCurrency } from "@/composables/formatters";
import { PriceList } from "@/models/priceList.interface";
import {
  PriceListProduct,
  tableConfig,
} from "@/models/priceListProduct.interface";
import VaTable from "@/components/organisms/VaTable.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaDialog from "@/components/molecules/VaDialog.vue";
import PriceListNewProductForm from "@/components/organisms/PriceListNewProductForm.vue";
import PriceListUpdateProductForm from "@/components/organisms/PriceListUpdateProductForm.vue";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import VaDeleteButton from "@/components/molecules/VaDeleteButton.vue";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import { useValidationMessages } from "@/composables/validation";
import { cloneDeep } from "lodash";
import { usePricelistsStore } from "@/stores/pricelists";
import { allItemsSearchOptions } from "@/services/configService";
import VaSelect from "@/components/molecules/VaSelect.vue";
import { useLookupStore } from "@/stores/lookup";
import { SelectOption } from "@/models/selectOptions.interface";
import { CurrencyData } from "@/models/currencyData.interface";
import { useMeta } from "vue-meta";

useMeta({
  title: "Pricelists",
});
const route = useRoute();
const priceListsStore = usePricelistsStore();
const lookupStore = useLookupStore();
const validateMessages = useValidationMessages();
const formatDate = useFormatDate;

const priceList = computed(() => priceListsStore.currentPriceList);

const newProductDialog = ref(false);
const editProductDialog = ref(false);
const editPricelistDialog = ref(false);
const formState = ref({} as PriceList);

const openPricelistEditDialog = () => {
  if (priceListsStore.currentPriceList) {
    formState.value = cloneDeep(priceListsStore.currentPriceList);
    editPricelistDialog.value = true;
  }
};

const onChangeCurrency = (opt: SelectOption<CurrencyData>) => {
  formState.value.currency = opt.fullItem;
};

const onEditPricelist = () => {
  priceListsStore.update(formState.value);
  editPricelistDialog.value = false;
};

const onEditProduct = (item: PriceListProduct) => {
  priceListsStore.currentProduct = item;
  editProductDialog.value = true;
};

const onNewProduct = () => {
  newProductDialog.value = true;
};

const closeEditProductDialog = () => {
  editProductDialog.value = false;
};

const closeNewProductDialog = () => {
  newProductDialog.value = false;
};

onBeforeMount(() => {
  priceListsStore.get(route.params.id as string);
});

const deletePriceList = () => {
  priceListsStore.delete(route.params.id as string);
};
</script>
