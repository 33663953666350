<template>
  <va-table
    :data-source="ordersStore.items"
    :table-config="sellInTableConfig"
    :search-options="ordersStore.searchOptions"
    :loading="ordersStore.isLoading"
    class="w-100"
    @update="ordersStore.updateSearchOptions"
  >
    <template #bodyCell="{ column, text, record }">
      <template v-if="column.dataIndex === 'code'">
        <router-link :to="`/sell-in/${record.id}`" class="text-indigo-600">
          {{ text }}
        </router-link>
      </template>
      <template v-if="column.dataIndex === 'buyer'">
        {{ text.name }}
      </template>
      <template v-if="column.dataIndex === 'vendor'">
        {{ text.name }}
      </template>
      <template v-if="column.dataIndex === 'refCode'">
        {{ record.refCode }}
      </template>
      <template v-if="column.dataIndex === 'creationDate'">
        {{ formatDate(record.creationDate) }}
      </template>
      <template v-if="column.dataIndex === 'status'">
        <a-tag :color="statusColors(record.status)">
          {{ t(record.status) }}
        </a-tag>
      </template>
      <template v-if="column.dataIndex === 'isInvoiced'">
        <div class="text-center">
          <va-check-icon :check="text" />
        </div>
      </template>
      <template v-if="column.dataIndex === 'isPaid'">
        <div class="text-center">
          <va-check-icon :check="text" />
        </div>
      </template>
      <template v-if="column.dataIndex === 'total'">
        <div class="text-right">
          {{ useFormatCurrency(text, record.currency) }}
        </div>
      </template>
    </template>
  </va-table>
</template>

<script setup lang="ts">
import { onBeforeMount } from "vue";
import VaTable from "@/components/organisms/VaTable.vue";
import { tableConfig } from "@/models/order.interface";
import { useI18n } from "vue-i18n";
import VaCheckIcon from "@/components/atoms/VaCheckIcon.vue";
import { useFormatDate, useFormatCurrency } from "@/composables/formatters";
import { cloneDeep } from "lodash";
import { useOrdersStoreAccount } from "@/stores/orders";
import { onBeforeRouteUpdate, useRoute } from "vue-router";
import { statusColors } from "@/models/orderDetails.interface";

const ordersStore = useOrdersStoreAccount();
const route = useRoute();
const { t } = useI18n();
const formatDate = useFormatDate;

const sellInTableConfig = cloneDeep(tableConfig);
delete sellInTableConfig.buyer;

onBeforeRouteUpdate(async (to, from) => {
  if (to.params.id !== from.params.id) {
    ordersStore.setParentAccountId(to.params.id as string);
  }
});

onBeforeMount(() => {
  ordersStore.setPreSort(
    {
      columnKey: "creationDate",
      order: "descend",
    },
    true
  );
  ordersStore.setParentAccountId(route.params.id as string, true);
});
</script>
