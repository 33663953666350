import UserModel, {
  User,
  tableConfig,
  UserFormData,
} from "@/models/user.interface";
import axios from "axios";

import * as configService from "@/services/configService";
import {
  searchOptionsSerialize,
  SearchOptions,
} from "@/models/listable.interface";

export async function search(
  options: SearchOptions
): Promise<{ items: User[]; count: number }> {
  const opt = options ? searchOptionsSerialize(options, tableConfig) : {};
  const res = await axios.post(configService.usersSearchUrl, opt);
  return {
    items: res.data.items.map(UserModel.deserialize),
    count: res.data.count,
  };
}

export async function remove(id: string): Promise<boolean> {
  const res = await axios.delete(`${configService.usersUrl}/${id}`);
  return !!res.data;
}

export async function update(data: UserFormData): Promise<boolean> {
  const res = await axios.put(`${configService.usersUrl}/${data.id}`, data);
  return !!res.data;
}

export async function searchInvitaiton(
  options: SearchOptions
): Promise<{ items: User[]; count: number }> {
  const opt = options ? searchOptionsSerialize(options, tableConfig) : {};
  const res = await axios.post(configService.userInvitationsSearchUrl, opt);
  return {
    items: res.data.items.map(UserModel.deserialize),
    count: res.data.count,
  };
}

export async function removeInvitation(id: string): Promise<boolean> {
  const res = await axios.delete(`${configService.userInvitationsUrl}/${id}`);
  return !!res.data;
}
