<template>
  <!--  <div class="flex">-->
  <!--    <div class="mr-4">-->
  <!--      <div class="text-right">-->
  <!--        <input-->
  <!--          id="previous"-->
  <!--          v-model="dashboardQuery.showPrevious"-->
  <!--          aria-describedby="comments-description"-->
  <!--          type="checkbox"-->
  <!--          class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded mr-2"-->
  <!--        />-->
  <!--        <label for="comments" class="text-sm text-gray-500">-->
  <!--          {{ t("includePrevious") }}-->
  <!--        </label>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="mr-4">-->
  <div class="text-right mb-3">
    <div class="mb-3">
      <va-select
        v-model="statsStore.interval"
        :options="statsStore.intervalOptions"
        @change="statsStore.changeInterval"
      >
      </va-select>
    </div>
    <div class="flex">
      <div class="mr-3">
        <va-date-picker
          v-model="query.from"
          :placeholder="t('from')"
          day-target="start"
          :disabled="statsStore.interval !== 'custom'"
          @change="statsStore.updateOrdersQuery(query)"
        />
      </div>

      <div>
        <va-date-picker
          v-model="query.to"
          :placeholder="t('to')"
          day-target="end"
          :disabled="statsStore.interval !== 'custom'"
          @change="statsStore.updateOrdersQuery(query)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import VaSelect from "@/components/molecules/VaSelect.vue";
import VaDatePicker from "@/components/molecules/VaDatePicker.vue";
import { useStatsStore } from "@/stores/stats";
import { ref } from "vue";

const statsStore = useStatsStore();
const query = ref(statsStore.query);

const { t } = useI18n();
</script>
