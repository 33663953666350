<template>
  <div v-if="user">
    <div class="va-card mt-6">
      <div class="px-4 py-5 sm:px-6 flex justify-between">
        <h3 class="va-title-s">{{ t("profile") }}</h3>
        <va-button @click="router.push('/profile/edit')">
          <i class="rm rm-ico-edit" />
        </va-button>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl class="sm:divide-y sm:divide-gray-200">
          <va-info-row :title="t('firstName')">
            {{ user.firstName }}
          </va-info-row>
          <va-info-row :title="t('lastName')">
            {{ user.lastName }}
          </va-info-row>
          <va-info-row :title="t('email')">
            {{ user.email }}
          </va-info-row>
          <va-info-row :title="t('timeZone')">
            {{ user.timeZone }}
          </va-info-row>
          <va-info-row :title="t('languageCode')">
            {{ user.languageCode }}
          </va-info-row>
          <va-info-row :title="t('creationDate')">
            {{ formatDate(user.creationDate) }}
          </va-info-row>
          <va-info-row :title="t('roles')">
            <span v-for="role in user.roles" :key="role.name"
              >{{ role.name }}
            </span>
          </va-info-row>
          <va-info-row :title="t('accountName')">
            {{ user.accountName }}
          </va-info-row>
          <va-info-row :title="t('status')">
            {{ t(user.status) }}
          </va-info-row>
          <va-info-row :title="t('notificationEnabled')">
            <va-check-icon :check="user.isNotificationEnabled" />
          </va-info-row>
          <va-info-row :title="t('permissions')">
            <permissions-table :permissions="user.permissions" />
          </va-info-row>
        </dl>
      </div>
    </div>
  </div>

  <mfa-providers-settings />

  <div v-if="!user?.isSuperAdmin" class="va-card mt-6">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="va-title-s">{{ t("dangerZone") }}</h3>
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
      <dl class="sm:divide-y sm:divide-gray-200">
        <va-info-row :title="t('deleteProfile')">
          <va-button @click="confirmDeleteDialog = true">
            {{ t("deleteMe") }}
          </va-button>
        </va-info-row>
        <va-confirm
          v-model="confirmDeleteDialog"
          :title="t('profileRemove')"
          @click:confirm="removeUser"
        >
          {{ t("profileRemoveDescription") }}
        </va-confirm>
      </dl>
    </div>
  </div>
</template>

<script setup lang="ts">
import MfaProvidersSettings from "@/components/organisms/MfaProvidersSettings.vue";
import { computed, ref } from "vue";
import { useFormatDate } from "@/composables/formatters";
import { useI18n } from "vue-i18n";
import PermissionsTable from "@/components/organisms/PermissionsTable.vue";
import VaInfoRow from "@/components/atoms/VaInfoRow.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import router from "@/router";
import VaConfirm from "@/components/molecules/VaConfirm.vue";
import { useUsersStore } from "@/stores/users";
import { useAuthStore } from "@/stores/auth";
import VaCheckIcon from "@/components/atoms/VaCheckIcon.vue";

const formatDate = useFormatDate;
const { t } = useI18n();
const usersStore = useUsersStore();
const authStore = useAuthStore();
const confirmDeleteDialog = ref(false);
const user = computed(() => authStore.user);
const removeUser = () => {
  if (user.value) {
    usersStore.remove(user.value);
  }
};
</script>
