<template>
  <va-page-title>Design System</va-page-title>
  <div class="py-4">
    <h1>Avatars</h1>
    <VaAvatar
      text="Luca Terzaghi"
      image="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
    />
    <h1>Tags</h1>
    <VaBadge closable>Text</VaBadge>
    <VaBadge color="red">Text</VaBadge>
    <VaBadge color="yellow">Text</VaBadge>
    <VaBadge color="green">Text</VaBadge>
    <VaBadge color="blue">Text</VaBadge>
    <VaBadge color="indigo">Text</VaBadge>
    <VaBadge color="purple">Text</VaBadge>
    <VaBadge color="pink">Text</VaBadge>

    <h1>Buttons</h1>
    <va-button>base</va-button>
    <va-button secondary>secondary</va-button>
    <va-button white>white</va-button>
    <va-button disabled>disabled</va-button>
    <va-button loading>loading</va-button>
    <va-button> <i class="rm rm-ico-dashboard mr-3" />prepend icon </va-button>

    <va-button>
      append icon
      <i class="rm rm-ico-dashboard ml-3" />
    </va-button>
    <va-button loading secondary disabled>mix & match</va-button>

    <h1>Loader</h1>
    <va-loader :loading="true" />

    <h1>Stats</h1>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <VaStatsWidget />
      <VaStatsWidget />
      <VaStatsWidget />
    </dl>
    <h1>Date Picker</h1>
    <VaDatePicker />
    <h1>Input</h1>
    <va-input v-model="val" placeholder="regular" class=""> </va-input>
    <va-input v-model="val" placeholder="prefix & suffix" class="">
      <template #prefix> prefix </template>
      <template #suffix> suffix </template>
    </va-input>
    <va-input
      v-model="val"
      type="textarea"
      placeholder="textarea"
      class=""
    ></va-input>
    <va-input
      v-model="val"
      type="number"
      placeholder="number"
      :max="3"
      :min="0"
      class=""
    >
    </va-input>
    <!--    <va-input type="password"></va-input>-->
  </div>
</template>

<script setup lang="ts">
import VaAvatar from "@/components/atoms/VaAvatar.vue";
import VaBadge from "@/components/atoms/VaBadge.vue";
import VaStatsWidget from "@/components/molecules/VaStatsWidget.vue";
import VaDatePicker from "@/components/molecules/VaDatePicker.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaLoader from "@/components/atoms/VaLoader.vue";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import VaInput from "@/components/molecules/VaInput.vue";

import { ref } from "vue";

const val = ref("");
</script>
