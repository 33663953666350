<template>
  <users-table
    :account-id="route.params.id as string"
    :create-path="`/accounts/${route.params.id}/users/create`"
  />
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import UsersTable from "@/components/organisms/UsersTable.vue";

const route = useRoute();
</script>
