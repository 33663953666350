<template>
  <va-page-title>{{ t("licenseNew") }}</va-page-title>
  <va-back-link target="/licenses">{{ t("backToLicenses") }}</va-back-link>

  <a-form
    class=""
    :model="formState"
    :validate-messages="validateMessages"
    @finish="onFinish"
  >
    <div class="va-card mt-6 p-4">
      <a-form-item
        :name="['parentAccountId']"
        :label="t('owner')"
        :rules="[{ required: true }]"
      >
        <va-select
          v-model="formState.parentAccountId"
          :options="accountsStore.currentNetworkOptions"
          :placeholder="t('createAsChild')"
          :disabled="
            stockStore.isLoading ||
            productsStore.isLoading ||
            !licensesStore.canCreate
          "
          @change="selectOwner"
        >
          <template #option="option">
            <option-account :item="option.nestedProps.fullItem" />
          </template>
        </va-select>
      </a-form-item>

      <a-form-item :name="['order']" :label="t('orderReference')">
        <va-select
          v-model="formState.derivedOrderId"
          :options="ordersStore.ordersOptions"
          :placeholder="t('order')"
          :disabled="ordersStore.isLoading || !licensesStore.canCreate"
        >
          <template #option="option">
            <option-order :item="option.nestedProps.fullItem" />
          </template>
        </va-select>
      </a-form-item>
    </div>

    <div class="va-card mt-6 p-4">
      <a-form-item
        :name="['product']"
        :label="t('product')"
        :rules="[{ required: true }]"
      >
        <va-select
          v-model="formState.product"
          :options="stockStore.licensesProductOptions"
          :placeholder="t('product')"
          :disabled="
            stockStore.isLoading ||
            productsStore.isLoading ||
            !licensesStore.canCreate
          "
          :not-found-content="t('notAvailableProducts')"
          @change="clearExtensions"
        >
          <template #option="option">
            <!--            <option-product :item="option.nestedProps.fullItem" />-->
            <option-product-stock :item="option.nestedProps.fullItem" />
          </template>
        </va-select>
      </a-form-item>

      <a-form-item :name="['quantity']" :label="t('quantity')">
        <va-input
          v-model="formState.quantity"
          :placeholder="t('quantity')"
          type="number"
          :min="1"
          integer
          :disabled="!licensesStore.canCreate"
        />
      </a-form-item>

      <div class="flex mt-3">
        <div class="mr-3">{{ t("generateSeparateKeys") }}:</div>
        <!--        {{ t("generateSeparateKeys") }}:-->
        <a-switch
          v-model:checked="formState.generateSeparateKeys"
          class="ml-4"
          :disabled="!licensesStore.canCreate"
        ></a-switch>
      </div>
      <div v-if="productExtensions.length" class="my-4">
        {{ t("generateExtensions") }}:
        <div class="flex mt-3">
          <va-select
            v-model="selectedExtension"
            class="grow mr-4"
            :options="productExtensions"
            :placeholder="t('product')"
            :disabled="productsStore.isLoading || !licensesStore.canCreate"
          />
          <va-button
            class="float-right"
            :disabled="!licensesStore.canCreate"
            @click="addExtension"
          >
            <i class="rm rm-ico-e-add" />
          </va-button>
        </div>
        <div
          v-for="(ext, index) in formState.extensions"
          :key="ext"
          class="flex my-2"
        >
          <div class="grow mr-4 ml-2">
            {{
              productExtensions.find((p: any) => {
                return p.value === ext;
              })?.label
            }}
          </div>
          <va-button
            class="float-right"
            :disabled="!licensesStore.canCreate"
            @click="removeExtension(index)"
          >
            <i class="rm rm-ico-bin" />
          </va-button>
        </div>
      </div>

      <a-form-item :name="['licenseNote']" :label="t('notes')" class="mt-4">
        <va-input
          v-model="formState.licenseNote"
          type="textarea"
          :placeholder="t('notes')"
        />
      </a-form-item>

      <stock-error :error-detail="licensesStore.creationError" />

      <a-form-item>
        <va-button
          html-type="submit"
          class="float-right"
          :disabled="!licensesStore.canCreate"
          :loading="licensesStore.isLoading"
        >
          {{ t("save") }}
        </va-button>
      </a-form-item>
    </div>
  </a-form>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import { t } from "@/plugins/i18n";
import { useValidationMessages } from "@/composables/validation";
import VaSelect from "@/components/molecules/VaSelect.vue";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import OptionAccount from "@/components/molecules/selectOptions/OptionAccount.vue";
import OptionOrder from "@/components/molecules/selectOptions/OptionOrder.vue";
import StockError from "@/components/organisms/StockError.vue";
import { useLicensesStore } from "@/stores/licenses";
import { useAuthStore } from "@/stores/auth";
import { LicenseCreationData } from "@/models/license.interface";
import { useProductsStore } from "@/stores/products";
import { useOrdersStore } from "@/stores/orders";
import { useAccountsStore } from "@/stores/accounts";
import { SelectOption } from "@/models/selectOptions.interface";
import { Account } from "@/models/account.interface";
import { useNotificationsStore } from "@/stores/notifications";
import { useStocksStore } from "@/stores/stocks";
import OptionProductStock from "@/components/molecules/selectOptions/OptionProductStock.vue";
import { useMeta } from "vue-meta";

useMeta({
  title: "Licenses",
});
const accountsStore = useAccountsStore();
const ordersStore = useOrdersStore();
const productsStore = useProductsStore();
const stockStore = useStocksStore();
const licensesStore = useLicensesStore();
const authStore = useAuthStore();
const notifications = useNotificationsStore();
const validateMessages = useValidationMessages();
const selectedExtension = ref("");

const ownerAccount = computed(() => authStore.userAccount);

const formState = ref({
  quantity: 1,
  product: "",
  generateSeparateKeys: false,
  extensions: [] as string[],
  licenseNote: "",
  parentAccountId: ownerAccount.value?.id,
  derivedOrderId: undefined,
} as LicenseCreationData);

const productExtensions = computed(() => {
  return stockStore.licensesProductOptions.filter((ext) => {
    return (
      ext.fullItem.extendedProductId !== "" &&
      ext.fullItem.extendedProductId !== ext.value &&
      ext.fullItem.extendedProductId == formState.value.product
    );
  });
});

const addExtension = () => {
  formState.value.extensions.push(selectedExtension.value);
};
const removeExtension = (index: number) => {
  formState.value.extensions.splice(index, 1);
};

const clearExtensions = () => {
  selectedExtension.value = "";
  formState.value.extensions = [];
};

onBeforeMount(() => {
  if (ownerAccount.value) {
    accountsStore.get(ownerAccount.value.id);
    ordersStore.fetchByBuyerId(ownerAccount.value.id);
    // productsStore.fetchAvailableToSellById(ownerAccount.value.id);
    stockStore.get(ownerAccount.value.id);
    accountsStore.fetchChildren();
    licensesStore.setCreationError(undefined);
  } else {
    notifications.error("noAccountError");
    authStore.recoverLogin();
  }
});

const onFinish = () => {
  licensesStore.create(formState.value);
};

const selectOwner = (item: SelectOption<Account>) => {
  formState.value.product = "";
  formState.value.derivedOrderId = undefined;
  // productsStore.fetchAvailableToSellById(item.value);
  stockStore.get(item.value);
  ordersStore.fetchByBuyerId(item.value);
};
</script>
