import SubscriptionModel, {
  Subscription,
} from "@/models/subscription.interface";
import axios from "axios";
import * as configService from "@/services/configService";

export async function fetchSubscriptions(): Promise<Subscription[]> {
  const res = await axios.post(configService.subscriptionsSearchUrl, {
    limit: configService.maxApiResults,
  });
  return res.data.items.map(SubscriptionModel.deserialize);
}
