<template>
  <div
    :class="[
      isPositive
        ? 'text-green-600 dark:text-green-500'
        : 'text-red-600 dark:text-red-500',
      'ml-2 flex items-baseline text-sm font-semibold ',
    ]"
  >
    <i v-if="isPositive" class="rm rm-ico-arrow-up" />
    <i v-else class="rm rm-ico-arrow-down" />
    {{ trend }}%
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  trend: {
    type: Number,
    required: true,
  },
});

const isPositive = computed(() => {
  return props?.trend && props.trend > 0;
});
</script>
