import addressModel, { Address } from "@/models/address.interface";
import { AccountGroup } from "@/models/accountGroup.interface";
import { TableConfig } from "@/models/listable.interface";
import { useLookupStore } from "@/stores/lookup";
import {
  CertificateBadge,
  deserialize as badgeDeserialize,
  serialize as badgeSerialize,
} from "@/models/certificateBadge.interface";

export interface Account {
  id: string;
  code: string;
  name: string;
  email: string;
  group: AccountGroup;
  creationDate?: Date;
  createdBy: string;
  address?: Address;
  parents: Account[];
  certificatesBadges: CertificateBadge[];
}

export interface AccountFormData {
  accountName: string;
  groupId: string;
  priceListId: string;
  address: Address;
  parentAccountId?: string;
  user?: {
    userName: string;
    firstName: string;
    lastName: string;
    timeZone: string;
  };
}
export interface AccountUserFormData {
  parentAccountId: string;
  user: {
    userName: string;
    firstName: string;
    lastName: string;
    timeZone: string;
    roles: string[];
  };
}

export const tableConfig = {
  name: {
    dtoName: "accountName",
    sortable: true,
    filterable: true,
    label: "name",
    hideColumn: false,
  },
  group: {
    dtoName: "groupId",
    sortable: false,
    filterable: false,
    label: "group",
    hideColumn: false,
  },
  certificatesBadges: {
    dtoName: "certificatesBadges",
    sortable: false,
    filterable: false,
    label: "certificates",
    hideColumn: false,
  },
  address: {
    dtoName: "address",
    sortable: false,
    filterable: false,
    label: "address",
    hideColumn: false,
  },
  code: {
    dtoName: "accountCode",
    sortable: false,
    filterable: true,
    label: "code",
    hideColumn: false,
  },
  id: {
    dtoName: "accountId",
    sortable: false,
    filterable: false,
    label: "id",
    hideColumn: true,
  },
} as TableConfig;

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
function deserialize(input: any): Account {
  //STORE DEPENDENCY: need already fetched aggountGroup to decode from id to full currency item
  const lookupStore = useLookupStore();
  const address =
    input?.address && input.address[0]
      ? addressModel.deserialize(input.address[0])
      : undefined;
  const parents = (
    input?.parents?.length ? input.parents.map(deserialize) : []
  ) as Account[];
  parents.sort((a, b) => {
    return a.group.level > b.group.level ? 1 : -1;
  });

  const certificatesBadges = input?.certificatesBadges
    ? input.certificatesBadges.map(badgeDeserialize)
    : [];
  return {
    id: input?.accountId || "",
    code: input.accountCode || "",
    name: input.accountName || "",
    email: input.notificationReceiverEmail || "",
    group: lookupStore.decodedAccountGroup(input.groupId),
    address: address,
    creationDate: input.creationDate ? new Date(input.creationDate) : undefined,
    createdBy: input.createdBy || "",
    parents: parents,
    certificatesBadges: certificatesBadges,
  };
}

export default {
  deserialize,
  serialize(input: Account): unknown {
    return {
      accountId: input.id,
      accountCode: input.code,
      accountName: input.name,
      notificationReceiverEmail: input.email,
      groupId: input.group.groupId,
      address: input.address
        ? [addressModel.serialize(input.address)]
        : undefined,
      creationDate: input.creationDate?.getTime() || undefined,
      createdBy: input.createdBy,
      certificatesBadges: input.certificatesBadges.map(badgeSerialize),
    };
  },
};
