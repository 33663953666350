import { TableConfig } from "@/models/listable.interface";

export interface OnPremEnvironment {
  id: string;
  accountName: string;
  customerName: string;
  customerEnvironmentId: string;
  accountId: string;
  // creationDate?: Date;
}

export const tableConfig = {
  id: {
    dtoName: "onPremiseEnvironmentId",
    sortable: false,
    filterable: false,
    label: "id",
    hideColumn: true,
  },
  customerName: {
    dtoName: "customerName",
    sortable: false,
    filterable: false,
    label: "name",
    hideColumn: false,
  },
  customerEnvironmentId: {
    dtoName: "customerEnvironmentId",
    sortable: false,
    filterable: false,
    label: "id",
    hideColumn: false,
  },
} as TableConfig;

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): OnPremEnvironment {
    return {
      id: input.onPremiseEnvironmentId || "",
      accountName: input.accountName || "",
      customerName: input.customerName || "",
      customerEnvironmentId: input.customerEnvironmentId || "",
      accountId: input.accountId || "",
    };
  },
  serialize(input: OnPremEnvironment): unknown {
    return {
      onPremiseEnvironmentId: input.id,
      accountName: input.accountName,
      customerName: input.customerName,
      customerEnvironmentId: input.customerEnvironmentId,
      accountId: input.accountId,
      parentAccountId: input.accountId,
    };
  },
};
