<template>
  <div
    aria-live="assertive"
    class="fixed z-50 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
  >
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="notification"
          class="va-card max-w-sm w-full pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <span :class="iconColor">
                  <i :class="icon" />
                </span>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p
                  v-if="notification.title"
                  class="text-sm mb-1 font-medium text-gray-900"
                >
                  {{ notification.title }}
                </p>
                <p class="text-sm text-gray-500">
                  {{ notification.text }}
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button
                  class="va-card inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  @click="notificationsStore.dismissNotification"
                >
                  <span class="sr-only">Close</span>
                  <i class="rm rm-ico-i-remove" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { Notification } from "@/models/notification.interface";
import { useNotificationsStore } from "@/stores/notifications";

const notificationsStore = useNotificationsStore();

const notification = computed(
  (): Notification | undefined => notificationsStore.notification
);
const icon = computed((): string => {
  switch (notification.value?.type) {
    case "success":
      return "rm rm-ico-c-check";
    case "warning":
      return "rm rm-ico-c-warning";
    case "error":
      return "rm rm-ico-c-warning";
    default:
      return "rm rm-ico-c-info";
  }
});
const iconColor = computed((): string => {
  switch (notification.value?.type) {
    case "success":
      return "text-green-500";
    case "warning":
      return "text-yellow-500";
    case "error":
      return "text-red-500";
    default:
      return "text-indigo-500";
  }
});
</script>
