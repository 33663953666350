import { defineStore } from "pinia";
import { t } from "@/plugins/i18n";
import * as listable from "./listableMixin";
import * as permissions from "./permissionMixin";
import { cloneDeep } from "lodash";
import { defaultSearchOptions } from "./listableMixin";
import { LicenseReport } from "@/models/licenseReport.interface";
import { searchReports, downloadReports } from "@/services/licensesService";
import { useNotificationsStore } from "@/stores/notifications";
import logger from "@/services/loggerService";

export interface LicenseReportsState
  extends listable.ListableMixinState,
    permissions.PermissionsMixinState {
  items: LicenseReport[];
}

export const useLicenseReportsStore = defineStore({
  id: "LicenseReports",
  state: (): LicenseReportsState => ({
    isLoading: false,
    searchOptions: cloneDeep(defaultSearchOptions),
    moduleName: "LicenseReports",
    items: [],
    searchError: t("licensesFetchError"),
    searchFnc: searchReports,
  }),
  getters: {
    ...permissions.getters,
  },
  actions: {
    ...listable.actions,
    async download() {
      const notifications = useNotificationsStore();
      this.isLoading = true;
      try {
        await downloadReports(this.searchOptions);
      } catch (err) {
        logger.error(err);
        notifications.error(t("downloadReportError"));
      } finally {
        this.isLoading = false;
      }
    },
  },
});
