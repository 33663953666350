import { defineStore } from "pinia";
import logger from "@/services/loggerService";
import { t } from "@/plugins/i18n";
import * as listable from "./listableMixin";
import * as permissions from "./permissionMixin";
import router from "@/router";
import { cloneDeep } from "lodash";
import { defaultListableState } from "./listableMixin";
import { useNotificationsStore } from "@/stores/notifications";
import * as accountsService from "@/services/accountsService";
import * as stockService from "@/services/stockService";
import { Product } from "@/models/product.interface";
import * as productsService from "@/services/productsService";
import { buildOption, SelectOption } from "@/models/selectOptions.interface";
import { PriceListProduct } from "@/models/priceListProduct.interface";
import { useAuthStore } from "@/stores/auth";
import { EnvironmentType } from "@/models/EnvironmentType.interface";
import { fixedEnvironmentTypes } from "@/services/configService";
import { ProductTenant } from "@/models/productTenant.interface";
import { useProductTypesStore } from "@/stores/productTypes";

export interface ProductsState
  extends listable.ListableMixinState,
    permissions.PermissionsMixinState {
  items: PriceListProduct[] | Product[];
  currentProduct?: PriceListProduct | Product;
  originalProduct?: Product;
  environmentTypes: EnvironmentType[];
  productTenants: ProductTenant[];
}

export const useProductsStore = defineStore({
  id: "products",
  state: (): ProductsState => ({
    ...cloneDeep(defaultListableState),
    moduleName: "Products",
    currentProduct: undefined,
    originalProduct: undefined,
    searchError: t("productsFetchError"),
    searchFnc: productsService.search,
    environmentTypes: fixedEnvironmentTypes,
    productTenants: [],
  }),
  getters: {
    ...permissions.getters,
    productOptions: (state): SelectOption<Product | PriceListProduct>[] => {
      return state.items.map((item) => {
        return buildOption<Product>(item, "id", "name");
      });
    },
    licensesProductOptions: (
      state
    ): SelectOption<Product | PriceListProduct>[] => {
      return state.items
        .filter((item) => {
          return item.isLicense;
        })
        .map((item) => {
          return buildOption<Product>(item, "id", "name");
        });
    },
    environmentTypesOptions: (state): SelectOption<EnvironmentType>[] => {
      return state.environmentTypes.map((item) => {
        return buildOption<EnvironmentType>(item, "environmentType", "name");
      });
    },
    productTenantsOptions: (state): SelectOption<ProductTenant>[] => {
      return state.productTenants.map((item) => {
        return buildOption<ProductTenant>(item, "id", "name");
      });
    },
    decodedEnvironmentType: (state) => {
      return (id: string): EnvironmentType => {
        return (
          state.environmentTypes.find((env) => env.environmentType == id) ||
          fixedEnvironmentTypes[0]
        );
      };
    },
  },
  actions: {
    ...listable.actions,
    async prepareStore() {
      const productTypes = useProductTypesStore();
      await productTypes.fetchProductTypes();
      if (!this.productTenants.length) {
        // this.isLoading = true;
        const notifications = useNotificationsStore();
        try {
          this.productTenants = await productsService.fetchProductTenants();
        } catch (err) {
          logger.error(err);
          notifications.error(t("productsTenantsFetchError"));
        } finally {
          // this.isLoading = false;
        }
      }
    },
    async get(id: string) {
      if (!id || this.currentProduct?.id === id) return;
      this.isLoading = true;
      await this.prepareStore();
      const notifications = useNotificationsStore();
      try {
        this.currentProduct = await productsService.get(id);
      } catch (err) {
        logger.error(err);
        notifications.error(t("productsFetchError"));
      } finally {
        this.isLoading = false;
      }
    },
    async getOriginal(id: string) {
      const notifications = useNotificationsStore();
      this.isLoading = true;
      await this.prepareStore();
      try {
        if (!this.currentProduct || this.currentProduct.id !== id) {
          this.currentProduct = await productsService.get(id);
        }
        if (this.currentProduct?.originalProductId) {
          this.originalProduct = await productsService.getOriginal(
            this.currentProduct.originalProductId
          );
        } else {
          throw new Error(
            "missing originalProductId from product: " + this.currentProduct?.id
          );
        }
      } catch (err) {
        logger.error(err);
        notifications.error(t("productsFetchError"));
      } finally {
        this.isLoading = false;
      }
    },
    async fetch() {
      this.isLoading = true;
      await this.prepareStore();
      const notifications = useNotificationsStore();
      const authStore = useAuthStore();
      try {
        // this.items = await productsService.fetch();
        if (authStore.userAccount) {
          this.items = await productsService.fetchAllByParentId(
            authStore.userAccount.id
          );
        } else {
          authStore.recoverLogin();
        }
      } catch (err) {
        logger.error(err);
        notifications.error(t("productsFetchError"));
      } finally {
        this.isLoading = false;
      }
    },
    async fetchByParentId(id: string) {
      this.isLoading = true;
      await this.prepareStore();
      const notifications = useNotificationsStore();
      try {
        this.items = await productsService.fetchAllByParentId(id);
      } catch (err) {
        logger.error(err);
        notifications.error(t("productsFetchError"));
      } finally {
        this.isLoading = false;
      }
    },
    async put(product: Product) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        // this.currentProduct = await productsService.put(product);
        await productsService.put(product);
        this.currentProduct = undefined;
        this.originalProduct = undefined;
        notifications.success(t("productEditSuccess"));
        router.push(`/products/${product.id}`);
      } catch (err) {
        logger.error(err);
        notifications.error(t("productEditError"));
      } finally {
        this.isLoading = false;
      }
    },
    async create(product: Product) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        const id = await productsService.post(product);
        notifications.success(t("productCreateSuccess"));
        router.push(`/products/${id}`);
      } catch (err) {
        logger.error(err);
        notifications.error(t("productCreateError"));
      } finally {
        this.isLoading = false;
      }
    },
    async remove(id: string) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await productsService.remove(id);
        this.items = this.items.filter((listItem) => {
          return listItem.id != id;
        });
        notifications.success(t("productDeleteSuccess"));
        router.push(`/products`);
      } catch (err) {
        logger.error(err);
        notifications.error(t("productDeleteError"));
      } finally {
        this.isLoading = false;
      }
    },
    async fetchAvailableToBuyByBuyerId(buyerId: string) {
      //all products in the buyer pricelist and in the parent stock
      this.isLoading = true;
      await this.prepareStore();
      const notifications = useNotificationsStore();
      try {
        const fullAccount = await accountsService.get(buyerId);
        const sellerId =
          fullAccount.parents[fullAccount.parents.length - 1]?.id;
        if (sellerId) {
          const buyerProducts = await productsService.fetchAllByParentId(
            buyerId
          );
          const sellerStock = await stockService.getByAccountId(sellerId);
          this.items = buyerProducts
            .map((p) => {
              for (const stockProd of sellerStock) {
                if (stockProd.productId == p.id) {
                  p.stock = stockProd.quantity;
                  break;
                }
              }
              return p;
            })
            .filter((p) => {
              return p.stock != undefined;
            });
        } else {
          this.items = [];
        }
      } catch (err) {
        logger.error(err);
        notifications.error(t("productsFetchError"));
      } finally {
        this.isLoading = false;
      }
    },
    async fetchAvailableToSellById(id: string) {
      //all products in the buyer pricelist and in the parent stock
      this.isLoading = true;
      await this.prepareStore();
      const notifications = useNotificationsStore();
      try {
        const sellerProducts = await productsService.fetchAllByParentId(id);
        const sellerStock = await stockService.getByAccountId(id);
        this.items = sellerProducts
          .map((p) => {
            for (const stockProd of sellerStock) {
              if (stockProd.productId == p.id) {
                p.stock = stockProd.quantity;
                break;
              }
            }
            return p;
          })
          .filter((p) => {
            return p.stock != undefined;
          });
      } catch (err) {
        logger.error(err);
        notifications.error(t("productsFetchError"));
      } finally {
        this.isLoading = false;
      }
    },
  },
});
