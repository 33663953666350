import { TableConfig } from "@/models/listable.interface";

export interface LicenseReport {
  // id: string;
  accountName: string;
  serialKey: string;
  productName: string;
  sku: string;
  seatsTotal: number;
  seatsOccupied: number;
  activationDate?: Date;
  creationDate?: Date;
  maintenanceExpirationDate?: Date;
  expirationDate?: Date;
  licenseIsPerpetual: boolean;
  maintenanceIsPerpetual: boolean;
  note: string;
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function deserializeReport(input: any): LicenseReport {
  return {
    accountName: input.accountName || "",
    serialKey: input.serialKey || "",
    productName: input.productName || "",
    sku: input.sku || "",
    seatsTotal: input.seatsTotal || 0,
    seatsOccupied: input.seatsOccupied || 0,
    activationDate: input.activationDate
      ? new Date(input.activationDate)
      : undefined,
    creationDate: input.creationDate ? new Date(input.creationDate) : undefined,
    maintenanceExpirationDate: input.maintenanceExpirationDate
      ? new Date(input.maintenanceExpirationDate)
      : undefined,
    expirationDate: input.expirationDate
      ? new Date(input.expirationDate)
      : undefined,
    licenseIsPerpetual: input.licenseIsPerpetual,
    maintenanceIsPerpetual: input.maintenanceIsPerpetual,
    note: input.note || "",
  };
}

export function serializeReport(input: LicenseReport): unknown {
  return {
    accountName: input.accountName,
    serialKey: input.serialKey,
    productName: input.productName,
    sku: input.sku,
    seatsTotal: input.seatsTotal,
    seatsOccupied: input.seatsOccupied,
    activationDate: input.activationDate
      ? input.activationDate.getTime()
      : undefined,
    expirationDate: input.expirationDate
      ? input.expirationDate.getTime()
      : undefined,
    maintenanceExpirationDate: input.maintenanceExpirationDate
      ? input.maintenanceExpirationDate.getTime()
      : undefined,
    licenseIsPerpetual: input.licenseIsPerpetual,
    maintenanceIsPerpetual: input.maintenanceIsPerpetual,
    note: input.note,
  };
}

export const tableConfig = {
  accountName: {
    dtoName: "accountName",
    sortable: false,
    filterable: true,
    label: "owner",
  },
  serialKey: {
    dtoName: "serialKey",
    sortable: false,
    filterable: true,
    label: "serialKey",
  },
  productName: {
    dtoName: "productName",
    sortable: false,
    filterable: true,
    label: "product",
  },
  sku: {
    dtoName: "sku",
    sortable: false,
    filterable: true,
    label: "sku",
  },
  seatsTotal: {
    dtoName: "seatsTotal",
    sortable: false,
    filterable: false,
    label: "seats",
  },
  // seatsOccupied: {
  //   dtoName: "seatsOccupied",
  //   sortable: true,
  //   filterable: false,
  //   label: "seats",
  // },
  activationDate: {
    dtoName: "activationDate",
    sortable: true,
    filterable: false,
    label: "activationDate",
  },
  expirationDate: {
    dtoName: "expirationDate",
    sortable: true,
    filterable: false,
    label: "licenseExp",
  },
  maintenanceExpirationDate: {
    dtoName: "maintenanceExpirationDate",
    sortable: true,
    filterable: false,
    label: "maintenanceExp",
  },
  note: {
    dtoName: "note",
    sortable: false,
    filterable: true,
    label: "notes",
  },
  creationDate: {
    dtoName: "creationDate",
    sortable: true,
    filterable: false,
    label: "creationDate",
  },
} as TableConfig;
