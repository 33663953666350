import axios from "axios";
import * as configService from "@/services/configService";
import {
  searchOptionsSerialize,
  SearchOptions,
} from "@/models/listable.interface";
import OnPremEnvironmentModel, {
  OnPremEnvironment,
  tableConfig,
} from "@/models/onPremEnvironment.interface";

export async function get(id: string): Promise<OnPremEnvironment> {
  const res = await axios.get(`${configService.onPremEnvUrl}/${id}`);
  return OnPremEnvironmentModel.deserialize(res.data);
}

export async function search(
  options: SearchOptions
): Promise<{ items: OnPremEnvironment[]; count: number }> {
  const opt = options ? searchOptionsSerialize(options, tableConfig) : {};
  const res = await axios.post(`${configService.onPremEnvUrl}/search`, opt);
  return {
    items: res.data.items.map(OnPremEnvironmentModel.deserialize),
    count: res.data.count,
  };
}

export async function put(env: OnPremEnvironment): Promise<OnPremEnvironment> {
  await axios.put(
    `${configService.onPremEnvUrl}/${env.id}`,
    OnPremEnvironmentModel.serialize(env)
  );
  return env;
}

export async function post(env: OnPremEnvironment): Promise<string> {
  const res = await axios.post(
    `${configService.onPremEnvUrl}`,
    OnPremEnvironmentModel.serialize(env)
  );
  return res.data;
}

export async function remove(id: string): Promise<boolean> {
  const res = await axios.delete(`${configService.onPremEnvUrl}/${id}`);
  return !!res.data;
}

export async function fetchAllByParentId(
  id: string
): Promise<OnPremEnvironment[]> {
  const res = await axios.get(`${configService.onPremEnvUrl}/lookup/${id}`);
  return res.data.map(OnPremEnvironmentModel.deserialize);
}
