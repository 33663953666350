<template>
  <a-form
    :model="formState"
    :validate-messages="validateMessages"
    @finish="onFinish"
  >
    <a-form-item
      :name="['id']"
      :label="t('product')"
      :rules="[{ required: true }]"
    >
      <va-select
        v-model="formState.id"
        :options="products"
        :placeholder="t('product')"
        :disabled="productsStore.isLoading"
        @change="selectProduct"
      >
        <template #option="option">
          <option-product :item="option.nestedProps.fullItem" />
        </template>
      </va-select>
    </a-form-item>
    <a-form-item
      :name="['customPrice']"
      :label="t('price')"
      :rules="[{ required: true }]"
    >
      <va-input
        v-model="formState.customPrice"
        type="number"
        :suffix="pricelist?.currency.symbol"
        :placeholder="t('customPrice')"
      />
    </a-form-item>
    <a-form-item>
      <span v-if="originalPrice > 0" class="text-gray-500">
        {{ t("fullPrice") }}:
        <span class="line-through">
          {{ useFormatCurrency(originalPrice, pricelist?.currency) }}
        </span>
      </span>
      <va-button html-type="submit" class="float-right">
        {{ t("create") }}
      </va-button>
      <va-button class="float-right mr-4" @click="emit('dismiss')">
        {{ t("dismiss") }}
      </va-button>
    </a-form-item>
  </a-form>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import { t } from "@/plugins/i18n";
import VaSelect from "@/components/molecules/VaSelect.vue";
import { useValidationMessages } from "@/composables/validation";
import { useFormatCurrency } from "@/composables/formatters";
import {
  PriceListProduct,
  PriceListProductFormData,
} from "@/models/priceListProduct.interface";
import OptionProduct from "@/components/molecules/selectOptions/OptionProduct.vue";
import { usePricelistsStore } from "@/stores/pricelists";
import { useProductsStore } from "@/stores/products";
import { useLookupStore } from "@/stores/lookup";
import { SelectOption } from "@/models/selectOptions.interface";

const lookupStore = useLookupStore();
const productsStore = useProductsStore();
const priceListsStore = usePricelistsStore();
const validateMessages = useValidationMessages();

const emit = defineEmits(["save", "dismiss"]);

onBeforeMount(() => {
  productsStore.fetch();
  lookupStore.fetchCurrencies();
});

const pricelist = computed(() => {
  return priceListsStore.currentPriceList;
});

const products = computed(() => {
  const includedProductsIds = priceListsStore.currentPriceList?.products.map(
    (p) => {
      return p.id;
    }
  );
  return productsStore.productOptions.filter((product) => {
    //filter product already in pricelist
    return includedProductsIds && !includedProductsIds.includes(product.value);
  });
});

const selectProduct = (item: SelectOption<PriceListProduct>) => {
  formState.value.customPrice = item.fullItem.price;
  originalPrice.value = item.fullItem.price;
};

const originalPrice = ref(0);

const formState = ref({
  id: "",
  customPrice: 0,
} as PriceListProductFormData);

const onFinish = () => {
  priceListsStore.postProduct(formState.value);
  emit("save");
};
</script>
