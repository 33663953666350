<template>
  <div class="flex justify-between">
    <va-page-title>{{ t("licenses") }}</va-page-title>
    <div>
      <span>{{ t("onlyMyLicenses") }}: </span>
      <a-switch v-model:checked="excludeChildren" @change="onFiltersChange" />
      <va-button
        class="ml-6"
        :disabled="!reportsStore.canRead"
        @click="router.push('/reports/licenses')"
      >
        {{ t("reports") }}
      </va-button>
      <va-button
        :disabled="licensesStore.isLoading"
        class="ml-4"
        @click="onFiltersChange"
      >
        <i class="rm rm-ico-refresh-1" style="" />
      </va-button>
      <va-button
        class="ml-6"
        :disabled="!licensesStore.canCreate"
        @click="router.push('/licenses/create')"
      >
        <i class="rm rm-ico-e-add" />
      </va-button>
    </div>
  </div>
  <va-table
    id="licenseDataTable"
    :data-source="licenses"
    :table-config="tableConfig"
    :search-options="searchOptions"
    :loading="licensesStore.isLoading"
    class="w-100"
    @update="onTableUpdate"
  >
    <template #bodyCell="{ text, column, record }">
      <template v-if="column.dataIndex === 'serialKey'">
        <router-link
          :to="`/licenses/${record.serialKey}`"
          class="text-indigo-600"
        >
          {{ text }}
        </router-link>
      </template>
      <template v-if="column.dataIndex === 'creationDate'">
        <div class="text-xs text-gray-500 mt-2">
          {{ formatDate(record.creationDate) }}
        </div>
      </template>
      <template v-if="column.dataIndex === 'licenseHasBeenActivated'">
        <div class="text-center">
          <a-tag v-if="text" color="success">{{ t("yes") }}</a-tag>
          <a-tag v-else color="default">{{ t("no") }}</a-tag>
          <div v-if="record.activationDate" class="text-xs text-gray-500 mt-2">
            {{ formatDate(record.activationDate) }}
          </div>
          <div v-else class="text-xs text-gray-500 mt-2">
            {{ t("noDate") }}
          </div>
          <div
            v-if="record.licenseHasBeenExported"
            class="text-xs text-gray-500 mt-3"
          >
            {{ t("hasBeenExported") }}
          </div>
        </div>
      </template>
      <template v-if="column.dataIndex === 'expirationDate'">
        <div class="text-center">
          <div v-if="record.licenseIsPerpetual">
            <a-tag color="success">
              {{ t("perpetual") }}
            </a-tag>
            <div class="text-xs text-gray-500 mt-2">
              {{ t("noDate") }}
            </div>
          </div>
          <div v-else>
            <a-tag
              v-if="
                !isExpired(record.expirationDate) ||
                record.product.islicenseUpgradeRight
              "
              color="success"
            >
              {{ t("notExpired") }}
            </a-tag>
            <a-tag v-else color="error">{{ t("expired") }}</a-tag>
            <div
              v-if="
                record.expirationDate && !record.product.islicenseUpgradeRight
              "
              class="text-xs text-gray-500 mt-2"
            >
              {{ formatDate(record.expirationDate) }}
            </div>
            <div v-else class="text-xs text-gray-500 mt-2">
              {{ t("noDate") }}
            </div>
          </div>
        </div>
      </template>

      <template v-if="column.dataIndex === 'maintenanceExpirationDate'">
        <div class="text-center">
          <div v-if="record.maintenanceIsPerpetual">
            <a-tag color="success">
              {{ t("perpetual") }}
            </a-tag>
            <div class="text-xs text-gray-500 mt-2">
              {{ t("noDate") }}
            </div>
          </div>
          <div v-else>
            <a-tag
              v-if="
                !isExpired(record.maintenanceExpirationDate) ||
                record.product.islicenseUpgradeRight
              "
              color="success"
            >
              {{ t("notExpired") }}
            </a-tag>
            <a-tag v-else color="error">{{ t("expired") }}</a-tag>
            <div
              v-if="
                record.maintenanceExpirationDate &&
                !record.product.islicenseUpgradeRight
              "
              class="text-xs text-gray-500 mt-2"
            >
              {{ formatDate(record.maintenanceExpirationDate) }}
            </div>
            <div v-else class="text-xs text-gray-500 mt-2">
              {{ t("noDate") }}
            </div>
          </div>
        </div>
      </template>
      <template v-if="column.dataIndex === 'product'">
        {{ record.product.name }}
        <div class="text-xs text-gray-500">{{ record.product.sku }}</div>
      </template>
      <template v-if="column.dataIndex === 'ownerAccount'">
        {{ record.ownerAccount.name }}
      </template>
      <template v-if="column.dataIndex === 'seatsOccupied'">
        <div class="text-center">
          {{ record.seatsOccupied }}/{{ record.seats }}
        </div>
      </template>
      <template v-if="column.dataIndex === 'notes'">
        <div class="cell-ellipsis">
          {{ record.notes }}
        </div>
      </template>
    </template>
  </va-table>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import VaTable from "@/components/organisms/VaTable.vue";
import { tableConfig } from "@/models/license.interface";
import { SearchOptions } from "@/models/listable.interface";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import router from "@/router";
import { t } from "@/plugins/i18n";
import VaButton from "@/components/molecules/VaButton.vue";
import { useFormatDate } from "@/composables/formatters";
import { useLicensesStore } from "@/stores/licenses";
import { useLicenseReportsStore } from "@/stores/licenseReports";
import { defaultPagination } from "@/services/configService";
import { useMeta } from "vue-meta";

useMeta({
  title: "Licenses",
});
const licensesStore = useLicensesStore();
const reportsStore = useLicenseReportsStore();
const formatDate = useFormatDate;
const licenses = computed(() => licensesStore.licenses);
const searchOptions = computed(() => licensesStore.searchOptions);

const excludeChildren = ref(!searchOptions.value.includeChildren);

onBeforeMount(() => {
  licensesStore.setPreSort(
    {
      columnKey: "creationDate",
      order: "descend",
    },
    true
  );
});

function onFiltersChange() {
  searchOptions.value.includeChildren = !excludeChildren.value;
  searchOptions.value.pagination = defaultPagination;
  licensesStore.updateSearchOptions(searchOptions.value);
}

function onTableUpdate(searchOptions: SearchOptions) {
  licensesStore.updateSearchOptions(searchOptions);
}

function isExpired(date: Date) {
  return date?.getTime() <= Date.now();
}
</script>
