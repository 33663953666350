import { TableConfig } from "@/models/listable.interface";
import productfeatureModel, {
  ProductFeature,
} from "@/models/productFeature.interface";

export interface ProductType {
  id: string;
  name: string;
  description: string;
  creationDate?: Date;
  features: ProductFeature[];
}

export const tableConfig = {
  name: {
    dtoName: "productTypeName",
    sortable: true,
    filterable: true,
    label: "name",
  },
  description: {
    dtoName: "productTypeDescription",
    sortable: false,
    filterable: false,
    label: "description",
  },
  id: {
    dtoName: "productTypeId",
    sortable: false,
    filterable: false,
    label: "id",
    hideColumn: true,
  },
} as TableConfig;

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  deserialize(input: any): ProductType {
    return {
      id: input?.productTypeId || "",
      name: input?.productTypeName || "",
      description: input?.productTypeDescription || "",
      creationDate: input?.productTypeCreationDate
        ? new Date(input.productTypeCreationDate)
        : undefined,
      features: input?.features?.length
        ? input.features
            .map(productfeatureModel.deserialize)
            .filter((f: ProductFeature) => {
              return f.typeName != "unknown";
            })
        : [],
    };
  },

  serialize(input: ProductType): unknown {
    // if (input.id == "") return undefined;
    return {
      productTypeId: input.id,
      productTypeName: input.name,
      productTypeDescription: input.description,
      productTypeCreationDate: input.creationDate?.getTime() || undefined,
      features: input.features.map(productfeatureModel.serialize),
    };
  },
};
