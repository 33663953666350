<template>
  <div class="flex justify-between mt-8">
    <va-page-title>{{ t("certifications") }}</va-page-title>
  </div>
  <account-certifications-list
    v-if="account"
    :account-id="route.params.id"
    class="mb-8"
  />

  <user-certifications-table v-if="account" :account-id="route.params.id" />
</template>

<script setup lang="ts">
import UserCertificationsTable from "@/components/organisms/UserCertificationsTable.vue";
import { computed } from "vue";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import { useI18n } from "vue-i18n";
import AccountCertificationsList from "@/components/organisms/account/AccountCertificationsList.vue";
import { useAccountsStore } from "@/stores/accounts";
import { useRoute } from "vue-router";

const route = useRoute();
const { t } = useI18n();
const accountStore = useAccountsStore();
const account = computed(() => accountStore.currentAccount);
</script>
