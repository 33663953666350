import AccountModel, {
  Account,
  AccountFormData,
  AccountUserFormData,
  tableConfig,
} from "@/models/account.interface";
import axios from "axios";
import * as configService from "@/services/configService";
import {
  SearchOptions,
  searchOptionsSerialize,
} from "@/models/listable.interface";
import addressModel, { Address } from "@/models/address.interface";

export async function fetch(): Promise<Account[]> {
  const res = await axios.post(configService.accountsSearchUrl, {
    includeChildren: true,
    limit: configService.maxApiResults,
  });
  return res.data.items.map(AccountModel.deserialize);
}

export async function search(
  options: SearchOptions
): Promise<{ items: Account[]; count: number }> {
  const opt = options ? searchOptionsSerialize(options, tableConfig) : {};
  const res = await axios.post(configService.accountsSearchUrl, opt);
  return {
    items: res.data.items.map(AccountModel.deserialize),
    count: res.data.count,
  };
}

export async function searchChildren(
  options: SearchOptions
): Promise<{ items: Account[]; count: number }> {
  const opt = options ? searchOptionsSerialize(options, tableConfig) : {};
  const res = await axios.post(configService.accountsSearchUrl, opt);
  return {
    items: res.data.items.map(AccountModel.deserialize),
    count: res.data.count,
  };
}

export async function get(id: string): Promise<Account> {
  const res = await axios.get(`${configService.accountsUrl}/${id}`);
  return AccountModel.deserialize(res.data);
}

export async function post(formData: AccountFormData): Promise<string> {
  const res = await axios.post(`${configService.accountsUrl}`, formData);
  return res.data;
}

export async function cancel(id: string): Promise<string> {
  const res = await axios.delete(`${configService.accountsUrl}/${id}`);
  return res.data;
}

export async function createUser(
  formData: AccountUserFormData
): Promise<string> {
  const res = await axios.post(`${configService.usersUrl}`, formData);
  return res.data;
}

export async function putAddress(
  address: Address,
  accountId: string
): Promise<Address> {
  const data = addressModel.serialize(address);
  await axios.put(
    `${configService.accountsUrl}/${accountId}/address/${address.id}`,
    data
  );
  //api does not return any data
  return address;
}

export async function put(account: Account): Promise<Account> {
  await axios.put(`${configService.accountsUrl}/${account.id}`, {
    accountName: account.name,
    notificationReceiverEmail: account.email,
  });
  return account;
}
