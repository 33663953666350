<template>
  <div class="flex justify-between">
    <va-page-title>{{ t("stocks") }}</va-page-title>
  </div>
  <va-table
    :data-source="stocksStore.stocks"
    :table-config="tableConfig"
    :search-options="stocksStore.searchOptions"
    :loading="stocksStore.isLoading"
    class="w-100"
    @update="stocksStore.updateSearchOptions"
  >
    <template #bodyCell="{ column, text }">
      <template v-if="column.dataIndex === 'productName'">{{ text }}</template>
      <template v-if="column.dataIndex === 'productDescription'">
        {{ text }}
      </template>
      <template v-if="column.dataIndex === 'sku'">{{ text }}</template>
      <template v-if="column.dataIndex === 'quantity'">
        <span :class="{ 'text-red-600': text < 0 }">{{ text }}</span>
      </template>
    </template>
  </va-table>
</template>

<script setup lang="ts">
import { onBeforeMount } from "vue";
import VaTable from "@/components/organisms/VaTable.vue";
import { tableConfig } from "@/models/stock.interface";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import { useI18n } from "vue-i18n";
import { useStocksStore } from "@/stores/stocks";
import { useMeta } from "vue-meta";

useMeta({
  title: "Stocks",
});
const stocksStore = useStocksStore();
const { t } = useI18n();

onBeforeMount(() => {
  stocksStore.setPreSort(
    {
      columnKey: "sku",
      order: "ascend",
    },
    true
  );
});
</script>
