<template>
  <div v-if="account">
    <div class="va-card mt-6">
      <div class="px-4 py-5 sm:px-6 flex justify-between">
        <h3 class="va-title-s">{{ t("company") }}</h3>
        <va-button @click="router.push('/profile/account-edit')">
          <i class="rm rm-ico-edit" />
        </va-button>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl class="sm:divide-y sm:divide-gray-200">
          <va-info-row :title="t('name')">
            {{ account.name }}
          </va-info-row>
          <va-info-row :title="t('code')">
            {{ account.code }}
          </va-info-row>
          <va-info-row :title="t('accountGroup')">
            {{ account.group.name }}
          </va-info-row>
          <va-info-row :title="t('address')">
            {{ account.address?.legalName }}:
            {{ formatAddress(account.address) }} <br />
            {{ t("vatId") }}: {{ account.address?.vatId }}
          </va-info-row>
          <va-info-row :title="t('creationDate')">
            {{ formatDate(account.creationDate) }}
          </va-info-row>
        </dl>
      </div>
    </div>
  </div>

  <users-table
    v-if="account"
    :account-id="account.id"
    create-path="/profile/create-user"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useFormatDate, useFormatAddressFull } from "@/composables/formatters";
import { useI18n } from "vue-i18n";
import VaInfoRow from "@/components/atoms/VaInfoRow.vue";
import UsersTable from "@/components/organisms/UsersTable.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import router from "@/router";
import { useAuthStore } from "@/stores/auth";

const formatDate = useFormatDate;
const formatAddress = useFormatAddressFull;
const authStore = useAuthStore();
const { t } = useI18n();

const account = computed(() => authStore.userAccount);
</script>
