<template>
  <div ref="signinBtn">
    <!--    <va-button class="w-full flex justify-center red-btn" @click="login">-->
    <!--      {{ t("signInGoogle") }}-->
    <!--    </va-button>-->
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { oauthProviderCodes } from "@/services/configService";
import { useAuthStore } from "@/stores/auth";
import { useRoute } from "vue-router";

const authStore = useAuthStore();
const route = useRoute();
const signinBtn = ref(null);

// eslint-disable-next-line no-undef
const onSuccess = (googleUser: google.accounts.id.CredentialResponse) => {
  authStore.login({
    oauthProviderType: oauthProviderCodes.google,
    callbackUrl: `${window.location.origin}`,
    authCode: googleUser.credential,
    redirect: route.query?.redirect?.toString() || undefined,
  });
};

// const login = () => {
//   window.google?.accounts.id.prompt();
// };

const initGSI = () => {
  const script = document.createElement("script");
  script.src = "https://accounts.google.com/gsi/client";
  document.head.appendChild(script);
  script.onload = () => {
    window.google?.accounts.id.initialize({
      client_id: process.env.VITE_GOOGLE_CLIENT_ID,
      callback: onSuccess,
      ux_mode: "popup",
      auto_select: false,
    });
    window.google?.accounts.id.renderButton(signinBtn.value, {
      text: "signin_with", // or 'signup_with' | 'continue_with' | 'signin'
      size: "large", // or 'small' | 'medium'
      width: "368px", // max width 400
      theme: "outline", // or 'filled_black' |  'filled_blue'
      logo_alignment: "center", // or 'center'
    });
  };
};

onMounted(initGSI);
</script>
<style lang="scss" scoped>
.red-btn {
  @apply bg-red-600 hover:bg-red-700 focus:bg-red-700 focus:ring-red-500;
}
</style>
