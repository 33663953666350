<template>
  <div>
    <a-menu v-model:selectedKeys="current" theme="dark">
      <a-menu-item-group v-for="(group, index) in voices" :key="index">
        <template #title>{{ t(group.title) }}</template>

        <div
          v-for="(voice, vindex) in group.children"
          :key="`${index}-${vindex}`"
        >
          <router-link v-if="!voice.external" :to="voice.path">
            <a-menu-item :key="voice.path">
              <i :class="voice.icon + ' mr-3'" />
              <span>{{ t(voice.label) }}</span>
            </a-menu-item>
          </router-link>
          <a v-else :href="voice.path" target="_blank">
            <a-menu-item :key="voice.path">
              <i :class="voice.icon + ' mr-3'" />
              <span>{{ t(voice.label) }}</span>
            </a-menu-item>
          </a>
        </div>
      </a-menu-item-group>
    </a-menu>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const route = useRoute();
const current = ref<string[]>(route.matched.map((match) => match.path));
defineProps({
  voices: {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
    type: Array as PropType<any[]>,
    required: true,
    default: undefined,
  },
});
</script>

<style lang="scss">
.ant-menu:not(.ant-menu-horizontal) {
  a:not(.router-link-active) {
    .ant-menu-item-selected {
      background-color: transparent !important;
      color: rgba(255, 255, 255, 0.65);
    }
  }
}

.ant-menu-item {
  border-radius: 6px;

  &:hover {
    background: rgba(17, 24, 39, 0.45) !important;
  }
}

.ant-menu-item-divider {
  background: #374151 !important;
}

.ant-menu-item-group-title {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.7rem !important;
  padding: 0.7rem 0.3rem 0.3rem 0.5rem !important;
}
</style>
