<template>
  <auth :title="t('signInTitle')" :sub-title="t('signInDescription')">
    <!--    <div v-if="challengeProviders.length">-->
    <div v-if="challengeProviders.length">
      <div v-if="!selectedProvider">
        <p class="mt-2 text-center text-sm text-gray-600 mb-4">
          {{ t("mfaChoseProvider") }}
        </p>

        <div v-for="item in challengeProviders" :key="item.id" class="mb-4">
          <va-button
            :loading="authStore.isLoading"
            class="w-full flex justify-center"
            @click="authStore.selectProvider(item)"
          >
            {{ item.name }}
          </va-button>
        </div>
      </div>
      <div v-else>
        <h3>{{ selectedProvider.name }}</h3>
        <div class="flex mt-4">
          <va-input
            id="authenticatorCode"
            v-model="mfaCode"
            :placeholder="t('insertCode')"
            class="w-full mr-4"
          />
          <va-button :loading="authStore.isLoading" @click="mfaLogin">
            {{ t("signInCta") }}
          </va-button>
        </div>
      </div>
    </div>
    <a-form
      v-else
      :model="formState"
      :validate-messages="validateMessages"
      @finish="login"
    >
      <a-form-item
        :name="['loginData', 'email']"
        :label="t('signInEmail')"
        :rules="[{ type: 'email' }, { required: true }]"
      >
        <va-input
          v-model="formState.loginData.email"
          :placeholder="t('signInEmail')"
        />
      </a-form-item>
      <a-form-item
        :name="['loginData', 'password']"
        :label="t('signInPassword')"
        :rules="[{ required: true }]"
      >
        <va-input
          v-model="formState.loginData.password"
          :placeholder="t('signInPassword')"
          type="password"
        />
      </a-form-item>

      <div class="flex items-center justify-between mb-6 -mt-3">
        <div class="flex items-center"></div>
        <div class="text-sm">
          <router-link
            to="/auth/forgot-password"
            class="font-medium text-indigo-600 hover:text-indigo-500"
          >
            {{ t("signInForgot") }}
          </router-link>
        </div>
      </div>

      <div>
        <va-button
          :loading="authStore.isLoading"
          class="w-full flex justify-center"
          html-type="submit"
        >
          {{ t("signInCta") }}
        </va-button>
      </div>
    </a-form>
    <div v-if="!challengeProviders.length && oauthEnabled">
      <va-divider class="mt-3"> {{ t("or") }}</va-divider>
      <google-sign-in class="mt-3" />
      <microsoft-sign-in class="mt-3" />
      <apple-sign-in class="mt-3" />
    </div>
  </auth>
</template>

<script setup lang="ts">
import { reactive, computed, ref } from "vue";
import GoogleSignIn from "@/components/organisms/auth/GoogleSignIn.vue";
import MicrosoftSignIn from "@/components/organisms/auth/MicrosoftSignIn.vue";
import AppleSignIn from "@/components/organisms/auth/AppleSignIn.vue";

import { useI18n } from "vue-i18n";
import VaButton from "@/components/molecules/VaButton.vue";
import VaDivider from "@/components/molecules/VaDivider.vue";
import { useValidationMessages } from "@/composables/validation";
import VaInput from "@/components/molecules/VaInput.vue";
import Auth from "@/components/templates/Auth.vue";
import { useAuthStore } from "@/stores/auth";
import { useRoute } from "vue-router";
import { useMeta } from "vue-meta";

useMeta({
  title: "Sign In",
});
const { t } = useI18n();
const authStore = useAuthStore();
const validateMessages = useValidationMessages();
const route = useRoute();
const mfaCode = ref("");
const selectedProvider = computed(() => authStore.mfaSelectedProvider);
const challengeProviders = computed(() => authStore.mfaChallengeProviders);
const oauthEnabled = computed(() => {
  return process.env.VITE_OAUTH_DISABLED !== "true";
});

const formState = reactive({
  loginData: {
    email: "",
    password: "",
    redirect: route.query?.redirect?.toString() || undefined,
  },
});

function login() {
  authStore.login(formState.loginData);
}

function mfaLogin() {
  authStore.login({
    mfaCode: mfaCode.value,
    redirect: route.query?.redirect?.toString() || undefined,
  });
}
</script>
