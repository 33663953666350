<template>
  <a-date-picker
    :value="date"
    :placeholder="placeholder"
    :disabled="disabled"
    :format="t('YYYY/MM/DD')"
    @change="onchange"
  >
    <template #suffixIcon>
      <i class="rm rm-ico-calendar" />
    </template>
  </a-date-picker>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

//need to fix antd bad dayJs import: https://github.com/ant-design/ant-design/issues/26190
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(weekday);
dayjs.extend(localeData);

const { t } = useI18n();

const props = defineProps({
  modelValue: { type: [Date, String], required: false, default: "" },
  placeholder: { type: String, required: false, default: "" },
  disabled: { type: Boolean, required: false, default: false },
  dayTarget: { type: String, required: false, default: "now" },
});

const emit = defineEmits(["change", "update:modelValue"]);

const date = computed(() => {
  return props.modelValue ? dayjs(props.modelValue) : undefined;
});

const onchange = (value: string) => {
  let date = value ? new Date(value) : undefined;
  if (date && props.dayTarget === "start") {
    date.setHours(0, 0, 0, 0);
  } else if (date && props.dayTarget === "end") {
    date.setHours(23, 59, 59, 999);
  }
  emit("update:modelValue", date);
  emit("change", date);
};
</script>
<style lang="scss" scoped>
.ant-picker {
  @apply shadow-sm  rounded-md;
}
</style>
