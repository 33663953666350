import {
  Permission,
  permissionFlag,
  permissionModule,
} from "@/models/permission.interface";
import { useAuthStore } from "@/stores/auth";

export interface PermissionsMixinState {
  moduleName: permissionModule;
}

export const state = {
  moduleName: "",
};

export const hasPermission = (
  flag: permissionFlag,
  module: permissionModule,
  permissions: Permission[] | undefined
): boolean => {
  return (
    !!permissions?.length &&
    permissions.some((permission: Permission) => {
      return (
        permission.module === module &&
        permission.flags.some((pFlag) => pFlag === flag)
      );
    })
  );
};

const getPermissions = (): Permission[] => {
  const auth = useAuthStore();
  return auth.userPermissions;
};

export const getters = {
  canRead: (state: PermissionsMixinState): boolean => {
    const permissions = getPermissions();
    return (
      hasPermission("r", state.moduleName, permissions) ||
      hasPermission("ra", state.moduleName, permissions)
    );
  },
  canUpdate: (state: PermissionsMixinState): boolean => {
    const permissions = getPermissions();
    return hasPermission("u", state.moduleName, permissions);
  },
  canCreate: (state: PermissionsMixinState): boolean => {
    const permissions = getPermissions();
    return hasPermission("c", state.moduleName, permissions);
  },
  canDelete: (state: PermissionsMixinState): boolean => {
    const permissions = getPermissions();
    return hasPermission("d", state.moduleName, permissions);
  },
};
