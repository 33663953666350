import { TableConfig } from "@/models/listable.interface";

export interface Subscription {
  id: string;
  name: string;
  //LEGACY FIELDS NOT USED:
  // status: "Active" | "Pending" | "Deleted";
  // updateDate?: Date;
  // creationDate?: Date;
  // createdBy: string;
  // user?: string;
  // keyValues: {
  //   keyId: string;
  //   value: string;
  //   valueType: "int" | "bool" | "string"
  // }[]
}

export const tableConfig = {
  name: {
    dtoName: "name",
    sortable: false,
    filterable: false,
    label: "name",
    hideColumn: true,
  },
  id: {
    dtoName: "subscriptionId",
    sortable: false,
    filterable: false,
    label: "id",
    hideColumn: true,
  },
} as TableConfig;

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): Subscription {
    return {
      id: input.subscriptionId || "",
      name: input.name || "",
    };
  },
  serialize(input: Subscription): unknown {
    return {
      subscriptionId: input.id,
      name: input.name,
    };
  },
};
