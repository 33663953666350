import PriceListModel, {
  PriceList,
  PriceListFormData,
  tableConfig,
} from "@/models/priceList.interface";
import axios from "axios";
import * as configService from "@/services/configService";
import {
  SearchOptions,
  searchOptionsSerialize,
} from "@/models/listable.interface";
import PriceListProductModel, {
  PriceListProduct,
  PriceListProductFormData,
} from "@/models/priceListProduct.interface";

export async function get(id: string): Promise<PriceList> {
  const res = await axios.get(`${configService.priceListUrl}/${id}`);
  return PriceListModel.deserialize(res.data);
}

export async function remove(id: string): Promise<boolean> {
  const res = await axios.delete(`${configService.priceListUrl}/${id}`);
  return !!res.data;
}

export async function update(pricelist: PriceList): Promise<boolean> {
  const res = await axios.put(`${configService.priceListUrl}/${pricelist.id}`, {
    name: pricelist.name,
    currencyId: pricelist.currency.id,
  });
  return !!res.data;
}

export async function post(data: PriceListFormData): Promise<string> {
  const res = await axios.post(`${configService.priceListUrl}`, data);
  return res.data;
}

export async function postProduct(
  priceList: PriceList,
  product: PriceListProductFormData
): Promise<boolean> {
  const res = await axios.post(
    `${configService.priceListUrl}/${priceList.id}/products`,
    PriceListProductModel.serializeFormData(product)
  );
  return res.data;
}

export async function putProduct(
  priceList: PriceList,
  product: PriceListProductFormData
): Promise<boolean> {
  const res = await axios.put(
    `${configService.priceListUrl}/${priceList.id}/products`,
    PriceListProductModel.serializeFormData(product)
  );
  return res.data;
}

export async function deleteProduct(
  priceList: PriceList,
  product: PriceListProduct
): Promise<boolean> {
  const res = await axios.delete(
    `${configService.priceListUrl}/${priceList.id}/products/${product.id}`
  );
  return res.data;
}

export async function fetchAllByParentId(id: string): Promise<PriceList[]> {
  /** returns all the priceLists associated to the account id **/
  const res = await axios.post(configService.priceListSearchUrl, {
    limit: configService.maxApiResults,
    parentAccountId: id,
  });
  return res.data.items.map(PriceListModel.deserialize);
}

export async function search(
  options: SearchOptions
): Promise<{ items: PriceList[]; count: number }> {
  const opt = options ? searchOptionsSerialize(options, tableConfig) : {};
  const res = await axios.post(configService.priceListSearchUrl, opt);
  return {
    items: res.data.items.map(PriceListModel.deserialize),
    count: res.data.count,
  };
}

export async function getAssigment(id: string): Promise<PriceList | undefined> {
  const res = await axios.get(`${configService.priceListAssignmentsUrl}/${id}`);
  return res.data ? PriceListModel.deserialize(res.data) : undefined;
}

export async function postAssigment(
  priceListId: string,
  accountId: string,
  canPropagate: boolean
): Promise<boolean> {
  const res = await axios.post(
    `${configService.priceListAssignmentsUrl}/${accountId}`,
    {
      priceListId: priceListId,
      canPropagate: canPropagate,
    }
  );
  return !!res.data;
}
export async function putAssigment(
  priceListId: string,
  accountId: string,
  canPropagate: boolean
): Promise<boolean> {
  const res = await axios.put(
    `${configService.priceListAssignmentsUrl}/${accountId}`,
    {
      priceListId: priceListId,
      canPropagate: canPropagate,
    }
  );
  return !!res.data;
}
export async function removeAssigment(
  priceListId: string,
  accountId: string
): Promise<boolean> {
  const res = await axios.delete(
    `${configService.priceListAssignmentsUrl}/${accountId}`,
    { data: { priceListId: priceListId } }
  );
  return !!res.data;
}
