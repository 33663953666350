<template>
  <h2 class="mb-4">{{ product?.name }}</h2>
  <a-form
    :model="formState"
    :validate-messages="validateMessages"
    @finish="onFinish"
  >
    <a-form-item
      :name="['customPrice']"
      :label="t('price')"
      :rules="[{ required: true }]"
    >
      <va-input
        v-model="formState.customPrice"
        type="number"
        :suffix="pricelist?.currency.symbol"
        :placeholder="t('customPrice')"
      />
    </a-form-item>

    <a-form-item>
      <va-button html-type="submit" class="float-right">
        {{ t("save") }}
      </va-button>
      <va-button class="float-right mr-4" @click="emit('dismiss')">
        {{ t("dismiss") }}
      </va-button>
    </a-form-item>
  </a-form>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from "vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import { t } from "@/plugins/i18n";
import { useValidationMessages } from "@/composables/validation";
import {
  PriceListProduct,
  PriceListProductFormData,
} from "@/models/priceListProduct.interface";
import { usePricelistsStore } from "@/stores/pricelists";
import { useLookupStore } from "@/stores/lookup";

const lookupStore = useLookupStore();
const validateMessages = useValidationMessages();
const priceListsStore = usePricelistsStore();

const emit = defineEmits(["save", "dismiss"]);

const formState = ref({
  id: "",
  customPrice: 0,
} as PriceListProductFormData);

const onFinish = () => {
  priceListsStore.putProduct(formState.value);
  emit("save");
};

const product = computed(() => priceListsStore.currentProduct);

const pricelist = computed(() => {
  return priceListsStore.currentPriceList;
});

function setFormState(product: PriceListProduct | undefined): void {
  if (product) {
    formState.value.id = product.id;
    formState.value.customPrice = product.customPrice;
  }
}

watch(product, setFormState);

onBeforeMount(() => {
  setFormState(product.value);
  lookupStore.fetchCurrencies();
});
</script>
