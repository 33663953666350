<template>
  <div v-if="deal" id="dealDetail">
    <div class="va-card">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="va-title-s">
          {{ t("deal") }} {{ deal.code }}
          <span v-if="deal.isExpired" class="text-red-600 ml-6">
            {{ t("expired") }}
          </span>
        </h3>
        <p class="va-subtitle mb-4">
          <span class="mr-2"> {{ t("creationDate") }}: </span>
          <span class="mr-4"> {{ formatDate(deal.creationDate) }}</span>
          <span class="mr-2"> {{ t("expirationDate") }}: </span>
          <span
            class="mr-4"
            :class="deal.isExpired ? 'text-red-600' : 'text-green-600'"
          >
            {{ formatDate(deal.expirationDate) }}
          </span>
        </p>

        <va-back-link target="/deals">{{ t("backToDeals") }}</va-back-link>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div class="sm:col-span-2">
            <dl class="sm:divide-y sm:divide-gray-200">
              <va-info-row :title="t('vendor')">
                <router-link
                  :to="`/accounts/${deal.vendor?.id}`"
                  class="text-indigo-700"
                  >{{ deal.vendor?.name }}
                </router-link>
              </va-info-row>
              <va-info-row :title="t('prospect')">
                <div class="grid grid-cols-4">
                  <div>{{ t("companyName") }}:</div>
                  <div class="col-span-3">{{ deal.prospect?.companyName }}</div>
                  <div>{{ t("prospectName") }}:</div>
                  <div class="col-span-3">
                    {{ deal.prospect?.firstName }} {{ deal.prospect?.lastName }}
                  </div>
                  <div>{{ t("email") }}:</div>
                  <div class="col-span-3">{{ deal.prospect?.email }}</div>
                  <div>{{ t("phone") }}:</div>
                  <div class="col-span-3">{{ deal.prospect?.phone }}</div>
                  <div>{{ t("webSite") }}:</div>
                  <div class="col-span-3">{{ deal.prospect?.webSite }}</div>
                  <div>{{ t("address") }}:</div>
                  <div class="col-span-3">
                    {{ formatAddress(deal.prospect?.address) }}
                  </div>
                </div>
              </va-info-row>
              <va-info-row :title="t('startDate')">
                {{ formatDate(deal.startDate) }}
              </va-info-row>
              <va-info-row :title="t('expirationDate')">
                {{ formatDate(deal.expirationDate) }}
              </va-info-row>
              <va-info-row :title="t('dealDescription')">
                <div>
                  <va-input
                    v-model="deal.description"
                    :disabled="deal.isExpired"
                    type="textarea"
                    :placeholder="t('description')"
                  />
                </div>
              </va-info-row>
              <va-info-row :title="t('estimatedCloseDate')">
                <div v-if="dealsStore.canUpdate && !deal.isExpired">
                  <va-date-picker
                    v-model="deal.estimatedCloseDate"
                    class="w-64"
                  />
                </div>
                <span v-else> {{ formatDate(deal.estimatedCloseDate) }}</span>
              </va-info-row>
              <va-info-row :title="t('closureChance')">
                <div
                  v-if="dealsStore.canUpdate && !deal.isExpired"
                  class="flex"
                >
                  <va-input
                    v-model="deal.closureChance"
                    type="number"
                    :min="0"
                    :max="100"
                    suffix="%"
                    class="w-64"
                  />
                </div>
                <span v-else> {{ deal.closureChance }}%</span>
              </va-info-row>
              <va-info-row :title="t('status')">
                <div
                  v-if="dealsStore.canUpdate && !deal.isExpired"
                  class="flex"
                >
                  <va-select
                    v-model="deal.status"
                    :options="statusOptions"
                    class="w-64"
                  />
                </div>
                <div v-else>{{ t(deal.status) }}</div>
              </va-info-row>
              <va-button
                v-if="dealsStore.canUpdate && !deal.isExpired"
                class="mt-4 float-right"
                :disabled="dealsStore.isLoading"
                @click="updateDealInfo"
              >
                {{ t("update") }}
              </va-button>
            </dl>
          </div>
        </dl>
      </div>
    </div>

    <deal-items :deal="deal" />

    <div class="va-card mt-6">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="va-title-s">{{ t("dealProtection") }}</h3>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div class="sm:col-span-2">
            <dl class="sm:divide-y sm:divide-gray-200">
              <va-info-row :title="t('protectionStatus')">
                <div
                  v-if="dealsStore.canManage && !deal.isExpired"
                  class="flex"
                >
                  <va-select
                    v-model="deal.protectionStatus"
                    :options="protectionOptions"
                    class="w-64 mr-4"
                  />
                  <va-button
                    v-if="dealsStore.canManage && !deal.isExpired"
                    :disabled="dealsStore.isLoading"
                    @click="dealsStore.updateProtectionStatus"
                    >{{ t("save") }}
                  </va-button>
                </div>
                <span
                  v-else
                  :class="statusColors(deal.protectionStatus)"
                  class="mr-6 self-center w-64"
                >
                  {{ t(deal.protectionStatus) }}
                </span>
              </va-info-row>
            </dl>
          </div>
        </dl>
      </div>
    </div>

    <div class="va-card mt-6">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="va-title-s">{{ t("eBid") }}</h3>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div class="sm:col-span-2">
            <dl class="sm:divide-y sm:divide-gray-200">
              <va-info-row :title="t('total')">
                {{ useFormatCurrency(deal.totalItemsPrice, deal.currency) }}
              </va-info-row>
              <va-info-row :title="t('eBidStatus')">
                <div>
                  <span :class="statusColors(deal.eBidStatus)" class="">
                    {{ t(deal.eBidStatus) }}
                  </span>
                </div>
              </va-info-row>
              <va-info-row :title="t('eBidValue')">
                <div
                  v-if="dealsStore.canUpdate && !deal.isExpired"
                  class="flex"
                >
                  <va-input
                    v-model="deal.eBidValue"
                    type="number"
                    :suffix="deal.currency.symbol"
                    class="w-64"
                  />
                  <va-button
                    class="ml-4"
                    :disabled="dealsStore.isLoading"
                    @click="updateEbidValue"
                    >{{ t("update") }}
                  </va-button>
                </div>
                <div v-else class="flex items-center">
                  <div class="mr-4">
                    {{ useFormatCurrency(deal.eBidValue, deal.currency) }}
                  </div>
                </div>
              </va-info-row>
              <va-info-row :title="t('eBidApprovedValue')">
                <div
                  v-if="dealsStore.canManage && !deal.isExpired"
                  class="flex"
                >
                  <va-input
                    v-model="deal.eBidApprovedValue"
                    type="number"
                    :suffix="deal.currency.symbol"
                    class="w-64"
                  />
                  <va-button
                    class="ml-4"
                    :disabled="dealsStore.isLoading"
                    @click="dealsStore.updateEbidApprovedValue('Approved')"
                    >{{ t("approve") }}
                  </va-button>
                  <va-button
                    v-if="dealsStore.canManage && !deal.isExpired"
                    class="ml-4"
                    :disabled="dealsStore.isLoading"
                    @click="dealsStore.updateEbidApprovedValue('Refused')"
                    >{{ t("deny") }}
                  </va-button>
                </div>
                <div v-else>
                  {{ useFormatCurrency(deal.eBidApprovedValue, deal.currency) }}
                </div>
              </va-info-row>
            </dl>
          </div>
        </dl>
      </div>
    </div>
  </div>
  <deal-history class="mt-6" />
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import { t } from "@/plugins/i18n";
import VaInfoRow from "@/components/atoms/VaInfoRow.vue";
import { useFormatAddressFull, useFormatDate } from "@/composables/formatters";
import VaButton from "@/components/molecules/VaButton.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import VaDatePicker from "@/components/molecules/VaDatePicker.vue";
import {
  possibleDealStatus,
  possibleProtectionStatus,
} from "@/models/deal.interface";
import VaSelect from "@/components/molecules/VaSelect.vue";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import { useStatusColors } from "@/composables/colors";
import DealHistory from "@/components/organisms/DealHistory.vue";
import DealItems from "@/components/organisms/DealItems.vue";
import { useFormatCurrency } from "@/composables/formatters";
import { useDealsStore } from "@/stores/deals";
import { useMeta } from "vue-meta";

useMeta({
  title: "Deals",
});
const route = useRoute();
const dealsStore = useDealsStore();
const formatDate = useFormatDate;
const formatAddress = useFormatAddressFull;
const statusColors = useStatusColors;

const deal = computed(() => dealsStore.currentDeal);

const statusOptions = computed(() => {
  return possibleDealStatus.map((item: string) => {
    return {
      value: item,
      label: t(item),
    };
  });
});
const protectionOptions = computed(() => {
  return possibleProtectionStatus.map((item: string) => {
    return {
      value: item,
      label: t(item),
    };
  });
});

onBeforeMount(() => {
  dealsStore.get(route.params.id as string);
});

const updateEbidValue = () => {
  if (deal.value) {
    dealsStore.updateEbidValue(deal.value.eBidValue);
  }
};

const updateDealInfo = () => {
  if (deal.value) {
    dealsStore.updateDealInfo(deal.value);
  }
};
</script>
