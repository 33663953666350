import { defineStore } from "pinia";
import logger from "@/services/loggerService";
import { t } from "@/plugins/i18n";
import * as listable from "./listableMixin";
import * as permissions from "./permissionMixin";
import { cloneDeep } from "lodash";
import { defaultSearchOptions } from "./listableMixin";
import { useNotificationsStore } from "@/stores/notifications";
import * as productsService from "@/services/productsService";
import { buildOption, SelectOption } from "@/models/selectOptions.interface";
import { ProductType } from "@/models/productType.interface";
import router from "@/router";

export interface ProductTypesState
  extends listable.ListableMixinState,
    permissions.PermissionsMixinState {
  items: ProductType[];
  currentType?: ProductType;
  emptyType: ProductType;
}

export const useProductTypesStore = defineStore({
  id: "productTypes",
  state: (): ProductTypesState => ({
    isLoading: false,
    searchOptions: cloneDeep(defaultSearchOptions),
    moduleName: "ProductsType",
    items: [],
    currentType: undefined,
    searchError: t("productsFetchError"),
    searchFnc: productsService.searchTypes,
    emptyType: {
      id: "",
      name: "",
      description: "",
      features: [],
    },
  }),
  getters: {
    ...permissions.getters,
    productTypesOptions: (state): SelectOption<ProductType>[] => {
      return state.items.map((item) => {
        return buildOption<ProductType>(item, "id", "name");
      });
    },
  },
  actions: {
    ...listable.actions,
    setCurrentType(type: ProductType | undefined) {
      this.currentType = type;
    },
    async getType(id: string) {
      if (!id || this.currentType?.id === id) return;
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        this.currentType = await productsService.getType(id);
      } catch (err) {
        logger.error(err);
        notifications.error(t("productsTypesFetchError"));
      } finally {
        this.isLoading = false;
      }
    },
    async removeType(id: string) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await productsService.removeType(id);
        this.items = this.items.filter((listItem) => {
          return listItem.id != id;
        });
        notifications.success(t("productTypeDeleteSuccess"));
        router.push(`/products/types`);
      } catch (err) {
        logger.error(err);
        notifications.error(t("productTypeDeleteError"));
      } finally {
        this.isLoading = false;
      }
    },
    async fetchProductTypes() {
      if (!this.items.length) {
        this.isLoading = true;
        const notifications = useNotificationsStore();
        try {
          this.items = await productsService.fetchTypes();
        } catch (err) {
          logger.error(err);
          notifications.error(t("productsTypesFetchError"));
        } finally {
          this.isLoading = false;
        }
      }
    },
    async saveType(type: ProductType) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await productsService.putType(type);
        this.currentType = type;
        notifications.success(t("productCreateSuccess"));
        router.push(`/products/types/${type.id}`);
      } catch (err) {
        logger.error(err);
        notifications.error(t("productCreateError"));
      } finally {
        this.isLoading = false;
      }
    },
    async createType(type: ProductType) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        const res = await productsService.postType(type);
        type.id = res;
        this.currentType = type;
        notifications.success(t("productCreateSuccess"));
        router.push(`/products/types/${res}`);
      } catch (err) {
        logger.error(err);
        notifications.error(t("productCreateError"));
      } finally {
        this.isLoading = false;
      }
    },
  },
});
