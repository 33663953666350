export interface AccountGroup {
  groupId: string;
  name: string;
  level: number;
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): AccountGroup {
    return {
      groupId: input.groupId || "",
      name: input.name.replace(" ", "_").toUpperCase() || "",
      level: input.level || 0,
    };
  },
  serialize(input: AccountGroup): unknown {
    return {
      groupId: input.groupId,
      name: input.name.replace("_", " "),
      level: input.level,
    };
  },
};
