import { defineStore } from "pinia";
import logger from "@/services/loggerService";
import { t } from "@/plugins/i18n";
import * as listable from "./listableMixin";
import * as permissions from "./permissionMixin";
import router from "@/router";
import { cloneDeep } from "lodash";
import { defaultSearchOptions } from "./listableMixin";
import {
  DeserializeProductError,
  ProductStockError,
} from "@/models/product.interface";
import { useNotificationsStore } from "@/stores/notifications";
import { Order, OrderFormData } from "@/models/order.interface";
import {
  search,
  sellOutSearch,
  get,
  post,
  put,
  update,
  fetchAllByBuyerId,
} from "@/services/ordersService";
import { possibleStatus } from "@/models/orderDetails.interface";
import { buildOption, SelectOption } from "@/models/selectOptions.interface";

export interface OrdersState
  extends listable.ListableMixinState,
    permissions.PermissionsMixinState {
  items: Order[];
  currentOrder?: Order;
  creationError: ProductStockError[];
  revokeError: ProductStockError[];
}

function createStore<Id extends string>(id: Id) {
  return defineStore({
    id: id,
    state: (): OrdersState => ({
      isLoading: false,
      searchOptions: cloneDeep(defaultSearchOptions),
      moduleName: "Orders",
      items: [],
      currentOrder: undefined,
      searchError: t("ordersFetchError"),
      searchFnc: search,
      creationError: [],
      revokeError: [],
    }),
    getters: {
      ...permissions.getters,
      ordersOptions: (state): SelectOption<Order>[] => {
        return state.items.map((item) => {
          return buildOption<Order>(item, "id", "code");
        });
      },
      stockError: (state): ProductStockError[] => {
        return state.creationError.concat(state.revokeError);
      },
    },
    actions: {
      ...listable.actions,
      cleanStockErrors() {
        this.creationError = [];
        this.revokeError = [];
      },
      async get(id: string) {
        if (!id || this.currentOrder?.id === id) return;
        const notifications = useNotificationsStore();
        this.isLoading = true;
        try {
          this.currentOrder = await get(id);
        } catch (err) {
          logger.error(err);
          notifications.error(t("ordersFetchError"));
        } finally {
          this.isLoading = false;
        }
      },
      async create(data: OrderFormData) {
        const notifications = useNotificationsStore();
        this.isLoading = true;
        try {
          const res = await post(data);
          this.creationError = [];
          data.buyerAccountId
            ? router.push(`/sell-out/${res}`)
            : router.push(`/sell-in/${res}`);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
        } catch (err: any) {
          if (err?.response?.data?.errorCode == "OC527") {
            this.creationError = DeserializeProductError(
              err.response.data.message
            );
          } else {
            logger.error(err);
          }
          notifications.error(t("orderCreateError"));
        } finally {
          this.isLoading = false;
        }
      },
      async updateStatus(status: possibleStatus) {
        const notifications = useNotificationsStore();
        const id = this.currentOrder?.id;
        if (id) {
          this.isLoading = true;
          try {
            const data = {
              source: "api",
              sourceData: "",
              items: [],
              status: status,
            } as OrderFormData;
            await put(id, data);
            this.currentOrder = await get(id);
            notifications.success(t("orderUpdateSuccess"));
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
          } catch (err: any) {
            if (err?.response?.data?.errorCode == "OC527") {
              this.creationError = DeserializeProductError(
                err.response.data.message
              );
              notifications.error(t("orderUpdateError"));
            } else if (err?.response?.data?.errorCode == "OC528") {
              this.revokeError = DeserializeProductError(
                err.response.data.message
              );
              // notifications.error(t("orderRevokeError"));
            } else {
              logger.error(err);
              notifications.error(t("orderUpdateError"));
            }
          } finally {
            this.isLoading = false;
          }
        }
      },
      async updateInvoiceStatus(invoiced: boolean) {
        const id = this.currentOrder?.id;
        const paid = this.currentOrder?.isPaid;
        if (id) {
          const notifications = useNotificationsStore();
          this.isLoading = true;
          try {
            await update(id, invoiced, paid);
            this.currentOrder = await get(id);
            notifications.success(t("orderUpdateSuccess"));
          } catch (err) {
            logger.error(err);
            notifications.error(t("orderUpdateError"));
          } finally {
            this.isLoading = false;
          }
        }
      },
      async updatePaidStatus(paid: boolean) {
        const id = this.currentOrder?.id;
        const invoiced = this.currentOrder?.isInvoiced;
        if (id) {
          const notifications = useNotificationsStore();
          this.isLoading = true;
          try {
            await update(id, invoiced, paid);
            this.currentOrder = await get(id);
            notifications.success(t("orderUpdateSuccess"));
          } catch (err) {
            logger.error(err);
            notifications.error(t("orderUpdateError"));
          } finally {
            this.isLoading = false;
          }
        }
      },
      async updateCodeRef(codeRef: string) {
        const id = this.currentOrder?.id;
        const invoiced = this.currentOrder?.isInvoiced;
        const paid = this.currentOrder?.isPaid;
        if (id) {
          const notifications = useNotificationsStore();
          this.isLoading = true;
          try {
            await update(id, invoiced, paid, codeRef);
            this.currentOrder = await get(id);
            notifications.success(t("orderUpdateSuccess"));
          } catch (err) {
            logger.error(err);
            notifications.error(t("orderUpdateError"));
          } finally {
            this.isLoading = false;
          }
        }
      },
      async updateNotes(notes: string) {
        const id = this.currentOrder?.id;
        const invoiced = this.currentOrder?.isInvoiced;
        const paid = this.currentOrder?.isPaid;
        const codeRef = this.currentOrder?.refCode;
        if (id) {
          const notifications = useNotificationsStore();
          this.isLoading = true;
          try {
            await update(id, invoiced, paid, codeRef, notes);
            this.currentOrder = await get(id);
            notifications.success(t("orderUpdateSuccess"));
          } catch (err) {
            logger.error(err);
            notifications.error(t("orderUpdateError"));
          } finally {
            this.isLoading = false;
          }
        }
      },
      async fetchByBuyerId(id: string) {
        this.isLoading = true;
        const notifications = useNotificationsStore();
        try {
          this.items = await fetchAllByBuyerId(id);
        } catch (err) {
          logger.error(err);
          notifications.error(t("ordersFetchError"));
        } finally {
          this.isLoading = false;
        }
      },
    },
  });
}

function createStoreOrdersOut<Id extends string>(id: Id) {
  return defineStore({
    id: id,
    state: (): OrdersState => ({
      isLoading: false,
      searchOptions: cloneDeep(defaultSearchOptions),
      moduleName: "Orders",
      items: [],
      currentOrder: undefined,
      searchError: t("ordersFetchError"),
      searchFnc: sellOutSearch,
      creationError: [],
      revokeError: [],
    }),
    getters: {
      ...permissions.getters,
    },
    actions: {
      ...listable.actions,
    },
  });
}

export const useOrdersStore = createStore("orders");
export const useOrdersStoreAccount = createStore("ordersAccount");
export const useOrdersOutStore = createStoreOrdersOut("ordersOut");
export const useOrdersOutStoreAccount =
  createStoreOrdersOut("ordersOutAccount");
