<template>
  <table class="min-w-full divide-y divide-gray-300">
    <thead class="bg-gray-50">
      <tr>
        <th
          scope="col"
          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          {{ t("productFeatures") }}
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        ></th>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200 bg-white">
      <tr v-for="feature in features" :key="feature.id">
        <td class="whitespace-nowrap px-3 py-4 text-sm">
          <div>{{ feature.name }}</div>
          <div class="text-xs text-gray-500 flex justify-between">
            {{ feature.description }}
          </div>
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm">
          <span v-if="feature.typeName == 'dateTime'">
            {{ formatDate(feature.value as Date) }}
          </span>
          <span
            v-if="
              feature.typeName == 'string' ||
              feature.typeName == 'double' ||
              feature.typeName == 'int'
            "
          >
            {{ feature.value }}
          </span>
          <va-check-icon
            v-if="feature.typeName == 'bool'"
            :check="feature.value as boolean"
          />
          <div v-if="feature.typeName == 'object'">
            <div class="border rounded p-3 text-sm font-medium text-gray-500">
              <pre>{{ feature.value }}</pre>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import VaCheckIcon from "@/components/atoms/VaCheckIcon.vue";
import { ProductFeature } from "@/models/productFeature.interface";
import { useFormatDate } from "@/composables/formatters";
import { t } from "@/plugins/i18n";

const formatDate = useFormatDate;

defineProps({
  features: {
    type: Array as PropType<ProductFeature[]>,
    required: true,
  },
});

// const { t } = useI18n();
</script>
