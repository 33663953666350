<template>
  <div class="py-4 flex flex-row-reverse">
    <va-button :disabled="!envStore.canCreate" @click="openNewEnvDialog">
      <i class="rm rm-ico-e-add" />
    </va-button>
  </div>

  <va-table
    :data-source="envStore.items"
    :table-config="tableConfig"
    :search-options="envStore.searchOptions"
    :loading="envStore.isLoading"
    class="w-100"
    @update="envStore.updateSearchOptions"
  >
    <template #bodyCell="{ column, text, record }">
      <template v-if="column.dataIndex === 'customerName'">
        <router-link :to="`/environments/${record.id}`" class="text-indigo-600">
          {{ text }}
        </router-link>
      </template>
    </template>
  </va-table>

  <va-dialog v-model="newEnvDialog">
    <a-form
      v-if="newEnvDialog"
      :model="formState"
      :validate-messages="validateMessages"
      @finish="onSaveEnv"
    >
      <a-form-item
        :name="['customerName']"
        :label="t('customerName')"
        :rules="[{ required: true }]"
      >
        <va-input
          v-model="formState.customerName"
          :placeholder="t('customerName')"
        />
      </a-form-item>

      <a-form-item
        :name="['customerEnvironmentId']"
        :label="t('customerEnvironmentId')"
        :rules="[{ required: true }]"
      >
        <va-input
          v-model="formState.customerEnvironmentId"
          :placeholder="t('customerEnvironmentId')"
        />
      </a-form-item>

      <a-form-item>
        <va-button html-type="submit" class="float-right">
          {{ t("save") }}
        </va-button>
        <va-button class="float-right mr-4" @click="newEnvDialog = false">
          {{ t("dismiss") }}
        </va-button>
      </a-form-item>
    </a-form>
  </va-dialog>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import {
  OnPremEnvironment,
  tableConfig,
} from "@/models/onPremEnvironment.interface";
import VaTable from "@/components/organisms/VaTable.vue";
import { useRoute } from "vue-router";
import { onBeforeRouteUpdate } from "vue-router";
import { useOnPremEnvironmentStore } from "@/stores/onPremEnvironments";
import VaButton from "@/components/molecules/VaButton.vue";
import { t } from "@/plugins/i18n";
import VaInput from "@/components/molecules/VaInput.vue";
import VaDialog from "@/components/molecules/VaDialog.vue";
import { useValidationMessages } from "@/composables/validation";
import { useAuthStore } from "@/stores/auth";

const envStore = useOnPremEnvironmentStore();
const authStore = useAuthStore();
const route = useRoute();
const validateMessages = useValidationMessages();

const formState = ref({
  accountId: route.params.id ? route.params.id : authStore.userAccount?.id,
  customerName: "",
  customerEnvironmentId: "",
} as OnPremEnvironment);

const newEnvDialog = ref(false);

onBeforeRouteUpdate(async (to, from) => {
  if (to.params.id !== from.params.id) {
    envStore.setParentAccountId(to.params.id as string);
  }
});

const openNewEnvDialog = () => {
  formState.value = {
    accountId: route.params.id ? route.params.id : authStore.userAccount?.id,
    customerName: "",
    customerEnvironmentId: "",
  } as OnPremEnvironment;
  newEnvDialog.value = true;
};

const onSaveEnv = async () => {
  await envStore.createEnv(formState.value);
  newEnvDialog.value = false;
};

onBeforeMount(() => {
  envStore.setParentAccountId(route.params.id as string, true);
});
</script>
