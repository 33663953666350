import * as configService from "@/services/configService";
import {
  SearchOptions,
  searchOptionsSerialize,
} from "@/models/listable.interface";
import {
  AccountCertification,
  Certification,
  CertificationType,
  CertificationUser,
  DeserializeAccountCertification,
  DeserializeCertification,
  DeserializeUserCertification,
  tableConfig,
  UserCertification,
  UserCertificationFormData,
} from "@/models/certification.interface";
import axios, { AxiosResponse } from "axios";

//----------------- CERTIFICATES -----------------

export async function getCertificateById(id: string): Promise<Certification> {
  const res = await axios.get(`${configService.certificateUrl}/${id}`);
  return DeserializeCertification(res.data);
}

export async function getAccountCertificateById(
  id: string
): Promise<AccountCertification> {
  const res = await axios.get(`${configService.certificateAccountUrl}/${id}`);
  return DeserializeAccountCertification(res.data);
}
export async function fetchAvailableAccountCertifications(): Promise<
  Certification[]
> {
  const res = await axios.get(`${configService.accountCertificationsUrl}`);
  return res.data.map(DeserializeCertification);
}
export async function fetchAvailableUserCertifications(): Promise<
  Certification[]
> {
  const res = await axios.get(`${configService.userCertificationsUrl}`);
  return res.data.map(DeserializeCertification);
}

//----------------- ACCOUNT CERTIFICATES -----------------

export async function createAccountCertification(
  accountId: string,
  certificateId: string,
  notes: string
): Promise<string> {
  const result = await axios.post(`${configService.certificateAccountUrl}`, {
    accountId,
    certificateId,
    Notes: notes,
  });
  return result.data;
}

export async function getAccountCertificationById(
  id: string
): Promise<AccountCertification> {
  const res = await axios.get(`${configService.certificateAccountUrl}/${id}`);
  return DeserializeAccountCertification(res.data);
}

export async function getAccountCertifications(
  id: string
): Promise<AccountCertification[]> {
  const res = await axios.get(
    `${configService.certificateAccountUrl}/all/${id}`
  );
  return res.data.map(DeserializeAccountCertification);
}

//----------------- USER CERTIFICATES -----------------

export async function getUserCertification(
  userCertificateId: string
): Promise<UserCertification> {
  const res = await axios.get(
    `${configService.certificateUserUrl}/${userCertificateId}`
  );
  return DeserializeUserCertification(res.data);
}

export async function createUserCertification(
  data: UserCertificationFormData
): Promise<string> {
  const result = await axios.post(`${configService.certificateUserUrl}`, data);
  return DeserializeUserCertification(result.data).id;
}

export async function fetchAvailableUsers(
  accountId: string,
  certificateId: string
): Promise<CertificationUser[]> {
  const result = await axios.get(
    `${configService.certificateUserUrl}/fetch/${accountId}/${certificateId}`
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return result.data.map((val: any) => {
    return {
      value: val.userId || "",
      label: `${val.firstName} ${val.lastName}`,
    };
  });
}

export async function validateUserCertification(
  validationCode: string
): Promise<AxiosResponse> {
  return await axios.get(
    `${configService.certificateUrl}/verify/${validationCode.toUpperCase()}`
  );
}

export async function deleteCertification(id: string, type: CertificationType) {
  const baseUrl =
    type == "Account"
      ? configService.certificateAccountUrl
      : configService.certificateUserUrl;
  return await axios.delete(`${baseUrl}/${id}`);
}

export async function updateCertificationNotes(
  id: string,
  notes: string,
  type: CertificationType
) {
  if (type == "Account") {
    return await axios.put(`${configService.certificateAccountUrl}/notes`, {
      AccountCertificateId: id,
      notes: notes,
    });
  } else {
    return await axios.put(`${configService.certificateUserUrl}/notes`, {
      userCertificateId: id,
      notes,
    });
  }
}

export async function renewCertification(id: string, type: CertificationType) {
  if (type == "Account") {
    return await axios.put(
      `${configService.certificateAccountUrl}/renew/${id}`
    );
  } else {
    return await axios.put(`${configService.certificateUserUrl}/renew/${id}`);
  }
}

export async function searchUserCertifications(
  options: SearchOptions
): Promise<{ items: UserCertification[]; count: number } | void> {
  try {
    const opt = options ? searchOptionsSerialize(options, tableConfig) : {};
    const res = await axios.post(
      `${configService.certificateUserUrl}/search`,
      opt
    );
    return {
      items: res.data.items.map(DeserializeUserCertification),
      count: res.data.count,
    };
  } catch (e) {
    return;
  }
}

export async function downloadPdf(
  id: string,
  type: CertificationType
): Promise<string | void> {
  let result;
  if (type == "Account") {
    result = await axios.post(`${configService.certificateAccountUrl}/pdf`, {
      AccountCertificateId: id,
    });
  } else {
    result = await axios.post(`${configService.certificateUserUrl}/pdf`, {
      UserCertificateId: id,
    });
  }
  return result.data;
}
