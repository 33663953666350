<template>
  <div class="va-card relative">
    <div class="flex justify-between py-4 px-4">
      <h3 class="va-title-s">{{ t("accountCertificate") }}</h3>
      <va-button
        v-if="certificatesStore.canCreate"
        @click="
          router.push(`/accounts/${accountId}/certifications/create/account`)
        "
      >
        <i class="rm rm-ico-e-add" />
      </va-button>
    </div>

    <div v-if="certificatesStore.accountCertifications.length > 0">
      <va-table
        :data-source="certificatesStore.accountCertifications"
        :table-config="accountTableConfig"
        :search-options="allItemsSearchOptions"
        :hide-pagination="true"
        :loading="certificatesStore.isLoading"
        class="w-100"
      >
        <template #bodyCell="{ text, column, record }">
          <template v-if="column.dataIndex === 'certificateName'">
            <router-link
              :to="`/accounts/${accountId}/accountcertifications/${record.idAccountCertificate}`"
              class="text-indigo-600"
            >
              {{ text }}
            </router-link>
          </template>
          <template v-if="column.dataIndex === 'valid'">
            <div
              :class="`flex justify-center items-center text-${
                isExpired(record.expirationDate) ? 'red' : 'green'
              }-600`"
            >
              <i
                :class="
                  isExpired(record.expirationDate)
                    ? 'rm rm-ico-i-remove text-red-600'
                    : 'rm rm-ico-check-single text-green-600'
                "
              />
            </div>
          </template>
          <template v-if="column.dataIndex === 'actions'">
            <va-delete-button
              v-if="certificatesStore.canDelete"
              class="mr-2"
              :confirm-text="t('certificateAccountRemoveRemoveDescription')"
              :confirm-title="t('certificateAccountRemove')"
              @click:confirm="
                certificatesStore.deleteCertification(
                  record.idAccountCertificate,
                  'Account'
                )
              "
            />
          </template>
        </template>
      </va-table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, watch } from "vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaDeleteButton from "@/components/molecules/VaDeleteButton.vue";
import { t } from "@/plugins/i18n";
import { accountTableConfig } from "@/models/certification.interface";
import { useCertificatesStore } from "@/stores/certificates";
import { allItemsSearchOptions } from "@/services/configService";
import VaTable from "@/components/organisms/VaTable.vue";
import router from "@/router";

const certificatesStore = useCertificatesStore();

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

watch(
  () => props.accountId,
  async (newValue) => {
    certificatesStore.getAccountCertifications(newValue);
  }
);

const isExpired = (expirationDate: string) =>
  new Date(expirationDate).getTime() < new Date().getTime();

onBeforeMount(async () => {
  certificatesStore.getAccountCertifications(props.accountId);
});
</script>

<style lang="scss">
#add-account-certificate-button {
  align-items: self-end;
  display: flex;
}
</style>
