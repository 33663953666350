import axios from "axios";
import * as configService from "@/services/configService";
import {
  SearchOptions,
  searchOptionsSerialize,
} from "@/models/listable.interface";
import FileModel, {
  FileDownload,
  tableConfig,
} from "@/models/fileDownload.interface";

export async function search(
  options: SearchOptions
): Promise<{ items: FileDownload[]; count: number }> {
  const opt = options ? searchOptionsSerialize(options, tableConfig) : {};
  const res = await axios.post(configService.filesSearchUrl, opt);
  return {
    items: res.data.items.map(FileModel.deserialize),
    count: res.data.count,
  };
}

export async function uploadFile(
  file: File,
  fileCategory = "document",
  notes = "",
  parentAccountId: string | undefined
): Promise<FileDownload> {
  const formData = new FormData();
  formData.append("File", file);
  formData.append("FileCategory", fileCategory);
  formData.append("FileNote", notes);
  if (parentAccountId) formData.append("ParentAccountId", parentAccountId);
  const res = await axios.post(`${configService.filesUrl}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return FileModel.deserialize(res.data);
}

export async function deleteFile(id: string) {
  return await axios.delete(`${configService.filesUrl}/${id}`);
}

export async function editFile(id: string, name: string, notes: string) {
  return await axios.put(`${configService.filesUrl}/${id}`, {
    fileName: name,
    fileNote: notes,
  });
}

export async function downloadFile(id: string) {
  const res = await axios.get(`${configService.filesUrl}/${id}/download`);

  fetch(res.data.downloadUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${res.data.fileName}.${res.data.fileType}`;
      link.click();
      document.body.removeChild(link);
    });
}
