import { defineStore } from "pinia";
import { menuvoices } from "@/services/configService";
import { NavigationItem } from "@/models/navigationItem.interface";
import tenantService from "@/services/tenantService";
import { useAuthStore } from "@/stores/auth";

export interface AppState {
  productionMode: boolean;
  allMenuVoices: {
    title: string;
    children: NavigationItem[];
  }[];
  tenant: string;
}

export const useAppStore = defineStore({
  id: "app",
  state: (): AppState => ({
    productionMode: process.env.VITE_USER_NODE_ENV === "production",
    allMenuVoices: menuvoices,
    tenant: tenantService.getTenant(),
  }),
  getters: {
    menuVoices: (state) => {
      const auth = useAuthStore();
      return state.allMenuVoices
        .map((menu) => {
          return {
            title: menu.title,
            children: menu.children.filter((voice) => {
              return (
                (voice.maxLevel === undefined ||
                  (auth.userAccount?.group.level &&
                    voice.maxLevel >= auth.userAccount.group.level)) &&
                (voice.excludeTenant === undefined ||
                  !voice.excludeTenant?.includes(state.tenant)) &&
                (!voice.hideProduction || !state.productionMode)
              );
            }),
          };
        })
        .filter((menu) => {
          return menu.children.length;
        });
    },
  },
  actions: {},
});
