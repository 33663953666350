import ProductModel, {
  Product,
  ProductOption,
  tableConfig,
} from "@/models/product.interface";
import PriceListProductModel, {
  PriceListProduct,
} from "@/models/priceListProduct.interface";
import ProductTypeModel, { ProductType } from "@/models/productType.interface";

import axios from "axios";
import * as configService from "@/services/configService";
import {
  searchOptionsSerialize,
  SearchOptions,
} from "@/models/listable.interface";
import ProductTenantModel, {
  ProductTenant,
} from "@/models/productTenant.interface";
import ProductFeatureModel, {
  ProductFeature,
} from "@/models/productFeature.interface";
import { buildOption, SelectOption } from "@/models/selectOptions.interface";

export async function fetchAllByParentId(
  id: string
): Promise<PriceListProduct[]> {
  const res = await axios.get(`${configService.productsFetchUrl}/${id}`);
  return res.data.map(PriceListProductModel.deserialize);
}

export async function fetchDashboardOptions(
  id: string,
  includeChildren: boolean,
  from?: Date,
  to?: Date
): Promise<SelectOption<ProductOption>[]> {
  const res = await axios.post(`${configService.productsDashboardOptionsUrl}`, {
    parentAccountId: id,
    includeChildren: includeChildren,
    currentFrom: from ? from.getTime() : undefined,
    currentTo: to ? to.getTime() : undefined,
  });
  return res.data.products.map((item: unknown) => {
    return buildOption(item, "productId", "productName");
  });
}

export async function get(id: string): Promise<Product> {
  const res = await axios.get(`${configService.productsUrl}/${id}`);
  return PriceListProductModel.deserialize(res.data);
}

export async function getOriginal(id: string): Promise<Product> {
  const res = await axios.get(`${configService.productsEditUrl}/${id}`);
  return ProductModel.deserialize(res.data);
}

export async function search(
  options: SearchOptions
): Promise<{ items: Product[]; count: number }> {
  const opt = options ? searchOptionsSerialize(options, tableConfig) : {};
  const res = await axios.post(configService.productsSearchUrl, opt);
  return {
    items: res.data.items.map(PriceListProductModel.deserialize),
    count: res.data.count,
  };
}

export async function put(product: Product): Promise<Product> {
  await axios.put(
    `${configService.productsEditUrl}/${product.id}`,
    ProductModel.serialize(product)
  );
  return product;
}

export async function post(product: Product): Promise<string> {
  const res = await axios.post(
    `${configService.productsEditUrl}`,
    ProductModel.serialize(product)
  );
  return res.data;
}

export async function remove(id: string): Promise<boolean> {
  const res = await axios.delete(`${configService.productsEditUrl}/${id}`);
  return !!res.data;
}

export async function fetchTypes(): Promise<ProductType[]> {
  const res = await axios.get(`${configService.productsTypesUrl}/lookup`);
  return res.data.map(ProductTypeModel.deserialize);
}

export async function searchTypes(
  options: SearchOptions
): Promise<{ items: ProductType[]; count: number }> {
  const opt = options ? searchOptionsSerialize(options, tableConfig) : {};
  const res = await axios.post(`${configService.productsTypesUrl}/search`, opt);
  return {
    items: res.data.items.map(ProductTypeModel.deserialize),
    count: res.data.count,
  };
}

export async function getType(id: string): Promise<ProductType> {
  const res = await axios.get(`${configService.productsTypesUrl}/${id}`);
  return ProductTypeModel.deserialize(res.data);
}

export async function removeType(id: string): Promise<boolean> {
  const res = await axios.delete(`${configService.productsTypesUrl}/${id}`);
  return !!res.data;
}

export async function putType(type: ProductType): Promise<string> {
  const res = await axios.put(
    `${configService.productsTypesUrl}/${type.id}`,
    ProductTypeModel.serialize(type)
  );
  return res.data;
}

export async function postType(type: ProductType): Promise<string> {
  const serializedType = ProductTypeModel.serialize(type);
  // delete serializedType.productTypeCreationDate;
  // delete serializedType.productTypeId;
  const res = await axios.post(
    `${configService.productsTypesUrl}`,
    serializedType
  );
  return res.data;
}

export async function fetchFeatures(): Promise<ProductFeature[]> {
  const res = await axios.get(
    `${configService.productsTypesUrl}/features/lookup`
  );
  return res.data.map(ProductFeatureModel.deserialize);
}

export async function removeFeature(id: string): Promise<boolean> {
  const res = await axios.delete(
    `${configService.productsTypesUrl}/features/${id}`
  );
  return !!res.data;
}

export async function putFeature(feature: ProductFeature): Promise<string> {
  const res = await axios.put(
    `${configService.productsTypesUrl}/features/${feature.id}`,
    ProductFeatureModel.serialize(feature)
  );
  return res.data;
}

export async function postFeature(feature: ProductFeature): Promise<string> {
  const res = await axios.post(
    `${configService.productsTypesUrl}/features`,
    ProductFeatureModel.serialize(feature)
  );
  return res.data;
}

export async function searchFeatures(
  options: SearchOptions
): Promise<{ items: ProductFeature[]; count: number }> {
  const opt = options ? searchOptionsSerialize(options, tableConfig) : {};
  const res = await axios.post(
    `${configService.productsTypesUrl}/features/search`,
    opt
  );
  return {
    items: res.data.items.map(ProductFeatureModel.deserialize),
    count: res.data.count,
  };
}

export async function fetchProductTenants(): Promise<ProductTenant[]> {
  const res = await axios.get(`${configService.productsTenantsUrl}/lookup`);
  return res.data.map(ProductTenantModel.deserialize);
}
