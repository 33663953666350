import { CurrencyData } from "@/models/currencyData.interface";
import logger from "@/services/loggerService";
import { useLookupStore } from "@/stores/lookup";

export const possibleEBidStatus = ["Unknown", "Pending", "Approved", "Refused"];

export type eBidStatus = typeof possibleEBidStatus[number];

export interface Ebid {
  eBidValue?: number;
  currency: CurrencyData;
  eBidStatus: eBidStatus;
  eBidApprovedValue?: number;
  updateDate: Date;
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): Ebid {
    //STORE DEPENDENCY: need already fetched currencies to decode from id to full currency item
    const lookupStore = useLookupStore();
    return {
      eBidValue: input.value,
      currency: lookupStore.decodedCurrency(input.priceCurrencyId),
      eBidStatus: deserializeEBidStatus(input.eBidStatus),
      eBidApprovedValue: input.eBidApprovedValue,
      updateDate: new Date(input.creationDate),
    };
  },
  serialize(input: Ebid): unknown {
    return {
      eBidValue: input.eBidValue,
      currency: input.currency.id,
      eBidStatus: input.eBidStatus,
      eBidApprovedValue: input.eBidApprovedValue,
      updateDate: input.updateDate.getTime(),
    };
  },
};

export const deserializeEBidStatus = (input?: string): eBidStatus => {
  if (input && possibleEBidStatus.includes(input)) {
    return input;
  } else {
    logger.error(`Deal deserializer: unhandled eBidStatus value "${input}"`);
    return "Pending";
  }
};
