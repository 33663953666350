import AccountModel, { Account } from "@/models/account.interface";
import PermissionModel, { Permission } from "@/models/permission.interface";
import RoleModel, { Role } from "@/models/role.interface";
import { TableConfig } from "@/models/listable.interface";
import logger from "@/services/loggerService";
import { langCodes } from "@/services/countriesService";

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  timeZone: string;
  languageCode: string;
  creationDate: Date;
  createdBy: string;
  roles: Role[];
  permissions: Permission[];
  account?: Account;
  accountId: string;
  accountName: string;
  status: "user_pending" | "user_active" | "user_suspended";
  isSuperAdmin: boolean;
  isNotificationEnabled: boolean;
}

export interface UserFormData {
  id: string;
  firstName: string;
  lastName: string;
  timeZone: string;
  languageCode: string;
  isNotificationEnabled: boolean;
}

export const tableConfig = {
  id: {
    dtoName: "userId",
    sortable: false,
    filterable: true,
    label: "id",
    hideColumn: true,
  },
  accountId: {
    dtoName: "accountId",
    sortable: false,
    filterable: true,
    label: "accountId",
    hideColumn: true,
  },
  lastName: {
    dtoName: "lastName",
    sortable: false,
    filterable: false,
    label: "name",
  },
  email: {
    dtoName: "email",
    sortable: false,
    filterable: false,
    label: "email",
  },
  role: {
    dtoName: "roles",
    sortable: false,
    filterable: false,
    label: "role",
  },
  status: {
    dtoName: "status",
    sortable: false,
    filterable: false,
    label: "status",
  },
  isNotificationEnabled: {
    dtoName: "isNotificationEnabled",
    sortable: false,
    filterable: false,
    label: "notificationEnabled",
  },
  actions: {
    dtoName: "",
    sortable: false,
    filterable: false,
    label: "actions",
    hideColumn: false,
  },
} as TableConfig;

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): User {
    const account = input.account
      ? AccountModel.deserialize(input.account)
      : undefined;
    const roles = input.roles?.length
      ? input.roles.map(RoleModel.deserialize)
      : [];
    const isSuperAdmin = roles.some((role: Role) => role.isSuperAdmin);
    const permissions = input.permissions?.length
      ? input.permissions.map(PermissionModel.deserialize)
      : [];
    let userstatus = "user_suspended" as
      | "user_pending"
      | "user_active"
      | "user_suspended";
    switch (input.status) {
      case "pending":
        userstatus = "user_pending";
        break;
      case "active":
        userstatus = "user_active";
        break;
      case "suspended":
        userstatus = "user_suspended";
        break;
      default:
        logger.error(
          `User deserializer: unhandled status value ${input.status} for user: ${input.userId}`
        );
    }
    const langCode = langCodes.map((l) => l.value).includes(input.languageCode)
      ? input.languageCode
      : navigator.language || "en";

    return {
      id: input.userId || "",
      email: input.email || "",
      firstName: input.firstName || "",
      lastName: input.lastName || "",
      timeZone: input.timeZone || "UTC",
      languageCode: langCode,
      creationDate: new Date(input.creationDate),
      createdBy: input.createdBy || "",
      account: account,
      roles: roles,
      permissions: permissions,
      accountId: input.accountId || "",
      accountName: input.accountName || "",
      status: userstatus,
      isSuperAdmin: isSuperAdmin,
      isNotificationEnabled: input.isNotificationEnabled,
    };
  },

  serialize(input: User): unknown {
    return {
      userId: input.id,
      email: input.email,
      firstName: input.firstName,
      lastName: input.lastName,
      timeZone: input.timeZone,
      languageCode: input.languageCode,
      creationDate: input.creationDate.getTime(),
      createdBy: input.createdBy,
      account: input.account
        ? AccountModel.serialize(input.account)
        : undefined,
      roles: input.roles.map(RoleModel.serialize),
      permissions: input.permissions.map(PermissionModel.serialize),
      accountId: input.accountId,
      accountName: input.accountName,
      status:
        input.status === "user_active"
          ? "active"
          : input.status === "user_pending"
          ? "pending"
          : "suspended",

      isNotificationEnabled: input.isNotificationEnabled,
    };
  },
};
