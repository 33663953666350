<template>
  <va-page-title>{{ t("newUser") }}</va-page-title>
  <va-back-link v-if="!route.params.id" target="/profile/account">
    {{ t("backToAccount") }}
  </va-back-link>
  <div class="va-card mt-6 relative p-4">
    <a-form
      class=""
      :model="formState"
      :validate-messages="validateMessages"
      @finish="onFinish"
    >
      <div class="grid grid-cols-2 gap-4">
        <a-form-item
          :name="['user', 'firstName']"
          :label="t('firstName')"
          :rules="[{ required: true }]"
        >
          <va-input
            v-model="formState.user.firstName"
            :placeholder="t('firstName')"
          />
        </a-form-item>
        <a-form-item
          :name="['user', 'lastName']"
          :label="t('lastName')"
          :rules="[{ required: true }]"
        >
          <va-input
            v-model="formState.user.lastName"
            :placeholder="t('lastName')"
          />
        </a-form-item>
        <a-form-item
          :name="['user', 'userName']"
          :label="t('email')"
          :rules="[{ type: 'email' }, { required: true }]"
        >
          <va-input
            v-model="formState.user.userName"
            :placeholder="t('email')"
          />
        </a-form-item>
        <a-form-item :name="['user', 'roles']" :label="t('Role')">
          <va-select
            v-model="formState.user.roles[0]"
            :options="lookupStore.regularRolesOptions"
            :placeholder="t('Role')"
            :disabled="lookupStore.rolesLoading || !usersStore.canCreate"
          />
        </a-form-item>
      </div>

      <a-form-item>
        <va-button
          html-type="submit"
          class="float-right"
          :disabled="!usersStore.canCreate"
          :loading="usersStore.isLoading"
        >
          {{ t("save") }}
        </va-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref, watch } from "vue";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import { t } from "@/plugins/i18n";
import VaSelect from "@/components/molecules/VaSelect.vue";
import { useValidationMessages } from "@/composables/validation";
import { AccountUserFormData } from "@/models/account.interface";
import { useRoute } from "vue-router";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import { useLookupStore } from "@/stores/lookup";
import { useUsersStore } from "@/stores/users";
import { storeToRefs } from "pinia";
import router from "@/router";
import { useAuthStore } from "@/stores/auth";
import { useAccountsStore } from "@/stores/accounts";
import { useNotificationsStore } from "@/stores/notifications";

const authStore = useAuthStore();
const accountStore = useAccountsStore();
const lookupStore = useLookupStore();
const usersStore = useUsersStore();
const notifications = useNotificationsStore();
const route = useRoute();
const validateMessages = useValidationMessages();

const formState = ref({
  parentAccountId: route.params.id
    ? route.params.id
    : authStore.userAccount?.id,
  user: {
    userName: "",
    firstName: "",
    lastName: "",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || "en-US",
    roles: [lookupStore.regularRoles[0]?.id],
  },
} as AccountUserFormData);

watch(storeToRefs(lookupStore).regularRoles, (currentValue) => {
  if (currentValue) {
    formState.value.user.roles[0] = currentValue[0].id;
  }
});

const onFinish = async () => {
  if (!formState.value.parentAccountId) {
    notifications.error(t("newUserError"));
  }
  await accountStore.createUser(formState.value);
  route.params.id
    ? router.push(`/accounts/${route.params.id}`)
    : router.push("/profile/account");
};

onBeforeMount(() => {
  lookupStore.fetchRoles();
});
</script>
