import { Address } from "@/models/address.interface";
import { t } from "@/plugins/i18n";
import loggerService from "@/services/loggerService";
import { useAuthStore } from "@/stores/auth";
import { CurrencyData } from "@/models/currencyData.interface";

const getLocale = (): string => {
  const authStore = useAuthStore();
  return authStore.user?.languageCode || navigator.language || "en";
};

export function useFormatDateShort(date?: Date): string {
  if (!date) return "";
  return date.toLocaleDateString(getLocale(), {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}

export function useFormatDateWeek(date?: Date): string {
  if (!date) return "";
  const startDate = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor(
    (date.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)
  );

  const weekNumber = Math.ceil(days / 7);
  return `${t("week")} ${weekNumber}`;
}

export function useFormatDateMonth(date?: Date): string {
  if (!date) return "";
  return date.toLocaleDateString(getLocale(), {
    year: "numeric",
    month: "short",
  });
}

export function useFormatDate(date?: Date): string {
  if (!date) return "";
  return date.toLocaleDateString(getLocale(), {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function useFormatDateTime(date?: Date): string {
  if (!date) return "";
  return date.toLocaleDateString(getLocale(), {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function useFormatDateUTC(date?: Date): string {
  if (!date) return "";
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  return (
    utcDate.toLocaleDateString(getLocale(), {
      year: "numeric",
      month: "short",
      day: "numeric",
    }) + " UTC"
  );
}

export function useFormatDateLong(date?: Date): string {
  if (!date) return "";
  return date.toLocaleDateString(getLocale(), {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function useFormatAddressFull(address?: Address): string {
  if (address) {
    return `${address.street}, ${address.postalCode} ${address.city} ${address.country}`;
  }
  return t("addressMissing");
}

export function useFormatAddressShort(address?: Address): string {
  if (address) {
    return `${address.city}, ${address.country}`;
  }
  return t("addressMissing");
}

export function useFormatNumber(number: number, integer = false): string {
  let options = {};
  if (!integer) options = { minimumFractionDigits: 2 };
  if (!isNaN(number)) {
    return new Intl.NumberFormat(getLocale(), options).format(number);
  }
  return number.toString();
}

export function useFormatCurrency(number = 0, currency?: CurrencyData): string {
  return new Intl.NumberFormat(getLocale(), {
    style: "currency",
    currency: currency?.code || "EUR",
    currencyDisplay: "narrowSymbol",
  }).format(number);
}

export function useFormatBytes(bytes = 0, decimals = 2): string {
  if (!+bytes) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

// = "EUR"
export function useParseNumber(val = "0", integer = false): number {
  const result = 0;
  try {
    // detecting and parsing between comma and dot
    const group = new Intl.NumberFormat(getLocale())
      .format(1111)
      .replace(/1/g, "");
    const decimal = new Intl.NumberFormat(getLocale())
      .format(1.1)
      .replace(/1/g, "");
    let stringVal = val.replace(new RegExp("\\" + group, "g"), "");
    stringVal = stringVal.replace(new RegExp("\\" + decimal, "g"), ".");
    //  => 1232.21 €
    // removing everything except the digits and dot
    stringVal = stringVal.replace(/[^0-9.]/g, "");
    //  => 1232.21
    if (integer) return Math.round(parseFloat(stringVal));
    return Math.round(parseFloat(stringVal) * 100) / 100;
  } catch (error) {
    loggerService.error(error);
    return result;
  }
}
