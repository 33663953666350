import { PaginationProps } from "ant-design-vue";
import { NavigationItem } from "@/models/navigationItem.interface";
import { EnvironmentType } from "@/models/EnvironmentType.interface";
import { t } from "@/plugins/i18n";

export const applicationName = "Sales";

const baseUrl = `${process.env.VITE_API_BASEURL}/api/${process.env.VITE_API_VERSION}`;
const invitationsUrl = `${process.env.VITE_API_INVITATIONSURL}/api/${process.env.VITE_API_VERSION}`;

//---auth---//
export const userMeUrl = `${baseUrl}/users/me`;
export const authUrl = `${baseUrl}/auth/token`;
export const refreshAuthUrl = `${baseUrl}/auth/token-refresh`;
export const mfaProvidersUrl = `${baseUrl}/auth/mfa/providers`;
export const mfaEnabledProvidersUrl = `${baseUrl}/auth/me/mfa/providers`;
export const mfaEnableCodeUrl = `${baseUrl}/auth/mfa/send-code`;
export const mfaVerificationCodeUrl = `${baseUrl}/auth/mfa/verify-code`;
export const mfaDisableProviderUrl = `${baseUrl}/auth/mfa`;
export const confirmMfaActivationUrl = `${baseUrl}/auth/mfa`;
export const providersChallengeUrl = `${baseUrl}/auth/token/mfa/challenge`;
export const mfaSendCodeUrl = `${baseUrl}/auth/token/mfa/send-code`;
export const backupCodesUrl = `${baseUrl}/auth/mfa/backup-codes`;
export const confirmMailUrl = `${invitationsUrl}/Invitations`;
export const forgotPasswordUrl = `${baseUrl}/auth/password/reset/request`;
export const resetPasswordUrl = `${baseUrl}/auth/password/reset`;

//---products---//
// export const productsSearchUrl = `${baseUrl}/products/search`;
// export const productsUrl = `${baseUrl}/products`;
export const productsSearchUrl = `${baseUrl}/pricelists/products/search`;
export const productsFetchUrl = `${baseUrl}/pricelists/products/fetch`;
export const productsDashboardOptionsUrl = `${baseUrl}/pricelists/products/dashboard/lookup`;
export const productsUrl = `${baseUrl}/pricelists/products`;
export const productsEditUrl = `${baseUrl}/products`;
export const productsTypesUrl = `${baseUrl}/productsType`;
export const productsTenantsUrl = `${baseUrl}/tenants`;
export const onPremEnvUrl = `${baseUrl}/onPremiseEnvironments`;

//---subscriptions---//
export const subscriptionsSearchUrl = `${baseUrl}/subscriptions/search`;

//---licenses---//
export const licensesSearchUrl = `${baseUrl}/licenses/search`;
export const licensesReportUrl = `${baseUrl}/licenseReports/search`;
export const licensesReportDownloadUrl = `${baseUrl}/licenseReports/download`;
export const licensesUrl = `${baseUrl}/licenses`;
export const licensesExportUrl = `${baseUrl}/licensesExport`;
export const licensesRmaUrl = `${baseUrl}/licensesRma`;
export const licensesSeats = `${baseUrl}/licenses/seats`;

//---ORDERS---//
export const ordersUrl = `${baseUrl}/orders`;
export const ordersSearchUrl = `${baseUrl}/orders/search`;
// export const ordersSellINSearchUrl = `${baseUrl}/orders/search`;
export const ordersOutSearchUrl = `${baseUrl}/orders/sell-out/search`;

//---DEALS---//
export const dealsUrl = `${baseUrl}/deals`;
export const dealsSearchUrl = `${baseUrl}/deals/search`;

//---PRICELISTS---//
export const priceListUrl = `${baseUrl}/pricelists`;
export const priceListSearchUrl = `${baseUrl}/pricelists/search`;
export const priceListAssignmentsUrl = `${baseUrl}/pricelists/assignments`;

//---ACCOUNTS---//
export const accountsUrl = `${baseUrl}/accounts`;
export const accountsSearchUrl = `${baseUrl}/accounts/search`;

//---USERS---//
export const usersUrl = `${baseUrl}/users`;
export const usersSearchUrl = `${baseUrl}/users/search`;

//---INVITATIONS---//
export const userInvitationsUrl = `${baseUrl}/invitations`;
export const userInvitationsSearchUrl = `${baseUrl}/invitations/search`;

//---LOOKUPS---//
export const currenciesUrl = `${baseUrl}/lookup/currencies`;
export const countriesUrl = `${baseUrl}/lookup/countries`;
export const accountGroupsUrl = `${baseUrl}/lookup/groups`;
export const rolesUrl = `${baseUrl}/lookup/roles`;

//---STATS---//
export const accountStatsUrl = `${baseUrl}/accounts/stats`;
export const licensesStatsUrl = `${baseUrl}/licenses/dashboard`;
export const ordersStatsUrl = `${baseUrl}/orders/dashboard`;
export const dealsStatsUrl = `${baseUrl}/deals/dashboard`;

//---CERTIFICATIONS---//
export const certificateUrl = `${baseUrl}/certificates`;
export const accountCertificationsUrl = `${baseUrl}/certificates/accounts/fetch`;
export const userCertificationsUrl = `${baseUrl}/certificates/users/fetch`;
export const certificateAccountUrl = `${baseUrl}/certificates/accounts`;
export const certificateUserUrl = `${baseUrl}/certificates/users`;

//---STOCKS---//
export const stocksUrl = `${baseUrl}/orders/stock`;
export const stocksSearchUrl = `${baseUrl}/orders/stock/search`;

//---FILES---//
export const filesUrl = `${baseUrl}/files`;
export const filesSearchUrl = `${baseUrl}/files/search`;

//list of URL that dont require auth
export const openUrls = [authUrl, refreshAuthUrl];

export const enum oauthProviderCodes {
  unknown = 0,
  google = 1,
  smarttech = 2,
  apple = 3,
  microsoft = 4,
}

export const notificationTimeout = 4000;

export const maxApiResults = 3000;

const totalFormatter = (total: number) => {
  return `${t("total")}: ${total} `;
};

export const defaultPagination = {
  total: 0,
  current: 1,
  pageSize: 20,
  "show-size-changer": true,
  showTotal: totalFormatter,
} as PaginationProps;

export const allItemsSearchOptions = {
  pagination: {
    total: 0,
    current: 1,
    pageSize: maxApiResults,
  },
};

export const approveDealMaxLevel = 30; //OPCO
export const createDealMinLevel = 40; //DISTRIBUTOR
export const createDealMaxLevel = 50; //RESELLER
export const canCreateDeal = (level: number): boolean => {
  return createDealMinLevel <= level && level <= createDealMaxLevel;
};

export const menuvoices = [
  {
    title: "sales",
    children: [
      {
        icon: "rm rm-ico-dashboard",
        label: "dashboard",
        path: "/dashboard",
      },
      {
        icon: "rm rm-ico-box-3d-50",
        label: "products",
        path: "/products",
      },
      {
        icon: "rm rm-ico-pricelist",
        label: "priceLists",
        path: "/pricelists",
        excludeTenant: ["SmartTech"],
        maxLevel: 40,
      },
      {
        icon: "rm rm-ico-partner-network",
        label: "salesNetwork",
        path: "/accounts",
        excludeTenant: ["SmartTech"],
        maxLevel: 40,
      },
      {
        icon: "rm rm-ico-handshake",
        label: "deals",
        path: "/deals",
        excludeTenant: ["SmartTech"],
      },
      {
        icon: "rm rm-ico-box-2",
        label: "sellIn",
        path: "/sell-in",
      },
      {
        icon: "rm rm-ico-storage-unit",
        label: "stocks",
        path: "/stocks",
      },
      {
        icon: "rm rm-ico-purchaseorder",
        label: "sellOut",
        path: "/sell-out",
        excludeTenant: ["SmartTech"],
        maxLevel: 40,
      },
      {
        icon: "rm rm-ico-license-key",
        label: "licenseKeys",
        path: "/licenses",
      },
    ] as NavigationItem[],
  },
  {
    title: "reports",
    children: [
      {
        icon: "rm rm-ico-statistics",
        label: "licenses",
        path: "/reports/licenses",
      },
    ] as NavigationItem[],
  },
  {
    title: "marketing",
    children: [
      {
        icon: "rm rm-ico-brandassets",
        label: "brandAssets",
        path: "https://bit.ly/3p8p33s",
        excludeTenant: ["IdeaHubRoom", "SmartTech", "TAEasyMeeting"],
        external: true,
      },
    ] as NavigationItem[],
  },
  {
    title: "support",
    children: [
      {
        icon: "rm rm-ico-knowledgebase",
        label: "knowledgebase",
        path: "https://kb.mago.io",
        external: true,
        excludeTenant: ["IdeaHubRoom", "SmartTech", "TAEasyMeeting"],
      },
      {
        icon: "rm rm-ico-helpdesk",
        label: "helpdesk",
        path: "https://support.mago.io",
        external: true,
        excludeTenant: ["SmartTech"],
      },
    ] as NavigationItem[],
  },
  {
    title: "devMenu",
    children: [
      {
        icon: "rm rm-ico-palette",
        label: "designSystem",
        path: "/ds",
        excludeTenant: ["IdeaHubRoom", "SmartTech", "TAEasyMeeting"],
        hideProduction: true,
      },
    ] as NavigationItem[],
  },
];

export const fixedEnvironmentTypes = [
  {
    environmentType: "1",
    name: "Mago Cloud",
  },
  {
    environmentType: "2",
    name: "OnPrem",
  },
] as EnvironmentType[];

// const accountGroups = [
//   {
//     groupId: "1fe0db84-8f58-4318-b2a0-441ff8e527d5",
//     name: "ODM",
//     level: 10,
//   },
//   {
//     groupId: "bd1926eb-f11b-4a09-b2dc-b82a2732d91a",
//     name: "OEM",
//     level: 20,
//   },
//   {
//     groupId: "370219f5-ef66-41e1-9b35-f727d17521a1",
//     name: "OPCO",
//     level: 30,
//   },
//   {
//     groupId: "7e8694ed-6a6c-4693-bbc5-4e82f58ccf12",
//     name: "DISTRIBUTOR",
//     level: 40,
//   },
//   {
//     groupId: "18cf753e-afc2-4485-b3db-7a50828d06a4",
//     name: "RESELLER GOLD",
//     level: 50,
//   },
//   {
//     groupId: "60cc0dc9-60a0-4da7-968f-2d61494a29f0",
//     name: "RESELLER SILVER",
//     level: 50,
//   },
//   {
//     groupId: "2f918cd3-3568-4a60-97af-7cb26610f413",
//     name: "BUYER",
//     level: 60,
//   },
// ];
