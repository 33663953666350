<template>
  <div v-if="env" id="licenseDetail">
    <div class="flex justify-between">
      <va-page-title>
        {{ t("onPremEnv") }} {{ env.accountName }}
      </va-page-title>

      <div>
        <va-button
          class="mr-2"
          :disabled="!onPremEnvStore.canUpdate"
          @click="openEditEnvDialog"
        >
          <i class="rm rm-ico-edit" />
        </va-button>

        <va-delete-button
          class="mr-2 float-right"
          :disabled="!onPremEnvStore.canDelete"
          :confirm-text="t('onPremEnvRemoveDescription')"
          :confirm-title="t('onPremEnvRemove')"
          @click:confirm="deleteEnv"
        />
      </div>
    </div>
    <va-back-link :target="`/accounts/${env.accountId}/environments`">
      {{ t("backToOnPremEnv") }}
    </va-back-link>
    <!--    <va-back-link target="/licenses">{{ t("backToLicenses") }}</va-back-link>-->
    <div class="va-card mt-6">
      <div class="px-4 py-5 sm:px-6">
        <va-info-row :title="t('customerName')">
          {{ env.customerName }}
        </va-info-row>
        <va-info-row :title="t('customerEnvironmentId')">
          {{ env.customerEnvironmentId }}
        </va-info-row>
        <va-info-row :title="t('accountName')">
          {{ env.accountName }}
        </va-info-row>
      </div>
    </div>

    <va-dialog v-model="editEnvDialog">
      <a-form
        v-if="editEnvDialog"
        :model="formState"
        :validate-messages="validateMessages"
        @finish="onEditEnv"
      >
        <a-form-item
          :name="['customerName']"
          :label="t('customerName')"
          :rules="[{ required: true }]"
        >
          <va-input
            v-model="formState.customerName"
            :placeholder="t('customerName')"
          />
        </a-form-item>

        <a-form-item
          :name="['customerEnvironmentId']"
          :label="t('customerEnvironmentId')"
          :rules="[{ required: true }]"
        >
          <va-input
            v-model="formState.customerEnvironmentId"
            :placeholder="t('customerEnvironmentId')"
          />
        </a-form-item>

        <a-form-item>
          <va-button html-type="submit" class="float-right">
            {{ t("save") }}
          </va-button>
          <va-button class="float-right mr-4" @click="editEnvDialog = false">
            {{ t("dismiss") }}
          </va-button>
        </a-form-item>
      </a-form>
    </va-dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import { t } from "@/plugins/i18n";
import VaInfoRow from "@/components/atoms/VaInfoRow.vue";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import { useOnPremEnvironmentStore } from "@/stores/onPremEnvironments";
import VaDialog from "@/components/molecules/VaDialog.vue";
import { cloneDeep } from "lodash";
import { OnPremEnvironment } from "@/models/onPremEnvironment.interface";
import VaButton from "@/components/molecules/VaButton.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import { useValidationMessages } from "@/composables/validation";
import VaDeleteButton from "@/components/molecules/VaDeleteButton.vue";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import { useMeta } from "vue-meta";

useMeta({
  title: "On Premise Environments",
});
const route = useRoute();
const onPremEnvStore = useOnPremEnvironmentStore();
const validateMessages = useValidationMessages();
const editEnvDialog = ref(false);
const formState = ref({} as OnPremEnvironment);

const env = computed(() => onPremEnvStore.currentEnvironment);

const openEditEnvDialog = () => {
  if (onPremEnvStore.currentEnvironment) {
    formState.value = cloneDeep(onPremEnvStore.currentEnvironment);
    editEnvDialog.value = true;
  }
};

const onEditEnv = async () => {
  await onPremEnvStore.saveEnv(formState.value);
  editEnvDialog.value = false;
};
const deleteEnv = async () => {
  if (env.value) {
    onPremEnvStore.removeEnv(env.value as OnPremEnvironment);
  }
};

onBeforeMount(() => {
  onPremEnvStore.getEnv(route.params.id as string);
});
</script>
