<template>
  <div class="va-card relative p-4">
    <div class="pb-4 flex justify-between">
      <h3 class="va-title-s">{{ t("accountName") }}</h3>
    </div>
    <a-form
      v-if="formState"
      :model="formState"
      :validate-messages="validateMessages"
      @finish="onUpdateAccount"
    >
      <div class="grid grid-cols-2 gap-4">
        <a-form-item
          :name="['name']"
          :label="t('name')"
          :rules="[{ required: true }]"
          :disabled="awaitingElaboration"
        >
          <va-input v-model="formState.name" :placeholder="t('accountName')" />
        </a-form-item>
        <a-form-item
          :name="['email']"
          :label="t('notificationReceiverEmail')"
          :rules="[{ type: 'email' }]"
          :disabled="awaitingElaboration"
        >
          <va-input v-model="formState.email" :placeholder="t('email')" />
        </a-form-item>
      </div>
      <a-form-item>
        <va-button
          html-type="submit"
          class="float-right"
          :loading="awaitingElaboration"
        >
          {{ t("save") }}
        </va-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref, watch } from "vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import { t } from "@/plugins/i18n";
import { useValidationMessages } from "@/composables/validation";
import { Account } from "@/models/account.interface";
import { cloneDeep } from "lodash";
import { useAccountsStore } from "@/stores/accounts";

const accountStore = useAccountsStore();
const validateMessages = useValidationMessages();

const props = defineProps({
  account: {
    type: Object as PropType<Account>,
    required: true,
  },
});

const isElaborating = ref(false);

const awaitingElaboration = computed(() => isElaborating.value);

const formState = ref(cloneDeep(props.account));

const onUpdateAccount = async () => {
  isElaborating.value = true;
  await accountStore.updateCurrentAccount(formState.value);
  isElaborating.value = false;
};

watch(
  () => props.account,
  (newValue) => {
    formState.value = cloneDeep(newValue);
  }
);
</script>
