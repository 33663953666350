<template>
  <div class="va-card relative p-4">
    <div class="pb-4 flex justify-between">
      <h3 class="va-title-s">{{ t("companyAddress") }}</h3>
    </div>
    <a-form
      :model="formState"
      :validate-messages="validateMessages"
      @finish="onFinish"
    >
      <div class="grid grid-cols-2 gap-4">
        <a-form-item
          :name="['legalName']"
          :label="t('legalName')"
          :rules="[{ required: true }]"
          :disabled="accountStore.isLoading"
        >
          <va-input
            v-model="formState.legalName"
            :placeholder="t('legalName')"
          />
        </a-form-item>
        <a-form-item
          :name="['vatId']"
          :label="t('vatId')"
          :rules="[{ required: true }]"
          :disabled="accountStore.isLoading"
        >
          <va-input v-model="formState.vatId" :placeholder="t('vatId')" />
        </a-form-item>
        <a-form-item
          :name="['street']"
          :label="t('street')"
          :rules="[{ required: true }]"
          :disabled="accountStore.isLoading"
        >
          <va-input v-model="formState.street" :placeholder="t('street')" />
        </a-form-item>
        <a-form-item
          :name="['city']"
          :label="t('city')"
          :rules="[{ required: true }]"
          :disabled="accountStore.isLoading"
        >
          <va-input v-model="formState.city" :placeholder="t('city')" />
        </a-form-item>
        <a-form-item
          :name="['countryCode']"
          :label="t('country')"
          :rules="[{ required: true }]"
        >
          <va-select
            v-model="formState.countryCode"
            :options="lookupStore.countriesOptions"
            :placeholder="t('country')"
            :disabled="lookupStore.countriesLoading"
            @change="selectCountry"
          />
        </a-form-item>
        <a-form-item
          :name="['postalCode']"
          :label="t('postalCode')"
          :rules="[{ required: true }]"
          :disabled="accountStore.isLoading"
        >
          <va-input
            v-model="formState.postalCode"
            :placeholder="t('postalCode')"
          />
        </a-form-item>
        <a-form-item
          :name="['locality']"
          :label="t('locality')"
          :disabled="accountStore.isLoading"
        >
          <va-input v-model="formState.locality" :placeholder="t('locality')" />
        </a-form-item>
        <a-form-item
          :name="['region']"
          :label="t('region')"
          :disabled="accountStore.isLoading"
        >
          <va-input v-model="formState.region" :placeholder="t('region')" />
        </a-form-item>
      </div>
      <a-form-item>
        <va-button
          html-type="submit"
          class="float-right"
          :loading="accountStore.isLoading"
        >
          {{ t("save") }}
        </va-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, PropType, ref, watch } from "vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import { t } from "@/plugins/i18n";
import VaSelect from "@/components/molecules/VaSelect.vue";
import { useValidationMessages } from "@/composables/validation";
import { Address } from "@/models/address.interface";
import { Account } from "@/models/account.interface";
import { cloneDeep } from "lodash";
import { useLookupStore } from "@/stores/lookup";
import { useAccountsStore } from "@/stores/accounts";
import { SelectOption } from "@/models/selectOptions.interface";
import { CountryData } from "@/models/countryData.interface";

const accountStore = useAccountsStore();
const lookupStore = useLookupStore();
const validateMessages = useValidationMessages();

const props = defineProps({
  account: {
    type: Object as PropType<Account>,
    required: true,
  },
});

const formState = ref(cloneDeep(props.account?.address) as Address);

watch(
  () => props.account,
  (newValue) => {
    formState.value = cloneDeep(newValue.address) as Address;
  }
);

const selectCountry = (item: SelectOption<CountryData>) => {
  formState.value.country = item.label;
};

const onFinish = () => {
  accountStore.updateCurrentAccountAddress(formState.value);
};

onBeforeMount(() => {
  lookupStore.fetchCountries();
});
</script>
