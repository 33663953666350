<template>
  <va-table
    :data-source="licensesStore.licenses"
    :table-config="shortTableConfig"
    :search-options="licensesStore.searchOptions"
    :loading="licensesStore.isLoading"
    class="w-100"
    @update="licensesStore.updateSearchOptions"
  >
    <template #bodyCell="{ text, column, record }">
      <template v-if="column.dataIndex === 'serialKey'">
        <router-link
          :to="`/licenses/${record.serialKey}`"
          class="text-indigo-600"
        >
          {{ text }}
        </router-link>
      </template>
      <template v-if="column.dataIndex === 'licenseHasBeenActivated'">
        <div class="text-center">
          <va-check-icon :check="text" />
          <div class="text-xs text-gray-500 w-20">
            {{ formatDate(record.activationDate) }}
          </div>
        </div>
        <!--        {{ record.activationDate }}-->
      </template>
      <template v-if="column.dataIndex === 'product'">
        {{ record.product.name }}
        <div class="text-xs text-gray-500">{{ record.product.sku }}</div>
      </template>
      <template v-if="column.dataIndex === 'ownerAccount'">
        {{ record.ownerAccount.name }}
      </template>
      <template v-if="column.dataIndex === 'seatsOccupied'">
        <div class="text-center">
          {{ record.seatsOccupied }}/{{ record.seats }}
        </div>
      </template>
      <template v-if="column.dataIndex === 'creationDate'">
        {{ formatDate(record.creationDate) }}
      </template>
      <template v-if="column.dataIndex === 'expirationDate'">
        <div class="text-center">
          <div v-if="record.licenseIsPerpetual">
            <a-tag color="success">
              {{ t("perpetual") }}
            </a-tag>
            <div class="text-xs text-gray-500 mt-2">
              {{ t("noDate") }}
            </div>
          </div>
          <div v-else>
            <a-tag
              v-if="
                !isExpired(record.expirationDate) ||
                record.product.islicenseUpgradeRight
              "
              color="success"
            >
              {{ t("notExpired") }}
            </a-tag>
            <a-tag v-else color="error">{{ t("expired") }}</a-tag>
            <div
              v-if="
                record.expirationDate && !record.product.islicenseUpgradeRight
              "
              class="text-xs text-gray-500 mt-2"
            >
              {{ formatDate(record.expirationDate) }}
            </div>
            <div v-else class="text-xs text-gray-500 mt-2">
              {{ t("noDate") }}
            </div>
          </div>
        </div>
      </template>
      <template v-if="column.dataIndex === 'maintenanceExpirationDate'">
        <div class="text-center">
          <div v-if="record.maintenanceIsPerpetual">
            <a-tag color="success">
              {{ t("perpetual") }}
            </a-tag>
            <div class="text-xs text-gray-500 mt-2">
              {{ t("noDate") }}
            </div>
          </div>
          <div v-else>
            <a-tag
              v-if="
                !isExpired(record.maintenanceExpirationDate) ||
                record.product.islicenseUpgradeRight
              "
              color="success"
            >
              {{ t("notExpired") }}
            </a-tag>
            <a-tag v-else color="error">{{ t("expired") }}</a-tag>
            <div
              v-if="
                record.maintenanceExpirationDate &&
                !record.product.islicenseUpgradeRight
              "
              class="text-xs text-gray-500 mt-2"
            >
              {{ formatDate(record.maintenanceExpirationDate) }}
            </div>
            <div v-else class="text-xs text-gray-500 mt-2">
              {{ t("noDate") }}
            </div>
          </div>
        </div>
      </template>
      <template v-if="column.dataIndex === 'type'">
        <div class="text-center">
          {{
            record.product.isLicenseTrial ? t("trialLicense") : t("fullLicense")
          }}
        </div>
      </template>
    </template>
  </va-table>
</template>

<script setup lang="ts">
import { onBeforeMount } from "vue";
import { useFormatDate } from "@/composables/formatters";
import { tableConfig } from "@/models/license.interface";
import VaTable from "@/components/organisms/VaTable.vue";
import { t } from "@/plugins/i18n";
import { cloneDeep } from "lodash";
import { onBeforeRouteUpdate, useRoute } from "vue-router";
import VaCheckIcon from "@/components/atoms/VaCheckIcon.vue";
import { useLicensesStoreAccount } from "@/stores/licenses";

const licensesStore = useLicensesStoreAccount();

const formatDate = useFormatDate;
const route = useRoute();

onBeforeRouteUpdate(async (to, from) => {
  if (to.params.id !== from.params.id) {
    licensesStore.setParentAccountId(to.params.id as string);
  }
});

const shortTableConfig = cloneDeep(tableConfig);
delete shortTableConfig.ownerAccount;
delete shortTableConfig.creationDate;
delete shortTableConfig.licenseHasBeenActivated;

onBeforeMount(() => {
  licensesStore.setParentAccountId(route.params.id as string);
});

function isExpired(date: Date) {
  return date?.getTime() <= Date.now();
}
</script>
