export default {
  getTenant(): string {
    return process.env.VITE_TENANT || "Mago";
  },
  getTenantCode(): string {
    return process.env.VITE_TENANT_CODE || "REM-MAG-PART";
  },
  getLogoAsset(asset: string): string {
    return new URL(
      `../assets/img/logos/${this.getTenant()}/${asset}`,
      import.meta.url
    ).href;
  },
  // requireBackground(asset: string): string {
  //   const t = this.getTenant();
  //   return require(`@/assets/img/backgrounds/${t}/${asset}`);
  // },
};
