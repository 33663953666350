export interface AuthData {
  expires: Date;
  refreshExpires: Date;
  token: string;
  refreshToken: string;
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): AuthData {
    return {
      expires: new Date(input.expirationUtc),
      refreshExpires: new Date(input.refreshTokenExpirationUtc),
      token: input.token || "",
      refreshToken: input.refreshToken || "",
    };
  },
  serialize(input: AuthData): unknown {
    return {
      expirationUtc: input.expires.getTime(),
      refreshTokenExpirationUtc: input.refreshExpires.getTime(),
      token: input.token,
      refreshToken: input.refreshToken,
    };
  },
};
