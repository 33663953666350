// import getSymbolFromCurrency from "currency-symbol-map";

export interface CurrencyData {
  id: string;
  name: string;
  code: string;
  symbol: string;
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): CurrencyData {
    return {
      id: input.currencyId || "",
      name: input.currencyName || "",
      code: input.currencyName || "",
      symbol: input.currencySymbol,
      // symbol: getSymbolFromCurrency(input.currencySymbol) || "",
    };
  },
  serialize(input: CurrencyData): unknown {
    return {
      currencyId: input.id,
      currencyName: input.name,
      currencySymbol: input.symbol,
    };
  },
};
