<template>
  <va-page-title class="mt-8">{{ t("newAccountCertification") }}</va-page-title>
  <va-back-link :target="`/accounts/${route.params.id}/certifications`">
    {{ t("backToCertifications") }}
  </va-back-link>
  <div class="va-card mt-6 relative pt-4 pb-0.5 px-4">
    <a-form
      :model="formState"
      :validate-messages="validateMessages"
      @finish="onAccountCertificateCreate"
    >
      <div class="gap-x-4 gap-y-8 sm:grid-cols-2">
        <a-form-item
          :name="['certificateId']"
          :label="t('certificates')"
          :rules="[{ required: true }]"
        >
          <va-select
            :model-value="formState.certificateId"
            :options="certificatesStore.availableAccountCertificationsOptions"
            @change="formState.certificateId = $event.value"
          ></va-select>
        </a-form-item>
        <a-form-item :name="['notes']" :label="t('notes')">
          <va-input
            v-model="formState.notes"
            type="textarea"
            :placeholder="t('notes')"
          />
        </a-form-item>
        <a-form-item id="add-account-certificate-button">
          <va-button
            html-type="submit"
            class="float-left"
            :loading="certificatesStore.isLoading"
          >
            {{ t("save") }}
          </va-button>
        </a-form-item>
      </div>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import { t } from "@/plugins/i18n";
import VaSelect from "@/components/molecules/VaSelect.vue";
import { useValidationMessages } from "@/composables/validation";
import { useRoute, useRouter } from "vue-router";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import { AccountCertificationFormData } from "@/models/certification.interface";
import { useCertificatesStore } from "@/stores/certificates";
import VaInput from "@/components/molecules/VaInput.vue";

const certificatesStore = useCertificatesStore();
const route = useRoute();
const router = useRouter();

const validateMessages = useValidationMessages();
const formState = ref({
  accountId: route.params.id,
  certificateId: "",
  notes: "",
} as AccountCertificationFormData);

const onAccountCertificateCreate = async () => {
  await certificatesStore.createAccountCertification(
    formState.value.accountId,
    formState.value.certificateId,
    formState.value.notes
  );
  router.push(`/accounts/${route.params.id}/certifications`);
};

onBeforeMount(async () => {
  certificatesStore.getAvailableAccountCertifications();
});
</script>
