import logger from "@/services/loggerService";
import {
  protectionStatus,
  dealStatus,
  deserializePossibleProtectionStatus,
  deserializeDealStatus,
} from "@/models/deal.interface";

import { eBidStatus, deserializeEBidStatus } from "@/models/dealEbid.interface";
import { CurrencyData } from "@/models/currencyData.interface";

export const possibleDealHistorySources = [
  "Unknown",
  "Creation",
  "UpdatedByCreator",
  "UpdatedByApprover",
  "System",
];

export type dealHistorySources = typeof possibleDealHistorySources[number];

export interface DealHistory {
  id: string;
  source: dealHistorySources;
  dealId: string;
  updateDate: Date;
  closureChance: number;
  description: string;
  estimatedCloseDate?: Date;
  protectionStatus: protectionStatus;
  status: dealStatus;
  eBidValue?: number;
  eBidStatus: eBidStatus;
  eBidApprovedValue?: number;
  currency?: CurrencyData;
}

export default {
  deserialize(
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
    input: any,
    currency?: CurrencyData //TODO see #968 tecnichal debt on currency, api don't include currency
  ): DealHistory {
    return {
      id: input.dealHistoryId,
      updateDate: new Date(input.creationDate),
      source: deserializeDealHistorySources(input.source),
      dealId: input.dealId,
      closureChance: input.dealChanceOFClosure || 0,
      description: input.dealDescription || "",
      estimatedCloseDate: input.dealEstimatedCloseDate
        ? new Date(input.dealEstimatedCloseDate)
        : undefined,
      protectionStatus: deserializePossibleProtectionStatus(
        input.dealProtectionStatus
      ),
      status: deserializeDealStatus(input.dealStatus),
      eBidStatus: deserializeEBidStatus(input.eBidStatus),
      eBidValue: input.eBidValue,
      eBidApprovedValue: input.eBidApprovedValue,
      currency: currency,
    };
  },
  serialize(input: DealHistory): unknown {
    return {
      dealHistoryId: input.id,
      creationDate: input.updateDate.getTime(),
      source: input.source,
      dealId: input.dealId,
      dealChanceOFClosure: input.closureChance,
      dealDescription: input.description,
      dealEstimatedCloseDate: input.estimatedCloseDate
        ? input.estimatedCloseDate.getTime()
        : undefined,
      dealProtectionStatus: input.protectionStatus,
      dealStatus: input.status,
      eBidValue: input.eBidValue,
      eBidStatus: input.eBidStatus,
      eBidApprovedValue: input.eBidApprovedValue,
    };
  },
};

const deserializeDealHistorySources = (input?: string): dealHistorySources => {
  if (input && possibleDealHistorySources.includes(input)) {
    return input;
  } else {
    logger.error(
      `Deal deserializer: unhandled deaHistoryStatus value "${input}"`
    );
    return "creation";
  }
};
