<template>
  <va-tabs
    :tabs="[
      {
        path: `/profile`,
        label: t('profile'),
      },
      {
        path: `/profile/account`,
        label: t('company'),
      },
    ]"
  />
  <router-view />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import VaTabs from "@/components/organisms/VaTabs.vue";
import { useMeta } from "vue-meta";

useMeta({
  title: "Profile",
});
const { t } = useI18n();
</script>
