<template>
  <!-- MOBILE -->
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog
      as="div"
      class="relative z-40 md:hidden"
      @close="sidebarOpen = false"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>

      <div class="fixed inset-0 flex z-40">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800"
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <svg
                    class="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 flex items-center px-4">
              <img class="h-8 w-auto" :src="logoUrl" alt="Workflow" />
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <div v-for="(group, index) in voices" :key="index" class="">
                  <div
                    v-for="(voice, vindex) in group.children"
                    :key="`${index}-${vindex}`"
                    class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md"
                  >
                    <!--                    class="bg-gray-900 text-white group flex items-center px-2 py-2 text-base font-medium rounded-md"-->
                    <router-link
                      v-if="!voice.external"
                      :to="voice.path"
                      @click="sidebarOpen = false"
                    >
                      <i :class="voice.icon + ' mr-3'" />
                      <span>{{ t(voice.label) }}</span>
                    </router-link>
                    <a v-else :href="voice.path">
                      <i :class="voice.icon + ' mr-3'" />
                      <span>{{ t(voice.label) }}</span>
                    </a>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <!-- MOBILE -->

  <!-- Static sidebar for desktop -->
  <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="flex-1 flex flex-col min-h-0 bg-gray-800">
      <div class="flex items-center h-16 flex-shrink-0 px-4 bg-gray-800">
        <img class="h-8 w-auto" :src="logoUrl" alt="Workflow" />
      </div>
      <div class="flex-1 flex flex-col overflow-y-auto">
        <nav class="flex-1 px-2 py-1">
          <VaNavigationMenu :voices="voices" />
        </nav>
      </div>
    </div>
  </div>
  <div class="md:pl-64 flex flex-col">
    <div class="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
      <button
        type="button"
        class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
        @click="sidebarOpen = true"
      >
        <span class="sr-only">Open sidebar</span>
        <i class="rm rm-ico-menu-4" />
      </button>
      <div class="flex flex-1 px-4">
        <div class="ml-4 flex flex-1 items-center justify-end md:ml-6">
          <button
            type="button"
            class="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="authStore.logout"
          >
            {{ t("logout") }}<i class="rm rm-ico-log-out ml-2" />
          </button>
          <!-- Profile dropdown -->
          <div class="ml-3 relative">
            <div>
              <router-link to="/profile">
                <button
                  id="user-menu-button"
                  type="button"
                  class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  aria-expanded="false"
                  aria-haspopup="true"
                >
                  <span class="sr-only">Open user menu</span>
                  <!-- TODO: use variable-->
                  <va-avatar
                    v-if="user"
                    :size="30"
                    color="rgb(79, 70, 229)"
                    :text="`${user?.firstName} ${user?.lastName}`"
                  />
                  <!--                  <img-->
                  <!--                    class="h-8 w-8 rounded-full"-->
                  <!--                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"-->
                  <!--                    alt=""-->
                  <!--                  />-->
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex flex-col flex-1 md:fixed md:inset-y-0"
      style="left: 16rem; right: 0; top: 4rem"
    >
      <main class="flex min-h-0">
        <div class="flex flex-col p-6 w-full overflow-y-auto">
          <router-view />
        </div>
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import VaNavigationMenu from "@/components/organisms/VaNavigationMenu.vue";
import { useI18n } from "vue-i18n";
import { computed, ref } from "vue";
import { Dialog, TransitionChild, TransitionRoot } from "@headlessui/vue";
import VaAvatar from "@/components/atoms/VaAvatar.vue";
import tenantService from "@/services/tenantService";
import { useAppStore } from "@/stores/app";
import { useAuthStore } from "@/stores/auth";

const { t } = useI18n();
const appStore = useAppStore();
const authStore = useAuthStore();
const sidebarOpen = ref(false);
const logoUrl = tenantService.getLogoAsset("logo-horizontal.svg");

const user = computed(() => authStore.user);

const voices = computed(() => appStore.menuVoices);
</script>
