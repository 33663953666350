// import { AccountGroup } from "@/models/accountGroup.interface";
// import { useLookupStore } from "@/stores/lookup";

import { CurrencyData } from "@/models/currencyData.interface";
import { useLookupStore } from "@/stores/lookup";

export type dateRange = {
  from: Date | undefined;
  to: Date | undefined;
};

export type samplingFrequency = "d" | "w" | "m";

export interface StatsQuery {
  from: Date | undefined;
  to: Date | undefined;
  showPrevious: boolean;
  accountId: string;
  includeChildren: boolean;
  products: string[];
}

export interface statsRequestData {
  parentAccountId: string;
  includeChildren?: boolean;
  previous: dateRange | undefined;
  current: dateRange;
  products: string[];
}

// export interface AccountSample {
//   date: Date;
//   values: {
//     groupId: string;
//     quantity: number;
//     group: AccountGroup;
//   }[];
// }

export interface Sample {
  date: Date;
  values: {
    quantity: number;
  }[];
}

// export interface AccountsStats {
//   previous: AccountSample[];
//   current: AccountSample[];
//   currentSamplingFrequency: samplingFrequency;
//   previousSamplingFrequency: samplingFrequency;
//   previousTotal: number;
//   currentTotal: number;
//   trend: number;
//   sameSamplingRate: boolean;
// }

export interface QuantityStats {
  previous: Sample[];
  current: Sample[];
  currentSamplingFrequency: samplingFrequency;
  previousSamplingFrequency: samplingFrequency;
  previousTotal: number;
  currentTotal: number;
  trend: number;
  trendType: boolean;
  sameSamplingRate: boolean;
}

export interface TrendStat {
  current: number;
  previous: number;
  trend: number;
}

export interface PieStats {
  name: string;
  amount: number;
}

export interface LicenseStats {
  totalCreated: TrendStat;
  totalActive: TrendStat;
  totalExpired: TrendStat;
  totalExpiredMaintenance: TrendStat;
  totalNearMaintenanceExpiration: TrendStat;
  totalSeatActive: TrendStat;
  statisticsResponse: QuantityStats;
}

export interface DealStats {
  totalDealsRegistration: TrendStat;
  statisticsResponse: QuantityStats;
}

export interface OrderStats {
  totalSellIn: TrendStat;
  totalSellOut: TrendStat;
  totalSellInPrice: TrendStat;
  totalSellOutPrice: TrendStat;
  statisticsResponseIn: QuantityStats;
  statisticsResponse: QuantityStats;
  sellOutPieStatistics: PieStats[];
  sellOutSkuPieStatistics: PieStats[];
  currency: CurrencyData;
}

const sampleSorter = (a: Sample, b: Sample) => {
  return a.date.getTime() - b.date.getTime();
};

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserializeSample(input: any): Sample {
    return {
      date: new Date(input.key),
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
      values: input.value?.map((val: any) => {
        return {
          quantity: val.qt !== undefined ? val.qt : val.amount,
        };
      }),
    };
  },

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserializeQuantityStats(input: any): QuantityStats {
    const trend = Math.round(input.trend) || 0;
    return {
      previous: input?.previous?.map(this.deserializeSample).sort(sampleSorter),
      current: input?.current?.map(this.deserializeSample).sort(sampleSorter),
      currentSamplingFrequency: input?.currentSamplingFrequency || "d",
      previousSamplingFrequency: input?.previousSamplingFrequency || "d",
      previousTotal: input.previousTotal || 0,
      currentTotal: input.currentTotal || 0,
      trend: trend,
      sameSamplingRate: input.sameSamplingRate || false,
      trendType: !!trend && trend > 0,
    };
  },

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  deserializeTrendStat(input: any): TrendStat {
    return {
      current: input?.current || 0,
      previous: input?.previous || 0,
      trend: input?.trend || 0,
    };
  },

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  deserializeLicenseStats(input: any): LicenseStats {
    return {
      totalCreated: this.deserializeTrendStat(input?.totalCreated),
      totalActive: this.deserializeTrendStat(input?.totalActive),
      totalExpired: this.deserializeTrendStat(input?.totalExpired),
      totalExpiredMaintenance: this.deserializeTrendStat(
        input?.totalExpiredMaintenance
      ),
      totalNearMaintenanceExpiration: this.deserializeTrendStat(
        input?.totalNearMaintenanceExpiration
      ),
      totalSeatActive: this.deserializeTrendStat(input?.totalSeatActive),
      statisticsResponse: this.deserializeQuantityStats(
        input?.statisticsResponse
      ),
    };
  },

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  deserializeDealStats(input: any): DealStats {
    return {
      totalDealsRegistration: this.deserializeTrendStat(
        input?.totalDealsRegistration
      ),
      statisticsResponse: this.deserializeQuantityStats(
        input?.statisticsResponse
      ),
    };
  },

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  deserializeOrderStats(input: any): OrderStats {
    const lookupStore = useLookupStore();
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    const piestats = input?.sellOutPieStatistics.map((s: any) => {
      return {
        name: s.name,
        amount: Number(s.amount),
      };
    });
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    const skuPiestats = input?.sellOutBySkuPieStatistics.map((s: any) => {
      return {
        name: s.name,
        amount: Number(s.amount),
      };
    });

    return {
      totalSellIn: this.deserializeTrendStat(input?.totalSellIn),
      totalSellOut: this.deserializeTrendStat(input?.totalSellOut),
      totalSellInPrice: this.deserializeTrendStat(input?.totalSellInPrice),
      totalSellOutPrice: this.deserializeTrendStat(input?.totalSellOutPrice),
      statisticsResponse: this.deserializeQuantityStats(
        input?.statisticsResponseSellOut
      ),
      statisticsResponseIn: this.deserializeQuantityStats(
        input?.statisticsResponseSellIn
      ),
      sellOutPieStatistics: piestats,
      sellOutSkuPieStatistics: skuPiestats,
      currency: lookupStore.decodedCurrency(input.totalSellOutPriceCurrencyId),
    };
  },

  serializeStatsRequestData(input: statsRequestData): unknown {
    return {
      parentAccountId: input.parentAccountId,
      includeChildren: input.includeChildren || false,
      previousFrom: input.previous?.from
        ? input.previous.from.getTime()
        : undefined,
      previousTo: input.previous?.to ? input.previous.to.getTime() : undefined,
      currentFrom: input.current.from
        ? input.current.from.getTime()
        : undefined,
      currentTo: input.current.to ? input.current.to.getTime() : undefined,
      products: input.products, //required for licenses stats, ask BE for more info
    };
  },
};
