<template>
  <div class="flex justify-between">
    <va-page-title>{{ t("priceLists") }}</va-page-title>
    <va-button
      :disabled="!priceListsStore.canCreate"
      @click="router.push('/pricelists/create')"
    >
      <i class="rm rm-ico-e-add" />
    </va-button>
  </div>
  <va-table
    :data-source="priceListsStore.items"
    :table-config="tableConfig"
    :search-options="priceListsStore.searchOptions"
    :loading="priceListsStore.isLoading"
    class="w-100"
    @update="priceListsStore.updateSearchOptions"
  >
    <template #bodyCell="{ column, text, record }">
      <template v-if="column.dataIndex === 'name'">
        <router-link :to="`/pricelists/${record.id}`" class="text-indigo-600">
          {{ text }}
        </router-link>
      </template>
      <template v-if="column.dataIndex === 'status'">
        {{ t(text) }}
      </template>
      <template v-if="column.dataIndex === 'currency'">
        {{ text.name }}
      </template>
      <template v-if="column.dataIndex === 'creationDate'">
        {{ formatDate(text) }}
      </template>
    </template>
  </va-table>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import VaTable from "@/components/organisms/VaTable.vue";
import { tableConfig } from "@/models/priceList.interface";
import { onBeforeMount } from "vue";
import { useFormatDate } from "@/composables/formatters";
import router from "@/router";
import VaButton from "@/components/molecules/VaButton.vue";
import { usePricelistsStore } from "@/stores/pricelists";
import { useMeta } from "vue-meta";

useMeta({
  title: "Pricelists",
});
const formatDate = useFormatDate;
const { t } = useI18n();
const priceListsStore = usePricelistsStore();

onBeforeMount(() => {
  priceListsStore.setPreSort(
    {
      columnKey: "name",
      order: "ascend",
    },
    true
  );
});
</script>
