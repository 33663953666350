import { createI18n } from "vue-i18n";

import en from "@/locales/en";
import it from "@/locales/it";

const messages = {
  en,
  it,
};

const getLocale = (): string => {
  const loc = navigator.language || "en-EN";
  return loc.split("-")[0];
};

const i18n = createI18n({
  legacy: false,
  locale: getLocale(),
  fallbackLocale: "en",
  messages,
  silentFallbackWarn: false,
});

export default i18n;

export const t = i18n.global.t;
