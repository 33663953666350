import { TableConfig } from "@/models/listable.interface";
import PriceListProductModel, {
  PriceListProduct,
} from "@/models/priceListProduct.interface";
import { CurrencyData } from "@/models/currencyData.interface";
import { useLookupStore } from "@/stores/lookup";

export interface PriceList {
  id: string;
  accountId: string;
  name: string;
  products: PriceListProduct[];
  status: "Active" | "Pending" | "Deleted";
  creationDate: Date;
  createdBy: string;
  currency: CurrencyData;
}

export interface PriceListFormData {
  name: string;
  currencyId: string;
  items: PriceListProduct[];
}

export const tableConfig = {
  id: {
    dtoName: "priceListId",
    sortable: false,
    filterable: true,
    label: "id",
    hideColumn: true,
  },
  accountId: {
    dtoName: "accountId",
    sortable: false,
    filterable: true,
    label: "code",
    hideColumn: true,
  },
  name: {
    dtoName: "name",
    sortable: true,
    filterable: true,
    label: "name",
  },
  status: {
    dtoName: "status",
    sortable: true,
    filterable: true,
    label: "status",
  },
  currency: {
    dtoName: "currencyId",
    sortable: false,
    filterable: false,
    label: "currency",
  },
  creationDate: {
    dtoName: "creationDate",
    sortable: true,
    filterable: false,
    label: "creationDate",
  },
  // actions: {
  //   dtoName: "",
  //   sortable: false,
  //   filterable: false,
  //   label: t("actions"),
  //   hideColumn: false,
  // },
} as TableConfig;

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): PriceList {
    const products = (
      input.products?.length
        ? input.products.map(PriceListProductModel.deserialize)
        : []
    ).sort((a: PriceListProduct, b: PriceListProduct) => {
      return a.name > b.name ? 1 : -1;
    });
    const lookupStore = useLookupStore();
    return {
      id: input.priceListId || "",
      accountId: input.accountId || "",
      name: input.name || "",
      // products: input.products.map(PriceListProductModel.deserialize),
      products: products,
      status: input.status || "",
      creationDate: new Date(input.creationDate),
      createdBy: input.createdBy || "",
      currency: lookupStore.decodedCurrency(input.currencyId),
    };
  },

  serialize(input: PriceList): unknown {
    return {
      priceListId: input.id,
      accountId: input.accountId,
      name: input.name,
      products: input.products.map(PriceListProductModel.serialize),
      status: input.status,
      creationDate: input.creationDate.getTime(),
      createdBy: input.createdBy,
    };
  },
};
