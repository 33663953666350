import { defineStore } from "pinia";
import logger from "@/services/loggerService";
import { t } from "@/plugins/i18n";
import * as listable from "./listableMixin";
import * as permissions from "./permissionMixin";
import router from "@/router";
import { cloneDeep } from "lodash";
import { defaultSearchOptions } from "./listableMixin";
import { useNotificationsStore } from "@/stores/notifications";
import { Deal, DealFormData } from "@/models/deal.interface";
import { DealHistory } from "@/models/dealHistory.interface";
import { eBidStatus } from "@/models/dealEbid.interface";
import {
  search,
  get,
  post,
  updateProtectionStatus,
  updateEbidValue,
  updateEbidApprovedValue,
  updateDealInfo,
  getHistory,
} from "@/services/dealsService";
import {
  approveDealMaxLevel,
  createDealMaxLevel,
} from "@/services/configService";
import { useAuthStore } from "@/stores/auth";

export interface DealsState
  extends listable.ListableMixinState,
    permissions.PermissionsMixinState {
  items: Deal[];
  currentDeal?: Deal;
  currentDealHistory: DealHistory[];
  isHistoryLoading: boolean;
}

export const useDealsStore = defineStore({
  id: "deals",
  state: (): DealsState => ({
    isLoading: false,
    searchOptions: cloneDeep(defaultSearchOptions),
    moduleName: "Deals",
    items: [],
    currentDeal: undefined,
    currentDealHistory: [],
    isHistoryLoading: false,
    searchError: t("licensesFetchError"),
    searchFnc: search,
  }),
  getters: {
    ...permissions.getters,
    canManage: (): boolean => {
      //extend deals with canManage permission
      const authStore = useAuthStore();
      const lev = authStore.userAccount?.group.level || Number.MAX_VALUE;
      return lev <= approveDealMaxLevel;
    },
    canUpdate: (state): boolean => {
      //need to override the canupdate permission to comepensate backend
      const authStore = useAuthStore();
      return authStore.userAccount?.id === state.currentDeal?.vendor?.id;
    },
    canCreate: (): boolean => {
      //need to override the canCreate permission to compensate backend
      const authStore = useAuthStore();
      const lev = authStore.userAccount?.group.level || Number.MAX_VALUE;
      return lev <= createDealMaxLevel;
    },
  },
  actions: {
    ...listable.actions,

    async get(id: string) {
      if (!id || this.currentDeal?.id === id) return;
      const notifications = useNotificationsStore();
      this.isLoading = true;
      try {
        this.currentDeal = await get(id);
      } catch (err) {
        logger.error(err);
        notifications.error(t("dealsFetchError"));
      } finally {
        this.isLoading = false;
      }
    },

    async getHistory() {
      if (this.currentDeal) {
        const notifications = useNotificationsStore();
        this.isHistoryLoading = true;
        try {
          this.currentDealHistory = await getHistory(this.currentDeal);
        } catch (err) {
          logger.error(err);
          notifications.error(t("dealsFetchError"));
        } finally {
          this.isHistoryLoading = false;
        }
      }
    },

    async updateProtectionStatus() {
      const deal = cloneDeep(this.currentDeal);
      if (deal) {
        this.isLoading = true;
        const notifications = useNotificationsStore();
        try {
          await updateProtectionStatus(deal);
          this.currentDeal = deal;
          notifications.success(t("dealUpdateSuccess"));
        } catch (err) {
          logger.error(err);
          notifications.error(t("dealUpdateError"));
        } finally {
          this.isLoading = false;
        }
      }
    },

    async create(data: DealFormData) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        const res = await post(data);
        router.push(`/deals/${res}`);
      } catch (err) {
        logger.error(err);
        notifications.error(t("dealCreateError"));
      } finally {
        this.isLoading = false;
      }
    },

    async updateDealInfo(deal: Deal) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await updateDealInfo(deal);
        this.currentDeal = deal;
        notifications.success(t("dealUpdateSuccess"));
      } catch (err) {
        logger.error(err);
        notifications.error(t("dealUpdateError"));
      } finally {
        this.isLoading = false;
      }
    },

    async updateEbidValue(value: number) {
      const deal = cloneDeep(this.currentDeal);
      if (deal) {
        const notifications = useNotificationsStore();
        deal.eBidValue = value;
        this.isLoading = true;
        try {
          await updateEbidValue(deal);
          this.currentDeal = deal;
          notifications.success(t("dealUpdateSuccess"));
        } catch (err) {
          logger.error(err);
          notifications.error(t("dealUpdateError"));
        } finally {
          this.isLoading = false;
        }
      }
    },

    async updateEbidApprovedValue(status: eBidStatus) {
      const deal = cloneDeep(this.currentDeal);
      if (deal) {
        const notifications = useNotificationsStore();
        deal.eBidStatus = status;
        this.isLoading = true;
        try {
          await updateEbidApprovedValue(deal);
          this.currentDeal = deal;
          notifications.success(t("dealUpdateSuccess"));
        } catch (err) {
          logger.error(err);
          notifications.error(t("dealUpdateError"));
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
});
