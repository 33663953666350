<template>
  <div class="sm:flex place-content-between">
    <va-page-title>{{ t("dashboard") }}</va-page-title>
    <dashboad-filters />
  </div>

  <div class="grid grid-cols-1 gap-4">
    <orders-chart />
    <licenses-chart />
    <!--    <deals-chart :dashboard-query="dashboardQuery" />-->
    <!--    <sell-out-chart />-->
  </div>
</template>

<script setup lang="ts">
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import { useI18n } from "vue-i18n";
import LicensesChart from "@/components/organisms/charts/LicensesChart.vue";
import OrdersChart from "@/components/organisms/charts/OrdersChart.vue";
import DashboadFilters from "@/components/organisms/charts/DashboadFilters.vue";
import { useMeta } from "vue-meta";

useMeta({
  title: "Dashboard",
});
const { t } = useI18n();
</script>
