<template>
  <va-page-title>{{ t("productCreate") }}</va-page-title>
  <va-back-link target="/products">{{ t("back") }}</va-back-link>
  <product-form :product="product" @save="onSave" />
</template>

<script setup lang="ts">
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import { t } from "@/plugins/i18n";
import { Product } from "@/models/product.interface";
import ProductForm from "@/components/organisms/ProductForm.vue";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import { ref } from "vue";
import { useProductsStore } from "@/stores/products";
import { ProductType } from "@/models/productType.interface";
import { useMeta } from "vue-meta";

useMeta({
  title: "Products",
});
const productsStore = useProductsStore();

const product = ref({
  id: "",
  sku: "",
  name: "",
  description: "",
  price: 0,
  currency: {
    id: "",
    name: "",
    code: "",
    symbol: "",
  },
  stock: 0,
  isLicense: false,
  licensePrefix: "",
  licenseDurationYears: 0,
  licenseDurationMonths: 0,
  licenseDurationDays: 0,
  maintenanceDurationYears: 0,
  maintenanceDurationMonths: 0,
  maintenanceDurationDays: 0,
  licenseActivationRule: "",
  islicenseUpgradeRight: false,
  isLicenseTrial: false,
  createdBy: "",
  status: "Pending",
  environmentType: "1",
  type: {
    id: "",
    name: "",
    description: "",
    features: [],
  } as ProductType,
  tenants: [],
} as unknown as Product);

const onSave = (prod: Product) => {
  productsStore.create(prod);
};
</script>
