import productModel, { Product } from "@/models/product.interface";
import accountModel, { Account } from "@/models/account.interface";
import { TableConfig } from "@/models/listable.interface";

export interface LicenseCreationData {
  quantity: number;
  product: string;
  generateSeparateKeys: boolean;
  extensions: string[];
  licenseNote?: string;
  parentAccountId?: string;
  derivedOrderId?: string;
}

export interface LicenseExportData {
  onPremisesEnvironmentId: string;
  parentAccountId: string;
  serials: { serialKey: string }[];
}

interface LicenseDetails {
  id: string;
  serialKey: string;
  durationYears: number;
  durationMonths: number;
  durationDays: number;
  maintenanceDurationYears: number;
  maintenanceDurationMonths: number;
  maintenanceDurationDays: number;
  licenseIsPerpetual: boolean;
  maintenanceIsPerpetual: boolean;
  creationDate: Date;
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
function deserializeDetails(input: any): LicenseDetails {
  return {
    id: input.licenseDetailId || "",
    serialKey: input.serialKey || "",
    durationYears: input.durationYears || 0,
    durationMonths: input.durationMonths || 0,
    durationDays: input.durationDays || 0,
    maintenanceDurationYears: input.maintenanceDurationYears || 0,
    maintenanceDurationMonths: input.maintenanceDurationMonths || 0,
    maintenanceDurationDays: input.maintenanceDurationDays || 0,
    licenseIsPerpetual: input.licenseIsPerpetual,
    maintenanceIsPerpetual: input.maintenanceIsPerpetual,
    creationDate: new Date(input.creationDate),
  };
}

function serializeDetails(input: LicenseDetails): unknown {
  return {
    licenseDetailId: input.id,
    serialKey: input.serialKey,
    durationYears: input.durationYears,
    durationMonths: input.durationMonths,
    durationDays: input.durationDays,
    maintenanceDurationYears: input.maintenanceDurationYears,
    maintenanceDurationMonths: input.maintenanceDurationMonths,
    maintenanceDurationDays: input.maintenanceDurationDays,
    licenseIsPerpetual: input.licenseIsPerpetual,
    maintenanceIsPerpetual: input.maintenanceIsPerpetual,
    creationDate: input.creationDate.getTime(),
  };
}

export interface LicenseSeats {
  id: string;
  deviceUuid: string;
  deviceName: string;
  deviceDescription: string;
  deviceLang: string;
  installedProduct: string;
  installedVersion: string;
  osName?: string;
  osVersion?: string;
  active: boolean;
  suspended: boolean;
  revoked: boolean;
  currentTrialStep: number;
  creationDate: Date;
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
function deserializeSeats(input: any): LicenseSeats {
  return {
    id: input.licenseSeatId || "",
    deviceUuid: input.deviceUuid || "",
    deviceName: input.deviceName || "",
    deviceDescription: input.deviceDescription || "",
    deviceLang: input.deviceLang || "",
    installedProduct: input.installedProduct || "",
    installedVersion: input.installedVersion || "",
    osName: input.osName || undefined,
    osVersion: input.osVersion || undefined,
    active: !!input.active,
    suspended: !!input.suspended,
    revoked: !!input.revoked,
    currentTrialStep: input.currentTrialStep || 0,
    creationDate: new Date(input.creationDate),
  };
}

function serializeSeats(input: LicenseSeats): unknown {
  return {
    licenseSeatId: input.id,
    deviceUuid: input.deviceUuid,
    deviceName: input.deviceName,
    deviceDescription: input.deviceDescription,
    deviceLang: input.deviceLang,
    installedProduct: input.installedProduct,
    installedVersion: input.installedVersion,
    osName: input.osName,
    osVersion: input.osVersion,
    active: input.active,
    suspended: input.suspended,
    revoked: input.revoked,
    currentTrialStep: input.currentTrialStep,
  };
}

export interface License {
  id: string;
  serialKey: string;
  seats: number;
  seatsOccupied: number;
  licenseHasBeenActivated: boolean;
  licenseHasBeenExported: boolean;
  creationDate: Date;
  activationDate?: Date;
  expirationDate?: Date;
  maintenanceExpirationDate?: Date;
  licenseIsPerpetual: boolean;
  maintenanceIsPerpetual: boolean;
  product: Product;
  ownerAccount: Account;
  licenseDetails: LicenseDetails[];
  licenseSeats: LicenseSeats[];
  notes?: string;
  derivedOrderId?: string;
  exportName?: string;
  exportOnPremiseEnvironmentId?: string;
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): License {
    return {
      id: input.licenseId || "",
      serialKey: input.serialKey || "",
      seats: input.seats || 0,
      seatsOccupied: input.seatsOccupied || 0,
      licenseHasBeenActivated: !!input.licenseHasBeenActivated,
      licenseHasBeenExported: !!input.licenseHasBeenExported,
      creationDate: new Date(input.creationDate),
      activationDate: input.activationDate
        ? new Date(input.activationDate)
        : undefined,
      expirationDate: input.expirationDate
        ? new Date(input.expirationDate)
        : undefined,
      maintenanceExpirationDate: input.maintenanceExpirationDate
        ? new Date(input.maintenanceExpirationDate)
        : undefined,
      licenseIsPerpetual: input.licenseIsPerpetual,
      maintenanceIsPerpetual: input.maintenanceIsPerpetual,
      product: productModel.deserialize(input.product),
      ownerAccount: accountModel.deserialize(input.ownerAccount),
      licenseDetails: input.licenseDetails?.length
        ? input.licenseDetails.map(deserializeDetails)
        : [],
      licenseSeats: input.licenseSeats?.length
        ? input.licenseSeats.map(deserializeSeats)
        : [],
      notes: input.licenseNote,
      derivedOrderId: input.derivedOrderId,
      exportName: input.licenseExport?.customerName || undefined,
      exportOnPremiseEnvironmentId:
        input.licenseExport?.onPremiseEnvironmentId || undefined,
    };
  },
  serialize(input: License): unknown {
    return {
      licenseId: input.id,
      serialKey: input.serialKey,
      seats: input.seats,
      seatsOccupied: input.seats,
      licenseHasBeenActivated: input.licenseHasBeenActivated,
      licenseHasBeenExported: input.licenseHasBeenExported,
      creationDate: input.creationDate.getTime(),
      activationDate: input.activationDate
        ? input.activationDate.getTime()
        : undefined,
      expirationDate: input.expirationDate
        ? input.expirationDate.getTime()
        : undefined,
      maintenanceExpirationDate: input.maintenanceExpirationDate
        ? input.maintenanceExpirationDate.getTime()
        : undefined,
      licenseIsPerpetual: input.licenseIsPerpetual,
      maintenanceIsPerpetual: input.maintenanceIsPerpetual,
      product: productModel.serialize(input.product),
      ownerAccount: accountModel.serialize(input.ownerAccount),
      licenseDetails: input.licenseDetails.map(serializeDetails),
      licenseSeats: input.licenseSeats.map(serializeSeats),
      licenseNote: input.notes,
      derivedOrderId: input.derivedOrderId,
      licenseExport:
        input.exportOnPremiseEnvironmentId || input.exportName
          ? {
              customerName: input.exportName,
              onPremiseEnvironmentId: input.exportOnPremiseEnvironmentId,
            }
          : undefined,
    };
  },
};

export const tableConfig = {
  serialKey: {
    dtoName: "serialKey",
    sortable: false,
    filterable: true,
    label: "serialKey",
  },
  product: {
    dtoName: "productName",
    sortable: false,
    filterable: true,
    label: "product",
  },
  id: {
    dtoName: "licenseId",
    sortable: false,
    filterable: false,
    label: "id",
    hideColumn: true,
  },
  seatsOccupied: {
    dtoName: "seatsOccupied",
    sortable: true,
    filterable: false,
    label: "seats",
  },
  creationDate: {
    dtoName: "creationDate",
    sortable: true,
    filterable: false,
    label: "created",
  },
  licenseHasBeenActivated: {
    dtoName: "licenseHasBeenActivated",
    sortable: false,
    filterable: false,
    label: "activated",
  },
  expirationDate: {
    dtoName: "expirationDate",
    sortable: true,
    filterable: false,
    label: "licenseExp",
  },
  maintenanceExpirationDate: {
    dtoName: "maintenanceExpirationDate",
    sortable: true,
    filterable: false,
    label: "maintenanceExp",
  },
  notes: {
    dtoName: "licenseNote",
    sortable: false,
    filterable: true,
    label: "notes",
  },
  ownerAccount: {
    dtoName: "OwnerAccountName",
    sortable: false,
    filterable: true,
    label: "owner",
  },
  // type: {
  //   dtoName: "type",
  //   sortable: false,
  //   filterable: false,
  //   label: "type",
  // },
  ownerAccountId: {
    dtoName: "ownerAccountId",
    sortable: false,
    filterable: true,
    label: "owner",
    hideColumn: true,
  },
} as TableConfig;
