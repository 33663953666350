<template>
  <div v-if="account" class="mb-6">
    <div>
      <div
        class="sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1"
      >
        <div class="2xl:block mt-6 min-w-0 flex-1">
          <h1 class="text-2xl font-bold text-gray-900 truncate">
            {{ account.name }}
          </h1>
          <div class="text-sm font-medium text-gray-500">
            {{ account.address?.legalName }}:
            {{ formatAddress(account.address) }} <br />
            {{ t("vatId") }}: {{ account.address?.vatId }}
          </div>
        </div>
        <div class="mt-6 flex space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
          <VaBadge color="default">
            {{ t(account.group.name) }}
          </VaBadge>
          <va-delete-button
            class="mr-2"
            :disabled="account.id === myAccount?.id || !accountStore.canDelete"
            :confirm-text="t('accountRemoveDescription')"
            :confirm-title="t('accountRemove')"
            @click:confirm="onDeleteAccount"
          />
          <va-button
            :disabled="!accountStore.canUpdate"
            @click="router.push(`/accounts/${account?.id}/edit`)"
          >
            <i class="rm rm-ico-edit" />
          </va-button>
        </div>
      </div>

      <nav class="flex" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-4">
          <li v-for="parent in account.parents" :key="parent.id">
            <div class="flex items-center">
              <router-link
                :to="`/accounts/${parent.id}`"
                class="mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {{ parent.name }}
              </router-link>
              <svg
                class="flex-shrink-0 h-5 w-5 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
            </div>
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <va-tabs :tabs="tabs" />
  <div id="salesNetworkContent">
    <router-view />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { onBeforeMount, watch } from "vue";
import { useFormatAddressShort } from "@/composables/formatters";
import router from "@/router";
import VaButton from "@/components/molecules/VaButton.vue";
import { useAccountsStore } from "@/stores/accounts";
import VaBadge from "@/components/atoms/VaBadge.vue";
import VaDeleteButton from "@/components/molecules/VaDeleteButton.vue";
import { computed } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useRoute } from "vue-router";
import VaTabs from "@/components/organisms/VaTabs.vue";
import { NavigationItem } from "@/models/navigationItem.interface";
import { useMeta } from "vue-meta";

useMeta({
  title: "Sales Network",
});
const route = useRoute();
const authStore = useAuthStore();
const myAccount = computed(() => authStore.userAccount);
const formatAddress = useFormatAddressShort;
const { t } = useI18n();
const accountStore = useAccountsStore();
const account = computed(() => accountStore.currentAccount);

const tabs = computed(
  () =>
    [
      {
        path: `/accounts/${account.value?.id}`,
        label: t("network"),
      },
      {
        path: `/accounts/${account.value?.id}/pricelist`,
        label: t("pricelList"),
      },
      {
        path: `/accounts/${account.value?.id}/sell-in`,
        label: t("sellIn"),
      },
      {
        path: `/accounts/${account.value?.id}/stock`,
        label: t("stock"),
      },
      {
        path: `/accounts/${account.value?.id}/sell-out`,
        label: t("sellOut"),
      },
      {
        path: `/accounts/${account.value?.id}/licenses`,
        label: t("licenseKeys"),
      },
      {
        path: `/accounts/${account.value?.id}/users`,
        label: t("users"),
      },
      {
        path: `/accounts/${account.value?.id}/certifications`,
        label: t("certifications"),
      },
      {
        path: `/accounts/${account.value?.id}/environments`,
        label: t("onPremEnv"),
      },
      {
        path: `/accounts/${account.value?.id}/files`,
        label: t("files"),
      },
    ] as NavigationItem[]
);

const onDeleteAccount = () => {
  if (account.value?.id) accountStore.cancel(account.value.id);
};

const fetchAccountData = () => {
  if (
    route.matched.some((match) => {
      return match.path == "/accounts";
    })
  ) {
    if (!route.params.id) {
      router.push(`/accounts/${myAccount.value?.id}`);
    } else {
      accountStore.get(route.params.id as string);
    }
  }
};
watch(route, fetchAccountData);
onBeforeMount(() => {
  if (!route.params.id) {
    router.push(`/accounts/${myAccount.value?.id}`);
  } else {
    accountStore.get(route.params.id as string);
  }
});
</script>
