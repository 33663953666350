<template>
  <div>
    <a-input-number
      v-if="type === 'number'"
      :value="modelValue"
      :placeholder="placeholder"
      :max="max"
      :min="min"
      style="width: 100%"
      :disabled="disabled"
      :addon-before="prefix"
      :addon-after="suffix"
      :formatter="formatValue"
      @step="updateStepValue"
      @input="updateAndFormatValue"
    />
    <a-input-password
      v-if="type === 'password'"
      :placeholder="placeholder"
      :value="modelValue"
      :disabled="disabled"
      @input="updateValue"
    />
    <a-textarea
      v-if="type === 'textarea'"
      :value="modelValue"
      :rows="rows"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="updateValue"
    />
    <a-input
      v-if="type === ''"
      :value="modelValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :maxlength="maxTextInput"
      @input="updateValue"
    >
      <template #prefix>
        <slot name="prefix"></slot>
      </template>
      <template #suffix>
        <slot name="suffix"></slot>
      </template>
    </a-input>
  </div>
</template>

<script setup lang="ts">
import { useFormatNumber, useParseNumber } from "@/composables/formatters";
import { computed } from "vue";

const props = defineProps({
  modelValue: { type: [String, Number], required: false, default: "" },
  placeholder: { type: String, required: false, default: "" },
  type: { type: String, required: false, default: "" },
  rows: { type: Number, required: false, default: 4 },
  max: { type: Number, required: false, default: Number.MAX_SAFE_INTEGER },
  min: { type: Number, required: false, default: 0 },
  disabled: { type: Boolean, required: false, default: false },
  prefix: { type: String, required: false, default: "" },
  suffix: { type: String, required: false, default: "" },
  currency: { type: String, required: false, default: "" },
  integer: { type: Boolean, required: false, default: false },
});
const emit = defineEmits(["update:modelValue"]);

const updateValue = (event: InputEvent) => {
  emit("update:modelValue", (event.target as HTMLInputElement).value);
};

const updateStepValue = (event: InputEvent) => {
  emit("update:modelValue", event);
};

const formatValue = (n: number): string => {
  return useFormatNumber(n, props.integer);
};

const updateAndFormatValue = (event: InputEvent) => {
  emit(
    "update:modelValue",
    event ? useParseNumber(event.toString(), props.integer) : 0
  );
};

const maxTextInput = computed(() => {
  return props.max !== Number.MAX_SAFE_INTEGER ? props.max : 255;
});
</script>

<style lang="scss" scoped>
.ant-input-affix-wrapper,
.ant-input-number {
  @apply shadow-sm  rounded-md;
}

.ant-input {
  @apply focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md shadow-sm;
}
</style>
