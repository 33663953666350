import userModel, { User } from "@/models/user.interface";
import * as configService from "@/services/configService";
import axios from "axios";
import authDataModel, { AuthData } from "@/models/authData.interface";
import loginDataModel, { LoginData } from "@/models/loginData.interface";
import * as Cookies from "es-cookie";
import MfaProviderModel, { MfaProvider } from "@/models/mfaProvider.interrface";

export async function login(
  data: LoginData
): Promise<AuthData | { mfaToken: string }> {
  try {
    const resp = await axios.post(
      configService.authUrl,
      loginDataModel.serialize(data)
    );
    return authDataModel.deserialize(resp?.data);
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any*/
  } catch (err: any) {
    if (err.response?.data?.errorCode === "A403") {
      return { mfaToken: err.response.data.info.mfaToken };
    } else {
      throw err as Error;
    }
  }
}

export async function forgotPassword(mail: string): Promise<boolean> {
  const res = await axios.post(configService.forgotPasswordUrl, {
    email: mail,
  });
  return !!res.data;
}

export async function resetPassword(
  token: string,
  password: string,
  email: string
): Promise<boolean> {
  const res = await axios.post(configService.resetPasswordUrl, {
    token: token,
    password: password,
    email: email,
  });
  return !!res.data;
}

export async function confirmEmail(
  email: string,
  token: string,
  password: string
): Promise<boolean> {
  const res = await axios.post(configService.confirmMailUrl, {
    email: email,
    token: token,
    password: password,
    languageCode: navigator.language || undefined,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || undefined,
  });
  return !!res.data;
}

export function getAuthDataCookie(): AuthData | undefined {
  const cookie = Cookies.get("auth-data");
  return cookie ? authDataModel.deserialize(JSON.parse(cookie)) : undefined;
}

export function setAuthDataCookie(data: AuthData | undefined): void {
  if (data) {
    Cookies.set("auth-data", JSON.stringify(authDataModel.serialize(data)), {
      expires: data.refreshExpires,
    });
  } else {
    Cookies.remove("auth-data");
  }
}

export function isAuthDataExpired(data: AuthData): boolean {
  return data.expires < new Date();
}

export async function getUser(): Promise<User> {
  const resp = await axios.get(configService.userMeUrl);
  return userModel.deserialize(resp.data);
}

export async function refreshToken(oldData: AuthData): Promise<AuthData> {
  const resp = await axios.post(
    configService.refreshAuthUrl,
    authDataModel.serialize(oldData)
  );
  return authDataModel.deserialize(resp.data);
}

async function getAllMfaProviders(): Promise<MfaProvider[]> {
  const res = await axios.get(configService.mfaProvidersUrl);
  return res.data.providers.map(MfaProviderModel.deserialize);
}

async function getEnabledMfaProviders(): Promise<Array<MfaProvider>> {
  const res = await axios.get(configService.mfaEnabledProvidersUrl);
  return res.data.providers.map(MfaProviderModel.deserialize);
}

export async function getMfaProviders(): Promise<MfaProvider[]> {
  const [available, enabled] = await Promise.all([
    getAllMfaProviders(),
    getEnabledMfaProviders(),
  ]);
  return available.map((provider) => {
    provider.enabled = !!enabled.find(
      (enabledProvider) => enabledProvider.name === provider.name
    );
    return provider;
  });
}

export async function getProvidersChallenge(
  mfaToken: string
): Promise<MfaProvider[]> {
  const res = await axios.post(configService.providersChallengeUrl, {
    mfaToken,
  });
  return res.data.providers.map(MfaProviderModel.deserialize);
}

//LOGIN FLOW
export async function selectMfaCode(
  providerId: number,
  mfaToken: string
): Promise<boolean> {
  const res = await axios.post(configService.mfaSendCodeUrl, {
    providerType: providerId,
    mfaToken: mfaToken,
  });
  return !!res.data?.mfaToken;
}

export async function disableMfaProvider(provider: MfaProvider): Promise<void> {
  await axios.delete(
    `${configService.mfaDisableProviderUrl}?providerType=${provider.id}`
  );
}

export async function enableMfaProvider(
  provider: MfaProvider
): Promise<string> {
  const res = await axios.post(
    configService.mfaEnableCodeUrl,
    MfaProviderModel.serialize(provider)
  );
  return res.data?.mfaToken;
}

export async function verifyMFACode(
  authCode: string,
  mfaToken: string
): Promise<boolean> {
  const res = await axios.post(configService.mfaVerificationCodeUrl, {
    authCode: authCode,
    mfaToken: mfaToken,
  });
  return res.status === 200;
}

export async function confirmMfaActivationCode(
  token: string
): Promise<boolean> {
  const res = await axios.put(configService.confirmMfaActivationUrl, {
    mfaToken: token,
  });
  return res.status === 202;
}

export async function getBackupCodes(): Promise<string[]> {
  const res = await axios.get(configService.backupCodesUrl);
  return res.data
    .filter(
      (item: { isConsumed: boolean; backupCode: string }) => !item.isConsumed
    )
    .map(
      (item: { isConsumed: boolean; backupCode: string }) => item.backupCode
    );
}
