import { defineStore } from "pinia";
import { CountryData } from "@/models/countryData.interface";
import { CurrencyData } from "@/models/currencyData.interface";
import {
  fetchAccountGroups,
  fetchCountries,
  fetchCurrencies,
  fetchRoles,
} from "@/services/lookupService";
import { fetchSubscriptions } from "@/services/subscriptionsService";
import logger from "@/services/loggerService";
import { t } from "@/plugins/i18n";
import { AccountGroup } from "@/models/accountGroup.interface";
import { Role } from "@/models/role.interface";
import { Subscription } from "@/models/subscription.interface";
import { useAuthStore } from "@/stores/auth";
import { useNotificationsStore } from "@/stores/notifications";
import { buildOption, SelectOption } from "@/models/selectOptions.interface";

export interface LookupState {
  countriesLoading: boolean;
  countries: CountryData[];
  accountGroupsLoading: boolean;
  accountGroups: AccountGroup[];
  currenciesLoading: boolean;
  currencies: CurrencyData[];
  rolesLoading: boolean;
  roles: Role[];
  subscriptionsLoading: boolean;
  subscriptions: Subscription[];
}

export const useLookupStore = defineStore({
  id: "lookup",
  state: (): LookupState => ({
    countriesLoading: false,
    countries: [],
    currenciesLoading: false,
    currencies: [],
    accountGroupsLoading: false,
    accountGroups: [],
    rolesLoading: false,
    roles: [],
    subscriptionsLoading: false,
    subscriptions: [],
  }),
  getters: {
    countriesOptions: (state): SelectOption<CountryData>[] => {
      return state.countries.map((item) => {
        return buildOption<CountryData>(item, "countryCode", "countryName");
      });
    },
    subscriptionsOptions: (state): SelectOption<Subscription>[] => {
      return state.subscriptions.map((item) => {
        return buildOption<Subscription>(item, "id", "name");
      });
    },
    currencyOptions: (state): SelectOption<CurrencyData>[] => {
      return state.currencies.map((item) => {
        return buildOption<CurrencyData>(item, "id", "name");
      });
    },
    regularRoles: (state): Role[] => {
      //exclude superadmin for security purposes
      return state.roles.filter((role: Role) => {
        return !role.isSuperAdmin;
      });
    },
    regularRolesOptions: (state): SelectOption<Role>[] => {
      //exclude superadmin for security purposes
      return state.roles
        .filter((role) => {
          return !role.isSuperAdmin;
        })
        .map((item) => {
          return buildOption<Role>(item, "id", "name");
        });
    },
    decodedCurrency: (state) => {
      return (id: string): CurrencyData => {
        return (
          state.currencies.find((group) => group.id === id) || {
            id: "-",
            name: "USD",
            code: "USD",
            symbol: "$",
          }
        );
      };
    },
    decodedAccountGroup: (state) => {
      return (id: string): AccountGroup => {
        return (
          state.accountGroups.find((group) => group.groupId === id) || {
            groupId: "-",
            name: "BUYER",
            level: 60,
          }
        );
      };
    },
  },
  actions: {
    async fetchCurrencies() {
      if (!this.countries.length) {
        this.currenciesLoading = true;
        const notifications = useNotificationsStore();
        try {
          this.currencies = await fetchCurrencies();
        } catch (err) {
          logger.error(err);
          notifications.error(t("countriesFetchError"));
        } finally {
          this.currenciesLoading = false;
        }
      }
    },
    async fetchCountries() {
      if (!this.countries.length) {
        const authStore = useAuthStore();
        const notifications = useNotificationsStore();
        this.countriesLoading = true;
        try {
          const langCode = authStore.user?.languageCode || "en";
          this.countries = await fetchCountries(langCode);
        } catch (err) {
          logger.error(err);
          notifications.error(t("countriesFetchError"));
        } finally {
          this.countriesLoading = false;
        }
      }
    },
    async fetchAccountGroups() {
      if (!this.accountGroups.length) {
        this.accountGroupsLoading = true;
        const notifications = useNotificationsStore();
        try {
          const res = await fetchAccountGroups();
          //sort groups by level
          res.sort((a, b) => a.level - b.level);
          this.accountGroups = res;
        } catch (err) {
          logger.error(err);
          notifications.error(t("accountGroupsFetchError"));
        } finally {
          this.accountGroupsLoading = false;
        }
      }
    },
    async fetchRoles() {
      if (!this.roles.length) {
        this.rolesLoading = true;
        const notifications = useNotificationsStore();
        try {
          this.roles = await fetchRoles();
        } catch (err) {
          logger.error(err);
          notifications.error(t("rolesFetchError"));
        } finally {
          this.rolesLoading = false;
        }
      }
    },
    async fetchSubscriptions() {
      if (!this.subscriptions.length) {
        this.subscriptionsLoading = true;
        const notifications = useNotificationsStore();
        try {
          this.subscriptions = await fetchSubscriptions();
        } catch (err) {
          logger.error(err);
          notifications.error(t("subscriptionsFetchError"));
        } finally {
          this.subscriptionsLoading = false;
        }
      }
    },
  },
});
