<template>
  <a-avatar
    :src="props.image"
    :size="props.size"
    :style="{ backgroundColor: props.color }"
  >
    <!--    <template v-if="props.icon" #icon>{{ props.icon }}</template>-->
    {{ initials }}
  </a-avatar>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  image: {
    type: String,
    required: false,
    default: undefined,
  },
  text: {
    type: String,
    required: false,
    default: undefined,
  },
  // tile: {
  //   type: Boolean,
  //   required: false,
  //   default: false,
  // },
  size: {
    type: Number,
    required: false,
    default: 32,
  },
  color: {
    type: String,
    required: false,
    default: "#AAA",
  },
});

const initials = computed(() => {
  return (props.text?.match(/\b(\w)/g) || []).join("").substring(0, 2);
});
</script>

<style lang="scss">
// noinspection CssInvalidAtRule
.ant-avatar {
  @apply flex items-center justify-center bg-gray-100 shadow-sm;

  &.ant-avatar-circle {
    @apply rounded-full;
  }

  &.ant-avatar-image > img {
    @apply rounded-full;
  }

  .ant-avatar-string {
    @apply text-xs font-medium leading-none text-white;
  }

  .ant-avatar-string {
    @apply transform-none #{!important};
  }
}
</style>
