import countryDataModel, { CountryData } from "@/models/countryData.interface";
import currencyDataModel, {
  CurrencyData,
} from "@/models/currencyData.interface";
import axios from "axios";
import * as configService from "@/services/configService";
import accoutGroupModel, {
  AccountGroup,
} from "@/models/accountGroup.interface";
import roleModel, { Role } from "@/models/role.interface";

export async function fetchCurrencies(): Promise<CurrencyData[]> {
  const res = await axios.get(`${configService.currenciesUrl}`);
  return res.data.items.map(currencyDataModel.deserialize);
}

export async function fetchCountries(langCode: string): Promise<CountryData[]> {
  const res = await axios.get(
    `${configService.countriesUrl}?languageId=${langCode}`
  );
  return res.data.items.map(countryDataModel.deserialize);
}

export async function fetchAccountGroups(): Promise<AccountGroup[]> {
  const res = await axios.get(`${configService.accountGroupsUrl}`);
  return res.data.items.map(accoutGroupModel.deserialize);
}

export async function fetchRoles(): Promise<Role[]> {
  const res = await axios.get(`${configService.rolesUrl}`);
  return res.data.items.map(roleModel.deserialize);
}
