import { defineStore } from "pinia";
import { User } from "@/models/user.interface";
import { AuthData } from "@/models/authData.interface";
import { MfaProvider } from "@/models/mfaProvider.interrface";
import i18n, { t } from "@/plugins/i18n";
import { Account } from "@/models/account.interface";
import { Permission } from "@/models/permission.interface";
import { useNotificationsStore } from "@/stores/notifications";
import { cloneDeep } from "lodash";
import logger from "@/services/loggerService";
import * as authService from "@/services/authService";
import router from "@/router/index";
import { LoginData } from "@/models/loginData.interface";
import { useLookupStore } from "@/stores/lookup";
import { useAccountsStore } from "@/stores/accounts";
import { getActivePinia } from "pinia";

export interface AuthState {
  user: User | undefined;
  authData: AuthData | undefined;
  mfaToken: string | undefined;
  mfaProviders: MfaProvider[];
  mfaProviderToEnable: MfaProvider | undefined;
  mfaChallengeProviders: MfaProvider[];
  mfaSelectedProvider: MfaProvider | undefined;
  mfaBackupCodes: string[];
  isLoading: boolean;
}

export const useAuthStore = defineStore({
  id: "auth",
  state: (): AuthState => ({
    user: undefined,
    // authData: undefined,
    authData: authService.getAuthDataCookie(),
    mfaToken: undefined,
    mfaProviders: [],
    mfaProviderToEnable: undefined,
    mfaChallengeProviders: [],
    mfaSelectedProvider: undefined,
    mfaBackupCodes: [],
    isLoading: false,
  }),
  getters: {
    userAccount: (state): Account | undefined => state.user?.account,
    userPermissions: (state): Permission[] => state.user?.permissions || [],
    isAuthenticated: (state): boolean => {
      return (
        state.authData != undefined &&
        new Date(state.authData.refreshExpires) > new Date()
      );
    },
    isMfaEnabled: (state): boolean => {
      return state.mfaProviders.some((p) => {
        return p.enabled;
      });
    },
  },
  actions: {
    setUser(data: User | undefined) {
      this.user = data;
      if (data?.languageCode) i18n.global.locale.value = data.languageCode;
    },
    setUserAccount(data: Account | undefined) {
      if (this.user) this.user.account = data;
    },
    updateMfaProvider(data: MfaProvider) {
      this.mfaProviders = this.mfaProviders.map((p) => {
        if (p.id == data.id) return data;
        return p;
      });
    },

    async login(data: LoginData) {
      this.isLoading = true;
      const lookupStore = useLookupStore();
      const accountStore = useAccountsStore();
      const notifications = useNotificationsStore();
      try {
        data.mfaToken = this.mfaToken;
        const loginResult = await authService.login(data);
        if ((loginResult as AuthData).token) {
          //regular flow
          this.authData = loginResult as AuthData;
          authService.setAuthDataCookie(this.authData);
          await Promise.all([
            lookupStore.fetchCurrencies(),
            lookupStore.fetchAccountGroups(),
          ]);
          const user = await authService.getUser();
          this.setUser(user);
          // if (this.user.languageCode) {
          //   i18n.global.locale.value = this.user.languageCode;
          // }
          accountStore.currentAccount = user.account;
          this.mfaToken = undefined;
          this.mfaSelectedProvider = undefined;
          this.mfaChallengeProviders = [];
          if (data.redirect) {
            router.push(data.redirect);
          } else {
            router.push("/");
          }
        } else {
          //MFA flow
          this.mfaToken = (loginResult as { mfaToken: string }).mfaToken;
          this.mfaChallengeProviders = await authService.getProvidersChallenge(
            this.mfaToken
          );
        }
      } catch (err) {
        logger.error(err);
        notifications.error(t("signInLoginError"));
      } finally {
        this.isLoading = false;
      }
    },

    async recoverLogin() {
      this.isLoading = true;
      const lookupStore = useLookupStore();
      const accountStore = useAccountsStore();
      try {
        if (!this.authData) {
          this.logout();
        } else {
          await Promise.all([
            lookupStore.fetchCurrencies(),
            lookupStore.fetchAccountGroups(),
          ]);
          const user = await authService.getUser();
          this.setUser(user);
          // this.user = await authService.getUser();
          if (!this.user) {
            logger.error(new Error(`unable to recover user`));
            this.logout();
          }
          accountStore.currentAccount = user.account;
        }
      } catch (err) {
        logger.error(err);
        this.logout();
      } finally {
        this.isLoading = false;
      }
    },

    async refreshToken(): Promise<void> {
      const oldData = cloneDeep(this.authData);
      if (!oldData) {
        this.logout();
      } else {
        try {
          const newData = await authService.refreshToken(oldData);
          this.authData = newData;
          authService.setAuthDataCookie(newData);
        } catch (err) {
          logger.error(err);
          this.logout();
        } finally {
          this.isLoading = false;
        }
      }
    },

    async logout() {
      authService.setAuthDataCookie(undefined);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getActivePinia()?._s.forEach((store) => store.$reset());
      await router.push("/auth/signin");
    },

    async forgotPassword(mail: string) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await authService.forgotPassword(mail);
        notifications.success(t("forgotSuccess"));
        return true;
      } catch (err) {
        logger.error(err);
        notifications.error(t("forgotError"));
      } finally {
        this.isLoading = false;
      }
    },

    async resetPassword(data: {
      token: string;
      password: string;
      email: string;
    }) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await authService.resetPassword(data.token, data.password, data.email);
        notifications.success(t("resetPassSuccess"));
        router.push("/auth/signin");
      } catch (e) {
        logger.error(e);
        notifications.error(t("resetPassError"));
      } finally {
        this.isLoading = false;
      }
    },

    async confirmEmail(email: string, token: string, password: string) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await authService.confirmEmail(email, token, password);
        notifications.success(t("confirmMailSuccess"));
        router.push("/auth/signin");
      } catch (e) {
        logger.error(e);
        notifications.error(t("confirmMailError"));
      } finally {
        this.isLoading = false;
      }
    },

    async fetchAvailableProviders(): Promise<void> {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        this.mfaProviders = await authService.getMfaProviders();
      } catch (e) {
        logger.error(e);
        notifications.error(t("signInFetchProvidersError"));
      } finally {
        this.isLoading = false;
      }
    },

    async fetchMfaBackupCodes(): Promise<void> {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        this.mfaBackupCodes = await authService.getBackupCodes();
      } catch (e) {
        logger.error(e);
        notifications.error(t("signInFetchProvidersError"));
      } finally {
        this.isLoading = false;
      }
    },

    async disableProvider(provider: MfaProvider): Promise<void> {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await authService.disableMfaProvider(provider);
        provider.enabled = !provider.enabled;
        this.updateMfaProvider(provider);
        notifications.success(t("mfaDisableProvider"));
      } catch (e) {
        logger.error(e);
        notifications.error(t("mfaDisableProviderError"));
      } finally {
        this.isLoading = false;
      }
    },

    async enableProvider(provider: MfaProvider): Promise<void> {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        this.mfaToken = await authService.enableMfaProvider(provider);
        this.mfaProviderToEnable = provider;
      } catch (e) {
        logger.error(e);
        notifications.error(t("mfaEnableProviderError"));
      } finally {
        this.isLoading = false;
      }
    },

    async confirmProvider(code: string): Promise<void> {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        if (this.mfaToken) {
          const verify = await authService.verifyMFACode(code, this.mfaToken);
          if (verify && this.mfaProviderToEnable) {
            await authService.confirmMfaActivationCode(this.mfaToken);
            const provider = cloneDeep(this.mfaProviderToEnable);
            provider.enabled = !provider.enabled;
            this.updateMfaProvider(provider);
            this.mfaProviderToEnable = undefined;
            notifications.success(t("mfaEnableProvider"));
          }
        }
      } catch (e) {
        logger.error(e);
        notifications.error(t("mfaEnableProviderError"));
      } finally {
        this.isLoading = false;
      }
    },

    async selectProvider(provider: MfaProvider): Promise<void> {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        if (this.mfaToken) {
          if (await authService.selectMfaCode(provider.id, this.mfaToken)) {
            this.mfaSelectedProvider = provider;
          }
        }
      } catch (e) {
        logger.error(e);
        notifications.error(t("mfaSelectProviderError"));
      } finally {
        this.isLoading = false;
      }
    },
  },
});
