import { PaginationProps } from "ant-design-vue";
import {
  ColumnType,
  SorterResult,
  FilterValue,
} from "ant-design-vue/es/table/interface";
import { t } from "@/plugins/i18n";

// import { PaginationType } from "ant-design-vue/es/transfer/interface";

export interface TableConfig {
  [key: string]: {
    label: string;
    dtoName: string;
    sortable: boolean;
    filterable: boolean;
    hideColumn?: boolean;
  };
}

export type FilterResult = { [key: string]: FilterValue };
export type FilterDateRange = {
  field: string;
  fromDate?: Date;
  toDate?: Date;
};

export interface SearchOptions {
  pagination: PaginationProps;
  sorter?: SorterResult;
  filters?: FilterResult;
  additionalFilters?: FilterResult;
  preSort?: SorterResult;
  parentAccountId?: string;
  includeChildren?: boolean;
  filterBetweenDate?: FilterDateRange[];
}

function sorterSerialize(
  sorter: SorterResult | undefined,
  tableconfig: TableConfig,
  preSort?: SorterResult
) {
  const res = [] as unknown[];
  if (sorter?.columnKey) {
    res.push({
      field: tableconfig[sorter.columnKey].dtoName,
      direction: sorter.order === "ascend" ? "asc" : "desc",
    });
  }
  if (preSort?.columnKey && preSort.columnKey !== sorter?.columnKey) {
    res.push({
      field: tableconfig[preSort.columnKey].dtoName,
      direction: preSort.order === "ascend" ? "asc" : "desc",
    });
  }

  return res;
}

function singleFilterSerialize(filter: FilterResult, tableConfig: TableConfig) {
  return Object.keys(filter)
    .filter((key) => {
      return filter[key] !== null;
    })
    .map((key) => {
      return [
        {
          field: tableConfig[key].dtoName,
          value: filter[key][0],
        },
      ];
    });
  /*
      [[{field: x, value: y}], [{field: x, value: y}]] => AND
      [[{field: x, value: y}, {field: x, value: y}]] => OR
      */
}

function filterSerialize(
  filter: FilterResult | undefined,
  tableConfig: TableConfig,
  additionalFilters?: FilterResult
) {
  let res = [] as unknown[];
  if (filter) {
    res = singleFilterSerialize(filter, tableConfig);
  }
  if (additionalFilters)
    res.concat(singleFilterSerialize(additionalFilters, tableConfig));
  return {
    values: res,
  };
}

export function searchOptionsSerialize(
  input: SearchOptions,
  tableConfig: TableConfig
): unknown {
  const res = {
    limit: input.pagination.pageSize,
    page: input.pagination.current,
    sortBy: sorterSerialize(input.sorter, tableConfig, input.preSort),
    filterBy: filterSerialize(
      input.filters,
      tableConfig,
      input.additionalFilters
    ),
    includeChildren: !!input.includeChildren,
    filterBetweenDate: [],
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  } as any;
  if (input?.filterBetweenDate?.length) {
    res.filterBetweenDate = input.filterBetweenDate
      ?.map((f) => {
        return {
          field: f.field,
          fromDate: f.fromDate ? f.fromDate.getTime() : undefined,
          toDate: f.toDate ? f.toDate.getTime() : undefined,
        };
      })
      .filter((f) => {
        return f.fromDate != undefined || f.toDate != undefined;
      });
  }
  if (input.parentAccountId) {
    res.parentAccountId = input.parentAccountId;
  }
  return res;
}

export function deserializeColumns(config: TableConfig): ColumnType[] {
  return Object.keys(config)
    .filter((key) => {
      return !config[key].hideColumn;
    })
    .map((key, index) => {
      return {
        title: t(config[key].label),
        dataIndex: key,
        key: key,
        sorter: config[key].sortable,
        customFilterDropdown: config[key].filterable,
        columnIndex: index,
      };
    });
}
