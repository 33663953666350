import { t } from "@/plugins/i18n";
import {
  PieStats,
  // AccountSample,
  // AccountsStats,
  QuantityStats,
  Sample,
  StatsQuery,
  statsRequestData,
} from "@/models/stats.interface";
import {
  useFormatDate,
  useFormatDateMonth,
  useFormatDateWeek,
} from "@/composables/formatters";

const formatDate = useFormatDate;
// const formatDateShort = useFormatDateShort;
const formatDateMonth = useFormatDateMonth;
const formatDateWeek = useFormatDateWeek;

export function useBuildChartData(formState: StatsQuery): statsRequestData {
  let previous;
  if (formState.from && formState.to) {
    const diffTime =
      Math.abs(formState.from.getTime() - formState.to.getTime()) +
      1000 * 60 * 60 * 24;
    previous = {
      from: new Date(new Date(formState.from).getTime() - diffTime),
      to: new Date(new Date(formState.to).getTime() - diffTime),
    };
  }

  return {
    parentAccountId: formState.accountId,
    includeChildren: formState.includeChildren,
    previous: previous,
    current: {
      from: formState.from ? new Date(formState.from) : undefined,
      to: formState.to ? new Date(formState.to) : undefined,
    },
    products: formState.products,
  };
}

const extractData = (samples: Sample[]): number[] => {
  return samples.map((sample: Sample) => {
    let tot = 0;
    sample.values.map((val) => {
      tot = tot + val.quantity;
    });
    return tot;
  });
};

export function usePieChartConfig(
  stats: PieStats[] | undefined,
  id: string,
  currencySymbol = ""
) {
  if (!stats) return undefined;
  const labels = stats.map((stat) => {
    return stat.name;
  });
  const data = stats.map((stat) => {
    return stat.amount;
  });

  return {
    id: id,
    type: "pie",
    options: {
      animation: true,
      plugins: {
        tooltip: {
          callbacks: {
            /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
            label(tooltipItems: any) {
              return `${tooltipItems.label}: ${tooltipItems.formattedValue}${currencySymbol}`;
            },
          },
        },
        legend: {
          display: true,
          position: "bottom",
          maxHeight: 50,
        },
      },
    },
    data: {
      labels: labels,
      datasets: [
        {
          label: "sellOut",
          data: data,
          backgroundColor: [
            "#4f46e5",
            "#c026d3",
            "#2563eb",
            "#7c3aed",
            "#db2777",
            "#0e7490",
            "#2563eb",
          ],
          hoverOffset: 10,
        },
      ],
    },
  };
}

export function useChartConfig(
  stats: QuantityStats | undefined,
  showPrevious: boolean,
  id: string,
  customLabel?: string,
  secondaryColor?: boolean,
  currencySymbol = ""
) {
  if (!stats) return undefined;
  const labels = stats.current?.map((sample: Sample) => {
    // if (showPrevious && false) {
    //   switch (stats.currentSamplingFrequency) {
    //     case "w":
    //       return `${formatDateWeek(sample.date)} vs ${formatDateWeek(
    //         stats.previous[index].date
    //       )}`;
    //     case "m":
    //       return `${formatDateMonth(sample.date)} vs ${formatDateMonth(
    //         stats.previous[index].date
    //       )}`;
    //     default:
    //       return `${formatDateShort(sample.date)} vs ${formatDateShort(
    //         stats.previous[index].date
    //       )}`;
    //   }
    // } else {
    switch (stats.currentSamplingFrequency) {
      case "w":
        return `${formatDateWeek(sample.date)}`;
      case "m":
        return `${formatDateMonth(sample.date)}`;
      default:
        return `${formatDate(sample.date)}`;
    }
    // }
  });
  const currentData = extractData(stats.current);
  // const previousData = extractData(stats.previous);

  const lineColor = secondaryColor ? "#c026d3" : "#4f46e5";
  const fillColor = secondaryColor
    ? "rgba(192,38,211,0.4)"
    : "rgba(79, 70, 229, 0.4)";

  const datasets = [
    {
      label: customLabel ? customLabel : t("currentPeriod"),
      pointRadius: 2,
      backgroundColor: [lineColor],
      borderColor: [lineColor], //indigo-600
      tension: 0.3,
      data: currentData,
      fill: {
        target: "origin",
        above: fillColor, // Area will be red above the origin
      },
    },
  ];

  // if (showPrevious) {
  //   const prevLineColor = secondaryColor ? "#c4b5fd" : "#94a3b8";
  //   const prevFillColor = secondaryColor
  //     ? "rgba(196,181,253,0.4)"
  //     : "rgba(148, 163, 184, 0.1)";
  //   datasets.push({
  //     label: t("previousPeriod"),
  //     pointRadius: 2,
  //     backgroundColor: [prevLineColor],
  //     borderColor: [prevLineColor], //slate-400
  //     tension: 0.3,
  //     data: previousData,
  //     fill: {
  //       target: "origin",
  //       above: prevFillColor, //slate-400
  //     },
  //   });
  // }

  return {
    id: id,
    type: "line",
    options: {
      animation: true,
      scales: {
        y: {
          grid: {
            display: true,
            drawBorder: true,
            // drawOnChartArea: true,
            drawTicks: false,
            color: "#e5e7eb", //gray-200
          },
          ticks: {
            display: false,
            // maxTicksLimit: 5,
            autoSkip: true,
          },
        },
        x: {
          grid: {
            display: false,
            drawBorder: true,
            drawTicks: false,
          },
          ticks: {
            // callback: function (value, index, ticks) {
            //   return "day " + (index + 1);
            //   // return "";
            // },
            align: "start",
            display: true,
            padding: 8,
            // maxTicksLimit: 5,
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
            label(tooltipItems: any) {
              return `${tooltipItems.label}: ${tooltipItems.formattedValue}${currencySymbol}`;
            },
          },
        },
        legend: {
          display: true,
          position: "bottom",
        },
      },
    },
    data: {
      labels: labels,
      datasets: datasets,
    },
  };
}
