export interface Coordinates {
  lat: number;
  lng: number;
}

export interface Address {
  id: string;
  legalName?: string;
  vatId?: string;
  street?: string;
  city?: string;
  locality?: string;
  region?: string;
  postalCode?: string;
  country?: string;
  countryCode?: string;
  coordinates?: Coordinates;
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): Address {
    return {
      id: input.addressId || "",
      legalName: input.legalName || "",
      vatId: input.vatId || "",
      street: input.street || "",
      city: input.city || "",
      locality: input.locality || "",
      region: input.region || "",
      postalCode: input.postalCode,
      country: input.country || "",
      countryCode: input.countryCode || "",
      coordinates: input.coordinates || undefined,
    };
  },

  serialize(input: Address): unknown {
    return {
      addressId: input.id,
      legalName: input.legalName,
      vatId: input.vatId,
      city: input.city,
      locality: input.locality,
      region: input.region,
      postalCode: input.postalCode,
      countryCode: input.countryCode,
      country: input.country,
      street: input.street,
      coordinates: input.coordinates,
    };
  },
};
