<template>
  <va-page-title class="mt-8">{{ t("newUserCertification") }}</va-page-title>
  <va-back-link :target="`/accounts/${route.params.id}/certifications`">
    {{ t("backToCertifications") }}
  </va-back-link>
  <div class="va-card mt-6 relative pt-4 pb-0.5 px-4">
    <a-form
      class=""
      :model="formState"
      :validate-messages="validateMessages"
      @finish="onFinish"
    >
      <div class="grid grid-cols-2 gap-4">
        <div>
          <div class="grid grid-cols-2 gap-4">
            <a-form-item
              :name="['certificateId']"
              :label="t('certificates')"
              :rules="[{ required: true }]"
            >
              <va-select
                :model-value="formState.certificateId"
                :options="certificatesStore.availableUserCertificationsOptions"
                @change="onSelectCertificate($event.value)"
              ></va-select>
            </a-form-item>
            <a-form-item
              :name="['isGuestCertificate']"
              :label="t('guestCertificate')"
            >
              <a-switch
                v-model:checked="formState.isGuestCertificate"
                @change="onChangeGuest"
              />
            </a-form-item>
          </div>
          <a-form-item
            v-if="!formState.isGuestCertificate"
            :name="['userId']"
            :label="t('user')"
            :rules="[{ required: true }]"
          >
            <va-select
              :model-value="formState.userId"
              :options="certificatesStore.userOptions"
              @change="formState.userId = $event.value"
            ></va-select>
          </a-form-item>
          <div v-else class="grid grid-cols-2 gap-4">
            <a-form-item
              :name="['userFirstName']"
              :label="t('firstName')"
              :rules="[{ required: true }]"
            >
              <va-input
                v-model="formState.userFirstName"
                :placeholder="t('firstName')"
              />
            </a-form-item>
            <a-form-item
              :name="['userLastName']"
              :label="t('lastName')"
              :rules="[{ required: true }]"
            >
              <va-input
                v-model="formState.userLastName"
                :placeholder="t('lastName')"
              />
            </a-form-item>
          </div>
        </div>
        <a-form-item :name="['notes']" :label="t('notes')">
          <va-input
            v-model="formState.notes"
            type="textarea"
            :placeholder="t('notes')"
            :rows="5"
          />
        </a-form-item>
        <a-form-item id="cta-button">
          <va-button
            html-type="submit"
            :disabled="!certificatesStore.canCreate"
            :loading="certificatesStore.isLoading"
          >
            {{ t("save") }}
          </va-button>
        </a-form-item>
      </div>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import { t } from "@/plugins/i18n";
import VaSelect from "@/components/molecules/VaSelect.vue";
import { useValidationMessages } from "@/composables/validation";
import { useRoute, useRouter } from "vue-router";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import { UserCertificationFormData } from "@/models/certification.interface";
import { useCertificatesStore } from "@/stores/certificates";
import VaInput from "@/components/molecules/VaInput.vue";

const certificatesStore = useCertificatesStore();
const route = useRoute();
const router = useRouter();

const validateMessages = useValidationMessages();
const formState = ref({
  userId: "",
  certificateId: "",
  notes: "",
  userFirstName: "",
  userLastName: "",
  isGuestCertificate: false,
  parentAccountId: route.params.id,
} as UserCertificationFormData);

const onSelectCertificate = (id: string) => {
  formState.value.certificateId = id;
  certificatesStore.fetchAvailableUsers(formState.value.parentAccountId, id);
};

const onChangeGuest = () => {
  if (formState.value.isGuestCertificate) {
    formState.value.userId = "";
  } else {
    formState.value.userFirstName = "";
    formState.value.userLastName = "";
  }
};

const onFinish = async () => {
  await certificatesStore.createUserCertification(formState.value);
  router.push(`/accounts/${route.params.id}/certifications`);
};

onBeforeMount(async () => {
  certificatesStore.getAvailableUserCertifications();
  // usersStore.setParentAccountId(route.params.id as string);
});
</script>
