<template>
  <div class="flex justify-between">
    <va-page-title>{{ t("products") }}</va-page-title>
    <div>
      <va-button
        v-if="productsTypesStore.canUpdate"
        class="mr-4"
        @click="router.push('/products/types')"
      >
        {{ t("productsTypes") }}
      </va-button>

      <va-button
        v-if="productsTypesStore.canUpdate"
        class="mr-4"
        @click="router.push('/products/features')"
      >
        {{ t("productFeatures") }}
      </va-button>
      <va-button
        :disabled="!productsStore.canCreate"
        @click="router.push('/products/create')"
      >
        <i class="rm rm-ico-e-add" style="" />
      </va-button>
    </div>
  </div>
  <va-table
    :data-source="productsStore.items"
    :table-config="tableConfig"
    :search-options="productsStore.searchOptions"
    :loading="productsStore.isLoading"
    class="w-100"
    @update="productsStore.updateSearchOptions"
  >
    <template #bodyCell="{ text, column, record }">
      <template v-if="column.dataIndex === 'name'">
        <router-link :to="`/products/${record.id}`" class="text-indigo-600">
          {{ text }}
        </router-link>
      </template>
      <template v-if="column.dataIndex === 'price'">
        <div class="text-right pr-6">
          {{ useFormatCurrency(text, record.currency) }}
        </div>
      </template>
      <template v-if="column.dataIndex === 'sku'">
        {{ text }}
        <div v-if="record.oemSKU" class="text-xs text-gray-500">
          {{ t("oemSKU") }}: {{ record.oemSKU }}
        </div>
      </template>
    </template>
  </va-table>
</template>

<script setup lang="ts">
import { onBeforeMount } from "vue";
import VaTable from "@/components/organisms/VaTable.vue";
import { tableConfig } from "@/models/product.interface";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import router from "@/router";
import { t } from "@/plugins/i18n";
import VaButton from "@/components/molecules/VaButton.vue";
import { useFormatCurrency } from "@/composables/formatters";
import { useProductsStore } from "@/stores/products";
import { useProductTypesStore } from "@/stores/productTypes";
import { useMeta } from "vue-meta";

useMeta({
  title: "Products",
});
const productsStore = useProductsStore();
const productsTypesStore = useProductTypesStore();

onBeforeMount(() => {
  productsStore.setPreSort(
    {
      columnKey: "name",
      order: "ascend",
    },
    true
  );
});
</script>
