<template>
  <auth :title="t('forgotTitle')" :sub-title="t('forgotDescription')">
    <a-form
      :model="formState"
      :validate-messages="validateMessages"
      @finish="recover"
    >
      <a-form-item
        :name="['email']"
        :label="t('signInEmail')"
        :rules="[{ type: 'email' }, { required: true }]"
      >
        <va-input v-model="formState.email" :placeholder="t('signInEmail')" />
      </a-form-item>

      <div class="flex items-center justify-between mb-6 -mt-3">
        <div class="flex items-center"></div>
        <div class="text-sm">
          <router-link
            to="/auth/signin"
            class="font-medium text-indigo-600 hover:text-indigo-500"
          >
            {{ t("forgotBack") }}
          </router-link>
        </div>
      </div>

      <div>
        <va-button
          :loading="authStore.isLoading"
          class="w-full flex justify-center"
          html-type="submit"
          :disabled="recoverSent"
        >
          {{ t("resetCta") }}
        </va-button>
      </div>
    </a-form>
  </auth>
</template>

<script setup lang="ts">
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import VaButton from "@/components/molecules/VaButton.vue";
import { useValidationMessages } from "@/composables/validation";
import VaInput from "@/components/molecules/VaInput.vue";
import Auth from "@/components/templates/Auth.vue";
import { useAuthStore } from "@/stores/auth";
import { useMeta } from "vue-meta";

useMeta({
  title: "Forgot password",
});
const validateMessages = useValidationMessages();

const { t } = useI18n();
const authStore = useAuthStore();

const recoverSent = ref(false);

const formState = reactive({
  email: "",
});

async function recover() {
  recoverSent.value =
    (await authStore.forgotPassword(formState.email)) || false;
}
</script>
