<template>
  <va-loader :loading="mfaProviders.length === 0" />
  <div class="va-card mt-6">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="va-title-s">{{ t("mfaProviders") }}</h3>
      <!--        <p class="va-subtitle">-->
      <!--          Personal details and application.-->
      <!--        </p>-->
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
      <dl class="sm:divide-y sm:divide-gray-200">
        <va-info-row
          v-for="(provider, index) in mfaProviders"
          :key="index"
          :title="provider.name"
        >
          <div v-if="provider.id === 3">
            <div class="flex">
              <va-button
                v-if="!(mfaProviderToEnable?.id === 3)"
                :loading="authStore.isLoading"
                @click="handleProvider(provider)"
              >
                {{ provider.enabled ? t("disable") : t("enable") }}
              </va-button>
              <va-input
                v-if="mfaProviderToEnable?.id === 3"
                v-model="code"
                :placeholder="t('mfaInsertCode')"
                class="mr-4"
              />
              <va-button
                v-if="mfaProviderToEnable?.id === 3"
                :loading="authStore.isLoading"
                @click="confirmProvider"
              >
                {{ t("enable") }}
              </va-button>
            </div>
            <div v-if="mfaProviderToEnable?.id === 3">
              <div class="my-3">{{ t("mfaAuthenticatorMessage") }}</div>
              <qrcode-vue
                class="d-flex justify-center"
                :value="mfaProviderToEnable.setup.deepLink"
                :size="200"
              />
            </div>
          </div>
          <div v-if="provider.id === 1">
            <div class="flex">
              <va-button
                v-if="!(mfaProviderToEnable?.id === 1)"
                :loading="authStore.isLoading"
                @click="handleProvider(provider)"
              >
                {{ provider.enabled ? t("disable") : t("enable") }}
              </va-button>
              <va-input
                v-if="mfaProviderToEnable?.id === 1"
                v-model="code"
                :placeholder="t('mfaInsertCode')"
                class="mr-4"
              />
              <va-button
                v-if="mfaProviderToEnable?.id === 1"
                :loading="authStore.isLoading"
                @click="confirmProvider"
              >
                {{ t("enable") }}
              </va-button>
            </div>
            <div v-if="mfaProviderToEnable?.id === 1">
              <div class="my-3">{{ t("mfaEmailMessage") }}</div>
            </div>
          </div>
        </va-info-row>
        <va-info-row v-if="isMfaEnabled" :title="t('mfaBackupCodes')">
          <va-button
            v-if="!showBackupCodes"
            :loading="authStore.isLoading"
            @click="displayBackupCodes"
          >
            {{ t("mfaShowBackupCodes") }}
          </va-button>

          <div v-if="showBackupCodes" class="mt-6">
            <va-loader :loading="authStore.isLoading" />
            <div class="mb-4">{{ t("mfaBackupCodesDescription") }}</div>
            <div v-for="(backupCode, index) in backupCodes" :key="index">
              {{ backupCode }}
            </div>
          </div></va-info-row
        >
      </dl>
    </div>
  </div>

  <!--  <div>-->
  <!--    <h2 class="text-2xl py-4">MFA Providers</h2>-->
  <!--    <va-loader :loading="mfaProviders.length === 0" />-->
  <!--    <div v-for="(provider, index) in mfaProviders" :key="index">-->
  <!--      {{ provider.name }}-->
  <!--      <va-button-->
  <!--        :loading="authStore.isLoading"-->
  <!--        @click="handleProvider(provider)"-->
  <!--      >-->
  <!--        {{ provider.enabled ? "disable" : "enable" }}-->
  <!--      </va-button>-->
  <!--    </div>-->
  <!--    <div v-if="mfaProviderToEnable" class="border p-4">-->
  <!--      <dl class="grid grid-cols-1 gap-5 sm:grid-cols-2">-->
  <!--        <div v-if="mfaProviderToEnable.id === 3">-->
  <!--          scan the qr code and magic magic magic-->
  <!--          <qrcode-vue-->
  <!--            class="d-flex justify-center"-->
  <!--            :value="mfaProviderToEnable.setup.deepLink"-->
  <!--            :size="200"-->
  <!--          />-->
  <!--        </div>-->
  <!--        <div v-if="mfaProviderToEnable.id === 1">-->
  <!--          check your email and super bla bla-->
  <!--        </div>-->
  <!--        <div>-->
  <!--          <div class="flex">-->
  <!--            <input-->
  <!--              id="authenticatorCode"-->
  <!--              v-model="code"-->
  <!--              name="code"-->
  <!--              class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"-->
  <!--            />-->
  <!--            <va-button-->
  <!--              :loading="authStore.isLoading"-->
  <!--              @click="confirmProvider"-->
  <!--            >-->
  <!--              enable-->
  <!--            </va-button>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </dl>-->
  <!--    </div>-->
  <!--    <div v-if="isMfaEnabled" class="mt-6">-->
  <!--      <va-button-->
  <!--        v-if="!showBackupCodes"-->
  <!--        :loading="authStore.isLoading"-->
  <!--        @click="displayBackupCodes"-->
  <!--      >-->
  <!--        {{ t("mfaShowBackupCodes") }}-->
  <!--      </va-button>-->

  <!--      <div v-if="showBackupCodes" class="mt-6">-->
  <!--        <va-loader :loading="authStore.isLoading" />-->
  <!--        <div class="mb-4">{{ t("mfaBackupCodesDescription") }}</div>-->
  <!--        <div v-for="(backupCode, index) in backupCodes" :key="index">-->
  <!--          {{ backupCode }}-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { MfaProvider } from "@/models/mfaProvider.interrface";
import QrcodeVue from "qrcode.vue";
import VaLoader from "@/components/atoms/VaLoader.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import { useI18n } from "vue-i18n";
import VaInfoRow from "@/components/atoms/VaInfoRow.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import { useAuthStore } from "@/stores/auth";

const { t } = useI18n();
const authStore = useAuthStore();
const mfaProviders = computed(() => authStore.mfaProviders);
const isMfaEnabled = computed(() => authStore.isMfaEnabled);
const backupCodes = computed(() => authStore.mfaBackupCodes);
const mfaProviderToEnable = computed(() => authStore.mfaProviderToEnable);
onBeforeMount(() => {
  authStore.fetchAvailableProviders();
});

function handleProvider(provider: MfaProvider) {
  provider.enabled
    ? authStore.disableProvider(provider)
    : authStore.enableProvider(provider);
}

const code = ref("");
const showBackupCodes = ref(false);

const displayBackupCodes = () => {
  authStore.fetchMfaBackupCodes();
  showBackupCodes.value = true;
};

const confirmProvider = () => {
  authStore.confirmProvider(code.value);
};
</script>
