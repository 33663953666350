<template>
  <span>
    <va-button :disabled="disabled" @click="confirmDeleteDialog = true">
      <i class="rm rm-ico-bin" />
    </va-button>

    <va-confirm
      v-model="confirmDeleteDialog"
      :title="confirmTitle"
      @click:confirm="emit('click:confirm')"
    >
      {{ confirmText }}
    </va-confirm>
  </span>
</template>

<script setup lang="ts">
import { ref } from "vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaConfirm from "@/components/molecules/VaConfirm.vue";

const confirmDeleteDialog = ref(false);

defineProps({
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  confirmText: {
    type: String,
    required: true,
    default: "",
  },
  confirmTitle: {
    type: String,
    required: true,
    default: "",
  },
});

const emit = defineEmits(["click:confirm"]);
</script>
