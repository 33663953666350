export type possibleStatus =
  | "unknown"
  | "pending"
  | "approved"
  | "canceled"
  | "revoked";

export interface OrderDetails {
  source: "unknown" | "email" | "api" | "manual";
  sourceData: string;
  status: possibleStatus;
  creationDate: Date;
  updateDate: Date;
  createdBy: string;
}

export const statusColors = (status: possibleStatus): string => {
  switch (status) {
    case "approved":
      return `success`;
    case "pending":
      return `warning`;
    default:
      return `error`;
  }
};

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): OrderDetails {
    return {
      source: input.source || "Unknown",
      sourceData: input.sourceData || "",
      status: input.orderStatus || "Unknown",
      updateDate: new Date(input.updateDate),
      creationDate: new Date(input.creationDate),
      createdBy: input.createdBy || "",
    };
  },
  serialize(input: OrderDetails): unknown {
    return {
      source: input.source,
      sourceData: input.sourceData,
      orderStatus: input.status,
      updateDate: input.updateDate.getTime(),
      creationDate: input.creationDate.getTime(),
      createdBy: input.createdBy,
    };
  },
};
