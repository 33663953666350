<template>
  <div class="flex">
    <div class="mr-4">
      <div class="text-right mb-3">
        <va-select
          v-model="query.products"
          :options="statsStore.productOptions"
          :placeholder="t('product')"
          :not-found-content="t('noProduct')"
          class="w-60"
          multiple
          @change="statsStore.updateStatsQuery(query)"
        >
          <template #option="option">
            <div>{{ option.nestedProps.fullItem.productName }}</div>
            <div class="text-xs text-gray-500 flex justify-between">
              {{ option.nestedProps.fullItem.sku || " - " }}
            </div>
          </template>
        </va-select>
      </div>
    </div>
    <div>
      <div class="text-right mb-3">
        <va-select
          v-if="account"
          v-model="query.accountId"
          :placeholder="t('account')"
          class="w-60"
          :options="accountStore.currentNetworkOptions"
          :disabled="accountStore.isLoading"
          @change="statsStore.updateStatsQuery(query)"
        >
        </va-select>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/stores/auth";
import { computed, onBeforeMount, ref, watch } from "vue";
import { useAccountsStore } from "@/stores/accounts";
import VaSelect from "@/components/molecules/VaSelect.vue";
import { useStatsStore } from "@/stores/stats";

const authStore = useAuthStore();
const accountStore = useAccountsStore();

const account = computed(() => authStore.userAccount);
const statsStore = useStatsStore();
const { t } = useI18n();

const query = ref(statsStore.query);
const updateId = (id?: string) => {
  if (id) {
    query.value.accountId = id;
    statsStore.updateStatsQuery(query.value);
    // accountStore.get(id);
    accountStore.fetchChildren();
  }
};

watch(account, (currentValue) => {
  updateId(currentValue?.id);
});

onBeforeMount(() => {
  updateId(account.value?.id);
});
</script>
