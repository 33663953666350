import { defineStore } from "pinia";
import logger from "@/services/loggerService";
import { t } from "@/plugins/i18n";
import * as listable from "./listableMixin";
import * as permissions from "./permissionMixin";
import { cloneDeep } from "lodash";
import { defaultSearchOptions } from "./listableMixin";
import { useNotificationsStore } from "@/stores/notifications";
import * as onPremEnvService from "@/services/onPremEnvService";
import { buildOption, SelectOption } from "@/models/selectOptions.interface";
import router from "@/router";
import { OnPremEnvironment } from "@/models/onPremEnvironment.interface";

export interface OnPremEnvironmentState
  extends listable.ListableMixinState,
    permissions.PermissionsMixinState {
  items: OnPremEnvironment[];
  currentEnvironment?: OnPremEnvironment;
}

export const useOnPremEnvironmentStore = defineStore({
  id: "OnPremiseEnvironments",
  state: (): OnPremEnvironmentState => ({
    isLoading: false,
    searchOptions: cloneDeep(defaultSearchOptions),
    moduleName: "OnPremiseEnvironments",
    items: [],
    currentEnvironment: undefined,
    searchError: t("onPremEnvFetchError"),
    searchFnc: onPremEnvService.search,
  }),
  getters: {
    ...permissions.getters,
    onPremEnvOptions: (state): SelectOption<OnPremEnvironment>[] => {
      return state.items.map((item) => {
        return buildOption<OnPremEnvironment>(item, "id", "customerName");
      });
    },
  },
  actions: {
    ...listable.actions,
    setCurrentOnPremEnv(env: OnPremEnvironment | undefined) {
      this.currentEnvironment = env;
    },
    async getEnv(id: string) {
      if (!id || this.currentEnvironment?.id === id) return;
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        this.currentEnvironment = await onPremEnvService.get(id);
      } catch (err) {
        logger.error(err);
        notifications.error(t("onPremEnvFetchError"));
      } finally {
        this.isLoading = false;
      }
    },
    async removeEnv(env: OnPremEnvironment) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await onPremEnvService.remove(env.id);
        this.items = this.items.filter((listItem) => {
          return listItem.id != env.id;
        });
        notifications.success(t("onPremEnvDeleteSuccess"));
        router.push(`/accounts/${env.accountId}/environments`);
      } catch (err) {
        logger.error(err);
        notifications.error(t("onPremEnvDeleteError"));
      } finally {
        this.isLoading = false;
      }
    },
    async fetchEnvByParentId(id: string) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        this.items = await onPremEnvService.fetchAllByParentId(id);
      } catch (err) {
        logger.error(err);
        notifications.error(t("onPremEnvFetchError"));
      } finally {
        this.isLoading = false;
      }
    },
    async saveEnv(env: OnPremEnvironment) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await onPremEnvService.put(env);
        this.currentEnvironment = env;
        notifications.success(t("onPremEnvSaveSuccess"));
        router.push(`/environments/${env.id}`);
      } catch (err) {
        logger.error(err);
        notifications.error(t("onPremEnvSaveError"));
      } finally {
        this.isLoading = false;
      }
    },
    async createEnv(env: OnPremEnvironment) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        const id = await onPremEnvService.post(env);
        this.currentEnvironment = undefined; //force get of the full item on the next load
        notifications.success(t("onPremEnvSaveSuccess"));
        router.push(`/environments/${id}`);
      } catch (err) {
        logger.error(err);
        notifications.error(t("onPremEnvSaveError"));
      } finally {
        this.isLoading = false;
      }
    },
  },
});
