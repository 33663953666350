import { defineStore } from "pinia";
import logger from "@/services/loggerService";
import { t } from "@/plugins/i18n";
import * as listable from "./listableMixin";
import * as permissions from "./permissionMixin";
import { cloneDeep } from "lodash";
import { defaultListableState } from "./listableMixin";
import { useNotificationsStore } from "@/stores/notifications";
import * as certificationService from "@/services/certificationsService";
import {
  AccountCertification,
  Certification,
  CertificationType,
  CertificationUser,
  UserCertification,
  UserCertificationFormData,
} from "@/models/certification.interface";
import { buildOption, SelectOption } from "@/models/selectOptions.interface";

export interface CertificatesState
  extends listable.ListableMixinState,
    permissions.PermissionsMixinState {
  accountCertifications: AccountCertification[];
  availableAccountCertifications: Certification[];
  availableUserCertifications: Certification[];
  currentUserCertification?: UserCertification;
  currentAccountCertification?: AccountCertification;
  currentCertification?: Certification;
  userOptions: CertificationUser[];
}

export const useCertificatesStore = defineStore({
  id: "certificates",
  state: (): CertificatesState => ({
    ...cloneDeep(defaultListableState),
    // isLoading: false,
    // searchOptions: searchOptions,
    moduleName: "Certificates",
    accountCertifications: [],
    availableAccountCertifications: [],
    availableUserCertifications: [],
    currentUserCertification: undefined,
    currentAccountCertification: undefined,
    currentCertification: undefined,
    searchError: t("certificatesFetchError"),
    searchFnc: certificationService.searchUserCertifications,
    userOptions: [],
    // items: [],
  }),
  getters: {
    ...permissions.getters,
    availableAccountCertificationsOptions: (
      state
    ): SelectOption<Certification>[] => {
      return state.availableAccountCertifications.map((item) => {
        return buildOption<Certification>(item, "id", "description");
      });
    },
    availableUserCertificationsOptions: (
      state
    ): SelectOption<Certification>[] => {
      return state.availableUserCertifications.map((item) => {
        return buildOption<Certification>(item, "id", "description");
      });
    },
  },
  actions: {
    ...listable.actions,

    async getCertificateById(id: string, type: CertificationType) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        if (type == "Account") {
          this.currentAccountCertification =
            await certificationService.getAccountCertificateById(id);
        } else {
          this.currentCertification =
            await certificationService.getCertificateById(id);
        }
      } catch (err) {
        logger.error(err);
        notifications.error(t("certificatesFetchError"));
      } finally {
        this.isLoading = false;
      }
    },
    async fetchAvailableUsers(accountId: string, certificateId: string) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        this.userOptions = await certificationService.fetchAvailableUsers(
          accountId,
          certificateId
        );
      } catch (err) {
        logger.error(err);
        notifications.error(t("certificatesFetchError"));
      } finally {
        this.isLoading = false;
      }
    },
    async getAvailableAccountCertifications() {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        this.availableAccountCertifications =
          await certificationService.fetchAvailableAccountCertifications();
      } catch (err) {
        logger.error(err);
        notifications.error(t("certificatesFetchError"));
      } finally {
        this.isLoading = false;
      }
    },
    async getAvailableUserCertifications() {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        this.availableUserCertifications =
          await certificationService.fetchAvailableUserCertifications();
      } catch (err) {
        logger.error(err);
        notifications.error(t("certificatesFetchError"));
      } finally {
        this.isLoading = false;
      }
    },
    async createAccountCertification(
      accountId: string,
      certificateId: string,
      notes = ""
    ) {
      const notifications = useNotificationsStore();
      this.isLoading = true;
      try {
        const result = await certificationService.createAccountCertification(
          accountId,
          certificateId,
          notes
        );
        notifications.success("", t("createAccountCertification"));
        const res = await certificationService.getAccountCertificationById(
          result
        );
        this.accountCertifications.push(res);
      } catch (err) {
        logger.error(err);
        notifications.error("", t("duplicateAccountCertification"));
      } finally {
        this.isLoading = false;
      }
    },
    async getAccountCertifications(id: string) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        this.accountCertifications =
          await certificationService.getAccountCertifications(id);
      } catch (err) {
        logger.error(err);
        notifications.error(t("certificatesDeletionError"));
      } finally {
        this.isLoading = false;
      }
    },
    async createUserCertification(data: UserCertificationFormData) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await certificationService.createUserCertification(data);
      } catch (err) {
        logger.error(err);
        notifications.error("", t("duplicateUserCertification"));
      } finally {
        this.isLoading = false;
      }
    },
    async getUserCertification(id: string) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        this.currentUserCertification =
          await certificationService.getUserCertification(id);
      } catch (err) {
        logger.error(err);
        notifications.error(t("certificatesFetchError"));
      } finally {
        this.isLoading = false;
      }
    },
    async deleteCertification(id: string, type: CertificationType) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await certificationService.deleteCertification(id, type);
        notifications.success(t("deleteUserCertification"));
        if (type == "User") {
          this.items = this.items.filter(
            (userCertificate: UserCertification) => {
              if (userCertificate.idUserCertificate != id) {
                return userCertificate;
              }
            }
          );
        } else {
          this.accountCertifications = this.accountCertifications.filter(
            (cert) => {
              return cert.idAccountCertificate != id;
            }
          );
        }
      } catch (err) {
        logger.error(err);
        notifications.error(t("certificatesFetchError"));
      } finally {
        this.isLoading = false;
      }
    },
    async updateCertificationsNotes(
      id: string,
      notes: string,
      type: CertificationType
    ) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        certificationService.updateCertificationNotes(id, notes, type);

        notifications.success(t("updateUserCertificationNotes"));
        if (type == "User" && this.currentUserCertification) {
          this.currentUserCertification.notes = notes;
        } else if (type == "Account" && this.currentAccountCertification) {
          this.currentAccountCertification.notes = notes;
        }
      } catch (err) {
        logger.error(err);
        notifications.error(t("certificatesUpdateError"));
      } finally {
        this.isLoading = false;
      }
    },
    async renewCertification(id: string, type: CertificationType) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await certificationService.renewCertification(id, type);
        notifications.success(t("renewUserCertification"));
        type == "Account"
          ? this.getCertificateById(id, "Account")
          : this.getUserCertification(id);
      } catch (err) {
        logger.error(err);
        notifications.error(t("renewCertificateError"));
      } finally {
        this.isLoading = false;
      }
    },
  },
});
