<template>
  <div v-if="order" id="orderDetail">
    <div class="va-card">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="va-title-s">{{ t("order") }} {{ order.code }}</h3>
        <p class="va-subtitle mb-4">
          <span class="mr-2"> {{ t("creationDate") }}: </span
          ><span class="mr-4"> {{ formatDate(order.creationDate) }}</span>
        </p>
        <va-back-link target="/sell-in">
          {{ t("backToOrders") }}
        </va-back-link>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div class="sm:col-span-2">
            <!--            <dt class="text-sm font-medium text-gray-500">{{ t("order") }}</dt>-->
            <dl class="sm:divide-y sm:divide-gray-200">
              <va-info-row :title="t('vendor')">
                {{ order?.vendor?.name }}
              </va-info-row>
              <va-info-row :title="t('buyer')">
                {{ order?.buyer?.name }}
              </va-info-row>
              <va-info-row :title="t('status')">
                <div class="flex justify-between">
                  <div class="mr-8 self-center grow">{{ t(order.status) }}</div>
                  <div v-if="order.status === 'pending' && canApprove">
                    <va-button
                      class="mr-4"
                      :disabled="ordersStore.isLoading"
                      @click="approve"
                    >
                      {{ t("approve") }}
                    </va-button>
                    <va-button @click="cancel">{{ t("cancel") }}</va-button>
                  </div>
                  <div v-if="order.status === 'approved' && canApprove">
                    <va-button :disabled="ordersStore.isLoading" @click="revoke"
                      >{{ t("revoke") }}
                    </va-button>
                  </div>
                </div>
              </va-info-row>
              <stock-error />
              <va-info-row :title="t('invoiced')">
                <div class="flex justify-between">
                  <va-check-icon
                    :check="order.isInvoiced"
                    class="mr-8 self-center"
                  />
                  <va-button
                    v-if="canApprove"
                    :disabled="ordersStore.isLoading"
                    @click="toggleInvoiced"
                    >{{ t("change") }}
                  </va-button>
                </div>
              </va-info-row>
              <va-info-row :title="t('paid')">
                <div class="flex justify-between">
                  <va-check-icon
                    :check="order.isPaid"
                    class="mr-8 self-center"
                  />
                  <va-button
                    v-if="canApprove"
                    :disabled="ordersStore.isLoading"
                    @click="togglePaid"
                  >
                    {{ t("change") }}
                  </va-button>
                </div>
              </va-info-row>
              <va-info-row :title="t('refCode')">
                <div class="flex justify-between">
                  {{ order.refCode }}
                  <va-button
                    v-if="canApprove"
                    :disabled="ordersStore.isLoading"
                    @click="openCodeRefDialog"
                  >
                    {{ t("change") }}
                  </va-button>
                </div>
              </va-info-row>
              <va-info-row :title="t('notes')">
                <div class="border rounded p-3 font-sans">
                  {{ order.notes }}
                </div>
                <va-button
                  v-if="canApprove"
                  :disabled="ordersStore.isLoading"
                  class="float-right mt-2"
                  @click="openNotesDialog"
                >
                  {{ t("change") }}
                </va-button>
              </va-info-row>
            </dl>
          </div>
        </dl>
      </div>
    </div>

    <div v-if="order.items" class="va-card px-4 py-5 sm:col-span-2 mt-6">
      <h3 class="va-title-s">{{ t("orderItems") }}</h3>
      <dd class="mt-1 text-sm text-gray-900">
        <div class="overflow-scroll">
          <table class="min-w-full divide-y divide-gray-300 mt-4">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {{ t("product") }}
                </th>
                <!--              <th-->
                <!--                scope="col"-->
                <!--                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"-->
                <!--              >-->
                <!--                {{ t("description") }}-->
                <!--              </th>-->
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {{ t("priceList") }}
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right"
                >
                  {{ t("quantity") }}
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right"
                >
                  {{ t("fullPrice") }}
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right"
                >
                  {{ t("orderPrice") }}
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right"
                >
                  {{ t("total") }}
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-for="(item, index) in order.items" :key="index">
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                  {{ item.name }}
                  <div
                    class="text-ellipsis overflow-hidden max-w-md text-sm text-gray-500"
                  >
                    {{ item.sku }}
                  </div>
                </td>
                <!--              <td class="whitespace-nowrap px-3 py-4 text-sm">-->
                <!--                <div class="text-ellipsis overflow-hidden max-w-xs">-->
                <!--                  {{ item.description }}-->
                <!--                </div>-->
                <!--              </td>-->
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                  <div class="text-ellipsis overflow-hidden max-w-xs">
                    {{ item.priceListName }}
                  </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                  <div class="text-right">
                    {{ item.quantity }}
                  </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                  <div class="text-right text-gray-500 line-through">
                    {{ useFormatCurrency(item.priceListPrice, item.currency) }}
                  </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                  <div class="text-right">
                    {{ useFormatCurrency(item.price, item.currency) }}
                  </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                  <div class="text-right">
                    {{
                      useFormatCurrency(
                        item.price * item.quantity,
                        item.currency
                      )
                    }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="flex flex-row-reverse">
          <h3 class="va-title-s mt-4">
            {{ t("orderTotal") }}:
            {{ useFormatCurrency(order.total, order.currency) }}
          </h3>
        </div>
      </dd>
    </div>

    <div v-if="order.details" class="va-card px-4 py-5 sm:col-span-2 mt-6">
      <h3 class="va-title-s">{{ t("orderHistory") }}</h3>
      <dd class="mt-1 text-sm text-gray-900">
        <table class="min-w-full divide-y divide-gray-300 mt-4">
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {{ t("source") }}
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {{ t("status") }}
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {{ t("creationDate") }}
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {{ t("updateDate") }}
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-for="(detail, index) in order.details" :key="index">
              <td class="whitespace-nowrap px-3 py-4 text-sm">
                {{ detail.source }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm">
                {{ detail.status }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm">
                {{ formatDate(detail.creationDate) }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm">
                {{ formatDate(detail.updateDate) }}
              </td>
            </tr>
          </tbody>
        </table>
      </dd>
    </div>

    <va-dialog v-model="refCodeDialog">
      <div class="flex justify-between">
        <va-input
          v-model="refCode"
          :placeholder="t('refCode')"
          class="mr-4 grow"
        />
        <va-button
          class="mr-4"
          :disabled="ordersStore.isLoading"
          @click="refCodeDialog = false"
        >
          {{ t("dismiss") }}
        </va-button>
        <va-button :loading="ordersStore.isLoading" @click="updateCodeRef">
          {{ t("save") }}
        </va-button>
      </div>
    </va-dialog>

    <va-dialog v-model="notesDialog">
      <va-input v-model="notes" type="textarea" :placeholder="t('notes')" />
      <div class="flex justify-end mt-4">
        <va-button
          class="mr-4"
          :disabled="ordersStore.isLoading"
          @click="notesDialog = false"
        >
          {{ t("dismiss") }}
        </va-button>
        <va-button :loading="ordersStore.isLoading" @click="updateNotes">
          {{ t("save") }}
        </va-button>
      </div>
    </va-dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import { t } from "@/plugins/i18n";
import VaInfoRow from "@/components/atoms/VaInfoRow.vue";
import { useFormatDate, useFormatCurrency } from "@/composables/formatters";
import VaCheckIcon from "@/components/atoms/VaCheckIcon.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaDialog from "@/components/molecules/VaDialog.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import StockError from "@/components/organisms/StockError.vue";
import { useOrdersStore } from "@/stores/orders";
import { useAuthStore } from "@/stores/auth";
import { useAccountsStore } from "@/stores/accounts";
import { useMeta } from "vue-meta";

useMeta({
  title: "Orders",
});
defineProps({
  sellIn: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const route = useRoute();
const ordersStore = useOrdersStore();
const authStore = useAuthStore();
const accountStore = useAccountsStore();
const formatDate = useFormatDate;

const order = computed(() => ordersStore.currentOrder);
const account = computed(() => authStore.userAccount);
// const isVendor = computed(
//   () => authStore.userAccount?.id === ordersStore.currentOrder?.vendor?.id
// );
const canApprove = computed(() => {
  return (
    account.value?.id !== order.value?.buyer?.id ||
    authStore.userAccount?.id === ordersStore.currentOrder?.vendor?.id
  );
});
const refCodeDialog = ref(false);
const refCode = ref("");
const notesDialog = ref(false);
const notes = ref("");

const approve = () => {
  ordersStore.updateStatus("approved");
};
const cancel = () => {
  ordersStore.updateStatus("canceled");
};
const revoke = () => {
  ordersStore.updateStatus("revoked");
};
const toggleInvoiced = () => {
  ordersStore.updateInvoiceStatus(!order.value?.isInvoiced);
};
const togglePaid = () => {
  ordersStore.updatePaidStatus(!order.value?.isPaid);
};
const updateCodeRef = async () => {
  ordersStore.updateCodeRef(refCode.value);
  refCodeDialog.value = false;
};
const updateNotes = async () => {
  ordersStore.updateNotes(notes.value);
  notesDialog.value = false;
};

const openNotesDialog = () => {
  notesDialog.value = true;
  notes.value = order.value?.notes || "";
};
const openCodeRefDialog = () => {
  refCodeDialog.value = true;
  refCode.value = order.value?.refCode || "";
};

onBeforeMount(() => {
  ordersStore.get(route.params.id as string);
  accountStore.fetchChildren();
  ordersStore.cleanStockErrors();
});
</script>
