<template>
  <div class="flex justify-between mt-8">
    <va-page-title>{{ t("salesNetwork") }}</va-page-title>
    <div>
      <span>{{ t("includeChildren") }}: </span>
      <a-switch
        v-model:checked="includeChildren"
        @change="onIncludeChildrenChange"
      />
      <va-button
        class="ml-4"
        :disabled="!accountStore.canCreate"
        :loading="accountStore.isLoading"
        @click="router.push(`/accounts/${route.params.id}/create`)"
      >
        <i class="rm rm-ico-e-add" />
      </va-button>
    </div>
  </div>
  <va-table
    v-if="account"
    :data-source="accountStore.items"
    :table-config="tableConfig"
    :search-options="accountStore.searchOptions"
    :loading="accountStore.isLoading"
    class="w-100"
    @update="accountStore.updateSearchOptions"
  >
    <template #bodyCell="{ column, text, record }">
      <template v-if="column.dataIndex === 'name'">
        <router-link :to="`/accounts/${record.id}`" class="text-indigo-600">
          {{ text }}
        </router-link>
      </template>
      <template v-if="column.dataIndex === 'certificatesBadges'">
        <a-tooltip
          v-for="badge in record.certificatesBadges"
          :key="badge.category"
        >
          <template #title>{{ badge.name }} - {{ badge.owners }}</template>
          <a-tag :color="badge.badgeColor" class="certificate-badge mr-1">
            {{ badge.category }}
          </a-tag>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'address'">
        {{ formatAddress(record.address) }}
      </template>
      <template v-if="column.dataIndex === 'group'">
        {{ t(record.group.name) }}
      </template>
    </template>
  </va-table>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import VaTable from "@/components/organisms/VaTable.vue";
import { tableConfig } from "@/models/account.interface";
import { useFormatAddressShort } from "@/composables/formatters";
import router from "@/router";
import VaButton from "@/components/molecules/VaButton.vue";
import { useAccountsStore } from "@/stores/accounts";
import { computed, onBeforeMount, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { defaultPagination } from "@/services/configService";

const route = useRoute();
const formatAddress = useFormatAddressShort;
const { t } = useI18n();
const accountStore = useAccountsStore();
const account = computed(() => accountStore.currentAccount);
const includeChildren = ref(accountStore.searchOptions.includeChildren);
const searchOptions = computed(() => accountStore.searchOptions);

function onIncludeChildrenChange() {
  searchOptions.value.includeChildren = includeChildren.value;
  searchOptions.value.pagination = defaultPagination;
  accountStore.updateSearchOptions(searchOptions.value);
}

const fetchByParentId = () => {
  if (
    route.params.id &&
    route.params.id !== accountStore.searchOptions.parentAccountId
  ) {
    accountStore.resetSearchOptions(true);
    includeChildren.value = searchOptions.value.includeChildren;
    accountStore.setParentAccountId(route.params.id as string);
  }
};

watch(route, fetchByParentId);
onBeforeMount(fetchByParentId);
</script>

<style lang="scss">
.certificate-badge {
  &.ant-tag {
    color: white !important;
  }
}
</style>
