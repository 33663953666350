export interface ProviderSetup {
  issuer: string;
  account: string;
  encodedSecret: string;
  deepLink: string;
}

// export const providerTypes = {
//   email: 1,
//   backup: 4,
//   authenticator: 3,
// };

export interface MfaProvider {
  id: number;
  setup: ProviderSetup;
  name: string;
  enabled: boolean;
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): MfaProvider {
    return {
      setup: input.providerSetup || undefined,
      name: input.name,
      id: input.providerType,
      enabled: false,
    };
  },
  serialize(input: MfaProvider): unknown {
    return {
      providerSetup: input.setup,
      name: input.name,
      providerType: input.id,
    };
  },
};
