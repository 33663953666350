import { TableConfig } from "@/models/listable.interface";
import logger from "@/services/loggerService";

export enum FeatureTypeDecoder {
  "unknown",
  "double",
  "bool",
  "string",
  "object",
  "dateTime",
  "int",
}

export const possibleFeatureTypeNames = Object.keys(FeatureTypeDecoder).filter(
  (v) => isNaN(Number(v))
);

export const possibleFeatureTypes = Object.keys(FeatureTypeDecoder).filter(
  (v) => !isNaN(Number(v))
);
// .map(Number);

export type productFeatureType = typeof possibleFeatureTypes[number];
export type productFeatureTypeNames = typeof possibleFeatureTypeNames[number];
export type productFeatureValue = boolean | string | Date | number | unknown;

export interface ProductFeature {
  id: string;
  value?: productFeatureValue;
  name: string;
  description: string;
  typeName?: productFeatureTypeNames;
  type: productFeatureType;
}

export const tableConfig = {
  name: {
    dtoName: "name",
    sortable: false,
    filterable: false,
    label: "name",
  },
  description: {
    dtoName: "featureDescription",
    sortable: false,
    filterable: false,
    label: "description",
  },
  typeName: {
    dtoName: "typeName",
    sortable: false,
    filterable: false,
    label: "type",
  },
  id: {
    dtoName: "productTypeId",
    sortable: true,
    filterable: true,
    label: "",
    hideColumn: false,
  },
} as TableConfig;

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  deserialize(input: any): ProductFeature {
    let typeName = "unknown" as productFeatureTypeNames;
    let value = {} as productFeatureValue;
    const type = input.featureTypeId?.toString() as productFeatureType;
    switch (type) {
      case "2":
        typeName = "bool";
        value = input.featureValue || false;
        break;
      case "3":
        typeName = "string";
        value = input.featureValue || "";
        break;
      case "1":
        typeName = "double";
        value = input.featureValue || 0;
        break;
      case "6":
        typeName = "int";
        value = input.featureValue || 0;
        break;
      case "4":
        typeName = "object";
        value = input.featureValue || {};
        break;
      case "5":
        typeName = "dateTime";
        value = new Date(input.featureValue);
        break;
      default:
        logger.info(
          `ProductFeature deserializer: unhandled featureType "${type}" for feature: ${input.featureId}`
        );
    }

    return {
      id: input.featureId,
      name: input.featureName,
      description: input.featureDescription,
      typeName: typeName,
      type: type,
      value: value,
    };
  },

  serialize(input: ProductFeature): unknown {
    return {
      featureId: input.id,
      featureTypeName: input.typeName,
      featureName: input.name,
      featureDescription: input.description,
      featureTypeId: input.type,
      featureValue: input.value,
    };
  },
};
