<template>
  <div class="flex justify-between">
    <va-page-title>{{ t("sellOut") }}</va-page-title>
    <div class="text-right">
      <div>
        <span>{{ t("onlyMyOrders") }}: </span>
        <a-switch
          v-model:checked="excludeChildren"
          @change="onIncludeChildrenChange"
        />
        <va-button
          class="ml-4"
          :disabled="!ordersOutStore.canCreate"
          @click="router.push('/sell-out/create')"
        >
          <i class="rm rm-ico-e-add" />
        </va-button>
      </div>
      <div class="mt-4 flex items-center">
        <span class="text-gray-500">{{ t("filterCreationDate") }}</span>
        <div class="ml-4">
          <va-date-picker
            v-model="dateRanges[0].fromDate"
            :placeholder="t('from')"
            day-target="start"
            @change="onFilterDateChange"
          />
        </div>
        <div class="ml-4">
          <va-date-picker
            v-model="dateRanges[0].toDate"
            :placeholder="t('to')"
            day-target="end"
            @change="onFilterDateChange"
          />
        </div>
      </div>
    </div>
  </div>
  <va-table
    :data-source="ordersOutStore.items"
    :table-config="tableConfig"
    :search-options="searchOptions"
    :loading="ordersOutStore.isLoading"
    class="w-100"
    @update="ordersOutStore.updateSearchOptions"
  >
    <template #bodyCell="{ column, text, record }">
      <template v-if="column.dataIndex === 'code'">
        <router-link :to="`/sell-out/${record.id}`" class="text-indigo-600">
          {{ text }}
        </router-link>
      </template>
      <template v-if="column.dataIndex === 'buyer'">
        {{ text.name }}
      </template>
      <template v-if="column.dataIndex === 'vendor'">
        {{ text.name }}
      </template>
      <template v-if="column.dataIndex === 'refCode'">
        {{ record.refCode }}
      </template>
      <template v-if="column.dataIndex === 'creationDate'">
        {{ formatDate(record.creationDate) }}
      </template>
      <template v-if="column.dataIndex === 'status'">
        <a-tag :color="statusColors(record.status)">
          {{ t(record.status) }}
        </a-tag>
      </template>
      <template v-if="column.dataIndex === 'isInvoiced'">
        <div class="text-center">
          <va-check-icon :check="text" />
        </div>
      </template>
      <template v-if="column.dataIndex === 'isPaid'">
        <div class="text-center">
          <va-check-icon :check="text" />
        </div>
      </template>
      <template v-if="column.dataIndex === 'total'">
        <div class="text-right">
          {{ useFormatCurrency(text, record.currency) }}
        </div>
      </template>
    </template>
    <template #footer>
      <div class="text-right font-bold">
        {{ t("total") }}:
        {{ useFormatCurrency(total, ordersOutStore.items[0]?.currency) }}
      </div>
    </template>
  </va-table>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import VaTable from "@/components/organisms/VaTable.vue";
import { Order, tableConfig } from "@/models/order.interface";
import { FilterDateRange } from "@/models/listable.interface";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import router from "@/router";
import { useI18n } from "vue-i18n";
import { useFormatDate, useFormatCurrency } from "@/composables/formatters";
import VaButton from "@/components/molecules/VaButton.vue";
import VaCheckIcon from "@/components/atoms/VaCheckIcon.vue";
import { cloneDeep } from "lodash";
import VaDatePicker from "@/components/molecules/VaDatePicker.vue";
import { useOrdersOutStore } from "@/stores/orders";
import { statusColors } from "@/models/orderDetails.interface";
import { defaultPagination } from "@/services/configService";
import { useMeta } from "vue-meta";

useMeta({
  title: "Orders Sell Out",
});
const ordersOutStore = useOrdersOutStore();
const { t } = useI18n();
const formatDate = useFormatDate;

const sellOutTableConfig = cloneDeep(tableConfig);
delete sellOutTableConfig.vendor;

const total = computed(() =>
  ordersOutStore.items.reduce(function (a: number, b: Order) {
    return a + (b.total || 0);
  }, 0)
);

const searchOptions = computed(() => ordersOutStore.searchOptions);

const excludeChildren = ref(false);
const dateRanges = ref([
  {
    field: "creationDate",
    fromDate: undefined,
    toDate: undefined,
  },
] as FilterDateRange[]);

onBeforeMount(() => {
  if (searchOptions.value.filterBetweenDate) {
    dateRanges.value = searchOptions.value.filterBetweenDate;
  }
  searchOptions.value.includeChildren = true;
  searchOptions.value.preSort = {
    columnKey: "creationDate",
    order: "descend",
  };
  ordersOutStore.updateSearchOptions(searchOptions.value, true);
});

function onFilterDateChange() {
  searchOptions.value.filterBetweenDate = dateRanges.value;
  ordersOutStore.updateSearchOptions(searchOptions.value);
}

function onIncludeChildrenChange() {
  searchOptions.value.includeChildren = !excludeChildren.value;
  searchOptions.value.pagination = defaultPagination;
  ordersOutStore.updateSearchOptions(searchOptions.value);
}
</script>
