<template>
  <a class="text-indigo-700 mb-4 cursor-pointer" @click="onClick">
    <i class="rm rm-ico-ctrl-left" />
    <slot></slot>
  </a>
</template>

<script setup lang="ts">
import router from "@/router";

const props = defineProps({
  target: {
    type: String,
    required: false,
    default: "/",
  },
});

const onClick = () => {
  if (window.history.state.back) {
    router.back();
  } else {
    router.push(props.target);
  }
};
</script>
