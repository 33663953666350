<template>
  <div v-if="type" id="productDetail" class="va-card mt-6">
    <div class="px-4 py-5 sm:px-6 flex">
      <div class="grow">
        <va-page-title> {{ type.name }}</va-page-title>
        <p class="va-subtitle mb-4">{{ type.description }}</p>

        <va-back-link target="/products/types">{{ t("back") }}</va-back-link>
      </div>

      <va-delete-button
        class="mr-4"
        :disabled="!productTypesStore.canDelete"
        :confirm-text="t('productRemoveDescription')"
        :confirm-title="t('productRemove')"
        @click:confirm="removeType"
      />

      <va-button
        :disabled="!productTypesStore.canUpdate"
        @click="router.push(`/products/types/${type?.id}/edit/`)"
      >
        <i class="rm rm-ico-edit" />
      </va-button>
    </div>
  </div>

  <div v-if="type && type.features.length" class="va-card mt-6 p-4 sm:px-6">
    <product-features-table :features="type.features" class="mt-3" />
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import { t } from "@/plugins/i18n";
import VaButton from "@/components/molecules/VaButton.vue";
import router from "@/router";
import VaDeleteButton from "@/components/molecules/VaDeleteButton.vue";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import ProductFeaturesTable from "@/components/organisms/ProductFeaturesTable.vue";
import { useProductTypesStore } from "@/stores/productTypes";
import { useMeta } from "vue-meta";

useMeta({
  title: "Products",
});
const route = useRoute();
const productTypesStore = useProductTypesStore();
const type = computed(() => productTypesStore.currentType);

onBeforeMount(() => {
  productTypesStore.getType(route.params.id as string);
});

const removeType = () => {
  productTypesStore.removeType(route.params.id as string);
};
</script>
