<template>
  <div class="va-card relative">
    <div class="flex justify-between pt-4 pb-2 px-4">
      <h3 class="va-title-s">{{ t("accountFiles") }}</h3>

      <va-button v-if="filesStore.canCreate" @click="uploader.click()">
        <i class="rm rm-ico-e-add" />
        <input ref="uploader" type="file" class="hidden" @change="uploadFile" />
      </va-button>
    </div>
    <div class="flex justify-between pt-4 pb-2 px-4">
      <div class="">
        <div class="text-gray-500 mb-1">{{ t("filterUploadDate") }}</div>
        <div class="flex">
          <va-date-range-picker
            v-model="dateRange[0]"
            day-target="start"
            @change="onFiltersChange"
          />
        </div>
      </div>
    </div>

    <div class="flex justify-between mt-4"></div>
    <va-table
      :data-source="filesStore.items"
      :table-config="tableConfig"
      :search-options="filesStore.searchOptions"
      :loading="filesStore.isLoading"
      class="w-100"
      @update="filesStore.updateSearchOptions"
    >
      <template #bodyCell="{ text, column, record }">
        <template v-if="column.dataIndex === 'uploadDate'">
          {{ formatDate(text) }}
        </template>
        <template v-if="column.dataIndex === 'fileType'">
          {{ text.toUpperCase() }}
        </template>
        <template v-if="column.dataIndex === 'fileSize'">
          {{ formatBytes(text) }}
        </template>
        <template v-if="column.dataIndex === 'fileNote'">
          <a-tooltip
            v-if="text.length > 80"
            placement="top"
            class="cursor-pointer"
          >
            <template #title>{{ text }}</template>
            {{ truncate(text, 80) }}
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'uploadedByAccountName'">
          {{ record.uploadedByAccountName }}
          <div class="text-xs text-gray-500">
            {{ record.uploadedByUserFullName }}
          </div>
        </template>
        <template v-if="column.dataIndex === 'actions'">
          <div class="flex">
            <va-delete-button
              :disabled="!filesStore.canDelete"
              class="mr-2"
              :confirm-text="t('fileRemoveDescription')"
              :confirm-title="t('fileRemove')"
              @click:confirm="filesStore.delete(record.accountFileId)"
            />
            <va-button
              class="mr-2"
              :disabled="!filesStore.canUpdate"
              @click="openEditDialog(record)"
            >
              <i class="rm rm-ico-edit" />
            </va-button>
            <va-button @click="filesStore.download(record.accountFileId)">
              {{ t("download") }}
            </va-button>
          </div>
        </template>
      </template>
    </va-table>
    <va-dialog v-model="editDialog">
      <a-form
        :model="formState"
        :validate-messages="validateMessages"
        @finish="onEditFile"
      >
        <a-form-item
          :name="['fileName']"
          :label="t('name')"
          :rules="[{ required: true }]"
        >
          <va-input v-model="formState.fileName" :placeholder="t('name')" />
        </a-form-item>

        <a-form-item
          :name="['fileNote']"
          :label="t('notes')"
          :rules="[{ max: 255 }]"
        >
          <va-input
            v-model="formState.fileNote"
            :placeholder="t('notes')"
            type="textarea"
          />
        </a-form-item>
        <a-form-item>
          <va-button html-type="submit" class="float-right">
            {{ t("save") }}
          </va-button>
          <va-button class="float-right mr-4" @click="editDialog = false">
            {{ t("dismiss") }}
          </va-button>
        </a-form-item>
      </a-form>
    </va-dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useFormatBytes, useFormatDate } from "@/composables/formatters";
import { FileDownload, tableConfig } from "@/models/fileDownload.interface";
import VaTable from "@/components/organisms/VaTable.vue";
import { t } from "@/plugins/i18n";
import { onBeforeRouteUpdate, useRoute } from "vue-router";
import { useFileDownloadsStore } from "@/stores/fileDownloads";
import VaDeleteButton from "@/components/molecules/VaDeleteButton.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import { cloneDeep } from "lodash";
import VaDialog from "@/components/molecules/VaDialog.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import { useValidationMessages } from "@/composables/validation";
import { FilterDateRange } from "@/models/listable.interface";
import VaDateRangePicker from "@/components/molecules/VaDateRangePicker.vue";

const filesStore = useFileDownloadsStore();

const formatDate = useFormatDate;
const formatBytes = useFormatBytes;
const route = useRoute();
const validateMessages = useValidationMessages();

const editDialog = ref(false);
const uploader = ref();
const formState = ref({} as FileDownload);
const searchOptions = computed(() => filesStore.searchOptions);

const dateRange = ref([
  {
    field: "uploadDate",
    fromDate: undefined,
    toDate: undefined,
  },
] as FilterDateRange[]);

function onFiltersChange() {
  searchOptions.value.filterBetweenDate = dateRange.value;
  filesStore.updateSearchOptions(searchOptions.value);
}

onBeforeRouteUpdate(async (to, from) => {
  if (to.params.id !== from.params.id) {
    filesStore.setParentAccountId(to.params.id as string);
  }
});

onBeforeMount(() => {
  filesStore.setParentAccountId(route.params.id as string);
});

const openEditDialog = (file: FileDownload) => {
  formState.value = cloneDeep(file);
  editDialog.value = true;
};

function truncate(str: string, n = 100) {
  return str.length > n ? str.slice(0, n - 1) + "…" : str;
}

const onEditFile = async () => {
  await filesStore.edit(formState.value);
  editDialog.value = false;
};

const uploadFile = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target?.files && target.files[0]) {
    const file = target.files[0];
    filesStore.upload(file, "");
  }
};
</script>
