<template>
  <div class="flex justify-between mt-8">
    <va-page-title>{{ t("users") }}</va-page-title>
    <va-button
      :disabled="!usersStore.canCreate"
      @click="router.push(createPath)"
    >
      <i class="rm rm-ico-e-add" />
    </va-button>
  </div>
  <va-table
    :data-source="usersStore.items"
    :table-config="tableConfig"
    :search-options="usersStore.searchOptions"
    :loading="usersStore.isLoading"
    class="w-100"
    @update="usersStore.updateSearchOptions"
  >
    <template #bodyCell="{ text, column, record }">
      <template v-if="column.dataIndex === 'lastName'">
        {{ record.firstName }} {{ record.lastName }}
      </template>
      <template v-if="column.dataIndex === 'email'">
        {{ text }}
      </template>
      <template v-if="column.dataIndex === 'role'">
        <div v-for="(role, index) in record.roles" :key="index">
          {{ t(role.code) }}
        </div>
      </template>
      <template v-if="column.dataIndex === 'status'">
        <span :class="statusColors(record.status)">
          {{ t(record.status) }}
        </span>
      </template>
      <template v-if="column.dataIndex === 'isNotificationEnabled'">
        <!--        {{ record.isNotificationEnabled }}-->
        <va-check-icon :check="text" />
      </template>
      <template v-if="column.dataIndex === 'actions'">
        <va-delete-button
          class="mr-2"
          :disabled="!(usersStore.canDelete && record.id !== me?.id)"
          :confirm-text="t('userRemoveRemoveDescription')"
          :confirm-title="t('userRemove')"
          @click:confirm="usersStore.remove(record)"
        />
      </template>
    </template>
  </va-table>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, watch } from "vue";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import { useI18n } from "vue-i18n";
import VaButton from "@/components/molecules/VaButton.vue";
import router from "@/router";
import VaTable from "@/components/organisms/VaTable.vue";
import { tableConfig } from "@/models/user.interface";
import VaDeleteButton from "@/components/molecules/VaDeleteButton.vue";
import { useStatusColors } from "@/composables/colors";
import { useUsersStore } from "@/stores/users";
import { useAuthStore } from "@/stores/auth";
import VaCheckIcon from "@/components/atoms/VaCheckIcon.vue";

const statusColors = useStatusColors;
const { t } = useI18n();
const usersStore = useUsersStore();
const authStore = useAuthStore();

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  createPath: {
    type: String,
    required: true,
  },
});

watch(props, (currentValue) => {
  if (currentValue.accountId) {
    usersStore.setParentAccountId(props.accountId);
  }
});
const me = computed(() => authStore.user);

onBeforeMount(() => {
  usersStore.setParentAccountId(props.accountId);
});
</script>
