<template>
  <div class="flex justify-between">
    <va-page-title>{{ t("licenseReports") }}</va-page-title>
    <div class="text-right">
      <div>
        <span>{{ t("onlyMyLicenses") }}: </span>
        <a-switch v-model:checked="excludeChildren" @change="onFiltersChange" />
        <va-button
          :disabled="reportsStore.isLoading"
          class="ml-4"
          @click="onFiltersChange"
        >
          <i class="rm rm-ico-refresh-1" style="" />
        </va-button>
        <va-button
          :loading="reportsStore.isLoading"
          class="ml-4"
          @click="reportsStore.download"
        >
          {{ t("downloadReport") }}
        </va-button>
      </div>
    </div>
  </div>
  <div class="flex justify-between mt-4">
    <div class="">
      <div class="text-gray-500 mb-2">{{ t("filterActivationDate") }}</div>
      <div class="flex">
        <div class="mr-2">
          <va-date-picker
            v-model="dateRanges[0].fromDate"
            :placeholder="t('from')"
            day-target="start"
            @change="onFiltersChange"
          />
        </div>
        <div class="ml-2">
          <va-date-picker
            v-model="dateRanges[0].toDate"
            :placeholder="t('to')"
            day-target="end"
            @change="onFiltersChange"
          />
        </div>
      </div>
    </div>

    <div class="">
      <div class="text-gray-500 mb-2">{{ t("filterExpirationDate") }}</div>
      <div class="flex">
        <div class="mr-2">
          <va-date-picker
            v-model="dateRanges[1].fromDate"
            :placeholder="t('from')"
            day-target="start"
            @change="onFiltersChange"
          />
        </div>
        <div class="ml-2">
          <va-date-picker
            v-model="dateRanges[1].toDate"
            :placeholder="t('to')"
            day-target="end"
            @change="onFiltersChange"
          />
        </div>
      </div>
    </div>

    <div class="">
      <div class="text-gray-500 mb-2">{{ t("filterMaintenanceDate") }}</div>
      <div class="flex">
        <div class="mr-2">
          <va-date-picker
            v-model="dateRanges[2].fromDate"
            :placeholder="t('from')"
            day-target="start"
            @change="onFiltersChange"
          />
        </div>
        <div class="ml-2">
          <va-date-picker
            v-model="dateRanges[2].toDate"
            :placeholder="t('to')"
            day-target="end"
            @change="onFiltersChange"
          />
        </div>
      </div>
    </div>

    <div>
      <div class="text-gray-500 mb-2">{{ t("filterParent") }}</div>
      <va-select
        v-model="parentAccountId"
        :options="accountStore.currentNetworkOptions"
        :placeholder="t('createAsChild')"
        @change="onFiltersChange"
      >
        <template #option="option">
          <option-account :item="option.nestedProps.fullItem" />
        </template>
      </va-select>
    </div>
  </div>
  <va-table
    id="licenseReportsDataTable"
    :data-source="items"
    :table-config="tableConfig"
    :search-options="searchOptions"
    :loading="reportsStore.isLoading"
    class="w-100"
    @update="onTableUpdate"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'seatsTotal'">
        <div class="text-center">
          {{ record.seatsOccupied }}/{{ record.seatsTotal }}
        </div>
      </template>
      <template v-if="column.dataIndex === 'activationDate'">
        <div class="text-center">
          <div v-if="record.activationDate" class="text-xs text-gray-500 mt-2">
            {{ formatDate(record.activationDate) }}
          </div>
          <div v-else class="text-xs text-gray-500 mt-2">
            {{ t("noDate") }}
          </div>
          <!--          </div>-->
        </div>
      </template>
      <template v-if="column.dataIndex === 'expirationDate'">
        <div class="text-center">
          <div v-if="record.licenseIsPerpetual">
            <a-tag color="success">
              {{ t("perpetual") }}
            </a-tag>
            <div class="text-xs text-gray-500 mt-2">
              {{ t("noDate") }}
            </div>
          </div>
          <div v-else>
            <a-tag v-if="!isExpired(record.expirationDate)" color="success">
              {{ t("notExpired") }}
            </a-tag>
            <a-tag v-else color="error">{{ t("expired") }}</a-tag>
            <div
              v-if="record.expirationDate"
              class="text-xs text-gray-500 mt-2"
            >
              {{ formatDate(record.expirationDate) }}
            </div>
            <div v-else class="text-xs text-gray-500 mt-2">
              {{ t("noDate") }}
            </div>
          </div>
        </div>
      </template>
      <template v-if="column.dataIndex === 'maintenanceExpirationDate'">
        <div class="text-center">
          <div v-if="record.maintenanceIsPerpetual">
            <a-tag color="success">
              {{ t("perpetual") }}
            </a-tag>
            <div class="text-xs text-gray-500 mt-2">
              {{ t("noDate") }}
            </div>
          </div>
          <div v-else>
            <a-tag
              v-if="!isExpired(record.maintenanceExpirationDate)"
              color="success"
            >
              {{ t("notExpired") }}
            </a-tag>
            <a-tag v-else color="error">{{ t("expired") }}</a-tag>
            <div
              v-if="record.maintenanceExpirationDate"
              class="text-xs text-gray-500 mt-2"
            >
              {{ formatDate(record.maintenanceExpirationDate) }}
            </div>
            <div v-else class="text-xs text-gray-500 mt-2">
              {{ t("noDate") }}
            </div>
          </div>
        </div>
      </template>

      <template v-if="column.dataIndex === 'creationDate'">
        <div class="text-center">
          {{ formatDate(record.creationDate) }}
        </div>
      </template>
    </template>
  </va-table>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import VaTable from "@/components/organisms/VaTable.vue";
import { tableConfig } from "@/models/licenseReport.interface";
import { FilterDateRange, SearchOptions } from "@/models/listable.interface";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import { t } from "@/plugins/i18n";
import { useFormatDate } from "@/composables/formatters";
import { useLicenseReportsStore } from "@/stores/licenseReports";
import VaDatePicker from "@/components/molecules/VaDatePicker.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import OptionAccount from "@/components/molecules/selectOptions/OptionAccount.vue";
import VaSelect from "@/components/molecules/VaSelect.vue";
import { useAccountsStore } from "@/stores/accounts";
import { useAuthStore } from "@/stores/auth";
import { defaultPagination } from "@/services/configService";
import { useMeta } from "vue-meta";

useMeta({
  title: "Reports",
});
// const licensesStore = useLicensesStore();
const reportsStore = useLicenseReportsStore();
const accountStore = useAccountsStore();
const authStore = useAuthStore();
const formatDate = useFormatDate;
const items = computed(() => reportsStore.items);
const searchOptions = computed(() => reportsStore.searchOptions);

const dateRanges = ref([
  {
    field: "activationDate",
    fromDate: undefined,
    toDate: undefined,
  },
  {
    field: "expirationDate",
    fromDate: undefined,
    toDate: undefined,
  },
  {
    field: "maintenanceExpirationDate",
    fromDate: undefined,
    toDate: undefined,
  },
] as FilterDateRange[]);

const parentAccountId = ref(authStore.userAccount?.id);
const excludeChildren = ref(!searchOptions.value.includeChildren);

function onFiltersChange() {
  searchOptions.value.includeChildren = !excludeChildren.value;
  searchOptions.value.parentAccountId = parentAccountId.value;
  searchOptions.value.filterBetweenDate = dateRanges.value;
  searchOptions.value.pagination = defaultPagination;
  reportsStore.updateSearchOptions(searchOptions.value);
}

onBeforeMount(() => {
  accountStore.fetchChildren();
});

function onTableUpdate(searchOptions: SearchOptions) {
  reportsStore.updateSearchOptions(searchOptions);
}

function isExpired(date: Date) {
  return date?.getTime() <= Date.now();
}
</script>
