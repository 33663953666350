<template>
  <a-spin v-if="loading" :indicator="indicator" />
</template>

<script setup lang="ts">
import { LoadingOutlined } from "@ant-design/icons-vue";
import { h } from "vue";
defineProps({
  loading: Boolean,
});
const indicator = h(LoadingOutlined, {
  style: {
    fontSize: "24px",
  },
  class: "text-indigo-600",
  spin: true,
});
</script>
