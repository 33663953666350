import { TableConfig } from "@/models/listable.interface";
import { CurrencyData } from "@/models/currencyData.interface";
import logger from "@/services/loggerService";
import { useLookupStore } from "@/stores/lookup";
import ProductTypeModel, { ProductType } from "@/models/productType.interface";
import ProductTenantModel, {
  ProductTenant,
} from "@/models/productTenant.interface";

export interface Product {
  id: string;
  sku: string;
  name: string;
  description: string;
  price: number;
  currency: CurrencyData;
  oemSKU: string;
  stock?: number;
  isLicense: boolean;
  licensePrefix: string;
  maintenanceDurationYears: number;
  maintenanceDurationMonths: number;
  maintenanceDurationDays: number;
  licenseDurationYears: number;
  licenseDurationMonths: number;
  licenseDurationDays: number;
  licenseActivationRule: string;
  licenseIsPerpetual: boolean;
  maintenanceIsPerpetual: boolean;
  islicenseUpgradeRight: boolean;
  isLicenseTrial: boolean;
  extendedProductId?: string;
  licenseMaxVersion?: string;
  creationDate?: Date;
  createdBy: string;
  status: "Active" | "Pending" | "Deleted";
  subscriptionId?: string;
  originalProductId?: string;
  airServerRequired: boolean;
  // productTypeId: string;
  type: ProductType;
  environmentType: string;
  tenants: ProductTenant[];
}

export interface ProductOption {
  productId: string;
  productName: string;
  sku: string;
}

export type ProductStockError = { product: string; quantity: number };

export const DeserializeProductError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
  input: any
): ProductStockError[] => {
  const prod = JSON.parse(input);
  return Object.keys(prod).map((key) => {
    return {
      product: prod[key].productSku,
      quantity: prod[key].quantity,
    };
  });
};

export const tableConfig = {
  name: {
    dtoName: "name",
    sortable: true,
    filterable: true,
    label: "name",
  },
  id: {
    dtoName: "productId",
    sortable: true,
    filterable: true,
    label: "id",
    hideColumn: true,
  },
  sku: {
    dtoName: "sku",
    sortable: true,
    filterable: true,
    label: "sku",
  },
  // oemSKU: {
  //   dtoName: "oemSKU",
  //   sortable: true,
  //   filterable: true,
  //   label: "oemSku",
  // },
  description: {
    dtoName: "description",
    sortable: false,
    filterable: false,
    label: "description",
    hideColumn: true,
  },
  price: {
    dtoName: "customPrice",
    sortable: true,
    filterable: false,
    label: "price",
  },
  stock: {
    dtoName: "stockQty",
    sortable: true,
    filterable: false,
    label: "stock",
    hideColumn: true,
  },
} as TableConfig;

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): Product {
    //STORE DEPENDENCY: need already fetched currencies to decode from id to full currency item
    const lookupStore = useLookupStore();
    let productSatus = "Active" as "Active" | "Pending" | "Deleted";
    switch (input.status) {
      case "Active":
        productSatus = "Active";
        break;
      case "Pending":
        productSatus = "Pending";
        break;
      case "Deleted":
        productSatus = "Deleted";
        break;
      default:
        logger.error(
          `Product deserializer: unhandled status value ${input.status} for productId: ${input.productId}`
        );
    }
    return {
      id: input.productId || "",
      sku: input.sku || "",
      name: input.name || "",
      description: input.description || "",
      price: input.customPrice || input.priceMsrp || 0,
      currency: lookupStore.decodedCurrency(input.priceCurrencyId),
      oemSKU: input.oemSku || input.oemSKU || "",
      stock: input.stockQty,
      isLicense: input.isLicense || false,
      licensePrefix: input.licensePrefix || "",
      maintenanceDurationYears: input.maintenanceDurationYears || 0,
      maintenanceDurationMonths: input.maintenanceDurationMonths || 0,
      maintenanceDurationDays: input.maintenanceDurationDays || 0,
      licenseDurationYears: input.licenseDurationYears || 0,
      licenseDurationMonths: input.licenseDurationMonths || 0,
      licenseDurationDays: input.licenseDurationDays || 0,
      licenseIsPerpetual: input.licenseIsPerpetual || false,
      maintenanceIsPerpetual: input.maintenanceIsPerpetual || false,
      licenseActivationRule: input.licenseActivationRule || "",
      islicenseUpgradeRight: input.licenseIsUpgradeRight || false,
      isLicenseTrial: input.licenseIsTrial || false,
      extendedProductId: input.extendedProductId || undefined,
      licenseMaxVersion: input.licenseMaxVersion || undefined,
      creationDate: input.creationDate
        ? new Date(input.creationDate)
        : undefined,
      createdBy: input.createdBy || "",
      status: productSatus,
      subscriptionId: input.subscriptionId || undefined,
      originalProductId: input.originalProductId || undefined,
      airServerRequired: input.airServerRequired || false,
      type: ProductTypeModel.deserialize(input.productType),
      environmentType: input.environmentType.toString() || "1",
      tenants: input.tenants
        ? input.tenants.map(ProductTenantModel.deserialize)
        : [],
    };
  },

  serialize(input: Product): unknown {
    return {
      productId: input.id,
      sku: input.sku,
      name: input.name,
      description: input.description,
      priceMsrp: input.price,
      customPrice: input.price,
      priceCurrencyId:
        input.currency.id !== "-" ? input.currency.id : undefined,
      oemSku: input.oemSKU,
      stockQty: input.stock,
      isLicense: input.isLicense,
      licensePrefix: input.licensePrefix,
      maintenanceDurationYears: input.maintenanceDurationYears,
      maintenanceDurationMonths: input.maintenanceDurationMonths,
      maintenanceDurationDays: input.maintenanceDurationDays,
      licenseDurationYears: input.licenseDurationYears,
      licenseDurationMonths: input.licenseDurationMonths,
      licenseDurationDays: input.licenseDurationDays,
      licenseIsPerpetual: input.licenseIsPerpetual,
      maintenanceIsPerpetual: input.maintenanceIsPerpetual,
      licenseActivationRule: input.licenseActivationRule,
      licenseIsUpgradeRight: input.islicenseUpgradeRight,
      licenseIsTrial: input.isLicenseTrial,
      extendedProductId: input.extendedProductId,
      licenseMaxVersion: input.licenseMaxVersion,
      creationDate: input.creationDate?.getTime() || undefined,
      createdBy: input.createdBy,
      status: input.status,
      subscriptionId: input.subscriptionId,
      originalProductId: input.originalProductId,
      airServerRequired: input.airServerRequired,
      productType: input.type?.id
        ? ProductTypeModel.serialize(input.type)
        : undefined,
      environmentType: input.environmentType,
      tenants: input.tenants.map(ProductTenantModel.serialize),
    };
  },
};
