import * as configService from "@/services/configService";
import {
  SearchOptions,
  searchOptionsSerialize,
} from "@/models/listable.interface";
import axios from "axios";
import StockModel, { Stock, tableConfig } from "@/models/stock.interface";

export async function getByAccountId(id: string): Promise<Stock[]> {
  const res = await axios.get(`${configService.stocksUrl}/${id}`);
  return res.data.map(StockModel.deserialize);
}

export async function search(
  options: SearchOptions
): Promise<{ items: Stock[]; count: number }> {
  const opt = options ? searchOptionsSerialize(options, tableConfig) : {};
  const res = await axios.post(configService.stocksSearchUrl, opt);
  return {
    items: res.data.items.map(StockModel.deserialize),
    count: res.data.count,
  };
}
