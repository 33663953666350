<template>
  <div class="va-card mt-6 dark:bg-black pt-4 px-4">
    <a-form
      class=""
      :model="formState"
      :validate-messages="validateMessages"
      @finish="onFinish"
    >
      <div class="grid grid-cols-2 gap-4">
        <a-form-item
          :name="['product', 'sku']"
          :label="t('sku')"
          :rules="[{ required: true }]"
          :disabled="!productsStore.isLoading"
        >
          <va-input v-model="formState.product.sku" :placeholder="t('sku')" />
        </a-form-item>
        <a-form-item
          :name="['product', 'name']"
          :label="t('name')"
          :rules="[{ required: true }]"
          :disabled="!productsStore.isLoading"
        >
          <va-input v-model="formState.product.name" :placeholder="t('name')" />
        </a-form-item>
        <a-form-item
          :name="['product', 'description']"
          :label="t('description')"
          :disabled="!productsStore.isLoading"
        >
          <va-input
            v-model="formState.product.description"
            :placeholder="t('description')"
          />
        </a-form-item>
        <a-form-item
          :name="['product', 'price']"
          :label="t('price')"
          :rules="[{ required: true }]"
          :disabled="!productsStore.isLoading"
        >
          <va-input
            v-model="formState.product.price"
            :placeholder="t('price')"
            type="number"
            suffix="$"
          />
        </a-form-item>
        <a-form-item
          :name="['product', 'oemSKU']"
          :label="t('oemSKU')"
          :disabled="!productsStore.isLoading"
        >
          <va-input
            v-model="formState.product.oemSKU"
            :placeholder="t('oemSKU')"
          />
        </a-form-item>
        <a-form-item
          class="p-2"
          :name="['product', 'licensePrefix']"
          :label="t('licensePrefix')"
          :rules="[{ required: true }]"
          :disabled="!productsStore.isLoading"
        >
          <va-input
            v-model="formState.product.licensePrefix"
            :placeholder="t('licensePrefix')"
          />
        </a-form-item>
        <div class="flex justify-between">
          <a-form-item
            :name="['product', 'licenseIsPerpetual']"
            :label="t('perpetual')"
            :disabled="!productsStore.isLoading"
            class="flex-shrink"
          >
            <a-switch v-model:checked="formState.product.licenseIsPerpetual" />
          </a-form-item>
          <div class="mr-2">
            <a-form-item
              :name="['product', 'licenseDurationYears']"
              :label="t('licenseDurationYears')"
              :rules="[{ required: true }]"
            >
              <va-input
                v-model="formState.product.licenseDurationYears"
                :placeholder="t('licenseDurationYears')"
                integer
                type="number"
                :max="255"
                :disabled="formState.product.licenseIsPerpetual"
              />
            </a-form-item>
          </div>
          <div class="mr-2">
            <a-form-item
              :name="['product', 'licenseDurationMonths']"
              :label="t('licenseDurationMonths')"
              :rules="[{ required: true }]"
            >
              <va-input
                v-model="formState.product.licenseDurationMonths"
                :placeholder="t('licenseDurationMonths')"
                integer
                :max="255"
                type="number"
                :disabled="formState.product.licenseIsPerpetual"
              />
            </a-form-item>
          </div>
          <div class="mr-2">
            <a-form-item
              :name="['product', 'licenseDurationDays']"
              :label="t('licenseDurationDays')"
              :rules="[{ required: true }]"
            >
              <va-input
                v-model="formState.product.licenseDurationDays"
                :placeholder="t('licenseDurationDays')"
                integer
                type="number"
                :disabled="formState.product.licenseIsPerpetual"
              />
            </a-form-item>
          </div>
        </div>
        <div class="flex justify-between">
          <a-form-item
            :name="['product', 'maintenanceIsPerpetual']"
            :label="t('perpetual')"
            class="flex-shrink"
          >
            <a-switch
              v-model:checked="formState.product.maintenanceIsPerpetual"
            />
          </a-form-item>
          <div class="mr-2">
            <a-form-item
              :name="['product', 'maintenanceDurationYears']"
              :label="t('maintenanceDurationYears')"
              :disabled="!productsStore.isLoading"
            >
              <va-input
                v-model="formState.product.maintenanceDurationYears"
                :placeholder="t('maintenanceDurationYears')"
                integer
                type="number"
                :max="255"
                :disabled="formState.product.maintenanceIsPerpetual"
              />
            </a-form-item>
          </div>
          <div class="mr-2">
            <a-form-item
              :name="['product', 'maintenanceDurationMonths']"
              :label="t('maintenanceDurationMonths')"
              :disabled="!productsStore.isLoading"
            >
              <va-input
                v-model="formState.product.maintenanceDurationMonths"
                :placeholder="t('maintenanceDurationMonths')"
                integer
                type="number"
                :max="255"
                :disabled="formState.product.maintenanceIsPerpetual"
              />
            </a-form-item>
          </div>
          <div class="mr-2">
            <a-form-item
              :name="['product', 'maintenanceDurationDays']"
              :label="t('maintenanceDurationDays')"
              :disabled="!productsStore.isLoading"
            >
              <va-input
                v-model="formState.product.maintenanceDurationDays"
                :placeholder="t('maintenanceDurationDays')"
                integer
                type="number"
                :disabled="formState.product.maintenanceIsPerpetual"
              />
            </a-form-item>
          </div>
        </div>
        <div class="flex">
          <a-form-item
            :name="['product', 'islicenseUpgradeRight']"
            :label="t('islicenseUpgradeRight')"
            :disabled="!productsStore.isLoading"
            class="flex-shrink"
          >
            <a-switch
              v-model:checked="formState.product.islicenseUpgradeRight"
              @change="
                formState.product.extendedProductId = undefined;
                formState.product.subscriptionId = undefined;
              "
            />
          </a-form-item>
          <!--          v-if="formState.product.islicenseUpgradeRight"-->
          <div class="flex-grow mr-2">
            <a-form-item
              :name="['product', 'extendedProductId']"
              :label="t('extendedProductId')"
              :rules="[{ validator: extendedProductIdRule }]"
            >
              <va-select
                v-model="formState.product.extendedProductId"
                :options="extensionOptions"
              />
            </a-form-item>
          </div>
          <div class="flex-grow">
            <a-form-item
              class="flex-grow"
              :name="['product', 'subscriptionId']"
              :label="t('subscriptionId')"
              :rules="[{ validator: subscriptionIdRule }]"
            >
              <va-select
                v-model="formState.product.subscriptionId"
                :options="lookupStore.subscriptionsOptions"
                :disabled="
                  formState.product.islicenseUpgradeRight &&
                  formState.product.extendedProductId !== props.product.id
                "
              />
            </a-form-item>
          </div>
        </div>

        <a-form-item
          :name="['product', 'licenseActivationRule']"
          :label="t('licenseActivationRule')"
          :rules="[{ required: true }]"
          :disabled="!productsStore.isLoading"
        >
          <va-select
            v-model="formState.product.licenseActivationRule"
            :options="[
              {
                value: 'firstnextmonth',
                label: 'firstnextmonth',
              },
              {
                value: 'activation',
                label: 'activation',
              },
            ]"
            :placeholder="t('isLicense')"
          />
        </a-form-item>

        <a-form-item
          :name="['product', 'licenseMaxVersion']"
          :label="t('licenseMaxVersion')"
          :disabled="!productsStore.isLoading"
        >
          <va-input
            v-model="formState.product.licenseMaxVersion"
            :placeholder="t('licenseMaxVersion')"
          />
        </a-form-item>

        <a-form-item
          :name="['product', 'type', 'id']"
          :label="t('productType')"
          :disabled="!productsStore.isLoading"
        >
          <va-select
            v-model="formState.product.type.id"
            :options="productTypesStore.productTypesOptions"
            :placeholder="t('productType')"
          >
            <template #option="option">
              <div>{{ option.nestedProps.fullItem.name }}</div>
              <div class="text-xs text-gray-500 flex justify-between">
                {{ option.nestedProps.fullItem.description || " - " }}
              </div>
            </template>
          </va-select>
        </a-form-item>

        <a-form-item
          :name="['product', 'environmentType']"
          :label="t('environmentType')"
          :rules="[{ required: true }]"
          :disabled="!productsStore.isLoading"
        >
          <va-select
            v-model="formState.product.environmentType"
            :options="productsStore.environmentTypesOptions"
            :placeholder="t('environmentType')"
          />
        </a-form-item>

        <div class="flex">
          <div class="mr-4">
            <a-form-item
              :name="['product', 'isLicense']"
              :label="t('isLicense')"
              :disabled="!productsStore.isLoading"
            >
              <a-switch v-model:checked="formState.product.isLicense" />
            </a-form-item>
          </div>
          <a-form-item
            :name="['product', 'isLicenseTrial']"
            :label="t('isLicenseTrial')"
            :disabled="!productsStore.isLoading"
          >
            <a-switch v-model:checked="formState.product.isLicenseTrial" />
          </a-form-item>
          <a-form-item
            :name="['product', 'airServerRequired']"
            :label="t('airServerRequired')"
            :disabled="!productsStore.isLoading"
          >
            <a-switch v-model:checked="formState.product.airServerRequired" />
          </a-form-item>
        </div>

        <div>
          <a-form-item
            class="flex-grow"
            :name="['product', 'tenants']"
            :label="t('tenants')"
          >
            <a-select
              v-model:value="tenatCodes"
              mode="multiple"
              style="width: 100%"
              placeholder="Please select"
              :options="productsStore.productTenantsOptions"
              @change="changeTenant"
            ></a-select>
          </a-form-item>
        </div>
        <a-form-item>
          <va-button
            html-type="submit"
            class="float-right mt-8"
            :disabled="!productsStore.canCreate"
            :loading="productsStore.isLoading"
          >
            {{ t("save") }}
          </va-button>
        </a-form-item>
      </div>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, PropType, ref, watch } from "vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import { t } from "@/plugins/i18n";
import { useValidationMessages } from "@/composables/validation";
import { Product } from "@/models/product.interface";
import VaSelect from "@/components/molecules/VaSelect.vue";
import { useProductsStore } from "@/stores/products";
import { useLookupStore } from "@/stores/lookup";
import { cloneDeep } from "lodash";
import { useProductTypesStore } from "@/stores/productTypes";

const lookupStore = useLookupStore();
const productsStore = useProductsStore();
const productTypesStore = useProductTypesStore();
const validateMessages = useValidationMessages();

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
});

watch(props, (currentValue) => {
  if (
    currentValue.product &&
    currentValue.product.id != formState.value.product.id
  ) {
    formState.value.product = currentValue.product;
  }
});

const extensionOptions = computed(() => {
  return productsStore.productOptions.filter((prod) => {
    if (formState.value.product.islicenseUpgradeRight) {
      return prod.value != props.product.id;
    } else {
      return prod.value == props.product.id;
    }
  });
});

const emit = defineEmits(["save"]);

const extendedProductIdRule = () => {
  return new Promise<void>((resolve, reject) => {
    !formState.value.product.islicenseUpgradeRight ||
    !!formState.value.product.extendedProductId
      ? resolve()
      : reject(t("rulesRequired"));
  });
};

const subscriptionIdRule = () => {
  return new Promise<void>((resolve, reject) => {
    formState.value.product.islicenseUpgradeRight ||
    // (!formState.value.product.extendedProductId &&
    !!formState.value.product.subscriptionId
      ? resolve()
      : reject(t("rulesRequired"));
  });
};

const formState = ref({
  product: cloneDeep(props.product),
});

const tenatCodes = computed(() =>
  formState.value.product.tenants.map((t) => {
    return t.id;
  })
);

const onFinish = () => {
  if (formState.value.product.licenseIsPerpetual) {
    formState.value.product.licenseDurationYears = 255;
  }
  if (formState.value.product.maintenanceIsPerpetual) {
    formState.value.product.maintenanceDurationYears = 255;
  }
  emit("save", formState.value.product);
};
const changeTenant = (args: string[]) => {
  formState.value.product.tenants = productsStore.productTenants.filter((t) => {
    return args.includes(t.id);
  });
};

onBeforeMount(() => {
  productsStore.fetch();
  lookupStore.fetchSubscriptions();
});
</script>
