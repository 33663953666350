<template>
  <va-page-title class="mt-4">{{ t("companyEdit") }}</va-page-title>
  <div class="flex justify-between">
    <va-back-link target="/profile/account">
      {{ t("backToCompany") }}
    </va-back-link>
  </div>
  <account-edit-form v-if="account" class="mt-6" :account="account" />
  <address-edit-form v-if="account" class="mt-6" :account="account" />
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from "vue";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import { t } from "@/plugins/i18n";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import AddressEditForm from "@/components/organisms/account/AddressEditForm.vue";
import AccountEditForm from "@/components/organisms/account/AccountEditForm.vue";
import { useAccountsStore } from "@/stores/accounts";
import { useAuthStore } from "@/stores/auth";

const accountStore = useAccountsStore();
const autStore = useAuthStore();

const account = computed(() => autStore.userAccount);
onBeforeMount(() => {
  accountStore.resetCurrentAccount();
});
</script>
