<template>
  <div>{{ item.code }}</div>
  <div class="text-xs text-gray-500">
    {{ formatDate(item.creationDate) }}
    <span v-if="item.refCode"> - {{ item.refCode }}</span>
  </div>
</template>

<script setup lang="ts">
import { useFormatDate } from "@/composables/formatters";
import { Order } from "@/models/order.interface";
import { PropType } from "vue";

defineProps({
  item: { type: Object as PropType<Order>, required: true },
});

const formatDate = useFormatDate;
</script>
