<template>
  <va-page-title>{{ t("priceListNew") }}</va-page-title>
  <va-back-link target="/pricelists">{{ t("priceListBack") }}</va-back-link>
  <div class="va-card mt-6 pt-4 px-4">
    <a-form
      class=""
      :model="formState"
      :validate-messages="validateMessages"
      @finish="onFinish"
    >
      <a-form-item
        :name="['name']"
        :label="t('priceListName')"
        :rules="[{ required: true }]"
        :disabled="!priceListsStore.canCreate"
      >
        <va-input v-model="formState.name" :placeholder="t('priceListName')" />
      </a-form-item>
      <a-form-item
        :name="['currencyId']"
        :label="t('currency')"
        :rules="[{ required: true }]"
      >
        <va-select
          v-model="formState.currencyId"
          :options="lookupStore.currencyOptions"
          :placeholder="t('currency')"
          :disabled="lookupStore.currenciesLoading"
        />
      </a-form-item>
      <a-form-item>
        <va-button
          html-type="submit"
          class="float-right"
          :disabled="!priceListsStore.canCreate"
          :loading="priceListsStore.isLoading"
        >
          {{ t("save") }}
        </va-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import { t } from "@/plugins/i18n";
import { useValidationMessages } from "@/composables/validation";
import VaBackLink from "@/components/atoms/VaBackLink.vue";
import { usePricelistsStore } from "@/stores/pricelists";
import { useLookupStore } from "@/stores/lookup";
import VaSelect from "@/components/molecules/VaSelect.vue";
import { useMeta } from "vue-meta";

useMeta({
  title: "Pricelists",
});
const priceListsStore = usePricelistsStore();
const lookupStore = useLookupStore();
const validateMessages = useValidationMessages();

const formState = ref({
  name: "",
  items: [],
  currencyId: "",
});

const onFinish = () => {
  priceListsStore.create(formState.value);
};

onBeforeMount(() => {
  lookupStore.fetchCurrencies();
});
</script>
