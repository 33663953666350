import { defineStore } from "pinia";
import {
  License,
  LicenseCreationData,
  LicenseExportData,
  LicenseSeats,
} from "@/models/license.interface";
import logger from "@/services/loggerService";
import { t } from "@/plugins/i18n";
import {
  get,
  post,
  search,
  fetch,
  update,
  exportToEnv,
  clearSeats,
  fullDeleteSeats,
} from "@/services/licensesService";
import * as listable from "./listableMixin";
import * as permissions from "./permissionMixin";
import router from "@/router";
import { cloneDeep } from "lodash";
import { defaultSearchOptions } from "./listableMixin";
import {
  DeserializeProductError,
  ProductStockError,
} from "@/models/product.interface";
import { useNotificationsStore } from "@/stores/notifications";
import { hasPermission } from "./permissionMixin";
import { useAuthStore } from "@/stores/auth";

export interface LicensesState
  extends listable.ListableMixinState,
    permissions.PermissionsMixinState {
  items: License[];
  currentLicense?: License;
  creationError?: ProductStockError[];
}

function createStore<Id extends string>(id: Id) {
  return defineStore({
    id: id,
    state: (): LicensesState => ({
      isLoading: false,
      searchOptions: cloneDeep(defaultSearchOptions),
      moduleName: "Licenses",
      items: [],
      currentLicense: undefined,
      searchError: t("licensesFetchError"),
      searchFnc: search,
      creationError: undefined,
    }),
    getters: {
      ...permissions.getters,
      canDeleteSeat: (): boolean => {
        const authStore = useAuthStore();
        return hasPermission("u", "LicensesRma", authStore.userPermissions);
      },
      licenses: (state): License[] => state.items,
    },
    actions: {
      ...listable.actions,
      setCreationError(error: ProductStockError[] | undefined) {
        this.creationError = error;
      },
      async get(serial: string) {
        const notifications = useNotificationsStore();

        if (!serial || this.currentLicense?.serialKey === serial) return;
        this.isLoading = true;
        try {
          this.currentLicense = await get(serial);
        } catch (err) {
          logger.error(err);
          notifications.error(t("licenseFetchError"));
        } finally {
          this.isLoading = false;
        }
      },
      async create(data: LicenseCreationData) {
        const notifications = useNotificationsStore();

        this.creationError = undefined;
        this.isLoading = true;
        try {
          await post(data);
          notifications.success(t("licenseCreateSuccess"));
          router.push(`/licenses`);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
        } catch (err: any) {
          if (err?.response?.data?.errorCode == "LC519") {
            this.creationError = DeserializeProductError(
              err.response.data.message
            );
          } else {
            logger.error(err);
          }
          notifications.error(t("licenseCreateError"));
        } finally {
          this.isLoading = false;
        }
      },
      async fetch() {
        const notifications = useNotificationsStore();

        this.isLoading = true;
        try {
          this.items = await fetch();
        } catch (err) {
          logger.error(err);
          notifications.error(t("licensesFetchError"));
        } finally {
          this.isLoading = false;
        }
      },
      async updateNotes(notes: string) {
        const notifications = useNotificationsStore();

        const key = this.currentLicense?.serialKey;
        if (key) {
          this.isLoading = true;
          try {
            await update(key, notes);
            this.currentLicense = await get(key);
            notifications.success(t("licenseUpdateSuccess"));
          } catch (err) {
            logger.error(err);
            notifications.error(t("licenseUpdateError"));
          } finally {
            this.isLoading = false;
          }
        }
      },
      async exportToEnv(data: LicenseExportData) {
        const notifications = useNotificationsStore();
        this.isLoading = true;
        try {
          await exportToEnv(data);
        } catch (err) {
          logger.error(err);
          notifications.error(t("licenseUpdateError"));
        } finally {
          this.isLoading = false;
        }
      },
      async clearAllSeats() {
        const notifications = useNotificationsStore();
        this.isLoading = true;
        try {
          if (this.currentLicense) {
            await clearSeats(this.currentLicense.licenseSeats);
            this.currentLicense = await get(this.currentLicense.serialKey);
          }
        } catch (err) {
          logger.error(err);
          notifications.error(t("clearSeatError"));
        } finally {
          this.isLoading = false;
        }
      },
      async fullDeleteSeats() {
        const notifications = useNotificationsStore();
        this.isLoading = true;
        try {
          if (this.currentLicense) {
            await fullDeleteSeats(this.currentLicense.id);
            this.currentLicense = await get(this.currentLicense.serialKey);
          }
        } catch (err) {
          logger.error(err);
          notifications.error(t("clearSeatError"));
        } finally {
          this.isLoading = false;
        }
      },
      async clearSeat(seat: LicenseSeats) {
        const notifications = useNotificationsStore();
        this.isLoading = true;
        try {
          if (this.currentLicense) {
            await clearSeats([seat]);
            this.currentLicense = await get(this.currentLicense.serialKey);
          }
        } catch (err) {
          logger.error(err);
          notifications.error(t("clearSeatError"));
        } finally {
          this.isLoading = false;
        }
      },
    },
  });
}

export const useLicensesStore = createStore("licenses");
export const useLicensesStoreAccount = createStore("licensesAccount");
