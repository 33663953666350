import { defineStore } from "pinia";
import {
  statsRequestData,
  DealStats,
  LicenseStats,
  OrderStats,
  StatsQuery,
} from "@/models/stats.interface";
import {
  getLicensesStats,
  getDealsStats,
  getOrdersStats,
} from "@/services/statsService";
import logger from "@/services/loggerService";
import { t } from "@/plugins/i18n";
import { useNotificationsStore } from "@/stores/notifications";
import { SelectOption } from "@/models/selectOptions.interface";
import { ProductOption } from "@/models/product.interface";
import * as productsService from "@/services/productsService";
import { useBuildChartData } from "@/composables/charts";

const defaultDays = 7;
// const authStore = useAuthStore();

const intervalOptions = [
  {
    value: "week",
    label: t("intervalWeek"),
  },
  {
    value: "thisMonth",
    label: t("intervalThisMonth"),
  },
  {
    value: "lastMonth",
    label: t("intervalLastMonth"),
  },
  {
    value: "thisQuarter",
    label: t("intervalThisQuarter"),
  },
  {
    value: "lastQuarter",
    label: t("intervalLastQuarter"),
  },
  {
    value: "thisYear",
    label: t("intervalThisYear"),
  },
  {
    value: "lastYear",
    label: t("intervalLastYear"),
  },
  {
    value: "allTime",
    label: t("intervalAllTime"),
  },
  {
    value: "custom",
    label: t("intervalCustom"),
  },
];
const possibleIntervals = intervalOptions.map((o) => o.value);
export type fixedInterval = typeof possibleIntervals[number];

export interface StatsState {
  // accountStats?: AccountsStats;
  // accountStatsLoading: boolean;
  licensesStats?: LicenseStats;
  licensesStatsLoading: boolean;
  dealsStats?: DealStats;
  dealsStatsLoading: boolean;
  ordersStats?: OrderStats;
  ordersStatsLoading: boolean;
  query: StatsQuery;
  intervalOptions: { value: string; label: string }[];
  interval: fixedInterval;
  productOptions: SelectOption<ProductOption>[];
}

export const useStatsStore = defineStore({
  id: "Stats",
  state: (): StatsState => ({
    // accountStats: undefined,
    // accountStatsLoading: false,
    licensesStats: undefined,
    licensesStatsLoading: false,
    dealsStats: undefined,
    dealsStatsLoading: false,
    ordersStats: undefined,
    ordersStatsLoading: false,
    query: {
      from: new Date(
        new Date(
          new Date().setDate(new Date().getDate() - defaultDays)
        ).setHours(0, 0, 0, 0)
      ),
      to: new Date(new Date().setHours(23, 59, 59, 999)),
      showPrevious: true,
      accountId: "",
      includeChildren: false,
      products: [],
    },
    intervalOptions: intervalOptions,
    interval: "week",
    productOptions: [],
  }),
  actions: {
    // async fetchAccountStats(data: statsRequestData) {
    //   const notifications = useNotificationsStore();
    //   this.accountStatsLoading = true;
    //   try {
    //     this.accountStats = await getAccountStats(data);
    //   } catch (err) {
    //     logger.error(err);
    //     notifications.error(t("accountStatsError"));
    //   } finally {
    //     this.accountStatsLoading = false;
    //   }
    // },
    updateStatsQuery(query: StatsQuery) {
      this.query = query;
      this.fetchProductOptions();
      this.fetchOrdersStats();
      this.fetchLicenseStats();
    },
    updateLicensesQuery(query: StatsQuery) {
      this.query = query;
      this.fetchProductOptions();
      this.fetchLicenseStats();
    },

    updateOrdersQuery(query: StatsQuery) {
      this.query = query;
      this.fetchProductOptions();
      this.fetchOrdersStats();
    },
    async fetchLicenseStats() {
      const notifications = useNotificationsStore();
      const data = useBuildChartData(this.query);
      this.licensesStatsLoading = true;
      try {
        this.licensesStats = await getLicensesStats(data);
      } catch (err) {
        logger.error(err);
        notifications.error(t("accountStatsError"));
      } finally {
        this.licensesStatsLoading = false;
      }
    },
    async fetchDealsStats(data: statsRequestData) {
      const notifications = useNotificationsStore();
      this.dealsStatsLoading = true;
      try {
        this.dealsStats = await getDealsStats(data);
      } catch (err) {
        logger.error(err);
        notifications.error(t("accountStatsError"));
      } finally {
        this.dealsStatsLoading = false;
      }
    },
    async fetchOrdersStats() {
      const data = useBuildChartData(this.query);
      const notifications = useNotificationsStore();
      this.ordersStatsLoading = true;
      try {
        this.ordersStats = await getOrdersStats(data);
      } catch (err) {
        logger.error(err);
        notifications.error(t("accountStatsError"));
      } finally {
        this.ordersStatsLoading = false;
      }
    },
    changeInterval(option: { value: string; label: string }) {
      const date_today = new Date();
      const year = date_today.getFullYear();
      const month = date_today.getMonth();
      const quarter = Math.floor((month + 3) / 3);
      const endQuarterMonth = quarter * 3;
      const startQuarterMonth = endQuarterMonth - 3;
      switch (option.value) {
        case "week":
          //(ultimi 7 gg indietro da oggi incluso)
          this.query.from = new Date(
            new Date().setDate(date_today.getDate() - 7)
          );
          this.query.to = date_today;
          break;
        case "thisMonth":
          // (mese in corso, dal 1 al 31, es. Gennaio 1.1.24 - 31.1.24)
          this.query.from = new Date(year, month, 1);
          this.query.to = new Date(year, month + 1, 0);
          break;
        case "lastMonth":
          // (mese scorso, es. Dicembre 2023 1.12.23 - 31.12.23)
          this.query.from = new Date(year, month - 1, 1);
          this.query.to = new Date(year, month, 0);
          break;
        case "thisQuarter":
          // (trimestre in corso, incluso l’attuale, considerando gen-mar, apr-giu, lug-set, ott-dic, es. gen-mar 2024)
          this.query.from = new Date(year, startQuarterMonth, 1);
          this.query.to = new Date(year, endQuarterMonth, 0);
          break;
        case "lastQuarter":
          //(scorsi 3 mesi, ovvero trimestre precedente, es. ott-dic 2023)
          this.query.from = new Date(year, startQuarterMonth - 3, 1);
          this.query.to = new Date(year, endQuarterMonth - 3, 0);
          break;
        case "thisYear":
          // (anno in corso, dal 1/1 al 31/12)
          this.query.from = new Date(year, 0, 1);
          this.query.to = new Date(year, 12, 0);
          break;
        case "lastYear":
          // (anno precedente, dal 1/1 al 31/12)
          this.query.from = new Date(year - 1, 0, 1);
          this.query.to = new Date(year - 1, 12, 0);
          break;
        case "allTime":
          this.query.from = undefined;
          this.query.to = undefined;
          break;
        case "custom":
          break;
      }
      this.fetchProductOptions();
      this.fetchOrdersStats();
    },
    async fetchProductOptions() {
      try {
        this.productOptions = await productsService.fetchDashboardOptions(
          this.query.accountId,
          this.query.includeChildren,
          this.query.from,
          this.query.to
        );
      } catch (err) {
        logger.error(err);
      }
    },
  },
});
