import { User } from "@/models/user.interface";
import AccountModel, { Account } from "@/models/account.interface";
import OrderItemModel, { OrderItem } from "@/models/orderItem.interface";
import OrderDetailsModel, {
  OrderDetails,
  possibleStatus,
} from "@/models/orderDetails.interface";
import { TableConfig } from "@/models/listable.interface";
import { CurrencyData } from "@/models/currencyData.interface";
import { PriceListProduct } from "@/models/priceListProduct.interface";

export interface Order {
  id: string;
  code: string;
  relationId: string;
  isInvoiced: boolean;
  isPaid: boolean;
  invoiceId?: string;
  invoiceProvider?: string; //??
  details: OrderDetails[];
  items: OrderItem[];
  buyerAccountId: string;
  buyer?: Account;
  vendorAccountId: string;
  vendor?: Account;
  creator: Partial<User>;
  creationDate: Date;
  createdBy: string;
  status: possibleStatus;
  notes?: string;
  refCode?: string;
  total?: number;
  currency?: CurrencyData;
}

export interface OrderFormData {
  source: "api" | "email" | "manual";
  sourceData: string;
  buyerAccountId?: string;
  addressId?: string;
  items: {
    productId: string;
    quantity: number;
    price?: number;
    fullItem?: PriceListProduct;
  }[];
  status?: possibleStatus;
  orderNote?: string;
  orderRefCode?: string;
  parentAccountId?: string;
}

export const tableConfig = {
  id: {
    dtoName: "orderId",
    sortable: true,
    filterable: true,
    label: "id",
    hideColumn: true,
  },
  code: {
    dtoName: "orderCode",
    sortable: true,
    filterable: true,
    label: "code",
  },
  vendor: {
    dtoName: "vendorAccountName",
    sortable: false,
    filterable: true,
    label: "vendor",
  },
  buyer: {
    dtoName: "buyerAccountName",
    sortable: false,
    filterable: true,
    label: "buyer",
  },
  refCode: {
    dtoName: "orderRefCode",
    sortable: false,
    filterable: true,
    label: "refCode",
  },
  status: {
    dtoName: "status",
    sortable: false,
    filterable: false,
    label: "status",
  },
  isInvoiced: {
    dtoName: "invoiced",
    sortable: true,
    filterable: false,
    label: "invoiced",
    hideColumn: false,
  },
  isPaid: {
    dtoName: "paid",
    sortable: true,
    filterable: false,
    label: "paid",
    hideColumn: false,
  },
  creationDate: {
    dtoName: "creationDate",
    sortable: true,
    filterable: false,
    label: "creationDate",
  },
  total: {
    dtoName: "total",
    sortable: false,
    filterable: false,
    label: "total",
  },
} as TableConfig;

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): Order {
    const details = input.details?.length
      ? input.details.map(OrderDetailsModel.deserialize)
      : [];
    const items = input.items?.length
      ? input.items.map(OrderItemModel.deserialize)
      : [];
    //get order stauts from last order details update
    const max = Math.max(...details.map((o: OrderDetails) => o.updateDate));
    const status = details.find(
      (d: OrderDetails) => d.updateDate.getTime() === max
    ).status;
    let currency;
    if (items.length) {
      currency = items[0].currency;
      // total = items.reduce(function (a: number, b: OrderItem) {
      //   return a + (b.price ? b.price : 0) * b.quantity;
      // }, 0);
    }
    return {
      id: input.orderId || "",
      code: input.orderCode || "",
      relationId: input.relationId || "",
      isInvoiced: input.invoiced || false,
      isPaid: input.paid || false,
      invoiceId: input.invoiceId || undefined,
      invoiceProvider: input.invoiceProvider || undefined,
      details: details,
      items: items,
      buyerAccountId: input.buyerAccountId || "",
      vendorAccountId: input.vendorAccountId || "",
      buyer: input.buyer ? AccountModel.deserialize(input.buyer) : undefined,
      vendor: input.vendor ? AccountModel.deserialize(input.vendor) : undefined,
      creator: {
        id: input.creator?.userId || "",
        firstName: input.creator?.firstName || "",
        lastName: input.creator?.lastName || "",
      },
      creationDate: new Date(input.creationDate),
      createdBy: input.createdBy || "",
      status: status,
      notes: input.orderNote,
      refCode: input.orderRefCode,
      total: input.orderTotal,
      currency: currency,
    };
  },

  serialize(input: Order): unknown {
    return {
      orderId: input.id,
      orderCode: input.code,
      relationId: input.relationId,
      invoiced: input.isInvoiced,
      paid: input.isPaid,
      invoiceId: input.invoiceId,
      invoiceProvider: input.invoiceProvider,
      details: input.details.map(OrderDetailsModel.serialize),
      items: input.items.map(OrderItemModel.serialize),
      buyerAccountId: input.buyerAccountId,
      vendorAccountId: input.vendorAccountId,
      buyer: input.buyer ? AccountModel.serialize(input.buyer) : undefined,
      vendor: input.vendor ? AccountModel.serialize(input.vendor) : undefined,
      creator: {
        userId: input.creator.id,
        firstName: input.creator.firstName,
        lastName: input.creator.lastName,
      },
      creationDate: input.creationDate.getTime(),
      createdBy: input.createdBy,
      orderNote: input.notes,
      orderRefCode: input.refCode,
      orderTotal: input.total,
    };
  },
};
