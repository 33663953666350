import { FilterResult, SearchOptions } from "@/models/listable.interface";
import { defaultPagination } from "@/services/configService";
import { SorterResult } from "ant-design-vue/es/table/interface";
import logger from "@/services/loggerService";
import { useNotificationsStore } from "@/stores/notifications";
import { cloneDeep } from "lodash";

export interface ListableMixinState {
  isLoading: boolean;
  searchOptions: SearchOptions;
  searchError: string;
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  searchFnc: (opt: SearchOptions) => any;
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  items: any[];
}

export const defaultSearchOptions = {
  pagination: defaultPagination,
  sorter: undefined,
  filters: undefined,
  includeChildren: false,
} as SearchOptions;

export const defaultListableState = {
  isLoading: false,
  searchOptions: cloneDeep(defaultSearchOptions),
  items: [],
  searchError: "",
  searchFnc: () => {
    return;
    // logger.log("mixin implementation");
  },
} as ListableMixinState;

export const actions = {
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  //this block is full of ts ignore because is a mixin for a state
  updateSearchOptions(options: SearchOptions, noSearch = false) {
    //need to update only some searchOptions
    // @ts-ignore
    this.searchOptions.pagination = options.pagination;
    // @ts-ignore
    this.searchOptions.pagination.showTotal =
      defaultSearchOptions.pagination.showTotal;
    // @ts-ignore
    this.searchOptions.filters = options.filters;
    // @ts-ignore
    this.searchOptions.sorter = options.sorter;
    if (options.filterBetweenDate) {
      // @ts-ignore
      this.searchOptions.filterBetweenDate = options.filterBetweenDate;
    }
    if (!noSearch) this.search();
  },
  resetSearchOptions(noSearch = false) {
    // @ts-ignore
    this.searchOptions = cloneDeep(defaultSearchOptions);
    if (!noSearch) this.search();
  },
  setAdditionalFilters(filters: FilterResult | undefined, noSearch = false) {
    // @ts-ignore
    this.searchOptions.additionalFilters = filters;
    if (!noSearch) this.search();
  },
  setPreSort(preSort: SorterResult | undefined, noSearch = false) {
    // @ts-ignore
    this.searchOptions.preSort = preSort;
    if (!noSearch) this.search();
  },
  setParentAccountId(id: string | undefined, noSearch = false) {
    // @ts-ignore
    this.searchOptions.parentAccountId = id;
    if (!noSearch) this.search();
  },
  async search() {
    const notifications = useNotificationsStore();
    // @ts-ignore
    this.isLoading = true;
    try {
      // @ts-ignore
      if (this.searchFnc) {
        // @ts-ignore
        const res = await this.searchFnc(this.searchOptions);
        // @ts-ignore
        this.items = res?.items;
        // @ts-ignore
        this.searchOptions.pagination.total = res?.count;
      }
    } catch (err) {
      logger.error(err);
      // @ts-ignore
      notifications.error(this.searchError);
      // @ts-ignore
      this.items = [];
      // @ts-ignore
      this.searchOptions.pagination.total = 0;
    } finally {
      // @ts-ignore
      this.isLoading = false;
    }
  },
  /* eslint-enable */
};

//store instance for testing purposes
// export const useListableStore = defineStore({
//   id: "listableMixin",
//   state: (): ListableMixinState => cloneDeep(defaultListableState),
//   actions: {
//     ...actions,
//   },
// });
