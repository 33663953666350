<template>
  <div class="flex justify-between">
    <va-page-title>{{ t("productsTypes") }}</va-page-title>
    <va-button
      :disabled="!productTypesStore.canCreate"
      @click="openCreateDialog"
    >
      <i class="rm rm-ico-e-add" style="" />
    </va-button>
  </div>
  <va-table
    :data-source="productTypesStore.items"
    :table-config="tableConfig"
    :search-options="productTypesStore.searchOptions"
    :loading="productTypesStore.isLoading"
    class="w-100"
    @update="productTypesStore.updateSearchOptions"
  >
    <template #bodyCell="{ text, column, record }">
      <template v-if="column.dataIndex === 'name'">
        <router-link
          :to="`/products/types/${record.id}`"
          class="text-indigo-600"
        >
          {{ text }}
        </router-link>
      </template>
    </template>
  </va-table>
  <va-dialog v-model="createDialog">
    <a-form
      v-if="formState"
      class=""
      :model="formState"
      :validate-messages="validateMessages"
      @finish="onSave"
    >
      <a-form-item
        :name="['name']"
        :label="t('name')"
        :rules="[{ required: true }]"
        :disabled="productTypesStore.isLoading"
      >
        <va-input v-model="formState.name" :placeholder="t('name')" />
      </a-form-item>
      <a-form-item
        :name="['description']"
        :label="t('description')"
        :disabled="productTypesStore.isLoading"
      >
        <va-input
          v-model="formState.description"
          :placeholder="t('description')"
        />
      </a-form-item>
      <product-features-edit
        v-if="formState.features"
        :features="formState.features"
        @update="updateFeatures"
      />
      <div class="flex justify-end mt-4">
        <va-button class="mr-4" @click="createDialog = false">
          {{ t("dismiss") }}
        </va-button>
        <a-form-item>
          <va-button
            html-type="submit"
            class="float-right"
            :disabled="productTypesStore.isLoading"
            :loading="productTypesStore.isLoading"
          >
            {{ t("save") }}
          </va-button>
        </a-form-item>
      </div>
    </a-form>
  </va-dialog>
</template>

<script setup lang="ts">
import VaTable from "@/components/organisms/VaTable.vue";
import { tableConfig } from "@/models/productType.interface";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import { t } from "@/plugins/i18n";
import VaButton from "@/components/molecules/VaButton.vue";
import { useProductTypesStore } from "@/stores/productTypes";
import VaInput from "@/components/molecules/VaInput.vue";
import VaDialog from "@/components/molecules/VaDialog.vue";
import { ref } from "vue";
import ProductFeaturesEdit from "@/components/organisms/ProductFeaturesEdit.vue";
import clone from "lodash/clone";
import { ProductFeature } from "@/models/productFeature.interface";
import { useValidationMessages } from "@/composables/validation";
import { useMeta } from "vue-meta";

useMeta({
  title: "Products",
});
const validateMessages = useValidationMessages();
const createDialog = ref(false);

const openCreateDialog = () => {
  createDialog.value = true;
  formState.value = clone(productTypesStore.emptyType);
};
const productTypesStore = useProductTypesStore();
const formState = ref(clone(productTypesStore.emptyType));

const updateFeatures = (args: ProductFeature[]) => {
  if (formState.value) formState.value.features = args;
};

const onSave = () => {
  productTypesStore.createType(formState.value);
};
</script>
