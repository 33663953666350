import { DefaultOptionType } from "ant-design-vue/lib/vc-select/Select";

//type for va-select options component
export interface SelectOption<T> extends DefaultOptionType {
  value: string;
  label: string;
  fullItem: T;
}

export const buildOption = <T>(
  item: T,
  value: string,
  label: string
): SelectOption<T> => {
  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    value: item[value] || "",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    label: item[label] || "",
    fullItem: item,
  };
};
