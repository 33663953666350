import { TableConfig } from "@/models/listable.interface";

// "accountFileId": "a2833be8-a74f-4c56-97bf-555111199f9b",
//   "fileCategory": 2,
//   "ownerAccountId": "936995ff-4833-46ed-b85b-1eb07f5d6a60",
//   "ownerAccountName": "Re Mago Ltd (ODM)",
//   "fileName": "PngItem_4852378",
//   "fileType": "png",
//   "fileSize": 18876,
//   "fileHash": "5nCZJMuvRFvZTlU1Av9dEQ==",
//   "fileNote": "Test 3",
//   "uploadedByAccountId": "936995ff-4833-46ed-b85b-1eb07f5d6a60",
//   "uploadedByAccountName": "Re Mago Ltd (ODM)",
//   "uploadedByUserFullName": "Re Mago Administrator",
//   "uploadDate": 1720615264913
//
// "ownerAccountId": "936995ff-4833-46ed-b85b-1eb07f5d6a60",
//   "accountFileId": "a2833be8-a74f-4c56-97bf-555111199f9b",
//   "fileCategory": 2,
//   "fileName": "PngItem_4852378",
//   "fileType": "png",
//   "contentType": "image/png",
//   "fileSize": 18876,
//   "fileHash": "5nCZJMuvRFvZTlU1Av9dEQ==",
//   "downloadUrl": "https://remagogroup.file.core.windows.net/valarea-pods-files/936995ff483346edb85b1eb07f5d6a60/a2833be8a74f4c5697bf555111199f9b?sv=2024-05-04&spr=https&se=2024-07-10T20%3A41%3A04Z&sr=f&sp=r&sig=LZ4Cw8szzRDu8kUEH%2BV%2FgIvmNGGadFzY6TddW%2Fdk3xY%3D",
//   "downloadUrlExpirationDate": 1720644064913

export interface FileDownload {
  accountFileId: string;
  fileCategory: number;
  ownerAccountId: string;
  ownerAccountName: string;
  fileName: string;
  fileType: string;
  fileSize: number;
  fileHash: string;
  fileNote: string;
  uploadedByAccountId: string;
  uploadedByAccountName: string;
  uploadedByUserFullName: string;
  uploadDate: Date;
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): FileDownload {
    return {
      accountFileId: input.accountFileId || "",
      fileCategory: input.fileCategory || 0,
      ownerAccountId: input.ownerAccountId || "",
      ownerAccountName: input.ownerAccountName || "",
      fileName: input.fileName || "",
      fileType: input.fileType || "",
      fileSize: input.fileSize || 0,
      fileHash: input.fileHash || "",
      fileNote: input.fileNote || "",
      uploadedByAccountId: input.uploadedByAccountId || "",
      uploadedByAccountName: input.uploadedByAccountName || "",
      uploadedByUserFullName: input.uploadedByUserFullName || "",
      uploadDate: new Date(input.uploadDate),
    };
  },
  serialize(input: FileDownload): unknown {
    return {
      accountFileId: input.accountFileId,
      fileCategory: input.fileCategory,
      ownerAccountId: input.ownerAccountId,
      ownerAccountName: input.ownerAccountName,
      fileName: input.fileName,
      fileType: input.fileType,
      fileSize: input.fileSize,
      fileHash: input.fileHash,
      fileNote: input.fileNote,
      uploadedByAccountId: input.uploadedByAccountId,
      uploadedByAccountName: input.uploadedByAccountName,
      uploadedByUserFullName: input.uploadedByUserFullName,
      uploadDate: input.uploadDate.getTime(),
    };
  },
};

export const tableConfig = {
  fileName: {
    dtoName: "fileName",
    sortable: true,
    filterable: true,
    label: "name",
  },
  fileType: {
    dtoName: "fileType",
    sortable: false,
    filterable: true,
    label: "type",
  },
  fileSize: {
    dtoName: "fileSize",
    sortable: false,
    filterable: false,
    label: "size",
  },
  fileNote: {
    dtoName: "fileNote",
    sortable: false,
    filterable: false,
    label: "notes",
  },
  uploadedByAccountName: {
    dtoName: "uploadedByAccountName",
    sortable: false,
    filterable: true,
    label: "uploadBy",
  },
  uploadDate: {
    dtoName: "uploadDate",
    sortable: true,
    filterable: false,
    label: "uploadDate",
  },
  ownerAccountName: {
    dtoName: "ownerAccountName",
    sortable: true,
    filterable: true,
    label: "owner",
  },
  actions: {
    dtoName: "accountFileId",
    sortable: false,
    filterable: false,
    label: "actions",
  },
} as TableConfig;
