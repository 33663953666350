<template>
  <a-tag
    v-model:visible="visible"
    :closable="props.closable"
    :color="props.color"
    @close="onClose"
  >
    <!--    <div v-if="props.dot">-->
    <!--      <svg-->
    <!--        :class="['-ml-0.5 mr-1.5 h-2 w-2', 'text-' + props.dot + '-600']"-->
    <!--        fill="currentColor"-->
    <!--        viewBox="0 0 8 8"-->
    <!--      >-->
    <!--        <circle cx="4" cy="4" r="3" />-->
    <!--      </svg>-->
    <!--    </div>-->
    <slot />
  </a-tag>
</template>

<script setup lang="ts">
import { ref } from "vue";

const props = defineProps({
  color: {
    type: String,
    required: false,
    default: "gray",
  },
  dot: {
    type: String,
    required: false,
    default: undefined,
  },
  closable: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const visible = ref(true);

function onClose() {
  visible.value = false;
}
</script>

<style lang="scss">
// noinspection CssInvalidAtRule
//.ant-tag {
//  @apply inline-flex rounded-full items-center py-0.5 px-2.5 text-sm font-medium bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-100;
//  &.ant-tag-hidden {
//    @apply hidden;
//  }
//
//  > span {
//    @apply flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white;
//    svg {
//      @apply h-2 w-2 text-gray-400;
//    }
//  }
//}
</style>
