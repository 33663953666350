import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouterView,
} from "vue-router";
import { h } from "vue";
import SignIn from "@/components/pages/auth/SignIn.vue";
import ForgotPassword from "@/components/pages/auth/ForgotPassword.vue";
import Dashboard from "@/components/pages/Dashboard.vue";
import Profile from "@/components/pages/profile/Profile.vue";
import ProfileUser from "@/components/pages/profile/ProfileUser.vue";
import ProfileAccount from "@/components/pages/profile/ProfileAccount.vue";
import ProfileEdit from "@/components/pages/profile/ProfileEdit.vue";
import AccountEdit from "@/components/pages/profile/AccountEdit.vue";
import AccountCertifications from "@/components/pages/accounts/AccountCertifications.vue";
import AccountLicenses from "@/components/pages/accounts/AccountLicenses.vue";
import AccountUsers from "@/components/pages/accounts/AccountUsers.vue";
import AccountSellIn from "@/components/pages/accounts/AccountSellIn.vue";
import AccountSellOut from "@/components/pages/accounts/AccountSellOut.vue";
import AccountPriceList from "@/components/pages/accounts/AccountPricelist.vue";
import AccountStocks from "@/components/pages/accounts/AccountStocks.vue";
import AccountList from "@/components/pages/accounts/AccountList.vue";
import AccountDetail from "@/components/pages/accounts/AccountDetail.vue";
import AccountFiles from "@/components/pages/accounts/AccountFiles.vue";
import EditAccount from "@/components/pages/accounts/EditAccount.vue";
import NewPartnerAccount from "@/components/pages/accounts/NewAccount.vue";
import NewUser from "@/components/pages/profile/NewUser.vue";
import SellOut from "@/components/pages/orders/OrderSellOut.vue";
import SellOutCreate from "@/components/pages/orders/OrderSellOutNew.vue";
import SellInCreate from "@/components/pages/orders/OrderSellInNew.vue";
import SellIn from "@/components/pages/orders/OrderSellIn.vue";
import OrderDetail from "@/components/pages/orders/OrderDetail.vue";
import ProductList from "@/components/pages/products/ProductList.vue";
import PriceLists from "@/components/pages/priceLists/Pricelists.vue";
import PriceListDetail from "@/components/pages/priceLists/PriceListDetail.vue";
import PriceListNew from "@/components/pages/priceLists/NewPricelist.vue";
import ProductDetail from "@/components/pages/products/ProductDetail.vue";
import ProductEdit from "@/components/pages/products/ProductEdit.vue";
import ProductNew from "@/components/pages/products/ProductNew.vue";
import ProductTypesList from "@/components/pages/products/ProductTypesList.vue";
import ProductTypeDetail from "@/components/pages/products/ProductTypeDetail.vue";
import ProductTypeEdit from "@/components/pages/products/ProductTypeEdit.vue";
import MainView from "@/components/templates/MainView.vue";
import ConfirmPassword from "@/components/pages/auth/ConfirmPassword.vue";
import ResetPassword from "@/components/pages/auth/ResetPassword.vue";
import LicenseList from "@/components/pages/licenses/LicensesList.vue";
import LicensesReports from "@/components/pages/licenses/LicensesReports.vue";
import LicenseDetail from "@/components/pages/licenses/LicenseDetail.vue";
import LicenseNew from "@/components/pages/licenses/LicenseNew.vue";
import DealsList from "@/components/pages/deals/DealsList.vue";
import DealDetail from "@/components/pages/deals/DealDetail.vue";
import DealNew from "@/components/pages/deals/DealNew.vue";
import NewUserCertification from "@/components/pages/certificicates/NewUserCertification.vue";
import NewAccountCertification from "@/components/pages/certificicates/NewAccountCertification.vue";
import CertificateDetail from "@/components/pages/certificicates/CertificateDetail.vue";
import CertificateAccountDetail from "@/components/pages/certificicates/CertificateAccountDetail.vue";
import ValidateCertificate from "@/components/pages/ValidateCertificate.vue";
import Stocks from "@/components/pages/stocks/StocksList.vue";
import { designSystemRoutes } from "@/router/designSystemRoutes";
import { useProductsStore } from "@/stores/products";
import { useProductTypesStore } from "@/stores/productTypes";
import { useAuthStore } from "@/stores/auth";
import AccountEnvironments from "@/components/pages/accounts/AccountEnvironments.vue";
import EnvDetail from "@/components/pages/onPremEnvironments/EnvDetail.vue";
import ProductFeaturesList from "@/components/pages/products/ProductFeaturesList.vue";
// import { h, resolveComponent } from "vue";

let routes: RouteRecordRaw[] = [
  {
    path: "/verify",
    name: "CertificateValidation",
    component: ValidateCertificate,
  },
  {
    path: "/auth/signin",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/auth/forgot-password",
    name: "ForgotPass",
    component: ForgotPassword,
  },
  {
    path: "/auth/confirm",
    name: "ConfirmSignUp",
    component: ConfirmPassword,
  },
  {
    path: "/auth/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/",
    name: "Home",
    component: MainView,
    redirect: "/dashboard",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
        children: [
          {
            path: "",
            name: "ProfileUser",
            component: ProfileUser,
          },
          {
            path: "edit",
            name: "ProfileEdit",
            component: ProfileEdit,
          },
          {
            path: "account",
            name: "ProfileAccount",
            component: ProfileAccount,
          },
          {
            path: "account-edit",
            name: "ProfileAccountEdit",
            component: AccountEdit,
          },
          {
            path: "create-user",
            name: "AccountNewUser",
            component: NewUser,
          },
        ],
      },
      {
        path: "/accounts",
        name: "Sales Network",
        component: AccountDetail,
        children: [
          {
            path: ":id/stock",
            name: "Account Stock",
            component: AccountStocks,
          },
          {
            path: ":id/users",
            name: "Account Users",
            component: AccountUsers,
          },
          {
            path: ":id/users/create",
            name: "AccountChildNewUser",
            component: NewUser,
          },
          {
            path: ":id/sell-in",
            name: "Account Sell In",
            component: AccountSellIn,
          },
          {
            path: ":id/sell-out",
            name: "Account Sell Out",
            component: AccountSellOut,
          },
          {
            path: ":id/pricelist",
            name: "AccountPricelist",
            component: AccountPriceList,
          },
          {
            path: ":id/certifications",
            name: "AccountCertifications",
            component: AccountCertifications,
          },
          {
            path: ":id/certifications/create",
            name: "AddNewUserCertification",
            component: NewUserCertification,
          },
          {
            path: ":id/certifications/create/account",
            name: "AddNewAccountCertification",
            component: NewAccountCertification,
          },
          {
            path: ":id/certifications/:idCertificate",
            name: "UserCertificationDetail",
            component: CertificateDetail,
          },
          {
            path: ":id/accountcertifications/:idCertificate",
            name: "AccountCertificationDetail",
            component: CertificateAccountDetail,
          },
          {
            path: ":id/licenses",
            name: "Account Licenses",
            component: AccountLicenses,
          },
          {
            path: ":id/files",
            name: "Account Files",
            component: AccountFiles,
          },
          {
            path: ":id/create",
            name: "New Partner",
            component: NewPartnerAccount,
          },
          {
            path: ":id/environments",
            name: "Account On Premise Environments",
            component: AccountEnvironments,
          },
          {
            path: ":id/edit",
            name: "Account edit",
            component: EditAccount,
          },
          {
            path: ":id",
            name: "Account Network",
            component: AccountList,
          },
          {
            path: "",
            name: "Account-Network",
            component: AccountList,
          },
        ],
      },
      {
        path: "/sell-in",
        component: { render: () => h(RouterView) },
        children: [
          {
            path: "",
            name: "SellIn",
            component: SellIn,
          },
          {
            path: "create",
            name: "SellInCreate",
            component: SellInCreate,
          },
          {
            path: ":id",
            name: "SellInDetail",
            component: OrderDetail,
            props: { sellIn: true },
          },
        ],
      },
      {
        path: "/sell-out",
        component: { render: () => h(RouterView) },
        children: [
          {
            path: "",
            name: "SellOut",
            component: SellOut,
          },
          {
            path: "create",
            name: "SellOutCreate",
            component: SellOutCreate,
          },
          {
            path: ":id",
            name: "SellOutDetail",
            component: OrderDetail,
          },
        ],
      },
      {
        path: "/products",
        component: { render: () => h(RouterView) },
        children: [
          {
            path: "",
            name: "ProductList",
            component: ProductList,
          },
          {
            path: ":id/edit",
            name: "ProductEdit",
            component: ProductEdit,
          },
          {
            path: "create",
            name: "ProductNew",
            component: ProductNew,
            beforeEnter: (to, from, next) => {
              const productsStore = useProductsStore();
              productsStore.canCreate ? next() : next("/products");
            },
          },
          {
            path: "types",
            name: "ProductTypes",
            component: ProductTypesList,
            beforeEnter: (to, from, next) => {
              const productsTypesStore = useProductTypesStore();
              productsTypesStore.canRead ? next() : next("/products");
            },
          },
          {
            path: "types/:id",
            name: "ProductTypeDetail",
            component: ProductTypeDetail,
            beforeEnter: (to, from, next) => {
              const productsTypesStore = useProductTypesStore();
              productsTypesStore.canRead ? next() : next("/products");
            },
          },
          {
            path: "types/:id/edit",
            name: "ProductTypeEdit",
            component: ProductTypeEdit,
            beforeEnter: (to, from, next) => {
              const productsTypesStore = useProductTypesStore();
              productsTypesStore.canUpdate ? next() : next("/products");
            },
          },
          {
            path: "features",
            name: "ProductFeatures",
            component: ProductFeaturesList,
            beforeEnter: (to, from, next) => {
              const productsTypesStore = useProductTypesStore();
              productsTypesStore.canRead ? next() : next("/products");
            },
          },
          {
            path: ":id",
            name: "ProductDetail",
            component: ProductDetail,
          },
        ],
      },
      {
        path: "/pricelists",
        component: { render: () => h(RouterView) },
        children: [
          {
            path: "create",
            name: "NewPriceList",
            component: PriceListNew,
          },
          {
            path: ":id",
            name: "PriceListDetail",
            component: PriceListDetail,
          },
          {
            path: "",
            name: "PriceList",
            component: PriceLists,
          },
        ],
      },
      {
        path: "/licenses",
        component: { render: () => h(RouterView) },
        children: [
          // {
          //   path: "reports",
          //   name: "LicensesReports",
          //   component: LicensesReports,
          // },
          {
            path: "create",
            name: "NewLicense",
            component: LicenseNew,
          },
          {
            path: ":id",
            name: "LicenseDetail",
            component: LicenseDetail,
          },
          {
            path: "",
            name: "LicensesList",
            component: LicenseList,
          },
        ],
      },
      {
        path: "/reports",
        component: { render: () => h(RouterView) },
        redirect: "/reports/licenses",
        meta: {
          title: "Reports",
        },
        children: [
          {
            path: "licenses",
            name: "LicensesReports",
            component: LicensesReports,
          },
        ],
      },
      {
        path: "/deals",
        component: { render: () => h(RouterView) },
        children: [
          {
            path: "create",
            name: "DealNew",
            component: DealNew,
          },
          {
            path: ":id",
            name: "DealDetail",
            component: DealDetail,
          },
          {
            path: "",
            name: "DealsList",
            component: DealsList,
          },
        ],
      },
      {
        path: "/stocks",
        component: Stocks,
      },
      {
        path: "/environments",
        component: { render: () => h(RouterView) },
        children: [
          {
            path: "create",
            name: "EnvNew",
            component: EnvDetail,
          },
          {
            path: ":id",
            name: "EnvDetail",
            component: EnvDetail,
          },
          // {
          //   path: "",
          //   name: "DealsList",
          //   component: DealsList,
          // },
        ],
      },
    ],
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/",
  },
];

if (process.env.VITE_USER_NODE_ENV !== "production") {
  //enable design system routes only for development
  routes = routes.concat(designSystemRoutes);
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    //route requires auth
    if (authStore.isAuthenticated) {
      if (!authStore.user) {
        await authStore.recoverLogin();
      }
      next();
    } else {
      next(`/auth/signin?redirect=${to.fullPath}`);
    }
  } else {
    //free route
    next();
  }
});

export default router;
