import { Address } from "@/models/address.interface";

export interface Prospect {
  companyName?: string;
  vatId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  webSite?: string;
  address?: Address;
  creationDate: Date;
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): Prospect {
    return {
      companyName: input.name,
      firstName: input.firstName,
      lastName: input.lastName,
      email: input.email,
      phone: input.phoneNumber,
      webSite: input.webSite,
      // address: addressModel.deserialize(input.address),
      address: {
        id: input.addressId || "",
        legalName: input.name || "",
        vatId: input.vatId || "",
        street: input.street || "",
        city: input.city || "",
        locality: input.locality || "",
        region: input.region || "",
        postalCode: input.postalCode,
        country: input.country || "",
        countryCode: input.countryCode || "",
        coordinates: input.coordinates || undefined,
      },
      creationDate: new Date(input.creationDate),
    };
  },
  serialize(input: Prospect): unknown {
    return {
      name: input.companyName,
      email: input.email,
      phoneNumber: input.phone,
      webSite: input.webSite,
      vatId: input.vatId,
      firstName: input.firstName,
      lastName: input.lastName,
      street: input.address?.street,
      city: input.address?.city,
      locality: input.address?.locality,
      region: input.address?.region,
      postalCode: input.address?.postalCode,
      country: input.address?.country,
      countryCode: input.address?.countryCode,
      coordinates: input.address?.coordinates,
      creationDate: input.creationDate.getTime(),
    };
  },
};
