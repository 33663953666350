<template>
  <va-page-title>{{ t("newDeal") }}</va-page-title>
  <router-link to="/deals" class="text-indigo-700">
    <i class="rm rm-ico-ctrl-left" /> {{ t("backToDeals") }}
  </router-link>
  <a-form
    class=""
    :model="formState"
    :validate-messages="validateMessages"
    @finish="onFinish"
  >
    <div class="va-card mt-6 relative p-4">
      <a-form-item
        :name="['parentAccountId']"
        :label="t('owner')"
        :rules="[{ required: true }]"
      >
        <va-select
          v-model="formState.parentAccountId"
          :options="currentChildren"
          :placeholder="t('createAsChild')"
          :disabled="priceListsStore.isLoading"
          @change="onChangeChild"
        >
          <template #option="option">
            <option-account :item="option.nestedProps.fullItem" />
          </template>
        </va-select>
      </a-form-item>
    </div>
    <div class="va-card mt-6 relative p-4">
      <h3 class="va-title-s mb-4">{{ t("prospect") }}</h3>
      <div class="grid grid-cols-3 gap-4">
        <a-form-item
          :name="['prospect', 'name']"
          :label="t('companyName')"
          :rules="[{ required: true }]"
        >
          <va-input
            v-model="formState.prospect.name"
            :placeholder="t('companyName')"
          />
        </a-form-item>
        <a-form-item
          :name="['prospect', 'vatId']"
          :label="t('vatId')"
          :rules="[{ required: true }]"
        >
          <va-input
            v-model="formState.prospect.vatId"
            :placeholder="t('vatId')"
          />
        </a-form-item>
        <a-form-item :name="['prospect', 'webSite']" :label="t('webSite')">
          <va-input
            v-model="formState.prospect.webSite"
            :placeholder="t('webSite')"
          />
        </a-form-item>
        <a-form-item
          :name="['prospect', 'firstName']"
          :label="t('firstName')"
          :rules="[{ required: true }]"
        >
          <va-input
            v-model="formState.prospect.firstName"
            :placeholder="t('firstName')"
          />
        </a-form-item>
        <a-form-item
          :name="['prospect', 'lastName']"
          :label="t('lastName')"
          :rules="[{ required: true }]"
        >
          <va-input
            v-model="formState.prospect.lastName"
            :placeholder="t('lastName')"
          />
        </a-form-item>
        <a-form-item :name="['prospect', 'email']" :label="t('email')">
          <va-input
            v-model="formState.prospect.email"
            :placeholder="t('email')"
          />
        </a-form-item>
        <a-form-item :name="['prospect', 'phoneNumber']" :label="t('phone')">
          <va-input
            v-model="formState.prospect.phoneNumber"
            :placeholder="t('phone')"
          />
        </a-form-item>
        <a-form-item
          :name="['prospect', 'street']"
          :label="t('street')"
          :rules="[{ required: true }]"
        >
          <va-input
            v-model="formState.prospect.street"
            :placeholder="t('street')"
          />
        </a-form-item>
        <a-form-item
          :name="['prospect', 'city']"
          :label="t('city')"
          :rules="[{ required: true }]"
        >
          <va-input
            v-model="formState.prospect.city"
            :placeholder="t('city')"
          />
        </a-form-item>
        <a-form-item
          :name="['prospect', 'postalCode']"
          :label="t('postalCode')"
          :rules="[{ required: true }]"
        >
          <va-input
            v-model="formState.prospect.postalCode"
            :placeholder="t('postalCode')"
          />
        </a-form-item>
        <a-form-item
          :name="['prospect', 'country']"
          :label="t('country')"
          :rules="[{ required: true }]"
        >
          <va-select
            v-model="formState.prospect.countryCode"
            :options="lookupStore.countriesOptions"
            :placeholder="t('country')"
            :disabled="lookupStore.countriesLoading"
            @change="selectCountry"
          />
        </a-form-item>
        <a-form-item :name="['prospect', 'region']" :label="t('region')">
          <va-input
            v-model="formState.prospect.region"
            :placeholder="t('region')"
          />
        </a-form-item>
      </div>
    </div>

    <div class="va-card mt-6 relative p-4">
      <h3 class="va-title-s mb-4">{{ t("deal") }}</h3>

      <div class="grid grid-cols-3 gap-4">
        <a-form-item :name="['chanceOfClosure']" :label="t('closureChance')">
          <va-input
            v-model="formState.chanceOfClosure"
            type="number"
            suffix="%"
            :min="0"
            :max="100"
            :placeholder="t('closureChance')"
          />
        </a-form-item>
        <a-form-item :name="['startDate']" :label="t('startDate')">
          <va-date-picker
            v-model="formState.startDate"
            :placeholder="t('startDate')"
          />
        </a-form-item>
        <a-form-item
          :name="['estimatedCloseDate']"
          :label="t('estimatedCloseDate')"
        >
          <va-date-picker
            v-model="formState.estimatedCloseDate"
            :placeholder="t('estimatedCloseDate')"
          />
        </a-form-item>
      </div>

      <a-form-item :name="['description']" :label="t('dealDescription')">
        <va-input
          v-model="formState.description"
          type="textarea"
          :placeholder="t('description')"
        />
      </a-form-item>
    </div>

    <div class="va-card mt-6 relative p-4">
      <h3 class="va-title-s mb-4">{{ t("addProduct") }}</h3>

      <div class="flex mt-3">
        <div class="grow mr-4">
          <a-form-item :label="t('product')">
            <va-select
              v-model="selectedProduct"
              :options="products"
              :placeholder="t('product')"
              :disabled="productsStore.isLoading || !dealsStore.canCreate"
            >
              <template #option="option">
                <option-product :item="option.nestedProps.fullItem" />
              </template>
            </va-select>
          </a-form-item>
        </div>
        <a-form-item :label="t('quantity')">
          <va-input
            v-model="selectedQuantity"
            type="number"
            placeholder="t('quantity')"
            :min="1"
            integer
            class="mr-4"
          >
          </va-input>
        </a-form-item>

        <va-button
          class="float-right mt-8"
          :disabled="!dealsStore.canCreate || selectedProduct === ''"
          @click="addProduct"
        >
          <i class="rm rm-ico-e-add" />
        </va-button>
      </div>
      <table class="min-w-full divide-y divide-gray-300 mt-4">
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("product") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("quantity") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("price") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ t("total") }}
            </th>

            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            ></th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-for="(item, index) in formState.items" :key="index">
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              {{ item.fullItem?.name }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              <div class="text-right">
                {{ item.qt }}
              </div>
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              <div class="text-right">
                {{
                  useFormatCurrency(
                    item.fullItem?.price,
                    item.fullItem?.currency
                  )
                }}
              </div>
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              <div class="text-right">
                {{
                  useFormatCurrency(
                    (item.fullItem?.price || 0) * item.qt,
                    item.fullItem?.currency
                  )
                }}
              </div>
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm">
              <va-button
                class="float-right"
                :disabled="!dealsStore.canCreate"
                @click="removeProduct(index)"
              >
                <i class="rm rm-ico-bin" />
              </va-button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="flex flex-row-reverse mt-4">
        <a-form-item>
          <va-button
            html-type="submit"
            :disabled="
              !dealsStore.canCreate ||
              !formState.items.length ||
              dealsStore.isLoading
            "
            :loading="dealsStore.isLoading"
          >
            {{ t("save") }}
          </va-button>
        </a-form-item>
        <h3 class="va-title-s mr-8 mt-1">
          {{ t("total") }}:
          {{ useFormatCurrency(totalDeal, pricelist?.currency) }}
        </h3>
      </div>
    </div>
  </a-form>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from "vue";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import { t } from "@/plugins/i18n";
import VaSelect from "@/components/molecules/VaSelect.vue";
import { Product } from "@/models/product.interface";
import VaDatePicker from "@/components/molecules/VaDatePicker.vue";
import { useValidationMessages } from "@/composables/validation";
import { DealFormData } from "@/models/deal.interface";
import { canCreateDeal } from "@/services/configService";
import { useFormatCurrency } from "@/composables/formatters";
import OptionAccount from "@/components/molecules/selectOptions/OptionAccount.vue";
import OptionProduct from "@/components/molecules/selectOptions/OptionProduct.vue";
import { usePricelistsStore } from "@/stores/pricelists";
import { useProductsStore } from "@/stores/products";
import { useLookupStore } from "@/stores/lookup";
import { useDealsStore } from "@/stores/deals";
import { useAccountsStore } from "@/stores/accounts";
import { buildOption, SelectOption } from "@/models/selectOptions.interface";
import { CountryData } from "@/models/countryData.interface";
import { Account } from "@/models/account.interface";
import { useMeta } from "vue-meta";

useMeta({
  title: "Deals",
});
const accountsStore = useAccountsStore();
const dealsStore = useDealsStore();
const lookupStore = useLookupStore();
const productsStore = useProductsStore();
const priceListsStore = usePricelistsStore();
const validateMessages = useValidationMessages();

const selectedProduct = ref("");
const selectedQuantity = ref(1);
const totalDeal = ref(0);

const accountId = computed(() => {
  return accountsStore.currentAccount &&
    canCreateDeal(accountsStore.currentAccount?.group.level)
    ? accountsStore.currentAccount.id
    : undefined;
});

const products = ref([] as SelectOption<Product>[]);

const pricelist = computed(() => priceListsStore.currentPriceList);

watch(pricelist, (currentValue) => {
  if (currentValue) {
    products.value = (currentValue.products as Product[]).map((product) => {
      return buildOption<Product>(product, "id", "name");
    });
  }
});

const formState = ref({
  description: "",
  startDate: new Date(),
  estimatedCloseDate: new Date(),
  chanceOfClosure: "",
  items: [] as { productId: string; qt: number; fullItem: Product }[],
  prospect: {
    customerInformationId: "",
    name: "",
    email: "",
    phoneNumber: "",
    webSite: "",
    legalName: "",
    vatId: "",
    firstName: "",
    lastName: "",
    street: "",
    city: "",
    locality: "",
    region: "",
    postalCode: "",
    country: "",
    countryCode: "",
    coordinates: "",
  },
  parentAccountId: accountId.value,
} as DealFormData);

const selectCountry = (item: SelectOption<CountryData>) => {
  formState.value.prospect.country = item.label;
  formState.value.prospect.countryCode = item.value as string;
};

const addProduct = () => {
  const prod = products.value.find((p) => {
    return p.value === selectedProduct.value;
  })?.fullItem as Product;

  formState.value.items.push({
    productId: selectedProduct.value,
    qt: selectedQuantity.value,
    fullItem: prod,
  });
  totalDeal.value = totalDeal.value + selectedQuantity.value * prod.price;
};

const removeProduct = (index: number) => {
  const rem = formState.value.items.splice(index, 1);
  totalDeal.value = totalDeal.value - rem[0].qt * rem[0].fullItem.price;
};

onBeforeMount(() => {
  productsStore.fetch();
  lookupStore.fetchCountries();
  accountsStore.fetchChildren();
  if (accountId.value) {
    priceListsStore.getAssociatedPriceList(accountId.value);
  } else {
    priceListsStore.setCurrentPriceList(undefined);
  }
});

const onFinish = () => {
  dealsStore.create(formState.value);
};

const currentChildren = computed(() => {
  return accountsStore.currentNetworkOptions.filter((opt) =>
    canCreateDeal(opt.fullItem.group.level)
  ); //discard account that are not dealers/resellers
});

const onChangeChild = (item: SelectOption<Account>) => {
  //fetch products associated to child pricelist
  priceListsStore.getAssociatedPriceList(item.value as string);
  formState.value.items = [];
  totalDeal.value = 0;
};
</script>
