<template>
  <div class="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
    <div class="my-6">
      {{ t("priceListAssociate") }}:
      <a-form class="mt-3" :model="formState" @finish="onAssociatePriceList">
        <div class="flex justify-between">
          <va-select
            v-model="formState.priceListId"
            :disabled="!canChangePriceList || !accountStore.canCreate"
            class="grow mr-4"
            :options="possiblePriceLists"
            :placeholder="t('country')"
          />
          <va-button
            html-type="submit"
            class="float-right"
            :disabled="
              !formState.priceListId ||
              !canChangePriceList ||
              !accountStore.canCreate
            "
          >
            {{ t("save") }}
          </va-button>
          <va-button
            class="ml-2"
            :disabled="
              !pricelist || !canChangePriceList || !priceListsStore.canUpdate
            "
            @click="onDeleteAssociation"
          >
            <i class="rm rm-ico-bin" />
          </va-button>
        </div>
        <!--        <div class="flex mt-3">-->
        <!--          <div class="mr-3">{{ t("pricelistAssociatePropagation") }}:</div>-->
        <!--          <a-switch-->
        <!--            v-model:checked="formState.canPropagate"-->
        <!--            :disabled="-->
        <!--              !formState.priceListId ||-->
        <!--              !canChangePriceList ||-->
        <!--              !accountStore.canCreate-->
        <!--            "-->
        <!--          ></a-switch>-->
        <!--        </div>-->
      </a-form>
    </div>
    <!--    <div v-else>-->
    <!--      {{ t("priceListAssociated") }}:-->
    <!--      <span v-if="pricelist" class="font-bold">{{ pricelist.name }}</span>-->
    <!--    </div>-->

    <span v-if="!pricelist" class="font-bold">
      {{ t("priceListNotAssociated") }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { Account } from "@/models/account.interface";
import { onBeforeMount, computed, watch, ref } from "vue";
import { useI18n } from "vue-i18n";
import VaSelect from "@/components/molecules/VaSelect.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import { useRoute } from "vue-router";
import { usePricelistsStoreAccount } from "@/stores/pricelists";
import { useAccountsStore } from "@/stores/accounts";
import { useAuthStore } from "@/stores/auth";

const { t } = useI18n();
const accountStore = useAccountsStore();
const authStore = useAuthStore();
const priceListsStore = usePricelistsStoreAccount();
const route = useRoute();

const account = computed(() => accountStore.currentAccount);
const pricelist = computed(() => priceListsStore.currentPriceList);
const possiblePriceLists = computed(
  () => priceListsStore.possiblePriceListsOptions
);
const canChangePriceList = computed(
  () =>
    authStore.userAccount?.group &&
    account.value &&
    authStore.userAccount.group.level < account.value?.group.level
);

const formState = ref({
  priceListId: pricelist.value?.id || "",
  canPropagate: false,
});

function fetchPriceLists(account: Account) {
  if (account) {
    priceListsStore.getAssociatedPriceList(account.id);
    const directParentId = account.parents.length
      ? account.parents[account.parents.length - 1].id
      : account.id;
    priceListsStore.fetchByParentId(directParentId);
  }
}

watch(account, (currentValue) => {
  if (currentValue) fetchPriceLists(currentValue);
});

watch(pricelist, (currentValue) => {
  formState.value.priceListId = currentValue?.id || "";
});

onBeforeMount(() => {
  if (account.value) {
    fetchPriceLists(account.value);
  } else {
    accountStore.get(route.params.id as string);
  }
});

const onAssociatePriceList = () => {
  if (account.value) {
    priceListsStore.associate({
      priceListId: formState.value.priceListId,
      accountId: account.value.id,
      canPropagate: formState.value.canPropagate,
    });
  }
};
const onDeleteAssociation = () => {
  if (pricelist.value?.id && account.value) {
    priceListsStore.deleteAssignment({
      priceListId: pricelist.value.id,
      accountId: account.value.id,
    });
  }
};
</script>
