<template>
  <div class="flex justify-between">
    <va-page-title>{{ t("productFeatures") }}</va-page-title>
    <va-button
      :disabled="!productTypesStore.canCreate"
      @click="openCreateDialog"
    >
      <i class="rm rm-ico-e-add" style="" />
    </va-button>
  </div>
  <va-table
    :data-source="productFeaturesStore.items"
    :table-config="tableConfig"
    :search-options="productFeaturesStore.searchOptions"
    :loading="productFeaturesStore.isLoading"
    class="w-100"
    @update="productFeaturesStore.updateSearchOptions"
  >
    <template #bodyCell="{ text, column, record }">
      <template v-if="column.dataIndex === 'id'">
        <va-delete-button
          class="mr-4"
          :disabled="!productTypesStore.canDelete"
          :confirm-text="t('productRemoveDescription')"
          :confirm-title="t('productRemove')"
          @click:confirm="productFeaturesStore.removeFeature(text)"
        />
        <va-button @click="openEditDialog(record)">
          <i class="rm rm-ico-edit" />
        </va-button>
      </template>
    </template>
  </va-table>
  <va-dialog v-model="editDialog">
    <a-form
      class=""
      :model="formState"
      :validate-messages="validateMessages"
      @finish="saveFeature"
    >
      <a-form-item
        :name="['name']"
        :label="t('name')"
        :rules="[{ required: true }]"
      >
        <va-input v-model="formState.name" :placeholder="t('name')" />
      </a-form-item>
      <a-form-item
        :name="['description']"
        :label="t('description')"
        :rules="[{ required: true }]"
      >
        <va-input
          v-model="formState.description"
          :placeholder="t('description')"
        />
      </a-form-item>
    </a-form>

    <div class="flex justify-end mt-4">
      <va-button class="mr-4" @click="editDialog = false">
        {{ t("dismiss") }}
      </va-button>
      <va-button @click="saveFeature">
        {{ t("save") }}
      </va-button>
    </div>
  </va-dialog>
  <va-dialog v-model="createDialog">
    <a-form
      class=""
      :model="formState"
      :validate-messages="validateMessages"
      @finish="saveFeature"
    >
      <a-form-item
        :name="['name']"
        :label="t('name')"
        :rules="[{ required: true }]"
      >
        <va-input v-model="formState.name" :placeholder="t('name')" />
      </a-form-item>
      <a-form-item
        :name="['description']"
        :label="t('description')"
        :rules="[{ required: true }]"
      >
        <va-input
          v-model="formState.description"
          :placeholder="t('description')"
        />
      </a-form-item>
      <a-form-item
        :name="['type']"
        :label="t('type')"
        :rules="[{ required: true }]"
      >
        <va-select
          v-model="formState.type"
          :options="productFeaturesStore.featuresTypesOptions"
          :placeholder="t('type')"
        >
        </va-select>
      </a-form-item>
    </a-form>

    <div class="flex justify-end mt-4">
      <va-button class="mr-4" @click="createDialog = false">
        {{ t("dismiss") }}
      </va-button>
      <va-button @click="createFeature">
        {{ t("save") }}
      </va-button>
    </div>
  </va-dialog>
</template>

<script setup lang="ts">
import VaTable from "@/components/organisms/VaTable.vue";
import { ProductFeature, tableConfig } from "@/models/productFeature.interface";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import { t } from "@/plugins/i18n";
import VaButton from "@/components/molecules/VaButton.vue";
import { useProductTypesStore } from "@/stores/productTypes";
import { useProductFeaturesStore } from "@/stores/productFeatures";
import VaDeleteButton from "@/components/molecules/VaDeleteButton.vue";
import VaDialog from "@/components/molecules/VaDialog.vue";
import { ref } from "vue";
import VaInput from "@/components/molecules/VaInput.vue";
import VaSelect from "@/components/molecules/VaSelect.vue";
import { useValidationMessages } from "@/composables/validation";
import { cloneDeep } from "lodash";
import { useMeta } from "vue-meta";

useMeta({
  title: "Products",
});
const productTypesStore = useProductTypesStore();
const productFeaturesStore = useProductFeaturesStore();
const validateMessages = useValidationMessages();

const editDialog = ref(false);
const createDialog = ref(false);

const formState = ref(cloneDeep(productFeaturesStore.emptyFeature));

const openCreateDialog = () => {
  formState.value = cloneDeep(productFeaturesStore.emptyFeature);
  createDialog.value = true;
};

const openEditDialog = (item: ProductFeature) => {
  formState.value = cloneDeep(item);
  editDialog.value = true;
};

const saveFeature = () => {
  productFeaturesStore.saveFeature(formState.value);
  editDialog.value = false;
};
const createFeature = () => {
  productFeaturesStore.createFeature(formState.value);
  createDialog.value = false;
};
</script>
