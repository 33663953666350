<template>
  <auth :title="t('validateTitle')" :sub-title="t('validateDescription')">
    <a-form
      :model="formState"
      :validate-messages="validateMessages"
      @finish="validate"
    >
      <a-form-item
        :name="['validationCode']"
        :label="t('validationCode')"
        :rules="[{ required: true }]"
      >
        <va-input
          v-model="formState.validationCode"
          :placeholder="t('validationPlaceholder')"
        />
      </a-form-item>
      <div>
        <va-button
          :loading="loading"
          class="w-full flex justify-center"
          html-type="submit"
        >
          {{ t("validate") }}
        </va-button>
      </div>
    </a-form>
  </auth>
  <Transition
    enter-active-class="duration-500 ease-out"
    enter-from-class="transform opacity-0"
    enter-to-class="opacity-100"
  >
    <div
      v-if="certificate.valid"
      :class="[
        certificate.expired ? 'border-red-600' : 'border-green-600',
        'my-8 rounded-lg border-2 bg-white sm:mx-auto sm:w-full sm:max-w-md',
      ]"
    >
      <div class="py-8 bg-none px-4 sm:px-10 flex flex-col items-center">
        <h2 class="mb-2 text-xl">
          {{
            !certificate.expired
              ? t("validCertificate")
              : t("expiredCertificate")
          }}
        </h2>
        <p class="mb-2 text-3xl">
          {{ certificate.ownerFullName }}
        </p>
        <p class="mb-2">{{ certificate.certificateName }}</p>
        <p>
          {{ t("expiration") }}:
          <b>{{ formatDateUTC(certificate.expirationDate) }}</b>
        </p>
      </div>
    </div>
  </Transition>
  <Transition
    enter-active-class="duration-500 ease-out"
    enter-from-class="transform opacity-0"
    enter-to-class="opacity-100"
  >
    <div
      v-if="error"
      class="mt-8 rounded-lg border-red-600 border-2 bg-white sm:mx-auto sm:w-full sm:max-w-md"
    >
      <div class="py-8 bg-none px-4 sm:px-10 flex flex-col items-center">
        <h2 class="mb-2 text-3xl">
          {{ t("validateError") }}
        </h2>
        <p class="mb-2 text-xl">{{ error }}</p>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import Auth from "@/components/templates/Auth.vue";
import VaButton from "@/components/molecules/VaButton.vue";
import VaInput from "@/components/molecules/VaInput.vue";
import { useI18n } from "vue-i18n";
import { useValidationMessages } from "@/composables/validation";
import { reactive, ref } from "vue";
import { useFormatDateUTC } from "@/composables/formatters";
import * as certificationService from "@/services/certificationsService";
import { cloneDeep } from "lodash";
import { useRoute } from "vue-router";
import { useMeta } from "vue-meta";

useMeta({
  title: "Certificate validation",
});
const validateMessages = useValidationMessages();
const formatDateUTC = useFormatDateUTC;
const { t } = useI18n();
const route = useRoute();

const emptyCertificate = {
  certificateName: "",
  expirationDate: new Date(),
  creationDate: new Date(),
  ownerFullName: "",
  expired: false,
  valid: false,
};
const formState = reactive({
  validationCode: route.query?.code ? route.query.code : "",
});
const loading = ref(false);
const certificate = ref(cloneDeep(emptyCertificate));
const error = ref("");

async function validate() {
  loading.value = true;
  error.value = "";
  certificate.value = cloneDeep(emptyCertificate);
  try {
    const result = await certificationService.validateUserCertification(
      formState.validationCode as string
    );
    certificate.value.valid = true;
    certificate.value.certificateName = result.data.certificateName;
    certificate.value.ownerFullName = result.data.ownerFullName;
    certificate.value.expirationDate = new Date(result.data.expirationDate);
    certificate.value.creationDate = new Date(result.data.creationDate);
    certificate.value.expired =
      certificate.value.expirationDate.getTime() < new Date().getTime();
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  } catch (e: any) {
    if (e.response.data.errorCode && e.response.data.errorCode == "CC503") {
      error.value = t("validationCodeNotFound");
    } else {
      error.value = t("validateCertificateGeneralError");
    }
  } finally {
    loading.value = false;
  }
}
</script>
