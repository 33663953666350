<template>
  <div>
    <a-select
      ref="select"
      :value="modelValue"
      show-search
      :options="options"
      :placeholder="placeholder"
      :disabled="disabled"
      :filter-option="filterOption"
      :not-found-content="notFoundContent"
      :mode="multiple ? 'multiple' : ''"
      @change="onchange"
    >
      <template v-if="slots.option" #option="option">
        <slot name="option" :nestedProps="option"></slot>
      </template>
    </a-select>
  </div>
</template>

<script setup lang="ts">
import { useSlots } from "vue";
import { SelectOption } from "@/models/selectOptions.interface";
const slots = useSlots();

defineProps({
  modelValue: { type: String, required: false, default: "" },
  placeholder: { type: String, required: false, default: "" },
  notFoundContent: { type: String, required: false, default: undefined },
  disabled: { type: Boolean, required: false, default: false },
  multiple: { type: Boolean, required: false, default: false },
  options: {
    type: Array,
    required: false,
    default() {
      return [];
    },
  },
});

const emit = defineEmits(["change", "update:modelValue"]);

const onchange = (value: string, item: SelectOption<unknown>) => {
  emit("update:modelValue", value);
  emit("change", item);
};

const filterOption = (input: string, option: SelectOption<unknown>) => {
  return option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
</script>

<style lang="scss" scoped>
.ant-select {
  width: 100% !important;
}
</style>
