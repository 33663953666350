import { CurrencyData } from "@/models/currencyData.interface";
import accountModel, { Account } from "@/models/account.interface";
import dealItemModel, { DealItem } from "@/models/dealItem.interface";
import prospectModel, { Prospect } from "@/models/prospect.interface";
import logger from "@/services/loggerService";
import { TableConfig } from "@/models/listable.interface";
import { Product } from "@/models/product.interface";
import eBidModel, {
  Ebid,
  eBidStatus,
  deserializeEBidStatus,
} from "@/models/dealEbid.interface";

export const possibleDealStatus = [
  "New",
  "Trial",
  "Demo",
  "Negotiation",
  "Won",
  "Lost",
];
export const possibleProtectionStatus = [
  "Pending",
  "Approved",
  "Refused",
  "Duplicated",
];

export type dealStatus = typeof possibleDealStatus[number];
export type protectionStatus = typeof possibleProtectionStatus[number];

export interface Deal {
  id: string;
  code: string;
  description: string;
  items: DealItem[]; //di fatto un subset di OrderItem ,
  vendor: Account;
  startDate: Date;
  estimatedCloseDate?: Date;
  creationDate: Date;
  expirationDate?: Date;
  closureChance: number;
  protectionStatus: protectionStatus;
  status: dealStatus;
  isExpired: boolean;
  eBidStatus: eBidStatus;
  eBidValue: number;
  currency: CurrencyData;
  eBidApprovedValue?: number;
  eBid: Ebid[]; //è una lista o basta uno stato piatto? chiedere
  prospect: Prospect;
  totalItemsPrice: number;
}

export interface DealFormData {
  description: string;
  startDate: Date;
  estimatedCloseDate: Date;
  chanceOfClosure: string;
  items: { productId: string; qt: number; fullItem: Product }[];
  prospect: {
    customerInformationId: string;
    name: string;
    email: string;
    phoneNumber: string;
    webSite: string;
    legalName: string;
    vatId: string;
    firstName: string;
    lastName: string;
    street: string;
    city: string;
    locality: string;
    region: string;
    postalCode: string;
    country: string;
    countryCode: string;
    coordinates: string;
  };
  parentAccountId?: string;
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): Deal {
    const ebidHistory = input.ebid?.length
      ? input.ebid.map(eBidModel.deserialize)
      : [];
    const items = input.items?.length
      ? input.items.map(dealItemModel.deserialize)
      : [];
    const total = items
      .map((item: DealItem) => {
        return item.price * item.quantity;
      })
      .reduce((sum: number, price: number) => sum + price, 0);

    return {
      id: input.dealId,
      code: input.code,
      description: input.description,
      items: items,
      vendor: accountModel.deserialize(input.vendor),
      startDate: new Date(input.startDate),
      estimatedCloseDate: input.estimatedCloseDate
        ? new Date(input.estimatedCloseDate)
        : undefined,
      creationDate: new Date(input.creationDate),
      expirationDate: input.expirationDate
        ? new Date(input.expirationDate)
        : undefined,
      closureChance: input.chanceOfClosure || 0,
      protectionStatus: deserializePossibleProtectionStatus(
        input.protectionStatus
      ),
      status: deserializeDealStatus(input.status),
      isExpired: input.isExpired || false,
      eBidApprovedValue: input.eBidApprovedValue || input.eBidValue || total,
      eBidValue: input.eBidValue || total,
      eBidStatus: deserializeEBidStatus(input.eBidStatus),
      currency: ebidHistory[0]?.currency || items[0]?.currency,
      eBid: ebidHistory,
      prospect: prospectModel.deserialize(input.prospect),
      totalItemsPrice: total,
    };
  },
  serialize(input: Deal): unknown {
    return {
      dealId: input.id,
      code: input.code,
      description: input.description,
      items: input.items.map(dealItemModel.serialize),
      vendor: input.vendor ? accountModel.serialize(input.vendor) : undefined,
      startDate: input.startDate.getTime(),
      estimatedCloseDate: input.estimatedCloseDate
        ? input.estimatedCloseDate.getTime()
        : undefined,
      creationDate: input.creationDate.getTime(),
      expirationDate: input.expirationDate
        ? input.expirationDate.getTime()
        : undefined,
      closureChance: input.closureChance,
      protectionStatus: input.protectionStatus,
      status: input.status,
      isExpired: input.isExpired,
      eBidStatus: input.eBidStatus,
      ebid: input.eBid.map(eBidModel.serialize),
      prospect: prospectModel.serialize(input.prospect),
    };
  },
};

export const tableConfig = {
  id: {
    dtoName: "dealId",
    sortable: true,
    filterable: true,
    label: "id",
    hideColumn: true,
  },
  code: {
    dtoName: "code",
    sortable: true,
    filterable: true,
    label: "deal",
    hideColumn: false,
  },
  prospect: {
    dtoName: "prospectName",
    sortable: false,
    filterable: true,
    label: "prospect",
  },
  prospectVat: {
    dtoName: "prospectVatId",
    sortable: false,
    filterable: true,
    label: "vatId",
  },
  vendor: {
    dtoName: "vendorAccountName",
    sortable: false,
    filterable: true,
    label: "vendor",
  },
  status: {
    dtoName: "status",
    sortable: true,
    filterable: false,
    label: "status",
  },
  protectionStatus: {
    dtoName: "protectionStatus",
    sortable: true,
    filterable: false,
    label: "protectionStatus",
  },
  eBidValue: {
    dtoName: "eBidValue",
    sortable: true,
    filterable: false,
    label: "eBid",
  },
  eBidStatus: {
    dtoName: "eBidStatus",
    sortable: true,
    filterable: false,
    label: "eBidStatus",
  },
  creationDate: {
    dtoName: "creationDate",
    sortable: true,
    filterable: false,
    label: "creationDate",
    hideColumn: false,
  },
} as TableConfig;

export const deserializePossibleProtectionStatus = (
  input?: string
): protectionStatus => {
  if (input && possibleProtectionStatus.includes(input)) {
    return input;
  } else {
    logger.error(
      `Deal deserializer: unhandled protectionStatus value "${input}"`
    );
    return "Pending";
  }
};

export const deserializeDealStatus = (input?: string): dealStatus => {
  if (input && possibleDealStatus.includes(input)) {
    return input;
  } else {
    logger.error(`Deal deserializer: unhandled dealStatus value "${input}"`);
    return "New";
  }
};
