import { defineStore } from "pinia";
import logger from "@/services/loggerService";
import { t } from "@/plugins/i18n";
import * as listable from "./listableMixin";
import * as permissions from "./permissionMixin";
import { defaultSearchOptions } from "./listableMixin";
import { cloneDeep } from "lodash";
import { Stock } from "@/models/stock.interface";
import { getByAccountId, search } from "@/services/stockService";
import { useNotificationsStore } from "@/stores/notifications";
import { buildOption, SelectOption } from "@/models/selectOptions.interface";

export interface StocksState
  extends listable.ListableMixinState,
    permissions.PermissionsMixinState {
  items: Stock[];
}

function createStore<Id extends string>(id: Id) {
  return defineStore({
    id: id,
    state: (): StocksState => ({
      isLoading: false,
      searchOptions: cloneDeep(defaultSearchOptions),
      moduleName: "Stocks",
      items: [],
      searchError: t("stocksFetchError"),
      searchFnc: search,
    }),
    getters: {
      ...permissions.getters,
      stocks: (state): Stock[] => state.items,
      licensesProductOptions: (state): SelectOption<Stock>[] => {
        return state.items
          .filter((item) => {
            return item.isLicense;
          })
          .map((item) => {
            return buildOption<Stock>(item, "productId", "productName");
          });
      },
    },
    actions: {
      ...listable.actions,
      async get(accountId: string) {
        const notifications = useNotificationsStore();
        this.isLoading = true;
        try {
          this.items = await getByAccountId(accountId);
        } catch (err) {
          logger.error(err);
          notifications.error(t("stocksFetchError"));
        } finally {
          this.isLoading = false;
        }
      },
    },
  });
}

export const useStocksStore = createStore("stocks");
export const useStocksStoreAccount = createStore("stocksAccount");
