<template>
  <va-table
    :data-source="stocksStore.stocks"
    :table-config="tableConfig"
    :search-options="stocksStore.searchOptions"
    :loading="stocksStore.isLoading"
    class="w-100"
    @update="stocksStore.updateSearchOptions"
  >
    <template #bodyCell="{ column, text }">
      <template v-if="column.dataIndex === 'productName'">{{ text }}</template>
      <template v-if="column.dataIndex === 'productDescription'">
        {{ text }}
      </template>
      <template v-if="column.dataIndex === 'sku'">{{ text }}</template>
      <template v-if="column.dataIndex === 'quantity'">
        <span :class="{ 'text-red-600': text < 0 }">{{ text }}</span>
      </template>
    </template>
  </va-table>
</template>

<script setup lang="ts">
import { onBeforeMount } from "vue";
import { tableConfig } from "@/models/stock.interface";
import VaTable from "@/components/organisms/VaTable.vue";
import { useRoute } from "vue-router";
import { useStocksStoreAccount } from "@/stores/stocks";
import { onBeforeRouteUpdate } from "vue-router";

const stocksStore = useStocksStoreAccount();
const route = useRoute();

onBeforeRouteUpdate(async (to, from) => {
  if (to.params.id !== from.params.id) {
    stocksStore.setParentAccountId(to.params.id as string);
  }
});

onBeforeMount(() => {
  stocksStore.setParentAccountId(route.params.id as string, true);
  stocksStore.setPreSort(
    {
      columnKey: "sku",
      order: "ascend",
    },
    true
  );
});
</script>
