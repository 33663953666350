export const possibleflags = ["c", "cu", "r", "ra", "u", "d"];
// c-> create
// cu-> create user
// r -> read
// ra-> read all
// u -> update
// d -> delete
const possibleModules = [
  "Accounts",
  "Licenses",
  "Orders",
  "PriceLists",
  "Products",
  "Subscriptions",
  "Users",
];
export type permissionFlag = typeof possibleflags[number];
export type permissionModule = typeof possibleModules[number];

export interface Permission {
  module: permissionModule;
  flags: permissionFlag[];
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): Permission {
    const flags = input?.flags
      ? input.flags.split("-").filter((f: string) => {
          return possibleflags.includes(f);
        })
      : [];
    return {
      module: input?.module || "",
      flags: flags,
    };
  },
  serialize(input: Permission): unknown {
    return {
      module: input.module,
      flags: input.flags.join("-"),
    };
  },
};
