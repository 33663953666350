<template>
  <div class="flex justify-between">
    <va-page-title>{{ t("deals") }}</va-page-title>
    <div>
      <span>{{ t("onlyMyDeals") }}: </span>
      <a-switch
        v-model:checked="excludeChildren"
        @change="onIncludeChildrenChange"
      />
      <va-button
        class="ml-6"
        :disabled="!dealsStore.canCreate"
        @click="router.push('/deals/create')"
      >
        <i class="rm rm-ico-e-add" />
      </va-button>
    </div>
  </div>
  <va-table
    :data-source="dealsStore.items"
    :table-config="tableConfig"
    :search-options="dealsStore.searchOptions"
    :loading="dealsStore.isLoading"
    class="w-100"
    @update="dealsStore.updateSearchOptions"
  >
    <template #bodyCell="{ column, text, record }">
      <template v-if="column.dataIndex === 'code'">
        <router-link :to="`/deals/${record.id}`" class="text-indigo-600">
          {{ text }}
        </router-link></template
      >
      <template v-if="column.dataIndex === 'vendor'">
        {{ text.name }}
      </template>
      <template v-if="column.dataIndex === 'prospect'">
        {{ record.prospect.companyName }}
        <div class="text-xs text-gray-500">
          {{ formatAddress(record.prospect.address) }}
        </div>
      </template>
      <template v-if="column.dataIndex === 'prospectVat'">
        {{ record.prospect.address.vatId }}
      </template>
      <template v-if="column.dataIndex === 'creationDate'">
        {{ formatDate(record.creationDate) }}
      </template>
      <template v-if="column.dataIndex === 'status'">
        <span :class="statusColors(record.status)">{{ t(record.status) }}</span>
      </template>
      <template v-if="column.dataIndex === 'eBidStatus'">
        <span
          v-if="record.eBidStatus !== 'Unknown'"
          :class="statusColors(record.eBidStatus)"
        >
          {{ t(record.eBidStatus) }}
        </span>
        <span v-else>&nbsp;</span>
      </template>
      <template v-if="column.dataIndex === 'eBidValue'">
        <div v-if="record.eBidValue !== 0" class="content-end">
          {{ useFormatCurrency(record.eBidValue, record.currency) }}
        </div>
      </template>
      <template v-if="column.dataIndex === 'protectionStatus'">
        <span v-if="record.isExpired" class="text-red-600">
          {{ t("expired") }}
        </span>
        <span v-else :class="statusColors(record.protectionStatus)">
          {{ t(record.protectionStatus) }}
        </span>
      </template>
    </template>
  </va-table>
</template>

<script setup lang="ts">
import { ref } from "vue";
import VaTable from "@/components/organisms/VaTable.vue";
import { tableConfig } from "@/models/deal.interface";
import VaPageTitle from "@/components/molecules/VaPageTitle.vue";
import router from "@/router";
import { useI18n } from "vue-i18n";
import VaButton from "@/components/molecules/VaButton.vue";
import { useFormatAddressShort, useFormatDate } from "@/composables/formatters";
import { useStatusColors } from "@/composables/colors";
import { useFormatCurrency } from "@/composables/formatters";
import { useDealsStore } from "@/stores/deals";
import { defaultPagination } from "@/services/configService";
import { useMeta } from "vue-meta";

useMeta({
  title: "Deals",
});
const { t } = useI18n();
const dealsStore = useDealsStore();
const formatDate = useFormatDate;
const formatAddress = useFormatAddressShort;
const statusColors = useStatusColors;

const excludeChildren = ref(!dealsStore.searchOptions.includeChildren);

function onIncludeChildrenChange() {
  dealsStore.searchOptions.includeChildren = !excludeChildren.value;
  dealsStore.searchOptions.pagination = defaultPagination;
  dealsStore.updateSearchOptions(dealsStore.searchOptions);
}
</script>
