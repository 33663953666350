<template>
  <va-button
    v-if="license.product.environmentType == 2"
    class="mr-4"
    @click="openExportDialog"
  >
    <i class="rm rm-ico-file-export" />
  </va-button>

  <va-dialog v-model="exportDialog">
    <div>{{ t("exportLicenseOnPrem") }}</div>
    <div class="mt-4">
      <a-form
        class=""
        :model="formState"
        :validate-messages="validateMessages"
        @finish="exportLicense"
      >
        <a-form-item
          :name="['parentAccountId']"
          :label="t('owner')"
          :rules="[{ required: true }]"
        >
          <va-select
            v-model="formState.parentAccountId"
            :options="accountsStore.currentNetworkOptions"
            :placeholder="t('createAsChild')"
            @change="onChangeChild"
          >
            <template #option="option">
              <option-account :item="option.nestedProps.fullItem" />
            </template>
          </va-select>
        </a-form-item>
        <a-form-item
          :name="['onPremisesEnvironmentId']"
          :label="t('onPremEnv')"
          :rules="[{ required: true }]"
        >
          <va-select
            v-model="formState.onPremisesEnvironmentId"
            :options="onPremEnvStore.onPremEnvOptions"
            :placeholder="t('onPremEnv')"
          >
          </va-select>
        </a-form-item>
      </a-form>
    </div>

    <div class="flex justify-end mt-4">
      <va-button class="mr-4" @click="exportDialog = false">
        {{ t("dismiss") }}
      </va-button>
      <va-button @click="exportLicense">
        {{ t("save") }}
      </va-button>
    </div>
  </va-dialog>
</template>

<script setup lang="ts">
import { ref, PropType } from "vue";
import { t } from "@/plugins/i18n";
import VaButton from "@/components/molecules/VaButton.vue";
import VaDialog from "@/components/molecules/VaDialog.vue";
import { useLicensesStore } from "@/stores/licenses";
import VaSelect from "@/components/molecules/VaSelect.vue";
import OptionAccount from "@/components/molecules/selectOptions/OptionAccount.vue";
import { useAccountsStore } from "@/stores/accounts";
import { SelectOption } from "@/models/selectOptions.interface";
import { Account } from "@/models/account.interface";
import { useOnPremEnvironmentStore } from "@/stores/onPremEnvironments";
import { License, LicenseExportData } from "@/models/license.interface";
import { useValidationMessages } from "@/composables/validation";

const accountsStore = useAccountsStore();
const onPremEnvStore = useOnPremEnvironmentStore();
const licensesStore = useLicensesStore();
const validateMessages = useValidationMessages();

const props = defineProps({
  license: {
    type: Object as PropType<License>,
    required: true,
  },
  accountId: {
    type: String,
    required: true,
  },
});

const exportDialog = ref(false);

const formState = ref({
  parentAccountId: props.accountId || "",
  onPremisesEnvironmentId: "",
  serials: [
    {
      serialKey: props.license?.serialKey,
    },
  ],
} as LicenseExportData);

const openExportDialog = () => {
  const data = {
    parentAccountId: props.accountId,
    onPremisesEnvironmentId: props.license.exportOnPremiseEnvironmentId,
    serials: [
      {
        serialKey: props.license?.serialKey,
      },
    ],
  } as LicenseExportData;
  if (props.license.exportOnPremiseEnvironmentId) {
    licensesStore.exportToEnv(data);
  } else {
    onPremEnvStore.fetchEnvByParentId(props.accountId as string);
    formState.value = data;
    exportDialog.value = true;
  }
};

const onChangeChild = (item: SelectOption<Account>) => {
  //fetch products associated to child pricelist
  onPremEnvStore.fetchEnvByParentId(item.value as string);
  formState.value.onPremisesEnvironmentId = "";
};

const exportLicense = async () => {
  await licensesStore.exportToEnv(formState.value);
  exportDialog.value = false;
};
</script>
