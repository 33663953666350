export interface CertificateBadge {
  name: string;
  category: string;
  badgeColor: string;
  owners: string;
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function deserialize(input: any): CertificateBadge {
  return {
    name: input.name || "",
    category: input.category || "",
    badgeColor: input.badgeColor || "",
    owners: input.owners || "",
  };
}

export function serialize(input: CertificateBadge): unknown {
  return {
    name: input.name,
    category: input.category,
    badgeColor: input.badgeColor,
    owners: input.owners,
  };
}
