<template>
  <div class="va-card">
    <div class="flex justify-between pt-4 px-4">
      <h3 class="va-title-s">{{ t("userCertifications") }}</h3>
      <va-button
        v-if="certificatesStore.canCreate"
        @click="router.push(`/accounts/${accountId}/certifications/create`)"
      >
        <i class="rm rm-ico-e-add" />
      </va-button>
    </div>
    <va-table
      :data-source="certificatesStore.items"
      :table-config="tableConfig"
      :search-options="allItemsSearchOptions"
      :hide-pagination="true"
      :loading="certificatesStore.isLoading"
      class="w-100"
    >
      <template #bodyCell="{ text, column, record }">
        <template v-if="column.dataIndex === 'certificateName'">
          <router-link
            :to="`/accounts/${accountId}/certifications/${record.idUserCertificate}`"
            class="text-indigo-600"
          >
            {{ record.certificateName }}
          </router-link>
        </template>
        <template v-if="column.dataIndex === 'firstName'">
          {{ record.firstName }} {{ record.lastName }}
        </template>
        <template v-if="column.dataIndex === 'valid'">
          <div
            :class="`flex justify-center items-center text-${
              isExpired(record.expirationDate) ? 'red' : 'green'
            }-600`"
          >
            <i
              :class="
                isExpired(record.expirationDate)
                  ? 'rm rm-ico-i-remove text-red-600'
                  : 'rm rm-ico-check-single text-green-600'
              "
            />
          </div>
        </template>
        <template v-if="column.dataIndex === 'isGuest'">
          <div
            :class="`flex justify-center items-center text-${
              !text ? 'red' : 'green'
            }-600`"
          >
            <i
              :class="
                !text
                  ? 'rm rm-ico-i-remove text-red-600'
                  : 'rm rm-ico-check-single text-green-600'
              "
            />
          </div>
        </template>
        <!--        <template v-if="column.dataIndex === 'email'">-->
        <!--          {{ text }}-->
        <!--        </template>-->
        <template v-if="column.dataIndex === 'actions'">
          <va-delete-button
            :disabled="!certificatesStore.canDelete"
            class="mr-2"
            :confirm-text="t('certificateRemoveRemoveDescription')"
            :confirm-title="t('certificateRemove')"
            @click:confirm="
              certificatesStore.deleteCertification(
                record.idUserCertificate,
                'User'
              )
            "
          />
        </template>
      </template>
    </va-table>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, watch } from "vue";
import { useI18n } from "vue-i18n";
import VaButton from "@/components/molecules/VaButton.vue";
import router from "@/router";
import VaTable from "@/components/organisms/VaTable.vue";
import { tableConfig } from "@/models/certification.interface";
import VaDeleteButton from "@/components/molecules/VaDeleteButton.vue";
import { useCertificatesStore } from "@/stores/certificates";
import { allItemsSearchOptions } from "@/services/configService";

const { t } = useI18n();
const certificatesStore = useCertificatesStore();

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

watch(
  () => props.accountId,
  (value) => {
    certificatesStore.setParentAccountId(value);
  }
);

const isExpired = (expirationDate: string) =>
  new Date(expirationDate).getTime() < new Date().getTime();

onBeforeMount(() => {
  certificatesStore.setParentAccountId(props.accountId);
});
</script>
