<template>
  <div
    class="va-card relative dark:bg-black pt-5 px-4 pb-6 sm:pt-6 sm:px-6 mt-6"
  >
    <div class="flex place-content-between">
      <div class="grow mb-6">
        <div class="va-title">
          {{ t("licenses") }}
        </div>
      </div>
      <div class="text-right">
        <input
          id="includeChildren"
          v-model="query.includeChildren"
          aria-describedby="comments-description"
          type="checkbox"
          class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded mr-2"
          @change="statsStore.updateLicensesQuery(query)"
        />
        <label for="comments" class="text-sm text-gray-500">
          {{ t("includeChildren") }}
        </label>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-5 lg:grid-cols-2">
      <div v-if="stats" class="grid grid-cols-1 gap-5 sm:grid-cols-2">
        <va-data-badge
          :value="useFormatNumber(stats.totalCreated.current, true)"
          :subtitle="`${t('ofWhich')} ${stats.totalSeatActive.current} ${t(
            'used'
          )}`"
          :label="t('totalSeats')"
        />
        <va-data-badge
          :value="useFormatNumber(stats.totalActive.current, true)"
          :label="t('totalActive')"
        />
        <va-data-badge
          :value="
            useFormatNumber(stats.totalNearMaintenanceExpiration.current, true)
          "
          :label="t('totalNearMaintenanceExpiration')"
        />
        <va-data-badge
          :value="useFormatNumber(stats.totalExpiredMaintenance.current, true)"
          :label="t('expired')"
        />
      </div>
      <div>
        <vue3-chart-js
          v-if="!statsStore.licensesStatsLoading && chartConfig"
          :id="chartConfig.id"
          ref="chartRef"
          :type="chartConfig.type"
          :data="chartConfig.data"
          :options="chartConfig.options"
        />
        <div v-else class="flex justify-center my-8">
          <va-loader :loading="statsStore.licensesStatsLoading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import VaLoader from "@/components/atoms/VaLoader.vue";
import { useChartConfig } from "@/composables/charts";
import { useStatsStore } from "@/stores/stats";
import VaDataBadge from "@/components/molecules/VaDataBadge.vue";
import { useFormatNumber } from "@/composables/formatters";

const { t } = useI18n();
const statsStore = useStatsStore();
const chartRef = ref(null);

const query = ref(statsStore.query);

const stats = computed(() => statsStore.licensesStats);

const chartConfig = computed(() =>
  useChartConfig(
    stats?.value?.statisticsResponse,
    statsStore.query.showPrevious,
    "licenses"
  )
);
</script>
